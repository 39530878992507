import React from 'react';

const ColoredD = ({ color, children }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' ,paddingRight:'30px'}}>
      <div
        style={{
          backgroundColor: color,
          width: '10px',
          height: '10px',
          marginRight: '10px',
          borderRadius: '50%',
        }}
      />
      <p style={{ margin: '0' }}>{children}</p>
    </div>
  );
};

export default ColoredD;
