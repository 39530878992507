import axios from '../api/axios';


const useTickets = async() => {
   const token = sessionStorage.getItem('token')
    const  ticketUrl = '/api/tickets/user'


        try {
          const response = await axios.get(ticketUrl,{
            headers: {  Authorization: `Bearer ${token}`
        }

        });
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useTickets;