import axios from '../api/axios';

const token = sessionStorage.getItem('token');
const useAddsOn = async() => {
    const  addsOnUrl = '/api/products/ads-on'


        try {
          const response = await axios.get(addsOnUrl,{
            headers: {  Authorization: `Bearer ${token}`
        }

        });
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useAddsOn;