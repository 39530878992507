import { useState, useRef, useEffect } from 'react';
import { Form, Button, Alert, Container, InputGroup, FormControl, InputGroupText } from 'react-bootstrap';
import axios from '../../api/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, message } from 'antd';
import Loader from '../Loader';
import TextTyper from 'text-type-animation-effect-react'
import logo from '../images/logo.jpg'
import { twit } from '../Notificationpopout';
import LoaderButton from '../LoaderButton';
import usePost from '../../hooks/hooks/postData';
import { useParams } from 'react-router-dom';
import successGif from '../images/success.gif';
import successImage from '../images/success.jpg';
import { getNairobiTime } from '../../Utilities/utilities';



const PaymentPage = () => {


    const navigate = useNavigate()


    const [loading, setLoading] = useState(false);
    const [pauseGif, setPauseGif] = useState(false);
    const [showGif, setShowGif] = useState(false);
    const [paid, setPaid] = useState(false);


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const TransactionToken = searchParams.get('TransactionToken');
  const token = sessionStorage.getItem('token');
  const { id } = useParams();

  const postData = {token:TransactionToken}
  const paymentConfirmationUrl = id==1?`/api/orders/confirm`:id==2?`/api/events/tagpurchase/confirm`:`/api/billing/credits/purchase/complete`


  const verifyToken = async ()=>{
    try {
        setLoading(true);
        const response = await axios.post(paymentConfirmationUrl, JSON.stringify(postData), {
          headers: {
            'Content-Type': 'application/json', Authorization: `Bearer ${token}`
          }
    
        });
    
        if (response.status === 201) {
          setLoading(false);
          return Promise.resolve(response.data.message)
        }
    
    
      } catch (err) {
        setLoading(false);
        console.log("Payment Error:", err)
        return Promise.reject('An error occured while proccessing your payment.')
    
      }
  }
  
  
  










    useEffect(() => {

    verifyToken().then((message)=>{

        setShowGif(true);
        
        setTimeout(function () {
            setPauseGif(true);
            if(id==1){
                navigate('/human')
            }else if(id==2){
                navigate('/events/tagorders')
            }else{
                navigate('/billing/accounts')
            }
            
            twit('success',message)
        }, 1500);


    }).catch((message)=>{
        navigate('/human')
        twit('info',message)
    })


    }, []);







    if (loading) {
        return (<Loader />)
    } else {


        return (
            <Container className='main-verify-container'>
                <div className="back-wrapper1"  ></div>
                <div className="back-wrapper2"  ></div>
                <div className="back-wrapper3" ></div>
                <Modal closable={false} width={400} open={true}
                    footer={[


                    ]}
                    className="allmodals"
                >

                    <Container className='verify-container' style={{ display: "flex", flexDirection: "column" }}>
                        <img src={logo} className='email-verification-image' alt='logo' />


                        {showGif && (
                            <div style={{ paddingLeft: "25%", paddingRight: "25%", marginTop: "25%" }} >
                                <img src={pauseGif ? successImage : successGif} alt='gif' className='email-verification-image' />
                            </div>
                        )}

                      
                        <div className='footer'>
                            <p className="text-muted" style={{ fontSize: "8px", marginTop: "auto" }}>
                                Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a style={{ color: "green" }} href="https://pinnisoft.com/">PinniSOFT</a> <br />
                                All rights reserved.
                            </p>
                        </div>


                    </Container>
                </Modal>

            </Container>



        );
    }
}

export default PaymentPage;
