import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"

const Missing = () => {
    return (
        <Container>
            <article style={{ padding: "100px" }}>
                <h1>Oops!</h1>
                <p>Page Not Found</p>
                <div className="flexGrow">
                    <Link to="/">Visit Our Homepage</Link>
                </div>
            </article>

        </Container>

    )
}

export default Missing
