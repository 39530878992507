import React from 'react';

// const Pagination = ({ postsPerPage, totalPosts, paginate,currentPage}) => {
//   const pageNumbers = [];

//   for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
//     pageNumbers.push(i);
//   }
  
//   return (
//     <nav>
//       <ul className='pagination'>
//         {pageNumbers.map(number => (
//           <li key={number} className='page-item'>
//             <a style={{color:number==currentPage? 'green':'blue', fontWeight:number==currentPage? 'bold':'light',fontSize:number==currentPage? '14px':'12px'}} onClick={() => 
//               paginate(number)} href='#' className='page-link pagination'>
//               {number}
            
//             </a>
//           </li>
//         ))}
//       </ul>
//     </nav>
//   );
// };




const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(totalPosts / postsPerPage)) {
      paginate(currentPage + 1);
    }
  };

  const visiblePages = Math.min(5, pageNumbers.length); // Number of visible pages
  const startIndex = Math.max(Math.min(currentPage - Math.floor(visiblePages / 2), pageNumbers.length - visiblePages), 1); // Starting index for displayed page numbers

  return (
    <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
      <nav>
      <ul className='pagination'>
        <li className='page-item'>
          <button
            className='page-link pagination'
            disabled={currentPage === 1}
            onClick={handlePrevious}
            style={{color:'green'}}
          >
            {'<'}Prev
          </button>
        </li>
        {pageNumbers.slice(startIndex - 1, startIndex + visiblePages - 1).map((number) => (
          <li key={number} className='page-item'>
            <a
              style={{
                color: number === currentPage ? 'green' : 'blue',
                fontWeight: number === currentPage ? 'bold' : 'light',
                fontSize: number === currentPage ? '14px' : '12px',
              }}
              onClick={() => paginate(number)}
              href='#'
              className='page-link pagination'
            >
              {number}
            </a>
          </li>
        ))}
        <li className='page-item'>
          <button
            className='page-link pagination'
            disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
            onClick={handleNext}
            style={{color:'green'}}
          >
            Next{'>'}
          </button>
        </li>
      </ul>
    </nav>
    </div>
    
  );
};




export default Pagination;
