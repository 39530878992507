import React, { useEffect, useState } from 'react'
import { Container, Card, Col, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Modal from 'antd/es/modal/Modal';
import { getData, patchData } from '../../Utilities/utilities';
import useFetch from '../../hooks/hooks/getData';
import { twit } from '../Notificationpopout';
import ButtonLoader from '../ButtonSpinner';
import * as GiIcons from 'react-icons/gi';
import * as MdIcons from 'react-icons/md';
import Invoice from '../Invoice';
import { downloadPDF } from '../../Utilities/utilities';
import Loader from '../Loader';
import LoaderButton from '../LoaderButton';
import TextTyper from 'text-type-animation-effect-react';
import useDoctypes from '../../hooks/getDocTypes';
import PhoneInput from 'react-phone-input-2';
import usePost from '../../hooks/hooks/postData';
import { validateEmail } from '../../Utilities/emailVerification';
import PaymentCard from '../PhonePaymentCard';



const CardOperations = () => {

  const [upgrade, setUpgrade] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [currentCardtype, setCurrentCardtype] = useState("");
  const [newCardtype, setNewCardtype] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [cardTypes, setCardTypes] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [selectedDoctype, setSelectedDoctype] = useState("Select Government Document.");
  const [useDetails, setUseDetails] = useState(false);
  const [useCardNo, setUseCardNo] = useState(false);
  const [doctypedata, setDoctypdata] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [doctype, setDoctype] = useState('');
  const [email, setEmail] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [step1, setStep1] = useState(true);
  const [showTransferInvoice, setShowTransferInvoice] = useState(false);
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [printable, setPrintable] = useState(false);

  const cardTypesData = useFetch("/api/products/card/types", () => { });



  const navigate = useNavigate();

  const { post } = usePost("/api/products/transfer/card", setButtonLoading);
  const transferPost = usePost(`/api/products/transfer/card/${cardNumber}`, setButtonLoading);


  //get user data for provided card number 

  const getUserData = async (id) => {

    if (id === "" || !id) {
      twit("info", "Invalid card number.");
    } else {
      const response = getData(`/api/products/card/${id}`, setButtonLoading);

      response?.then((data) => {

        if (data?.data) {

          setCurrentCardtype(data?.data?.data?.data?.CardType.ctype);
        } else if (data?.error) {

          if (data?.error?.response?.request?.status === 500) {
            twit("error", "Invalid card number.");
          } else {
            twit("error", "Network error.");
          }

        }
      })

    }




  }

  //function to verify new card type

  const verifyNewcardtype = () => {

    if (currentCardtype === "PinniSEED") {

      const filtered = cardTypesData?.data?.data?.filter((data) => {
        return (
          data?.id === "04391120-F32E-49C6-BFB8-18534B6D4D03" ||//PinniSPROUT
          data?.id === "0B784BE1-8DF3-434B-B17B-07FB52F963DE" ||//PinniBUD
          data?.id === "A496C38B-03B5-48A0-ABE6-E18767D3BAF4" ||//PinniBlossom
          data?.id === "BAF30514-36E0-4FA3-B3A4-76FAB14806C6" ||//PinniPOLLEN
          data?.id === "B537121C-39C2-46A5-9466-FD7E50E4FAAD"//enterprise
        )
      })



      return filtered;


    } else if (currentCardtype === "PinniSPROUT") {

      const filtered = cardTypesData?.data?.data?.filter((data) => {
        return (
          data?.id === "0B784BE1-8DF3-434B-B17B-07FB52F963DE" ||//PinniBUD
          data?.id === "A496C38B-03B5-48A0-ABE6-E18767D3BAF4" ||//PinniBlossom
          data?.id === "BAF30514-36E0-4FA3-B3A4-76FAB14806C6" ||//PinniPOLLEN
          data?.id === "B537121C-39C2-46A5-9466-FD7E50E4FAAD"//enterprise
        )
      })

      return filtered;








    } else if (currentCardtype === "PinniBUD") {


      const filtered = cardTypesData?.data?.data?.filter((data) => {
        return (
          data?.id === "A496C38B-03B5-48A0-ABE6-E18767D3BAF4" ||//PinniBlossom
          data?.id === "BAF30514-36E0-4FA3-B3A4-76FAB14806C6" ||//PinniPOLLEN
          data?.id === "B537121C-39C2-46A5-9466-FD7E50E4FAAD"//enterprise
        )
      })

      setCardTypes(filtered);







    } else if (currentCardtype === "PinniBlossom") {


      const filtered = cardTypesData?.data?.data?.filter((data) => {
        return (
          data?.id === "BAF30514-36E0-4FA3-B3A4-76FAB14806C6" ||//PinniPOLLEN
          data?.id === "B537121C-39C2-46A5-9466-FD7E50E4FAAD"//enterprise
        )
      })

      return filtered;





    } else if (currentCardtype === "PinniPOLLEN") {


      const filtered = cardTypesData?.data?.data?.filter((data) => {
        return (
          data?.id === "B537121C-39C2-46A5-9466-FD7E50E4FAAD"//enterprise
        )
      })

      return filtered;




    } else if (currentCardtype === "enterprise") {
      return [];
    } else {
      return [];
    }
  }


  const doctypes = useDoctypes();

  useEffect(() => {
    doctypes.then((data) => {
      setDoctypdata(data);
    });


  }, []);










  //handle upgrade

  const handleUpgrade = (value) => {
    if (newCardtype !== "") {
      setUpgrade(false);
      setShowInvoice(true);
    } else {
      twit("info", "Please select a new card type.");
    }


  }


  // handling transfers

  const transferBydetailsPayload = { phone: phoneNumber, email: email, docType: doctype, docno: docNumber }

  const HandleDetailsTransfer = () => {
    if (phoneNumber?.length < 12) {
      twit('info', "Please enter a valid phone number.");
    } else if (!validateEmail(email)) {
      twit('info', "Please enter a valid email.");
    } else if (doctype === '') {
      twit('info', "Please select a document type.");
    } else if (docNumber === '') {
      twit('info', "Please enter the document number.");
    } else {
      const response = post(transferBydetailsPayload);
      response.then((data) => {

        if (data?.type === "data") {
          // setCardNumber(data?.data?.hid)
          // setShowTransferInvoice(true);
          setTransfer(false);
          twit("success", "Transfer initiated.");
        } else {


          if (data?.error?.response?.data?.data) {
            twit("info", data?.error?.response?.data?.data);

          } else {
            twit("info", 'Network error.');
          }



        }
      })
    }

  }

  const HandleCardNoTransfer = () => {
    if (cardNumber === '') {
      twit("info", "Please enter the card number.")
    } else {
      const response = transferPost.post({});
      response.then((data) => {

        if (data?.type === "data") {
          setTransfer(false);
          //setShowTransferInvoice(true);
          twit("success", "Transfer inititiated.");
        } else {
          if (data?.error?.response?.data?.data) {
            twit("info", data?.error?.response?.data?.data);
          } else {
            twit("info", 'Network error.');
          }

        }
      })
    }

  }






  if (loading) {
    return (<Loader />);
  } else {



    return (
      <Container className='card-operations-container'>

        {showPaymentCard && <Modal open={true} onCancel={() => {
          setShowPaymentCard();
        }}
          footer={[


          ]}
          style={{ maxWidth: "400px" }}
          closable={false}
          className='allmodals'

        >

          <PaymentCard orderId={cardNumber} close={setShowPaymentCard} />












        </Modal>}


        {showInvoice && <Modal open={true} onCancel={() => { setShowInvoice(false) }}
          footer={[


          ]}
          className='allmodals'
        >
          <div id="invoice">
            <Invoice

              items={[{name:'Card Upgrade',qty:1,unitPrice:1000,amount:1000}]}
              invoiceNumber={cardNumber}
              quantity={1}
              price={50000}
            />

          </div>


          <div>
            <Button

              title='Pay for the items'
              onClick={() => {
                setPrintable(true);
                downloadPDF("invoice");


              }}

              className='identity-form-button ' variant="success" type="button">

              <span style={{ fontWeight: "bold" }}>Download </span>
            </Button>

            <Button

              title='Pay for the items'
              onClick={() => {

                setShowInvoice(false);
                setShowPaymentCard(true);
              }}

              className='identity-form-button ' variant="success" type="button">

              <span style={{ fontWeight: "bold" }}>Pay and Upgrade </span>
            </Button>


          </div>








        </Modal>}



        {showTransferInvoice && <Modal open={true} onCancel={() => { setShowTransferInvoice(false) }}
          footer={[


          ]}
          className='allmodals'
        >
          <div id="invoice">
            <Invoice

              items={[{ name: "Data transfer.", unitPrice: 5000, quantity: 1 }]}
              invoiceNumber={cardNumber}
              printable={printable}
            />

          </div>


          <div>
            <Button

              title='Pay for the items'
              onClick={() => {
                setPrintable(true);
                downloadPDF("invoice", setPrintable);


              }}

              className='identity-form-button ' variant="success" type="button">

              <span style={{ fontWeight: "bold" }}>Download </span>
            </Button>

            <Button

              title='Pay for the items'
              onClick={() => {

                setShowTransferInvoice(false);
                setShowPaymentCard(true);

              }}

              className='identity-form-button ' variant="success" type="button">

              <span style={{ fontWeight: "bold" }}>Pay and Transfer </span>
            </Button>


          </div>








        </Modal>}


        {upgrade && <Modal open={true} title={'Card Upgrade'} onCancel={() => {
          setUpgrade(false);
        }}
          footer={[


          ]}
          className='allmodals'
        >

          <div>
            <Form.Group controlId="formBasicLocation">
              <Form.Label id="cardNo" style={{ marginBottom: "0px" }}>Card No.</Form.Label>
              <Form.Control

                value={cardNumber}
                onChange={(e) => {
                  setCardNumber(e.target.value);
                  setCurrentCardtype(false);
                }}

                className=' input-box-identity form-control-sm' type="text" id="cardNo" placeholder=" Card number" />
            </Form.Group>



            {currentCardtype && (
              <div>
                <Form.Group style={{ marginBottom: "14px" }} >
                  <Form.Label style={{ marginBottom: "0px" }}>

                    Current Card Type.
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    value={currentCardtype}

                    className=' input-box-identity form-control-sm' type="text" name="currentCardT" />


                </Form.Group>
                <Form.Group style={{ marginBottom: "14px" }} >
                  <Form.Label style={{ marginBottom: "0px" }}>
                    New Card Type.
                  </Form.Label>

                  <Form.Select


                    onChange={(e) => {
                      setNewCardtype(e.target.value);
                    }}

                    className='form-selector'>
                    <option key={1} value={""}>Select new card type.</option>
                    {verifyNewcardtype().map((data) => {
                      return <option className='input-box' key={data.id} value={data.id}>{data.ctype}</option>
                    })}

                  </Form.Select>

                </Form.Group>
              </div>

            )}

            <div>
              {!currentCardtype && (
                <div>

                  <LoaderButton onClick={() => {
                    getUserData(cardNumber);
                  }} loading={buttonLoading}
                    variant={"primary"}
                    label={buttonLoading ? "loading" : "Continue"}
                    style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                  />

                </div>

              )}
              {currentCardtype && (
                <Button
                  onClick={() => {
                    handleUpgrade(newCardtype);

                  }}
                  className='identity-form-button' variant="success" type="button">
                  Upgrade
                </Button>
              )}

            </div>


          </div>



        </Modal>}

        {transfer && <Modal open={true} title={'Transfers'} onCancel={() => {
          setTransfer(false);
          setUseDetails(false);
          setUseCardNo(false);
          setStep1(true);
        }}
          footer={[


          ]}
          className='allmodals'
        >
          <div>

            {step1 && (
              <div style={{ textAlign: "center" }}>
                <Form.Label className="mt-3" >
                  <TextTyper text={"Have an active card number ?"} interval={50} Markup={"p"} />
                </Form.Label>

                <div style={{ width: "100%" }}>

                  <LoaderButton onClick={() => {
                    setStep1(false);
                    setUseCardNo(true);

                  }} loading={false}
                    variant={"primary"}
                    label="Yes"
                    style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", width: "100px" }}
                    className={"mx-2"}
                  />
                  <LoaderButton onClick={() => {
                    setUseDetails(true);
                    setUseCardNo(false);
                    setStep1(false);

                  }} loading={false}
                    variant={"primary"}
                    label="No"
                    style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", width: "100px" }}
                    className={"mx-2"}
                  />
                </div>
              </div>

            )}

            {useCardNo && (
              <div>
                <Form.Label className="mt-3" >
                  <TextTyper text={"Please enter the active card number below..."} interval={50} Markup={"p"} />
                </Form.Label>

                <Form.Group controlId="CardNo">
                  <Form.Label id="cardNo" style={{ marginBottom: "0px" }}>Card No.</Form.Label>
                  <Form.Control

                    value={cardNumber}
                    onChange={(e) => {
                      setCardNumber(e.target.value);

                    }}

                    className=' input-box-identity form-control-sm' name='cardNo' type="text" id="cardNo" placeholder=" Card number" />
                </Form.Group>
                <LoaderButton onClick={() => {
                  HandleCardNoTransfer();
                }} loading={buttonLoading}
                  variant={"primary"}
                  label={buttonLoading ? "loading" : "Request transfer"}
                  style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                />
              </div>

            )}

            {useDetails && (
              <div>
                <Form.Label className="mt-3" style={{ textAlign: "center" }}>
                  <TextTyper text={"Please enter the following details to proceed..."} interval={50} Markup={"p"} />
                </Form.Label>
                <Form.Group style={{ marginBottom: "14px" }} controlId="phoneNumber">

                  <Form.Label>Phone Number<span style={{ color: "red" }}>*</span></Form.Label>
                  <PhoneInput
                    country={'ug'}
                    value={phoneNumber}
                    localization={{ ug: 'Uganda' }}
                    onChange={(e) => {

                      setPhoneNumber(e);
                    }}

                    disableDropdown
                    countryCodeEditable={false}
                    masks={{ ug: '... ... ...' }}



                    inputProps={{
                      name: 'phone',
                      required: true,

                    }}


                    inputStyle={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px"
                    }}

                  />

                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label style={{ marginBottom: "0px" }}> Email<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control

                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}

                    className=' input-box-identity form-control-sm' type="email" name="email" placeholder="Enter email" />
                </Form.Group>
                <Form.Group style={{ marginBottom: "14px" }}>
                  <Form.Label style={{ marginBottom: "0px" }}> Recognized Government Document <span style={{ color: "red" }}>*</span>  </Form.Label>
                  <Form.Select

                    onChange={(e) => {
                      setDoctype(e.target.value);
                      const selected = doctypedata?.filter((data) => {
                        return data.gid === e.target.value;
                      })
          
                      setSelectedDoctype(selected[0]?.dname);
                    }}

                    className='form-selector'>
                    <option key={1} value={doctype}>{selectedDoctype} </option>
                    {doctypedata?.map((data) => {
                      return <option className='input-box' key={data.gid} value={data.gid}>{data.dname}</option>
                    })}


                  </Form.Select>
                </Form.Group>



                <Form.Group controlId="document number">
                  <Form.Label style={{ marginBottom: "0px" }}> Document Number <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    required
                    value={docNumber}
                    onChange={(e) => {
                      setDocNumber(e.target.value);
                    }}

                    className=' input-box-identity form-control-sm' type="text" name="docNumber" placeholder="Enter document number" />
                </Form.Group>
                <LoaderButton onClick={() => {
                  HandleDetailsTransfer();
                }} loading={buttonLoading}
                  variant={"primary"}
                  label={buttonLoading ? "loading" : "Request transfer"}
                  style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}

                />
              </div>
            )}







          </div>




        </Modal>}

















        <Col>
          <div className='card-operations-row'>
            <Card className='card-operations-card' onClick={() => {
              navigate("/carddesign");
            }}>

              <Card.Body style={{ textAlign: "center" }} >
                <div>
                  <MdIcons.MdDesignServices style={{ height: "30px", width: "30px" }} />
                </div>

                <Card.Text>
                  Design
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className='card-operations-card'
              onClick={() => {
                setUpgrade(true);
              }}


            >

              <Card.Body style={{ textAlign: "center" }}>
                <div>
                  <GiIcons.GiUpgrade style={{ height: "30px", width: "30px" }} />
                </div>

                <Card.Text>

                  Upgrades
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className='card-operations-card' onClick={() => {
              setTransfer(true);
            }}>

              <Card.Body style={{ textAlign: "center" }}>
                <div>
                  <MdIcons.MdTransferWithinAStation style={{ height: "30px", width: "30px" }} />
                </div>

                <Card.Text>
                  Transfers
                </Card.Text>
              </Card.Body>
            </Card>


          </div>





        </Col>
      </Container>
    )
  }
}

export default CardOperations;
