import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card, Table, InputGroup, ListGroup, Collapse } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Button, Divider, Drawer, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as MdIcons from 'react-icons/md';
import { fetchData } from '../../Utilities/apiRequests';
import { formatPrice, getNairobiTime, getNairobiTimeWithParam, getNumberOnly, getformattedDate, removeSpacesAndLowerCase } from '../../Utilities/utilities';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import * as GrIcon from 'react-icons/gr';
import useGeneralState from '../../hooks/hooks/generalState';
import Pagination from '../Pagination';
import ImageCropper from '../ImageCropper';
import ImagePreview from '../ImagePreview';
import { apiBaseUrl, fileUploadBaseUrl } from '../../Utilities/globals';
import BatchesPreview from '../BatchesSearch';
import ContainedLoader from '../ContainedLoader';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Rectangle, PieChart, Pie, Cell, ResponsiveContainer, } from 'recharts';
import ColorCube from '../ColorCube';
import TicketsPreview from '../TicketSearch';
import BatchesPreviewSellers from '../BatchesSearchTicketSeller';
import TicketSellerSearch from '../EventTicketSellerSearch';
import { FaFileImport } from 'react-icons/fa';
import TicketsPreviewSeller from '../TicketSearchSeller';
import VendorTransactionSearch from '../VendorTransactionSearch';




const EventVendorManagement = () => {

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loadingBatches, setLoadingBatches] = useState(false);
    const [loadingBatchStatistics, setLoadingBatchStatistics] = useState(false);
    const [loadingVendorStatistics, setLoadingVendorStatistics] = useState(false);
    const [loadingVendorCollections, setLoadingVendorCollections] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [showVendorBatches, setShowVendorBatches] = useState(false);
    const [selectedVendorId, setSelectedVendorId] = useState('');
    const [eventInfo, setEventInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [vendorBatches, setVendorBatches] = useState([]);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [batchData, setBatchData] = useState();
    const [showBatchDetails, setShowBatchDetails] = useState(false);
    const [batchStatistics, setbatchStatistics] = useState({ sales: [], entry: [] });
    const [selectedVendorName, setSelectedVendorName] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [onSmallScreen, setOnSmallScreen] = useState(false);
    const [viewTickets, setViewTickets] = useState(false);
    const [showVendorSummary, setShowVendorSummary] = useState(false);
    const [showVendorImport, setShowVendorImport] = useState(false);
    const [ticketVendors, setTicketVendors] = useState([]);
    const [ticketVendorStatsData, setTicketVendorStatsData] = useState([]);
    const [ticketVendorStats, setTicketVendorStats] = useState({});
    const [comissionPerTicket, setComissionPerTicket] = useState(0);
    const [updateVendorDetails, setUpdateVendorDetails] = useState();
    const [isActive, setIsActive] = useState(false);
    const [vendorRowData, setVendorRowData] = useState();
    const [viewTransactions, setViewTransactions] = useState(false);
    const [vendorCollectionsData, setVendorCollectionsData] = useState(false);

    const { eid } = useParams();



    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    useEffect(() => {
        setOnSmallScreen((screenWidth < 770))
    }, [screenWidth]);




    useEffect(() => {

        fetchData(`/api/events/vendor/event/${eid}`, setLoading).then((response) => {

            setVendors(response.data.vendors)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading vendors.')
        })



        fetchData(`/api/events/details/${eid}`, setLoading1).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })


        fetchData(`/api/events/vendor/user`, setLoading2).then((response) => {
            setTicketVendors(response.data.vendors)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading vendors.')
        })



    }, [refresh])




    useEffect(() => {

        if (selectedVendorId) {
            fetchData(`/api/events/vendor/batches/list/${selectedVendorId}/${eid}`, setLoadingBatches).then((response) => {
                setVendorBatches(response.data.batches?.map((data) => data.Batch))
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Vendor batches.')
            })
        }

    }, [selectedVendorId, refresh])



    useEffect(() => {

        if (selectedVendorId) {
            fetchData(`/api/events/vendor/batches/stats/${selectedVendorId}/${eid}`, setLoadingVendorStatistics).then((response) => {
                setTicketVendorStatsData(response.data)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Vendor stats.')
            })

            fetchData(`/api/events/vendor/stellify/statistics/${eid}/${vendorRowData?.Vendor?.email}`, setLoadingVendorCollections).then((response) => {
                setVendorCollectionsData(response.data)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Vendor stats.')
            })


        }



    }, [selectedVendorId, refresh])



    useEffect(() => {



        if (selectedBatchId) {
            fetchData(`/api/events/batchManagement/batches/statistics/${selectedBatchId}`, setLoadingBatchStatistics).then((response) => {
                setBatchData(response.data)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Batch Data.')
            })
        }



    }, [selectedBatchId])




    useEffect(() => {



        if (batchData) {
            const salesData = [

                {
                    "name": "Sold",
                    "value": batchData.sold,
                    "color": 'gold'
                },
                {
                    "name": "Not Sold",
                    "value": Number(batchData.total) - Number(batchData.sold),
                    "color": 'gray'
                },
            ];

            const entryData = [

                {
                    "name": "Entered",
                    "value": batchData.entered,
                    "color": 'green'
                },

                {
                    "name": "Not Entered",
                    "value": Number(batchData.total) - Number(batchData.entered),
                    "color": 'gray'
                }
            ];


            const statusData = [

                {
                    "name": "Active",
                    "value": batchData.active,
                    "color": '#89CFF0'
                },

                {
                    "name": "Inactive",
                    "value": Number(batchData.total) - Number(batchData.active),
                    "color": 'gray'
                }
            ];


            setbatchStatistics((prevBatchStatistics) => ({
                ...prevBatchStatistics,
                sales: salesData,
                entry: entryData,
                status: statusData
            }));

        }



    }, [batchData])

    useEffect(() => {



        if (ticketVendorStatsData) {
            const salesData = [

                {
                    "name": "Sold",
                    "value": ticketVendorStatsData.sold,
                    "color": 'gold'
                },
                {
                    "name": "Not Sold",
                    "value": Number(ticketVendorStatsData.total) - Number(ticketVendorStatsData.sold),
                    "color": 'gray'
                },
            ];

            const entryData = [

                {
                    "name": "Entered",
                    "value": ticketVendorStatsData.entered,
                    "color": 'green'
                },

                {
                    "name": "Not Entered",
                    "value": Number(ticketVendorStatsData.total) - Number(ticketVendorStatsData.entered),
                    "color": 'gray'
                }
            ];


            const statusData = [

                {
                    "name": "Active",
                    "value": ticketVendorStatsData.active,
                    "color": '#89CFF0'
                },

                {
                    "name": "Inactive",
                    "value": Number(ticketVendorStatsData.total) - Number(ticketVendorStatsData.active),
                    "color": 'gray'
                }
            ];


            setTicketVendorStats((prevBatchStatistics) => ({
                ...prevBatchStatistics,
                sales: salesData,
                entry: entryData,
                status: statusData
            }));

        }



    }, [ticketVendorStatsData])


    const handleViewBatchDetails = (batch) => {

        setShowBatchDetails(true)
        setSelectedBatchId(batch.bid)
        setSelectedBatch(batch)
    }



    const handleViewBatchDetailsClose = () => {

        setShowBatchDetails(false)
        setSelectedBatchId('')
        setSelectedBatch()
        setSelectedVendorId()
        setViewTickets(false)

    }


    const handleViewVendorSummaryClose = () => {

        setShowVendorSummary(false)
        setSelectedVendorId()

    }

    const tableStyles = {
        backgroundColor: '#f8f9fa', // Light gray background
        fontSize: '10px',
    };

    const headerStyles = {
        backgroundColor: '#343a40',
        color: '#fff',
    };

    const rowStyles = {
        height: '1px',
        padding: '0px',
        margin: '0px'
    };

    const rowCellStyles = {
        paddingTop: '2px',
        paddingBottom: '2px'
    }



    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedVendors = vendors?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }


    const COLORS = [
        '#0088FE',  // Blue (base)
        '#00C49F',  // Teal (base)
        '#FFBB28',  // Orange (base)
        '#FF8042',  // Dark Orange (base)
        '#33aaff',  // Lighter Blue (lightened from #0088FE)
        '#ffde5a',  // Yellow-Orange (analogous to #FFBB28)
        '#00ceff',  // Turquoise (triadic to #FF8042)

        '#A6CEE3',  // Light Blue (ColorBrewer2)
        '#1F77B4',  // Medium Blue (ColorBrewer2)
        '#FF7F0E',  // Bright Orange (Tableau)
        '#539E0B',  // Dark Green (Tableau)
        '#9467BD',  // Purple (accessible)

        // Shades of gray for emphasis (ensure sufficient contrast)
        // '#cccccc',
        // '#e0e0e0',
        // '#f2f2f2',

        // Additional contrasting colors (test for accessibility)
        '#f00',    // Red
        '#0f0',    // Green
        '#00f',    // Blue (darker)
        '#ffc0cb', // Light Pink
    ];


    const CustomTooltipRegistration = ({ active, payload, label }) => {

        const alldata = payload[0]
        const salesData = alldata?.payload
        const textColor = salesData?.fill


        if (active && payload && payload.length) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', backgroundColor: 'white', padding: '5px', border: '1px solid gray' }}>

                    <p className='mt-0 mb-1' style={{ color: `black`, fontWeight: 'bold', fontSize: '13px' }} >{salesData?.name}</p>
                    <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />
                    <p className='my-0' style={{ color: textColor, fontWeight: 'bolder', fontSize: '11px' }} ><span style={{ color: 'gray' }}> Total:</span> {salesData?.value}</p>

                </div>
            );
        }

        return null;
    };

    const importTicketVendors = async (cvids) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            cvids,
            comissionPerTicket,
            eid
        };

        const Url = '/api/events/vendor/assign'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Vendors Imported successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log(" Vendor Import Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while importing vendors.')

        }
    };


    const handleVendorImportation = (selectedVendorsData) => {
        const cvids = selectedVendorsData.map((data) => {
            return data.id
        })

        if (cvids?.length > 0) {
            importTicketVendors(cvids).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setShowVendorImport(false)
                setComissionPerTicket(0)
            }).catch((error) => {
                setShowVendorImport(false)
                twit('info', error, 4)
            })
        } else {
            twit('info', 'Please select atleast one vendor to import.', 4)
        }

    }


    const updateVendorEventDetails = async (setLoading) => {
        const token = sessionStorage.getItem('token');


        const postData = {
            cvid: vendorRowData.id,
            comissionPerTicket,
            eid,
            status: isActive
        };

        const Url = '/api/events/vendor/details/update'
        try {
            setLoading(true);
            const response = await axios.patch(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Vendor details updated successfully.')

            }


        } catch (err) {
            setLoading(false);
            console.log("Vendor details update Error:", err.response?.message)
            return Promise.reject(err.response?.data?.message ? err.response?.data?.message : 'An error occured while updating vendor details.')

        }
    };

    const handleVendorSubmitUpdate = (e) => {
        e.preventDefault();

        updateVendorEventDetails(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            setUpdateVendorDetails(false)
            setComissionPerTicket(0)
            setIsActive(false)
        }).catch((error) => {
            twit('info', error)
        })

    };



    if (loading || loading1) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>

                <div className='mt-2' style={{ width: '100%', textAlign: 'end' }}>
                    <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={() => {
                        setShowVendorImport(true)
                    }} >
                        <FaFileImport className='mx-1' style={{ width: '15px', height: '15px' }} /> | Import Vendors
                    </Button>
                </div>


                {updateVendorDetails && <Modal open={true} title={'Vendor Management'} onCancel={() => {


                    setUpdateVendorDetails(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >



                        <Form onSubmit={handleVendorSubmitUpdate}>

                            <Card>
                                <Card.Body >

                                    <Row>


                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="commission-per-ticket">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>commission Per Ticket<span style={{ color: "red" }}> *</span></p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="number"
                                                        required
                                                        placeholder="Enter commission"
                                                        value={comissionPerTicket}
                                                        onChange={(e) => {
                                                            setComissionPerTicket(e.target.value)
                                                        }}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>



                                        <Col className='my-1' md={12} >

                                            <Form.Group className='my-2' controlId="vendorActivity">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                    <div className='d-flex flex-row   w-100'>



                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Is Active"
                                                            name="active"
                                                            checked={isActive}
                                                            onChange={(e) => {
                                                                setIsActive(!isActive)
                                                            }}

                                                            className="my-0 mx-1 input-box-events form-control-sm"
                                                        />




                                                    </div>
                                                </div>


                                            </Form.Group>

                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GrIcon.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Vendor Details
                                </Button>
                            </div>
                        </Form>



                    </div>


                </Modal>}




                {showVendorBatches && (
                    <>

                        <Drawer title="Vendor Batches" placement="right"
                            onClose={

                                () => {
                                    setShowVendorBatches(false)
                                }

                            } open={true}>
                            {
                                loadingBatches ?
                                    <ContainedLoader />
                                    :
                                    <BatchesPreviewSellers batches={vendorBatches} batchId={selectedBatchId} handleBatchView={handleViewBatchDetails} SellerName={selectedVendorName} />
                            }

                        </Drawer>

                    </>


                )}


                {showBatchDetails && <Modal open={true} title={'Batch Summary'} onCancel={() => {

                    handleViewBatchDetailsClose()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {loadingBatchStatistics ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>

                                            <div className='my-2' style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={`${apiBaseUrl}/generalFiles/${removeSpacesAndLowerCase(selectedBatch?.TagColor.name)}.jpg`} alt={'product-color'} style={{ width: '70px', height: '70px', borderRadius: '20%', marginRight: '10px' }} />
                                                <span>{selectedBatch?.DocumentTag.tagName}</span>
                                            </div>



                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                <Table striped bordered hover style={tableStyles}>
                                                    <thead>
                                                        <tr style={headerStyles}>
                                                            <th style={{ textAlign: "left", ...rowCellStyles }}>Property</th>
                                                            <th style={{ textAlign: "left", ...rowCellStyles }}> Value </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr style={{ ...rowStyles }}>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>Zone </td>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>{selectedBatch?.EventZone.name}</td>
                                                        </tr>

                                                        <tr style={{ ...rowStyles }}>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>Status</td>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>{!(selectedBatch?.isActive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                                        </tr>
                                                        <tr style={{ ...rowStyles }}>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>Price</td>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>UGX {formatPrice(selectedBatch?.EventZone.cost)}</td>
                                                        </tr>
                                                        <tr style={{ ...rowStyles }}>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>Tickets In Batch</td>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>{formatPrice(batchData?.tickets?.length)}</td>
                                                        </tr>
                                                        <tr style={{ ...rowStyles }}>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>Color</td>
                                                            <td style={{ textAlign: "left", ...rowCellStyles }}>{selectedBatch?.TagColor.name}</td>
                                                        </tr>

                                                        {(selectedBatch?.isActive) ?
                                                            <>

                                                                <tr style={{ ...rowStyles }}>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>Sell On Checkin</td>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>{!(selectedBatch?.sellOnCheckin) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                                                </tr>

                                                                <tr style={{ ...rowStyles }}>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>Sell On Vendor Scan</td>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>{!(selectedBatch?.sellOnVendorScan) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                                                </tr>


                                                                <tr style={{ ...rowStyles }}>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>Sell All on Activation</td>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>{!(selectedBatch?.sellAllonActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                                                </tr>
                                                                <tr style={{ ...rowStyles }}>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>Checkin On Batch Activation</td>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>{!(selectedBatch?.checkinOnBatchActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                                                </tr>
                                                                <tr style={{ ...rowStyles }}>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>Activated By</td>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>{selectedBatch?.doorChecker?.name?selectedBatch?.doorChecker?.name:'Admin'}</td>
                                                                </tr>
                                                                <tr style={{ ...rowStyles }}>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>Assigned To</td>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>{(selectedBatch?.sellOnVendorScan)?batchData?.vendor?.name:batchData?.seller?.TicketSeller.name}</td>
                                                                </tr>
                                                                <tr style={{ ...rowStyles }}>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>Activated On</td>
                                                                    <td style={{ textAlign: "left", ...rowCellStyles }}>{getformattedDate(selectedBatch?.activatedAt)}</td>
                                                                </tr>
                                                            </>
                                                            :
                                                            <></>
                                                        }

                                                    </tbody>
                                                </Table>
                                            </div>


                                            <>

                                                <Divider orientation="left">Sales</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.sales}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.sales?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.sales?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>

                                                        </Col>
                                                    </Row>

                                                }


                                                <Divider orientation="left">Entry</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.entry}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.entry?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.entry?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Divider orientation="left">Status</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.status}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.status?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.status?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Row>
                                                    <Col>
                                                        <div className='my-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <a style={{ margin: 0, fontWeight: 'bold', color: viewTickets ? 'red' : 'green' }} onClick={(e) => {
                                                                e.preventDefault()
                                                                if (viewTickets) {
                                                                    setViewTickets(false)
                                                                } else {
                                                                    setViewTickets(true)
                                                                }

                                                            }} >{viewTickets ? <MdIcons.MdOutlineExpandLess title='hide' style={{ height: "18px", width: "18px", color: 'gray' }} /> : <MdIcons.MdOutlineExpandMore title='Expand' style={{ height: "18px", width: "18px", color: 'gray' }} />}{viewTickets ? `Hide Batch Tickets` : `View Batch Tickets`}</a>
                                                        </div>
                                                        <Collapse in={viewTickets}>
                                                            <div>
                                                                <TicketsPreview tickets={batchData?.tickets} batchNumber={selectedBatch?.DocumentTag.tagName} />
                                                            </div>
                                                        </Collapse>
                                                    </Col>
                                                </Row>

                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}

                {showVendorSummary && <Modal open={true} title={'Vendor Summary'} onCancel={() => {

                    handleViewVendorSummaryClose()

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {(loadingVendorStatistics || loadingVendorCollections) ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold' }}>{selectedVendorName}</p>
                                </div>
                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>



                                            <>

                                                <Divider orientation="left">Sales</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={ticketVendorStats.sales}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {ticketVendorStats.sales?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ticketVendorStats.sales?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>

                                                        </Col>
                                                    </Row>

                                                }


                                                <Divider orientation="left">Entry</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={ticketVendorStats.entry}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {ticketVendorStats.entry?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ticketVendorStats.entry?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Divider orientation="left">Status</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={ticketVendorStats.status}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {ticketVendorStats.status?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ticketVendorStats.status?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Row>
                                                    <Col>
                                                        <div className='my-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <a style={{ margin: 0, fontWeight: 'bold', color: viewTickets ? 'red' : 'green' }} onClick={(e) => {
                                                                e.preventDefault()
                                                                if (viewTickets) {
                                                                    setViewTickets(false)
                                                                } else {
                                                                    setViewTickets(true)
                                                                }

                                                            }} >{viewTickets ? <MdIcons.MdOutlineExpandLess title='hide' style={{ height: "18px", width: "18px", color: 'gray' }} /> : <MdIcons.MdOutlineExpandMore title='Expand' style={{ height: "18px", width: "18px", color: 'gray' }} />}{viewTickets ? `Hide Vendor Tickets` : `View Vendor Tickets`}</a>
                                                        </div>
                                                        <Collapse in={viewTickets}>
                                                            <div>
                                                                <TicketsPreviewSeller tickets={ticketVendorStatsData?.tickets} sellerName={selectedVendorName} />
                                                            </div>
                                                        </Collapse>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <div className='my-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <a style={{ margin: 0, fontWeight: 'bold', color: viewTransactions ? 'red' : 'green' }} onClick={(e) => {
                                                                e.preventDefault()
                                                                if (viewTransactions) {
                                                                    setViewTransactions(false)
                                                                } else {
                                                                    setViewTransactions(true)
                                                                }

                                                            }} >{viewTransactions ? <MdIcons.MdOutlineExpandLess title='hide' style={{ height: "18px", width: "18px", color: 'gray' }} /> : <MdIcons.MdOutlineExpandMore title='Expand' style={{ height: "18px", width: "18px", color: 'gray' }} />}{viewTransactions ? `Hide Vendor Transactions` : `View Vendor Transactions`}</a>
                                                        </div>
                                                        <Collapse in={viewTransactions}>
                                                            <div>
                                                                <VendorTransactionSearch tickets={vendorCollectionsData?.transactions} vendorName={selectedVendorName} />
                                                            </div>
                                                        </Collapse>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <p style={{ margin: 0, fontWeight: 'bold' }}>Consolidated Totals</p>
                                                        </div>
                                                        <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                            <ul>

                                                                <li >
                                                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Revenue:</span> {formatPrice(vendorCollectionsData?.totalAmountCollected)} UGX</p>
                                                                </li>
                                                                <li >
                                                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Commission:</span> {formatPrice(vendorCollectionsData?.totalCommissionCollected)} UGX </p>
                                                                </li>



                                                            </ul>
                                                        </div>

                                                    </Col>
                                                </Row>

                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}

                {showVendorImport && <Modal open={true} title={'Import Vendors'} onCancel={() => {
                    setShowVendorImport(false)
                }}
                    footer={[


                    ]}



                    className="allmodals"

                >


                    <TicketSellerSearch onSubmit={handleVendorImportation} users={ticketVendors} isVendor setComissionPerTicket={setComissionPerTicket} comissionPerTicket={comissionPerTicket} />


                </Modal>}

                <Row>
                    <p style={{ fontWeight: "bold" }}> Vendors </p>
                    <div className="table-responsive">
                        {vendors.length > 0 ? (
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedVendors?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.name}</td>
                                            <td>{data.phone}</td>
                                            <td>{data?.Vendor.email}</td>
                                            <td >{!(data?.Vendor?.VendorEvents?.filter((data) => { return data?.eid == eid })[0].status) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                            <td>

                                                <MdIcons.MdPreview
                                                    className="mx-1"
                                                    title="View Vendor Batches "
                                                    style={{ height: "15px", width: "15px" }}
                                                    onClick={() => {
                                                        setSelectedVendorId(data.vid)
                                                        setSelectedVendorName(data.name)
                                                        setVendorRowData(data)
                                                        setShowVendorBatches(true)

                                                    }}
                                                />

                                                <MdIcons.MdOutlineSummarize
                                                    className="mx-1"
                                                    title="View Vendor Summary "
                                                    style={{ height: "15px", width: "15px" }}
                                                    onClick={() => {
                                                        setSelectedVendorId(data.vid)
                                                        setSelectedVendorName(data.name)
                                                        setVendorRowData(data)
                                                        setShowVendorSummary(true)
                                                    }}
                                                />

                                                <GrIcon.GrUploadOption className='mx-1' title='Update Vendor Details' style={{ height: "15px", width: "15px" }} onClick={() => {

                                                    setComissionPerTicket(data?.Vendor?.VendorEvents?.filter((data) => { return data?.eid == eid })[0]?.commissionPerTicket)
                                                    setIsActive(data?.Vendor?.VendorEvents?.filter((data) => { return data?.eid == eid })[0].status)
                                                    setVendorRowData(data)
                                                    setUpdateVendorDetails(true)

                                                }} />


                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No vendors.</p>
                        )}

                        {vendors?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={vendors?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }

}

export default EventVendorManagement;
