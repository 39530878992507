import { Button, Dropdown } from 'antd';
import useFetch from '../hooks/hooks/getData';
import { useEffect, useState } from 'react';
import Loader from './Loader';
import Cookies from 'js-cookie';
import * as IoIcons from 'react-icons/io';
import { Image } from 'react-bootstrap';
import { logOut } from '../Utilities/utilities';
import TransparentOverlay from './TransparentOverlay';
import { fetchData } from '../Utilities/apiRequests';

const LoggedinDropdown = ({ children, imageSource, userData , firstLetter,loadingProfile }) => {
    const [loading, setLoading] = useState(false);
    
    
   

    
    
    
    const items = [
        {
            key: '1',
            label: (
                <div className='Loggedin-dropdown' >
                    <div className='Loggedin-content' >
                        {imageSource ? (
                            <Image src="user-image.jpg" roundedCircle className="user-image mx-2" />
                        ) : (
                            <div className="user-image mx-2">{firstLetter}</div>

                        )}

                        <p>{userData?.regname}</p>
                    </div>

                    <div className='Loggedin-content text-muted mt-3' style={{ fontSize: "10px", height: "12px" }}>
                        <p>{userData?.email}</p>
                    </div>



                    <hr />
                    <a href='#'  className='Loggedin-content'  onClick={(e)=>{
                          e.preventDefault()
                          logOut("You have logged out successfully.",setLoading)  
                        }}  >
                        <IoIcons.IoIosLogOut className='mx-2' title="Logout" style={{ height: "20px", width: "20px" }} onClick={()=>{
                          logOut("You have logged out successfully.",setLoading)  
                        }} />
                        <p onClick={()=>{logOut("You have logged out successfully.",setLoading)}}>Logout</p>
                    </a>



                </div>

            ),
        }

    ];

    if (loading||loadingProfile) {
        return (<TransparentOverlay message={loadingProfile?'Loading Profile...':'Logging you out...'} />);
    } else {

        return (<>

            <Dropdown

                trigger={"click"}
                menu={{
                    items,
                }}
                placement="bottomRight"
                arrow={{
                    pointAtCenter: true,
                }}
            >
                {/* <Button>bottomRight</Button> */}
                {children}
            </Dropdown>
            <br />

        </>)

    }

};
export default LoggedinDropdown;