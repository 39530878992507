import React from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import { Outlet, Link } from 'react-router-dom';

const BranchHome = () => {


    const sections = [
        {
          name: "Dashboard",
          path: "#",
        },
        {
          name: "Assets",
          path: "#",
        }
      ];
      


  return (
    <Container style={{fontSize:'12px',height:'60vh'}}>
      <Row>
        <Col  md={2}>
          {/* Left Card - Various Sections */}
          <Card >
            <Card.Header> Sections</Card.Header>
            <Card.Body  className='d-flex flex-column align-items-center' style={{fontSize:'12px',height:'80vh',width:'100%' ,}}>
            <Nav className="flex-column">
                {sections.map((section, index) => (
                  <Nav.Item key={index}>
                    <Link to={section.path} style={{color:'green'}} className="nav-link">
                      {section.name}
                    </Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Body>
          </Card>
        </Col>
        <Col  md={10}>
          {/* Right Card - Outlet for Other Paths */}
          <Card  >
            <Card.Header> Asset Management </Card.Header>
            <Card.Body   className='d-flex flex-column align-items-center' style={{fontSize:'12px',height:'80vh',width:'100%',overflowY:'scroll'}}>
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BranchHome;
