import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetters, downloadImage, getformattedDate2 } from '../Utilities/utilities';
import pinnitags from './images/plogotransparent.png'
import hologram from './images/holog.png'
import bugandaLogo from './images/buganda-shield.png'
import passBg from './images/bg1.jpg'
import visualPlacer from './images/imagePlaceholder.png'
import generateBarcode from "pdf417";
import Loader from './Loader';
import { clientEngineBaseUrl } from '../Utilities/globals';
import { Col, Row } from 'react-bootstrap';

const PassTemplateV2 = ({ passDetails, eventDetails, passZones, allZones, sponsors }) => {

    const [imageLoaded, setImageLoaded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [imageData, setImageData] = useState(true);
    const [logoData, setLogoData] = useState(true);
    // const [sponsors, setSponsors] = useState([]);
    const pcolor = passDetails?.eventPassCategory?.pcolor

    const textColors = [{ color: '#d8e309', tcolor: 'black' }, { color: '#1a7d04', tcolor: 'white' }, { color: '#b50505', tcolor: 'white' }, { color: 'Black', tcolor: 'white' }, { color: '#036780', tcolor: 'white' }, { color: 'black', tcolor: 'white' }, { color: '#00000000', tcolor: 'black' }, { color: 'purple', tcolor: 'white' }, { color: 'orange', tcolor: 'white' }]

    const getTextColor = () => {
        const scolor = textColors.filter((data) => {
            return data.color == pcolor
        })

        return scolor[0]?.tcolor
    }

    const sponsorData = eventDetails?.EventSponsors ? eventDetails?.EventSponsors.filter((data) => {
        return data.active
    }) : []

    useEffect(() => {
        if (passDetails?.face) {
            setLoading(true)
            downloadImage(passDetails.face).then((data) => {
                setLoading(false)
                setImageData(data)
            }).catch((error) => {
                setLoading(false)
                console.log('Pass template image load error:', error)
            })
        }
        if (eventDetails?.ticketPassLogo) {
            setLoading1(true)
            downloadImage(eventDetails?.evLogo).then((data) => {
                setLoading1(false)
                setLogoData(data)
            }).catch((error) => {
                setLoading1(false)
                console.log('Pass template image load error:', error)
            })
        }

        // for (const sp of sponsorData){
        //   if(sp?.url){
        //     setLoading(true)
        //     downloadImage(sp?.url).then((data)=>{
        //       setLoading(false)
        //       setSponsors((prev)=>{if(!sponsors.includes({src:data})) {return [...prev,{src:data}]} else { return sponsors }})
        //     }).catch((error)=>{
        //       setLoading(false)
        //       console.log('Pass template image load error:',error)
        //     })
        //   }
        // }





    }, [])


    const useURL = ((eventDetails?.listingImageOnTicket) || (eventDetails.selfReg))


    // const tagURL = (eventDetails?.attendeeVer && eventDetails?.contactSharing) ? `${clientEngineBaseUrl}/pass/verify/${passDetails?.DocumentTag?.tagName}?mode=3` : eventDetails?.attendeeVer ? `${clientEngineBaseUrl}/pass/verify/${passDetails?.DocumentTag?.tagName}?mode=2` : `${clientEngineBaseUrl}/pass/verify/${passDetails?.DocumentTag?.tagName}?mode=1`

    const tagURL = useURL ? `${clientEngineBaseUrl}/passticket/registration/2/${passDetails?.DocumentTag?.tagName}` : passDetails?.DocumentTag?.tagName

    const zonesString = passZones?.map((data) => {
        return data.name
    }).join(', ')

    const isAllAccess = (passZones?.length == allZones?.length)

    const styles = {
        body: {
            margin: 0,
            padding: 0,
            fontFamily: "'Poppins', sans-serif",
            backgroundColor: '#f0f0f0',
        },
        passContainer: {
            width: '390px',
            height: '517px',
            margin: 'auto',
            marginTop: '10px',
            border: `3px solid ${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}`,
            borderRadius: '5px',
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: `${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}`,
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        },
        eventBanner: {
            height: '40%',
            position: 'relative',
        },
        eventBannerAfter: {
            content: "''",
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '18px',
            borderTop:'none',
            background: `linear-gradient(to top, ${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}, transparent)`,
            borderRadius: '0px 0px 5px 5px',
        },
        personImage: {
            width: '120px',
            height: '120px',
            borderRadius: '50%',
            position: 'absolute',
            top: '110px',
            left: '130px',
            overflow: 'hidden',
            boxShadow: '0 0 10px rgba(0,0,0,0.3), inset 0 0 0 4px rgba(255, 216, 61, 0)',
            zIndex: 10,
        },
        personImageImg: {
            width: '100%',
            height: '100%',
            objectFit: 'fill',
        },
        detailsSection: {
            height: '64%',
            padding: '10px 5px 0px 4px',
            boxSizing: 'border-box',
            backgroundColor: '#FFFFFF',
            borderRadius: '0px 0px 0px 0px',
            display: 'flex',
            marginTop: '-20px',
            paddingTop: '30px',
            flexDirection: 'column',
        },
        detailsGrid: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '10px',
            flexGrow: 1,
        },
        detailBox: {
            border: `2px solid ${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}`,
            borderRadius: '10px',
            padding: '3px 0px 0px 8px',
            backgroundColor: '#ffffff00',
            fontSize: '10px',
            height:'90px',
            width:'100%',
            marginTop:'5px',
            position:'relative'
        },
        detailBoxH3: {
            color: `${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}`,
            margin: '0 0 1px 0',
            fontSize: '0.95em',
            position: 'absolute',
            top: '-6px',
            padding: '0px 0px', 
            backgroundColor: 'white',
            borderRadius: '2px',
            width:'fit-content',
            textTransform:'uppercase',
            fontSize: '8px',
        },
        detailBoxP: {
            margin: '1px 0px 0px 0px ',
        },
        sponsorImages: {
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '5px',
            borderRadius: '8px',
            padding: '0px',
        },
        sponsorImagesImg: {
            width: '55px',
            height: '40px',
        },
        barcodeSection: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginBottom:'0px'
        },
        barcode: {
            width: '210px',
            height: '50px',
        },
        companyLogo: {
            width: '80px',
            height: '40px',
            textAlign:'center'
        },
        overlayHoloGramStyle: {
            position: 'absolute',
            top: '190px',
            left: '312px',
            width: '10px',
            backgroundColor: 'transparent',
            zIndex: 10,
            textAlign: 'center',
            color: 'white'
          },
        
    };

    if (loading || loading1) {
        return (<Loader />);
    } else {

        return (
            <div style={styles.passContainer}>
                <div style={styles.overlayHoloGramStyle}>
                    <img style={{ width: '50px' }} src={hologram} />
                </div>
                <div style={styles.eventBanner}>
                    <div style={{ width: '100%', maxHeight: '203px', overflowY: 'hidden', borderRadius: '0px 0px 5px 5px' }}>
                        <img style={{ width: '100%' }} src={logoData} alt="event-banner" />
                    </div>
                    <div style={styles.eventBannerAfter}></div>
                </div>
                <div style={styles.personImage}>
                    <img style={styles.personImageImg} src={imageLoaded ? `${imageData}` : visualPlacer} onError={() => {
                      setImageLoaded(false)
                    }} alt="Person's face" />
                </div>
                <div style={styles.detailsSection}>
                    <Row >
                        <Col  style={{paddingRight:'2px'}} md={6}>
                        <div style={styles.detailBox}>
                            <h3 style={styles.detailBoxH3}>Person Details</h3>
                            <p style={styles.detailBoxP}><strong>Name: </strong>{passDetails?.EventPassTitle?.name ? passDetails?.EventPassTitle?.name == 'Other' ? passDetails?.otherTitle : passDetails?.EventPassTitle?.name : ''} {capitalizeFirstLetters(String(passDetails?.name))}</p>
                            <p style={styles.detailBoxP}><strong>ID: </strong>{passDetails?.DocumentTag?.tagName}</p>
                            <p style={styles.detailBoxP}><strong>Designation: </strong> <span style={styles.animatedText}>{passDetails?.profession ? capitalizeFirstLetters(passDetails?.profession) : ''}</span></p>
                        </div>
                        </Col>
                        <Col style={{paddingLeft:'2px'}} md={6}>
                        <div style={styles.detailBox}>
                            <h3 style={styles.detailBoxH3}>Event Details</h3>
                            <p style={styles.detailBoxP}><strong>Event: </strong> {capitalizeFirstLetters(String(eventDetails?.name))}</p>
                            <p style={styles.detailBoxP}><strong>Theme: </strong>{capitalizeFirstLetters(String(eventDetails?.eventdescription))}</p>
                            <p style={{...styles.detailBoxP,fontSize:'8px'}}><strong style={{fontSize:'10px'}}>Date: </strong>{`${getformattedDate2(eventDetails?.eventDate)}`}</p>
                            <p style={styles.detailBoxP}><strong>Venue: </strong>{capitalizeFirstLetters(String(`${eventDetails?.venue} - ${eventDetails?.location}`))}</p>
                        </div>
                        </Col>
                        <Col style={{ paddingRight:'2px'}} md={6}>
                        <div style={styles.detailBox}>
                            <h3 style={styles.detailBoxH3}>Pass Category</h3>
                            <div style={{ display: 'flex', textAlign: 'center', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ color: `${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}`,fontSize:'17px' }}>{passDetails?.eventPassCategory?.name}</h1>
                            </div>
                        </div>
                        </Col>
                        <Col style={{paddingLeft:'2px'}} md={6}>
                        <div style={styles.detailBox}>
                            <h3 style={styles.detailBoxH3}>Accredited Zones</h3>
                            <p style={{ ...styles.detailBoxP, color: `${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}` }}>{zonesString}</p>
                        </div>
                        </Col>
                        
                       
                        
                        
                    </Row>
                    <div style={styles.sponsorImages}>
                        {
                            sponsors.map((sp, index) => (

                                <img style={styles.sponsorImagesImg} src={sp.src} alt={`Sponsor ${index + 1}`} />

                            ))}


                    </div>
                    <div style={styles.barcodeSection}>
                        <div style={styles.barcode}>
                            <img style={{ width: '100%' }} src={generateBarcode(tagURL, 6, 2)} alt="tag" />
                        </div>
                        <div style={styles.companyLogo}>
                            <p style={{ margin: '0%', fontWeight: 'bold', fontSize: '6px' }}>Powered By</p>
                            <img style={{ width: '100%' }} src={pinnitags} alt="company-logo" />
                        </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', margin: '0' }}>
                        <p style={{ fontSize: '8px', fontWeight: 'bold', margin: '0px' }}>SerialNo.<span style={{ fontWeight: 'lighter', margin: '0px' }}>{passDetails?.id}</span></p>
                    </div>
                </div>
            </div>
        )

    };
}

export default PassTemplateV2;
