import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card, Table, InputGroup, ListGroup, Collapse } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Button, Divider, Drawer, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as MdIcons from 'react-icons/md';
import { fetchData } from '../../Utilities/apiRequests';
import { formatPrice, getNairobiTime, getNairobiTimeWithParam, getNumberOnly, getformattedDate } from '../../Utilities/utilities';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import * as GrIcon from 'react-icons/gr';
import useGeneralState from '../../hooks/hooks/generalState';
import Pagination from '../Pagination';
import ImageCropper from '../ImageCropper';
import ImagePreview from '../ImagePreview';
import { fileUploadBaseUrl } from '../../Utilities/globals';
import BatchesPreview from '../BatchesSearch';
import ContainedLoader from '../ContainedLoader';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Rectangle, PieChart, Pie, Cell, ResponsiveContainer, } from 'recharts';
import ColorCube from '../ColorCube';
import TicketsPreview from '../TicketSearch';
import BatchesPreviewSellers from '../BatchesSearchTicketSeller';
import TicketSellerSearch from '../EventTicketSellerSearch';
import { FaFileImport } from 'react-icons/fa';
import TicketsPreviewSeller from '../TicketSearchSeller';



const EventTicketSellerManagement = () => {

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loadingBatches, setLoadingBatches] = useState(false);
    const [loadingBatchStatistics, setLoadingBatchStatistics] = useState(false);
    const [loadingSellerStatistics, setLoadingSellerStatistics] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [sellers, setSellers] = useState([]);
    const [showSellerBatches, setShowSellerBatches] = useState(false);
    const [selectedSellerId, setSelectedSellerId] = useState('');
    const [eventInfo, setEventInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sellerBatches, setSellerBatches] = useState([]);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [batchData, setBatchData] = useState();
    const [showBatchDetails, setShowBatchDetails] = useState(false);
    const [batchStatistics, setbatchStatistics] = useState({ sales: [], entry: [] });
    const [selectedSellerName, setSelectedSellerName] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [onSmallScreen, setOnSmallScreen] = useState(false);
    const [viewTickets, setViewTickets] = useState(false);
    const [showSellerSummary, setShowSellerSummary] = useState(false);
    const [showSellerImport, setShowSellerImport] = useState(false);
    const [ticketSellers, setTicketSellers] = useState([]);
    const [ticketSellerStatsData, setTicketSellerStatsData] = useState([]);
    const [ticketSellerStats, setTicketSellerStats] = useState({});




    const { eid } = useParams();



    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    useEffect(() => {
        setOnSmallScreen((screenWidth < 770))
    }, [screenWidth]);




    useEffect(() => {

        fetchData(`/api/events/ticketSellers/event/${eid}`, setLoading).then((response) => {

            setSellers(response.data.sellers)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading box office sellers.')
        })



        fetchData(`/api/events/details/${eid}`, setLoading1).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })


        fetchData(`/api/events/ticketSellers/all`, setLoading2).then((response) => {
            setTicketSellers(response.data.sellers)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading box office sellers.')
        })



    }, [refresh])




    useEffect(() => {

        if (selectedSellerId) {
            fetchData(`/api/events/ticketSellers/batches/list/${selectedSellerId}/${eid}`, setLoadingBatches).then((response) => {
                setSellerBatches(response.data.batches?.map((data) => data.Batch))
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Seller batches.')
            })
        }

    }, [selectedSellerId, refresh])



    useEffect(() => {

        if (selectedSellerId) {
            fetchData(`/api/events/ticketSellers/batches/stats/${selectedSellerId}/${eid}`, setLoadingSellerStatistics).then((response) => {
                setTicketSellerStatsData(response.data)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Seller stats.')
            })
        }

    }, [selectedSellerId, refresh])



    useEffect(() => {



        if (selectedBatchId) {
            fetchData(`/api/events/batchManagement/batches/statistics/${selectedBatchId}`, setLoadingBatchStatistics).then((response) => {
                setBatchData(response.data)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Batch Data.')
            })
        }



    }, [selectedBatchId])




    useEffect(() => {



        if (batchData) {
            const salesData = [

                {
                    "name": "Sold",
                    "value": batchData.sold,
                    "color": 'gold'
                },
                {
                    "name": "Not Sold",
                    "value": Number(batchData.total) - Number(batchData.sold),
                    "color": 'gray'
                },
            ];

            const entryData = [

                {
                    "name": "Entered",
                    "value": batchData.entered,
                    "color": 'green'
                },

                {
                    "name": "Not Entered",
                    "value": Number(batchData.total) - Number(batchData.entered),
                    "color": 'gray'
                }
            ];


            const statusData = [

                {
                    "name": "Active",
                    "value": batchData.active,
                    "color": '#89CFF0'
                },

                {
                    "name": "Inactive",
                    "value": Number(batchData.total) - Number(batchData.active),
                    "color": 'gray'
                }
            ];


            setbatchStatistics((prevBatchStatistics) => ({
                ...prevBatchStatistics,
                sales: salesData,
                entry: entryData,
                status: statusData
            }));

        }



    }, [batchData])

    useEffect(() => {



        if (ticketSellerStatsData) {
            const salesData = [

                {
                    "name": "Sold",
                    "value": ticketSellerStatsData.sold,
                    "color": 'gold'
                },
                {
                    "name": "Not Sold",
                    "value": Number(ticketSellerStatsData.total) - Number(ticketSellerStatsData.sold),
                    "color": 'gray'
                },
            ];

            const entryData = [

                {
                    "name": "Entered",
                    "value": ticketSellerStatsData.entered,
                    "color": 'green'
                },

                {
                    "name": "Not Entered",
                    "value": Number(ticketSellerStatsData.total) - Number(ticketSellerStatsData.entered),
                    "color": 'gray'
                }
            ];


            const statusData = [

                {
                    "name": "Active",
                    "value": ticketSellerStatsData.active,
                    "color": '#89CFF0'
                },

                {
                    "name": "Inactive",
                    "value": Number(ticketSellerStatsData.total) - Number(ticketSellerStatsData.active),
                    "color": 'gray'
                }
            ];


            setTicketSellerStats((prevBatchStatistics) => ({
                ...prevBatchStatistics,
                sales: salesData,
                entry: entryData,
                status: statusData
            }));

        }



    }, [ticketSellerStatsData])


    const handleViewBatchDetails = (batch) => {

        setShowBatchDetails(true)
        setSelectedBatchId(batch.bid)
        setSelectedBatch(batch)
    }



    const handleViewBatchDetailsClose = () => {

        setShowBatchDetails(false)
        setSelectedBatchId('')
        setSelectedBatch()
        setSelectedSellerId()
        setViewTickets(false)
        
    }


    const handleViewSellerSummaryClose = () => {

        setShowSellerSummary(false)
        setSelectedSellerId()
        
    }

    const tableStyles = {
        backgroundColor: '#f8f9fa', // Light gray background
        fontSize: '10px',
    };

    const headerStyles = {
        backgroundColor: '#343a40',
        color: '#fff',
    };

    const rowStyles = {
        height: '1px',
        padding: '0px',
        margin: '0px'
    };

    const rowCellStyles = {
        paddingTop: '2px',
        paddingBottom: '2px'
    }



    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedSellers = sellers?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }


    const COLORS = [
        '#0088FE',  // Blue (base)
        '#00C49F',  // Teal (base)
        '#FFBB28',  // Orange (base)
        '#FF8042',  // Dark Orange (base)
        '#33aaff',  // Lighter Blue (lightened from #0088FE)
        '#ffde5a',  // Yellow-Orange (analogous to #FFBB28)
        '#00ceff',  // Turquoise (triadic to #FF8042)

        '#A6CEE3',  // Light Blue (ColorBrewer2)
        '#1F77B4',  // Medium Blue (ColorBrewer2)
        '#FF7F0E',  // Bright Orange (Tableau)
        '#539E0B',  // Dark Green (Tableau)
        '#9467BD',  // Purple (accessible)

        // Shades of gray for emphasis (ensure sufficient contrast)
        // '#cccccc',
        // '#e0e0e0',
        // '#f2f2f2',

        // Additional contrasting colors (test for accessibility)
        '#f00',    // Red
        '#0f0',    // Green
        '#00f',    // Blue (darker)
        '#ffc0cb', // Light Pink
    ];


    const CustomTooltipRegistration = ({ active, payload, label }) => {

        const alldata = payload[0]
        const salesData = alldata?.payload
        const textColor = salesData?.fill


        if (active && payload && payload.length) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', backgroundColor: 'white', padding: '5px', border: '1px solid gray' }}>

                    <p className='mt-0 mb-1' style={{ color: `black`, fontWeight: 'bold', fontSize: '13px' }} >{salesData?.name}</p>
                    <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />
                    <p className='my-0' style={{ color: textColor, fontWeight: 'bolder', fontSize: '11px' }} ><span style={{ color: 'gray' }}> Total:</span> {salesData?.value}</p>

                </div>
            );
        }

        return null;
    };

    const importTicketSellers = async (sids) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            sellerIds: sids,
            eid
        };

        const Url = '/api/events/ticketSellers/import'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Sellers Imported successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log(" Seller Import Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while importing box office sellers.')

        }
    };


    const handleSellerImportation = (selectedSellersData) => {
        const sids = selectedSellersData.map((data) => {
            return data.id
        })

        if (sids?.length > 0) {
            importTicketSellers(sids).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setShowSellerImport(false)
            }).catch((error) => {
                setShowSellerImport(false)
                twit('info', error, 4)
            })
        } else {
            twit('info', 'Please select atleast one seller to import.', 4)
        }

    }





    if (loading || loading1) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>

                <div className='mt-2' style={{ width: '100%', textAlign: 'end' }}>
                    <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={() => {
                        setShowSellerImport(true)
                    }} >
                        <FaFileImport className='mx-1' style={{ width: '15px', height: '15px' }} /> | Import Box Office Sellers
                    </Button>
                </div>

                {showSellerBatches && (
                    <>

                        <Drawer title="Seller Batches" placement="right"
                            onClose={

                                () => {
                                    setShowSellerBatches(false)
                                }

                            } open={true}>
                            {
                                loadingBatches ?
                                    <ContainedLoader />
                                    :
                                    <BatchesPreviewSellers batches={sellerBatches} batchId={selectedBatchId} handleBatchView={handleViewBatchDetails} SellerName={selectedSellerName} />
                            }

                        </Drawer>

                    </>


                )}


                {showBatchDetails && <Modal open={true} title={'Batch Summary'} onCancel={() => {

                    handleViewBatchDetailsClose()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {loadingBatchStatistics ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>

                                            <div className='my-2' style={{ width: '100%', backgroundColor: selectedBatch?.TagColor.name }}>

                                                <div style={{ width: '30%', textAlign: 'center', backgroundColor: `white`, marginLeft: '5%', padding: '5px' }}>
                                                    <Card.Title style={{ fontSize: '15px', margin: '0px', fontWeight: 'bold' }}>{selectedBatch?.DocumentTag.tagName}</Card.Title>
                                                </div>

                                            </div>

                                            <div style={{ width: '100%', textAlign: 'start' }}>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Zone:</span> {selectedBatch?.EventZone.name}</p>
                                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }} >Status:</span> {!(selectedBatch?.isActive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Price:</span> UGX {formatPrice(selectedBatch?.EventZone.cost)}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Tickets In Batch:</span>  {formatPrice(batchData?.tickets?.length)}</p>
                                                {(selectedBatch?.isActive) ?
                                                    <>

                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell On Checkin:</span>  {!(selectedBatch?.sellOnCheckin) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell All on Activation: </span>  {!(selectedBatch?.sellAllonActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold', color: (selectedBatch?.sellAllonActivation) ? 'black' : 'gray' }} >Checkin On Batch Activation:</span>  {!(selectedBatch?.checkinOnBatchActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated By : </span>{selectedBatch?.doorChecker?.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated On : </span>{getformattedDate(selectedBatch?.activatedAt)} </p>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </div>



                                            <>

                                                <Divider orientation="left">Sales</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.sales}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.sales?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.sales?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>

                                                        </Col>
                                                    </Row>

                                                }


                                                <Divider orientation="left">Entry</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.entry}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.entry?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.entry?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Divider orientation="left">Status</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.status}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.status?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.status?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Row>
                                                    <Col>
                                                        <div className='my-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <a style={{ margin: 0, fontWeight: 'bold', color: viewTickets ? 'red' : 'green' }} onClick={(e) => {
                                                                e.preventDefault()
                                                                if (viewTickets) {
                                                                    setViewTickets(false)
                                                                } else {
                                                                    setViewTickets(true)
                                                                }

                                                            }} >{viewTickets ? <MdIcons.MdOutlineExpandLess title='hide' style={{ height: "18px", width: "18px", color: 'gray' }} /> : <MdIcons.MdOutlineExpandMore title='Expand' style={{ height: "18px", width: "18px", color: 'gray' }} />}{viewTickets ? `Hide Batch Tickets` : `View Batch Tickets`}</a>
                                                        </div>
                                                        <Collapse in={viewTickets}>
                                                            <div>
                                                                <TicketsPreview tickets={batchData?.tickets} batchNumber={selectedBatch?.DocumentTag.tagName} />
                                                            </div>
                                                        </Collapse>
                                                    </Col>
                                                </Row>

                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}

                {showSellerSummary && <Modal open={true} title={'Sellers Summary'} onCancel={() => {

                    handleViewSellerSummaryClose()

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {loadingSellerStatistics ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold' }}>{selectedSellerName}</p>
                                </div>
                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>

                                            

                                            <>

                                                <Divider orientation="left">Sales</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={ticketSellerStats.sales}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {ticketSellerStats.sales?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ticketSellerStats.sales?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>

                                                        </Col>
                                                    </Row>

                                                }


                                                <Divider orientation="left">Entry</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={ticketSellerStats.entry}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {ticketSellerStats.entry?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ticketSellerStats.entry?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Divider orientation="left">Status</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={ticketSellerStats.status}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {ticketSellerStats.status?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ticketSellerStats.status?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Row>
                                                    <Col>
                                                        <div className='my-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <a style={{ margin: 0, fontWeight: 'bold', color: viewTickets ? 'red' : 'green' }} onClick={(e) => {
                                                                e.preventDefault()
                                                                if (viewTickets) {
                                                                    setViewTickets(false)
                                                                } else {
                                                                    setViewTickets(true)
                                                                }

                                                            }} >{viewTickets ? <MdIcons.MdOutlineExpandLess title='hide' style={{ height: "18px", width: "18px", color: 'gray' }} /> : <MdIcons.MdOutlineExpandMore title='Expand' style={{ height: "18px", width: "18px", color: 'gray' }} />}{viewTickets ? `Hide Seller Tickets` : `View Seller Tickets`}</a>
                                                        </div>
                                                        <Collapse in={viewTickets}>
                                                            <div>
                                                                <TicketsPreviewSeller tickets={ticketSellerStatsData?.tickets} sellerName={selectedSellerName} />
                                                            </div>
                                                        </Collapse>
                                                    </Col>
                                                </Row>

                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}

                {showSellerImport && <Modal open={true} title={''} onCancel={() => {
                    setShowSellerImport(false)
                }}
                    footer={[


                    ]}



                    className="allmodals"

                >


                    <TicketSellerSearch onSubmit={handleSellerImportation} users={ticketSellers} />


                </Modal>}

                <Row>
                    <p style={{ fontWeight: "bold" }}> Box Office Sellers </p>
                    <div className="table-responsive">
                        {sellers.length > 0 ? (
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Batches</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedSellers?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.name}</td>
                                            <td>{data.phone}</td>
                                            <td>{data.email}</td>
                                            <td >{!(data.status) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                            <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{data?.TicketSellerBatches?.length}</td>
                                            <td>
                                                
                                                    <MdIcons.MdPreview
                                                        className="mx-1"
                                                        title="View Seller Batches "
                                                        style={{ height: "15px", width: "15px" }}
                                                        onClick={() => {
                                                            setSelectedSellerId(data.id)
                                                            setSelectedSellerName(data.name)
                                                            setShowSellerBatches(true)
                                                        }}
                                                    />

                                                <MdIcons.MdOutlineSummarize
                                                    className="mx-1"
                                                    title="View Seller Summary "
                                                    style={{ height: "15px", width: "15px" }}
                                                    onClick={() => {
                                                        setSelectedSellerId(data.id)
                                                        setSelectedSellerName(data.name)
                                                        setShowSellerSummary(true)
                                                    }}
                                                />
                                                


                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No orders.</p>
                        )}

                        {sellers?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={sellers?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }

}

export default EventTicketSellerManagement;
