import { useEffect } from 'react';
import axios from '../api/axios';


const token = sessionStorage.getItem('token');
const useGender = async() => {


    const  genderUrl = '/api/gender'

    


        try {
          const response = await axios.get(genderUrl,{
            headers: {  Authorization: `Bearer ${token}`
        }

        });
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useGender;