import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import axios from '../api/axios';

const Recapture = ({setRecapture}) => {

    const handleRecaptchaChange = async (token) => {
        // Send the token to your server for verification
        const data = {
            token: token
        };

        const url = '/api/auth/re-captcha';

        try {
            const response = await axios.post(url, JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            setRecapture(response.data.data.success);
        } catch (error) {
            console.log(error);
        }


    }






    return (
        
            <ReCAPTCHA
                sitekey='6LfoSbwlAAAAAEzFleR_N_eWL6zNpUrvfTWs_Ozd'
                
                onChange={handleRecaptchaChange}
            />
        
    )
}

export default Recapture;
