import axios from '../api/axios';


const useEntities = async() => {
    const  EntityUrl = '/api/entities'

        try {
          const response = await axios.get(EntityUrl);
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useEntities;