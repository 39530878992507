import React, { useState } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import Dashboard from "./Dashboard";


const MeetingDash = () => {
    const [dash, setDash] = useState(true);
    const [products, setProducts] = useState(false);

    const selectDash = () => {
        setDash(true);
        setProducts(false);

    };
    const selectProducts = () => {
        setDash();
        setProducts(true);

    };




    return (
        <Container className="meetingsdash-container">
            <Card>
                <Card.Header>
                    <Nav variant="tabs" defaultActiveKey="dash">
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="dash" onClick={selectDash}>
                                Dash
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="products" onClick={selectProducts}>
                                Products
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {dash && <p>Dash tab content goes here</p>}
                    {products && <Dashboard />}
                </Card.Body>
            </Card>

        </Container>

    );
}

export default MeetingDash;
