import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { twit } from "./Notificationpopout";
import Cookies from 'js-cookie';
import { fetchData } from "../Utilities/apiRequests";
import { Form, Container, Row, Col, Card, Table, InputGroup } from 'react-bootstrap';
import { VscEyeClosed } from "react-icons/vsc";
import { VscEye } from "react-icons/vsc";
import * as GiIcon from 'react-icons/gi';
import axios from "../api/axios";
import Loader from "./Loader";
import { Button, Modal } from 'antd';
import { useInactivitySignOutColl } from "./Inactivity";
import { innerTimeOutSeconds } from "../Utilities/globals";
import * as MdIcons from 'react-icons/md';

const ProtectedInnerRoute = ({
    redirectPath = '/',
    children,
}) => {

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [userInfo, setUserInfo] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [checked, setChecked] = useState(false);
    const [hasLoggedIn, setHasLoggedIn] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const loggedin = Cookies.get('CollAccess');;
    const inputRef = useRef(null);
    const navigate = useNavigate();
    

    useEffect(() => {

        fetchData(`/api/auth/user/profile`, setLoading).then((response) => {
            setEmail(response.data.data?.email)
            setUserInfo(response.data.data)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading user data.')
        })



    }, [refresh])



    

    const requestOtp = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {};
        const Url = '/api/auth/collections/access/otp'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                
                return Promise.resolve(response?.data?.message)

            }


        } catch (error) {
            setLoading(false);
            console.log("Collection Account OTP request Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while requesting an OTP.')

        }
    };


    useEffect(() => {
        if(!loggedin){
            requestOtp(setLoading1).then((data) => {
                twit('success', data ,5)
                // setShowModal(true)
                inputRef?.current?.focus();
            }).catch((error) => {
                twit('error', error)
            })
            
            
        }
        
    }, [])

    const {} = useInactivitySignOutColl(hasLoggedIn,innerTimeOutSeconds)
    
    if (loggedin||hasLoggedIn) {
        return children;
    }
    

    

    const login = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = { otp };
        const Url = '/api/auth/collections/access/login'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 200) {

                setLoading(false);
                return Promise.resolve(response?.data?.message)

            }


        } catch (error) {
            setLoading(false);
            console.log("Collection Account Access Login Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while logging you in.')

        }
    };

    

  

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!otp){
            twit('info','Please provide a valid OTP.')
        }else{
            login(setLoading).then((data) => {
                const expires = new Date();
                expires.setMinutes(expires.getMinutes() + 1440)
                Cookies.set('CollAccess', true, { expires });
                setHasLoggedIn(true)
            }).catch((error) => {
                twit('error', error)
            })
        }
        
    }

    
    if (loading||loading1) {
        return (<Loader />);
    } else {

        return (

            <Container>
                {<Modal open={true} title={'Collections Account Access'} closable={true} onCancel={() => {
                    navigate('/home')
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        <Row>
                            <Col>

                                <Form onSubmit={handleSubmit}>

                                    <Card>
                                        <Card.Body>
                                            <Row>


                                                <Col className='my-1' md={12}>


                                                    <Form.Group className='my-2' controlId="user-email">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Email<span style={{ color: "red" }}> *</span></p>
                                                            </div>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter user email"
                                                                value={email}
                                                                onChange={(e) => {

                                                                    setEmail(e.target.value);

                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>


                                                </Col>
                                                <Col className='my-1' md={12}>

                                                    <Form.Group className='my-2' controlId="user-otp">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>OTP<span style={{ color: "red" }}> *</span></p>
                                                            </div>
                                                            <InputGroup className=" login-inputs" controlId="user-otp">




                                                                <Form.Control autoComplete="off" ref={inputRef} maxLength={5} minLength={5} className=' input-box-events form-control-sm' type={checked ? "text" : "password"} placeholder="OTP" value={otp} onChange={(e) => {
                                                                    setOtp(e.target.value)
                                                                }} />
                                                                <InputGroup.Text onClick={() => { setChecked(!checked) }}>

                                                                    {checked ? <VscEye style={{ fontSize: '13px' }} /> : <VscEyeClosed style={{ fontSize: '13px' }} />}
                                                                </InputGroup.Text>

                                                            </InputGroup>
                                                        </div>


                                                    </Form.Group>



                                                </Col>





                                            </Row>
                                        </Card.Body>
                                    </Card>



                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                            <MdIcons.MdLogin className='mx-1' style={{ width: '15px', height: '15px' }} /> | login
                                        </Button>
                                    </div>
                                </Form>
                            </Col>

                        </Row>


                    </div>


                </Modal>}
            </Container>
        )
    }
};

export default ProtectedInnerRoute;