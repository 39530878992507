import { Modal } from "antd";

const TransparentOverlay = ({ message }) => {
    return (
        <Modal
            open={true}
            closable={false}
            footer={null}
            centered
            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            className="allmodals overlay-modal"
        >
            <div style={{ display: 'flex', flexDirection: 'column' }} className="transparent-loading-page">
                <div className="loading-animation"></div>
                <h5 style={{color:'white',fontWeight:'bold',fontSize:'10'}} >{message}</h5>
            </div>

        </Modal>
    );
};


export default TransparentOverlay;