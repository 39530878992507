import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { capitalizeFirstLetter, containsUniqueIdentifier } from '../Utilities/utilities';

const BreadcrumbNav = ()=> {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

 
  if(containsUniqueIdentifier(pathSegments)){
    pathSegments.splice(-1)
  }

  const breadcrumbStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  };

  const breadcrumbItemStyle = {
    marginRight: '8px',
  };

  const linkStyle = {
    color: 'green',
    textDecoration: 'none',
  };

  return (
    <div className='mx-2 mb-2' style={breadcrumbStyle}>
      <div style={breadcrumbItemStyle}>
        <Link to="/" style={linkStyle}>Home</Link>
      </div>
      {pathSegments.map((segment, index) => (
        <React.Fragment key={index}>
          <div style={breadcrumbItemStyle}>|</div>
          <div style={breadcrumbItemStyle}>
            <Link to={(index === pathSegments.length - 1)?'#':`/${pathSegments.slice(0, index + 1).join('/')}`} style={linkStyle}>
              {capitalizeFirstLetter(segment)}
            </Link>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default BreadcrumbNav;
