import React, { useState } from 'react';
import { Container, Table, Form } from 'react-bootstrap';
import LoaderButton from '../LoaderButton';
import { Modal } from 'antd';
import usePost from '../../hooks/hooks/postData';
import { twit } from '../Notificationpopout';
import useFetch from '../../hooks/hooks/getData';
import { getData } from '../../Utilities/utilities';
import { patchData } from '../../Utilities/utilities';
import Loader from '../Loader';


const MyLoyalty = () => {

    const [rowdata, setRowdata] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newMember, setNewMember] = useState(false);
    const [newClass, setNewClass] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [lclassName, setLclassName] = useState("");
    const [membershipClass, setMembershipClass] = useState("");
    const [cardValidity, setCardValidity] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [refresh, setRefresh] = useState(false);



    const createClass = usePost("/api/products/identity/loyalty/create", setLoading);
    const myClasses = useFetch('/api/products/identity/loyalty/packages', () => { }, refresh);
    const createMember = usePost("/api/products/identity/membership/people/add", setLoading);
    const myMembers = useFetch("/api/products/identity/membership/people", () => { }, refresh);






    const compareDates = (date1, date2) => {
        // Convert the date strings to Date objects.
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        // Compare the Date objects.
        if (d1 < d2) {
            return -1;
        } else if (d1 == d2) {
            return 0;
        } else {
            return 1;
        }
    }



    const validateCard = (id) => {


        const response = getData(`/api/products/card/${id}`, () => { });



        response?.then((data) => {

            if (data?.data) {



                if (data?.data?.data?.data?.CardType.ctype === "silver" || data?.data?.data?.data?.CardType.ctype === "bronze") {


                    setCardValidity("invalid")
                } else {
                    setCardValidity("valid")
                }
            } else {
                if (data?.error?.response?.request?.status === 500) {
                    setCardValidity("invalidCard")
                } else {
                    setCardValidity("Network error");
                }

            }
        })






    }







    if (loading) {
        return (<Loader />);
    } else {


        return (

            <Container>

                {rowdata && <Modal open={true} title={"Member Information"} onCancel={() => {
                    setRowdata(false);
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div>
                        <p>Name : {rowdata.HumanIdentification.fhname + " " + rowdata.HumanIdentification.lhname} </p>
                        <p>Contact : {rowdata.HumanIdentification.contactno} </p>
                        <p>Start Date : {rowdata.dfrom}</p>
                        <p>End Date: {rowdata.dto}</p>
                    </div>




                    <div>

                        {!rowdata.status && (
                            <LoaderButton onClick={() => {

                                patchData(`/api/products/identity/membership/${"activate"}`, {
                                    hid: rowdata.HumanIdentification.hid
                                }, setLoading).then((data) => {
                                    if (data?.data) {

                                        setRefresh(!refresh);
                                        setRowdata(false);
                                        twit("success", data?.data?.data?.data);
                                    } else {
                                        twit("error", "An error occurred while processing.")
                                    }
                                    
                                });

                            }} loading={false}
                                variant={"primary"}
                                label={" Activate "}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                                className={"mx-1"}
                            />
                        )}

                        {rowdata.status && (
                            <LoaderButton onClick={() => {

                                patchData(`/api/products/identity/membership/${"deactivate"}`, {
                                    hid: rowdata.HumanIdentification.hid
                                }, setLoading).then((data) => {
                                    if (data?.data) {
                                        setRefresh(!refresh);
                                        setRowdata(false);
                                        twit("success", data?.data?.data?.data);
                                    } else {
                                        twit("error", "An error occurred while processing.")
                                    }
                                    
                                });

                            }} loading={false}
                                variant={"primary"}
                                label={" Deactivate "}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                                className={"mx-1"}
                            />
                        )}


                    </div>


                </Modal>}


                {newMember && <Modal open={true} title={'Add New Member'} onCancel={() => {
                    setNewMember(false);
                }}
                    footer={[


                    ]}
                    className='allmodals'
                >

                    <div>
                        <Form.Group controlId="formBasicLocation">
                            <Form.Label id="cardNo" style={{ marginBottom: "0px" }}>Card No.</Form.Label>
                            <Form.Control

                                value={cardNumber}
                                onChange={(e) => {
                                    setCardNumber(e.target.value);
                                    validateCard(e.target.value);

                                }}

                                className=' input-box-identity form-control-sm' type="text" id="cardNo" placeholder=" Card number" />
                        </Form.Group>

                        <Form.Group style={{ marginBottom: "14px" }} >
                            <Form.Label style={{ marginBottom: "0px" }}>
                                Membership Class
                            </Form.Label>

                            <Form.Select


                                onChange={(e) => {
                                    setMembershipClass(e.target.value);
                                }}

                                className='form-selector'>
                                <option key={1} value={""}>Select Membership Class</option>
                                {myClasses?.data?.data?.map((data) => {
                                    return <option className='input-box' key={data.mid} value={data.mid
                                    }>{data.mcname}</option>
                                })}

                            </Form.Select>

                        </Form.Group>
                        <Form.Group controlId="date">
                            <Form.Label style={{ marginBottom: "0px" }}>Start Date</Form.Label>
                            <Form.Control


                                className=' input-box-identity form-control-sm'
                                type="date"
                                name="date"
                                value={startDate}
                                onChange={(e) => {

                                    setStartDate(e.target.value);
                                }}

                            />
                        </Form.Group>
                        <Form.Group controlId="date">
                            <Form.Label style={{ marginBottom: "0px" }}>End Date</Form.Label>
                            <Form.Control


                                className=' input-box-identity form-control-sm'
                                type="date"
                                name="date"
                                value={endDate}
                                onChange={(e) => {

                                    setEndDate(e.target.value);
                                }}

                            />
                        </Form.Group>

                        <div>

                            <LoaderButton onClick={() => {
                                
                                if (cardNumber === "" || membershipClass === "" || startDate === "" || endDate === "" || compareDates(startDate, endDate) === 1) {
                                    if (cardNumber === "") {
                                        twit("info", "Please input the card number.");
                                    } else if (membershipClass === "") {
                                        twit("info", "Please select a membership class.");
                                    } else if (startDate === "") {
                                        twit("info", "Please select a start date.");
                                    } else if (endDate === "") {
                                        twit("info", "Please select an end date.");
                                    } else if (compareDates(startDate, endDate) === 1) {
                                        twit("info", "End date should be greater than start date");
                                    }

                                } else {


                                    if (cardValidity === "valid") {

                                        createMember.post({ mid: membershipClass, hid: cardNumber, dfrom: startDate, dto: endDate }).then((data) => {
                                            if (data?.type === "data") {
                                                setNewMember(false);
                                                setRefresh(!refresh);
                                                twit("success", data?.data?.message);
                                            } else {
                                                
                                                twit("info", data?.error?.response?.data?.data);
                                            }

                                        });
                                    } else if (cardValidity === "invalid") {

                                        twit("info", "Membership not supported on entered card.");

                                    } else if (cardValidity === "invalidCard") {
                                        twit("info", "Card number is invalid. ");
                                    } else {
                                        twit("error", "Network error ");
                                    }

                                }

                            }} loading={loading}
                                variant={"primary"}
                                label={" Add "}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}

                            />


                        </div>








                    </div>



                </Modal>}
                {newClass && <Modal open={true} title={'Create new Class'} onCancel={() => {
                    setNewClass(false);
                }}
                    footer={[


                    ]}
                    className='allmodals'
                >

                    <div>
                        <Form.Group controlId="membershipClass">
                            <Form.Label id="MembershipClass" style={{ marginBottom: "0px" }}>Membership Class Name</Form.Label>
                            <Form.Control
                                name='mclass'
                                value={lclassName}
                                onChange={(e) => {
                                    setLclassName(e.target.value);

                                }}

                                className=' input-box-identity form-control-sm' type="text" id="cardNo" placeholder=" Loyalty Class Name" />
                        </Form.Group>

                        <div>

                            <LoaderButton onClick={() => {
                                if (lclassName === "") {
                                    twit("info", "Please input a valid name.")
                                } else {
                                    createClass.post({ name: lclassName }).then((data) => {
                                        if (data?.type === "data") {
                                            setRefresh(!refresh);
                                            setNewClass(false);
                                            twit("success", data?.data?.message);
                                        } else {

                                            twit("info", data?.error?.response?.data?.data);
                                        }
                                    });
                                }

                            }} loading={loading}
                                variant={"primary"}
                                label={" Create "}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}

                            />


                        </div>








                    </div>



                </Modal>}



                <div style={{ textAlign: "end" }} >

                    <LoaderButton onClick={() => {
                        setNewMember(true);
                    }} loading={false}
                        variant={"primary"}
                        label={"Add Member"}
                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        className={"mx-1 my-1"}
                    />
                    <LoaderButton onClick={() => {
                        setNewClass(true);
                    }} loading={false}
                        variant={"primary"}
                        label={"Add New Class"}
                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        className={"mx-1 my-1"}
                    />

                </div>




                <div className='table-responsive'>

                    <Table striped hover style={{ cursor: "pointer" }}>
                        <thead>
                            <tr>

                                <th>Name</th>
                                <th>Points</th>
                                <th>Status</th>




                            </tr>
                        </thead>
                        <tbody>
                            {myMembers?.data?.data?.map((member, index) => (
                                <tr key={index} onClick={() => {

                                    setRowdata(member);



                                }}>

                                    <td>{member.HumanIdentification.fhname + " " + member.HumanIdentification.lhname}</td>
                                    <td>{member.dto}</td>
                                    <td>{member.status ? "Active" : "Inactive"}</td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </div>
            </Container>



        )
    }
}
export default MyLoyalty;
