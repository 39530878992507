import { Button } from 'antd';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as GiIcon from 'react-icons/gi';



const TicketSellerSearch = ({ onSubmit, users = [],isVendor,setComissionPerTicket,comissionPerTicket }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };





  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
    
  };



  const handleSelectAll = () => {

    if (selectedUsers?.length == users?.length) {
      setSelectedUsers([])
    } else {
      setSelectedUsers(users?.map((data) => { return data.id }))
    }

   
  };




  const handleSubmit = () => {
    const selectedUserObjects = users.filter(user => selectedUsers.includes(user.id));
    onSubmit(selectedUserObjects);

  };

  const filteredUsers = users?.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) || user?.EventPassTitle?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="user-selection-component">

      <Form.Control
        className=' input-box-events form-control-sm my-2'
        type="text"
        placeholder={`Search ${isVendor?'vendors':'sellers'}...`}
        value={searchTerm}
        onChange={handleSearch}

      />
      {
        isVendor?
        <>
            <p className='my-0 text-muted' style={{fontSize:'11px'}}>Commission Per Ticket</p>
            <Form.Control
              className=' input-box-events form-control-sm mt-0 mb-2'
              type="number"
              placeholder={`commission per ticket`}
              value={comissionPerTicket}
              onChange={(e) => { setComissionPerTicket(e.target.value) }}

            />
        </>
        
      :
      <></>
      }
      
      <ul className="user-list">
        {filteredUsers?.length == users?.length ?

          <li key={'1'} className="user-item">
            <label className="user-label">
              <input
                type="checkbox"
                checked={selectedUsers.length == users.length}
                onChange={() => handleSelectAll()}
                className="user-checkbox"
              />

              <span className="user-name">{'Select All'}</span>
            </label>
          </li>

          :

          <></>



        }

        {filteredUsers.map((user) => (
          <li key={user.id} className="user-item">
            <label className="user-label">
              <input
                type="checkbox"
                checked={selectedUsers.includes(user.id)}
                onChange={() => handleUserSelection(user.id)}
                className="user-checkbox"
              />
              <img src={user.face} alt={user.name} className="user-image" />
              <span className="user-name">{`${user?.name}`}</span>
            </label>
          </li>
        ))}
      </ul>
      

      <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={handleSubmit}  >
          <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add {isVendor?'Vendor(s)':'Veller(s)'}
        </Button>
      </div>
      <style jsx>{`
        .user-selection-component {
          font-family: Arial, sans-serif;
          max-width: 400px;
          margin: 0 auto;
          padding: 20px;
          
        }
        .search-input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .user-list {
          list-style-type: none;
          padding: 0;
          margin-bottom: 20px;
          max-height:500px;
          overflow-y: scroll;
        }
        .user-item {
          margin-bottom: 10px;
        }
        .user-label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .user-checkbox {
          margin-right: 10px;
        }
        .user-image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .user-name {
          font-size: 14px;
        }
        .submit-button {
          width: 100%;
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          margin-top: 10px;
        }
        .submit-button:hover {
          background-color: #0056b3;
        }
        .email-toggle {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .email-toggle label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .email-toggle input {
          margin-right: 10px;
        }
      `}</style>
    </div>
  );
};

export default TicketSellerSearch;