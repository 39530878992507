import axios from 'axios';


export default axios.create({
    baseURL: 'https://pinnitagsapiv1.westus.cloudapp.azure.com'
});


// export default axios.create({
//     baseURL: 'https://api.pinnitags.com/'
// });


// export default axios.create({
//     baseURL: 'http://localhost:9091'
// });





