import React from 'react';
import { Container } from 'react-bootstrap';
import useAddsOn from '../hooks/getAddsOn';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card1 from './IdentityCards/Card1'
import { useEffect } from 'react';
import { useState } from 'react';
import useFetch from '../hooks/hooks/getData';
import Loader from './Loader';
import { twit } from './Notificationpopout';

const IdentityAddsOn = () => {

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const rid = sessionStorage.getItem('rid');
  const addsOnUrl = '/api/products/ads-on'

  const alladdsOn = useFetch(addsOnUrl, setLoading, refresh);


  const productsUrl = `/api/products/${rid}`
  const allProducts = useFetch(productsUrl, setLoading);

  const navigate = useNavigate();

  const isActive = (productPtid) => {

    const match = allProducts?.data?.data.filter((data) => {
      return data.ProductType.ptid === productPtid;
    });

    if (match?.length > 0) {

      return false;

    } else {

      return true;

    }

  }




  if (loading) {
    return (<Loader />);
  } else {

    return (
      <Container className='dashboard-container  justify-content-center' style={{ minHeight: "100vh" }}>
        {/* first row */}
        <div className="my-4 identity-adds-on">

          {alladdsOn?.data?.data.slice(0, 4).map((product, index) => (
            <div key={index}>
              <Card1 addsOn={true} active={isActive(product?.ptid)} title={product.rname} productData={product} refresh={refresh} refreshFunction={setRefresh} />
            </div>

          ))}


          {alladdsOn?.error && <div style={{ textAlign: "center" }}>
            *No products loaded*
          </div>
          }


        </div>
        {/* second row */}

        <div className="my-4 identity-adds-on">



          {alladdsOn?.data?.data.slice(4, alladdsOn?.data?.data.length).map((product, index) => (
            <div key={index}>
              <Card1 addsOn={true} active={isActive(product?.ptid)} title={product.rname} productData={product} refresh={refresh} refreshFunction={setRefresh} />
            </div>

          ))}
          {alladdsOn?.error && <div style={{ textAlign: "center" }}>
            *No products loaded*
          </div>
          }


        </div>
      </Container>
    );
  }
}


export default IdentityAddsOn;
