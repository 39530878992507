import React, { useEffect, useState } from 'react';
import { Card, Container, Table,  Form, Row, Col, Navbar, Nav, FormControl } from 'react-bootstrap';
import { Modal, Button } from 'antd';
import Loader from './Loader';
import ColoredD from './ColorSport';
import { useNavigate } from 'react-router-dom';
import useFetch from '../hooks/hooks/getData';
import IdentityCard from './IdentityCard';
import * as BiIcons from 'react-icons/bi';
import * as BsIcons from 'react-icons/bs';
import * as CiIcons from 'react-icons/ci';
import * as MdIcons from 'react-icons/md';
import { Dropdown } from 'antd';
import useAuth from '../hooks/useAuth';
import Pagination from './Pagination';
import Cookies from 'js-cookie';
import { patchData } from '../Utilities/utilities';
import { twit } from './Notificationpopout';
import FilterSort from './FilterSort';
import useCart from '../hooks/hooks/useCart';
import { verifyEngineBaseUrl } from '../Utilities/globals';



const HumanIdentity = () => {


    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [cardForm, setCardForm] = useState(false)
    const [rowdata, setRowdata] = useState();
    const [people, setPeople] = useState();
    const [sortedPeople, setSortedPeople] = useState();
    const [sorti, setSorti] = useState("desc");
    const [filterby, setFilterby] = useState('');
    const [filterbyValue, setFilterbyValue] = useState();
    const [condition, setCondition] = useState();
    const [sortby, setSortby] = useState("number");
    const [orderButton, setOrderButton] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostPerPage] = useState(5);
    const [activate, setActivate] = useState(false);
    const [deactivate, setDeactivate] = useState(false);
    const [refresh, setRefresh] = useState(false);






    const productsCart = useCart();
    const rid = sessionStorage.getItem('rid');
    const { cardCartItems, setCardCartItems } = useAuth();
    // getting data from the api 
    const { data, error } = useFetch(`/api/products/registered/people/${rid}`, setLoading, refresh)
    

    const userLogo = useFetch('/api/products/identity/card/user/logo', setLoading);
    const userInfo = useFetch('/api/auth/user/profile', setLoading);


    //getting percentage
    const Percentage = (numerator, denominator) => {

        const percent = (numerator / denominator) * 100;

        return percent;
    };



    useEffect(() => {
        if (data) {
            filterPeople();
            if (sortedPeople) {
                setPeople(sortedPeople);

            }
            // } else {
            //     setPeople(data.data);
            // }

            //     const objectLength = Object.keys(data.data[0].HumanIdentification).length;
            //     const nullValuesCount = Object.values(data.data[0].HumanIdentification).filter(val => val !== null).length;
            
            //    Percentage(nullValuesCount,objectLength);
        }

    }, [data]);


    const [successMsg, setSuccessMsg] = useState()
    const [errorMsg, setErrorMsg] = useState()







    const handleCancel = () => {
        setCardForm();
        setSuccessMsg();

    }

    const handleCancelerror = () => {
        setErrorMsg(false);
        setRowdata(false);

    }

    //filtering logic

    const filterPeople = (cond, filtby) => {
        if (filterbyValue && filtby === "Position") {
            const filteredData = data.data.filter((value) => {
               
                if (cond === "equal") {

                    return value.HumanIdentification.Position.toLowerCase() === filterbyValue.toLowerCase()
                } else if (cond === "not equal") {
                    return value.HumanIdentification.Position.toLowerCase() !== filterbyValue.toLowerCase()
                } else if (cond === "contains") {
                    return value.HumanIdentification.Position.toLowerCase().includes(filterbyValue.toLowerCase())
                } else {
                    return !value.HumanIdentification.Position.toLowerCase().includes(filterbyValue.toLowerCase())
                }

            });
            setPeople(filteredData);
            

        } else if (filterbyValue && filtby === "TagStatus") {
            const filteredData = data.data.filter((value) => {

                if (cond === "equal") {
                    return value.HumanIdentification?.TagStatus.sname.toLowerCase() === filterbyValue.toLowerCase()
                } else if (cond === "not equal") {
                    return value.HumanIdentification?.TagStatus.sname.toLowerCase() !== filterbyValue.toLowerCase()
                } else if (cond === "contains") {
                    return value.HumanIdentification?.TagStatus.sname.toLowerCase().includes(filterbyValue.toLowerCase())
                } else {
                    return !value.HumanIdentification?.TagStatus.sname.toLowerCase().includes(filterbyValue.toLowerCase())
                }



            }

            );
            setPeople(filteredData);
        } else if (filterbyValue && filtby === "name") {
            const filteredData = data.data.filter((value) => {

                if (cond === "equal") {
                    return value.HumanIdentification.fhname.toLowerCase() === filterbyValue.toLowerCase()
                } else if (cond === "not equal") {
                    return value.HumanIdentification.fhname.toLowerCase() !== filterbyValue.toLowerCase()
                } else if (cond === "contains") {
                    return value.HumanIdentification.fhname.toLowerCase().includes(filterbyValue.toLowerCase())
                } else {
                    return !value.HumanIdentification.fhname.toLowerCase().includes(filterbyValue.toLowerCase())
                }


            });
            setPeople(filteredData);
        } else if (filterbyValue && filtby === "Id Status") {
            const filteredData = data.data.filter((value) => {

                if (cond === "equal") {
                    return value.HumanIdentification.IdStatus?.sname.toLowerCase() === filterbyValue.toLowerCase()
                } else if (cond === "not equal") {
                    return value.HumanIdentification.IdStatus?.sname.toLowerCase() !== filterbyValue.toLowerCase()
                } else if (cond === "contains") {
                    return value.HumanIdentification.IdStatus?.sname.toLowerCase().includes(filterbyValue.toLowerCase())
                } else {
                    return !value.HumanIdentification.IdStatus?.sname.toLowerCase().includes(filterbyValue.toLowerCase())
                }


            });
            setPeople(filteredData);
        } else if (filterbyValue && filtby === "Id State") {
            const filteredData = data.data.filter((value) => {

                if (cond === "equal") {
                    return value.HumanIdentification.IdState?.sname.toLowerCase() === filterbyValue.toLowerCase()
                } else if (cond === "not equal") {
                    return value.HumanIdentification.IdState?.sname.toLowerCase() !== filterbyValue.toLowerCase()
                } else if (cond === "contains") {
                    return value.HumanIdentification.IdState?.sname.toLowerCase().includes(filterbyValue.toLowerCase())
                } else {
                    return !value.HumanIdentification.IdState?.sname.toLowerCase().includes(filterbyValue.toLowerCase())
                }


            });
            setPeople(filteredData);

        } else {
            setPeople(data.data);
        }

    }


    //filter ready to request handler

    const readyTorequest = () => {
        const filteredData = data?.data?.filter((value) => {
            setOrderButton(true);
            //return value.HumanIdentification["TagStatus "].sname === "Complete"&&value.HumanIdentification.IdStatus?.sname!=="Requested"
            return value.HumanIdentification.TagStatus.sname === "Complete"

        });
        setPeople(filteredData);
    }

    






    //sorting logic

    const sortByNo = (value) => {

        if (people) {
            const sortedData = people.sort((a, b) => {
                


                return value === "asc" ? b.number - a.number : a.number - b.number;

                // if(a.HumanIdentification.Regstat==="F0B7C01D-F120-43A7-A843-AFEDC624A7BA"&& b.HumanIdentification.Regstat==="A5BC347D-D4FD-43E5-924A-938CE333A2D4"){
                //     return -1
                // }else{
                //     return 1
               // }
            });
            setSortedPeople(sortedData);


        } else {
            return 1

        }




    }

    const sortByRegStatus = (value) => {
        
        if (people) {
            const sortedData = people.sort((a, b) => {
                
                if (a.HumanIdentification?.TagStatus.sname === 'Complete' && b.HumanIdentification?.TagStatus.sname === 'InComplete') {

                    return value === "asc" ? 1 : -1; // a comes before b in the sorted array
                } else if (a.HumanIdentification?.TagStatus.sname === 'InComplete' && b.HumanIdentification?.TagStatus.sname === 'Complete') {

                    return value === "asc" ? -1 : 1; // a comes after b in the sorted array
                } else {
                    return 0; // the order of a and b doesn't change
                }
            });
            setSortedPeople(sortedData);


        } else {
            return 1

        }




    }

    const sortByLevel = (value) => {

        if (people) {
            const sortedData = people.sort((a, b) => {
                
                if (a.HumanIdentification.Position.toLowerCase() < b.HumanIdentification.Position.toLowerCase()) {

                    return value === "asc" ? 1 : -1; // a comes before b in the sorted array
                } else if (a.HumanIdentification.Position.toLowerCase() > b.HumanIdentification.Position.toLowerCase()) {

                    return value === "asc" ? -1 : 1; // a comes after b in the sorted array
                } else {
                    return 0; // the order of a and b doesn't change
                }
            });
            setSortedPeople(sortedData);


        } else {
            return 1

        }




    }

    //all sort function
    const sortFunction = (value, value2) => {
        if (value === "Position") {

            sortByLevel(value2);


        } else if (value === "TagStatus") {
            sortByRegStatus(value2);
        } else if (value === "number") {
            sortByNo(value2);
        } else {
            setPeople(data.data);
        }


    }



    //handle focus
    const handleSelectFocus = (event) => {
        event.target.style.outline = 'none';
        event.target.style.boxShadow = 'none';
    };

    //pagination logic
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPeople = people?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }


    //filter menu
    const filterMenu = (



        <Card className="align-items-center border-0" style={{ fontSize: "12px", padding: "10px", display: "flex", flexDirection: "column", backgroundColor: "#CDCDCD" }}>
            <div style={{ marginBottom: "0px", display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", alignItems: "center" }}>
                <p><span style={{ fontWeight: "bold" }}>Advanced Filters  </span> Showing {people?.length} of {data?.data?.length} rows. </p>
                <p>|</p>
                <div style={{ width: "10%" }}>
                    <p style={{ width: "100%", fontWeight: "bold" }}>Results : <span style={{}}>{people?.length}</span></p>
                </div>
                <p>|</p>
                <p>
                    <BiIcons.BiReset title='reset filter' style={{ height: "20px", width: "20px" }} onClick={() => {
                        setPeople(data?.data);
                        setOrderButton();
                        setFilterby("default");
                        setCondition("default");
                        setFilterbyValue("");
                    }} />
                </p>

            </div>
            <div className="align-items-center" style={{ fontSize: "12px", padding: "10px", display: "flex", flexDirection: "row", paddingTop: "0px" }}>
                <div style={{ width: "30%", margin: "10px" }}>
                    <p style={{ width: "100%", marginTop: "10px" }}>Where</p>
                </div>
                <div style={{ width: "100%", margin: "10px" }}>
                    <Form.Select
                        onFocus={handleSelectFocus}
                        style={{ borderRadius: "0px", width: "170px" }}
                        title='Select column to filter by'
                        className='form-selector'
                        as="select"
                        value={filterby}
                        onChange={(e) => {
                            setFilterby(e.target.value);
                            filterPeople(condition, e.target.value);
                        }}
                    >
                        <option key={1} value="default">Column </option>
                        <option key={2} value="Position">Level</option>
                        <option key={3} value="TagStatus">Registration Status</option>
                        <option key={4} value="name">Name</option>

                        <option key={4} value="Id Status">Id Status</option>
                        <option key={5} value="Id State">Id State</option>



                    </Form.Select>
                </div>
                <div style={{ width: "100%", margin: "10px" }}>
                    <Form.Select
                        onFocus={handleSelectFocus}
                        style={{ borderRadius: "0px", width: "130px" }}
                        title='Select condition '
                        className='form-selector'
                        as="select"
                        value={condition}
                        onChange={(e) => {
                            setCondition(e.target.value);
                            filterPeople(e.target.value, filterby);
                        }}
                    >
                        <option key={1} value="default">Condition</option>
                        <option key={2} value="equal">Is</option>
                        <option key={3} value="not equal">Is not</option>
                        <option key={4} value="contains">Contains</option>
                        <option key={5} value="not contains">Not contain</option>

                    </Form.Select>
                </div>




                <div style={{ width: "100%", margin: "10px" }}>


                    <Form.Control
                        onFocus={handleSelectFocus}
                        style={{ fontSize: "12px", borderRadius: "0px" }}
                        className='form-control-sm'
                        type="text"
                        value={filterbyValue}
                        onChange={(e) => {

                            setFilterbyValue(e.target.value);


                            setOrderButton();





                        }}
                        onKeyUp={() => {
                            filterPeople(condition, filterby);
                        }}
                        placeholder="Value"
                    />

                </div>



            </div>
            {/* <div>
                <BiIcons.BiReset title='reset filter' style={{height:"20px",width:"20px",margin:"10px"}} onClick={()=>{
                    setPeople(data?.data);
                    setOrderButton();
                }}/>
                <Button

                        title='sort the columns'
                        onClick={() => {
                            readyTorequest()
                            
                        }}
                        
                        className='identity-form-button' variant="success" type="button">

                        <span style={{  fontWeight: "bold" }}> <BiIcons.BiSortAlt2 /> Filter Ready to request </span>
                    </Button>
                  {orderButton&&(
                    <Button

                    title='sort the columns'
                    onClick={() => {
                        
                       handleAddtocart(); 
                    }}
                    
                    className='identity-form-button' variant="success" type="button">

                    <span style={{  fontWeight: "bold" }}> <MdIcons.MdOutlineAddShoppingCart /> Add to Cart </span>
                </Button>
                  )}
                </div> */}

        </Card>











    );


    //sort menu
    const sortMenu = (



        <Card className="align-items-center border-0" style={{ fontSize: "12px", padding: "10px", display: "flex", flexDirection: "row", backgroundColor: "#CDCDCD" }}>


            <div style={{ width: "100%", margin: "10px" }}>
                <Form.Select
                    onFocus={handleSelectFocus}
                    style={{ fontSize: "12px", borderRadius: "0px" }}
                    title='Select column to sort by'
                    className='form-selector'
                    as="select"
                    value={sortby}
                    onChange={(e) => {
                        setSortby(e.target.value);
                        
                        sortFunction(e.target.value, sorti);
                        
                    }}


                >
                    <option key={4} value="number">No.</option>
                    <option key={2} value="Position">Level</option>
                    <option key={3} value="TagStatus">Registration Status</option>


                </Form.Select>
            </div>
            <div style={{ width: "100%", margin: "10px" }}>
                <Form.Select
                    onFocus={handleSelectFocus}
                    style={{ fontSize: "12px", borderRadius: "0px" }}
                    title='Ascending or Descending ?'
                    className='form-selector'
                    as="select"

                    onChange={(e) => {
                        setSorti(e.target.value);
                        sortFunction(sortby, e.target.value)
                    }}
                >

                    <option key={2} value="desc" > Ascending </option>
                    <option key={3} value="asc"> Descending</option>


                </Form.Select>
            </div>

            {/* <div style={{ width: "100%", margin: "10px" }}>
                    <Button

                        title='sort the columns'
                        onClick={() => {
                            sortFunction();
                        }}
                        className='identity-form-button' variant="success" type="button">

                        <span style={{  fontWeight: "bold" }}> <BiIcons.BiSortAlt2 /> Sort </span>
                    </Button>
                </div> */}






        </Card>








    )


    //getting number of gold cards
    const goldcards = cardCartItems?.filter((cards) => {
        
        return cards.HumanIdentification.CardType?.ctype === "gold";
    })




    //getting number of bronze cards
    const bronzecards = people?.filter((cards) => {
        return cards.HumanIdentification.CardType?.ctype === "Bronze";
    })

    //getting number of diamond cards
    const diamondcards = people?.filter((cards) => {
        return cards.HumanIdentification.CardType?.ctype === "Diamond";
    })

    //getting number of silver cards
    const silvercards = people?.filter((cards) => {
        return cards.HumanIdentification.CardType?.ctype === "Silver";
    })


    //getting number of entreprise cards
    const platinumcards = people?.filter((cards) => {
        return cards.HumanIdentification.CardType?.ctype === "Platinum";
    })

    //function to get card ranges
    const getCardRange = (qty) => {
        if (qty <= 50) {
            return "A";
        } else if (qty > 50 && qty <= 200) {
            return "B";
        } else if (qty > 200 && qty <= 500) {
            return "C";
        } else if (qty > 500) {
            return "D";
        }
    }





    const cardIcon = <BsIcons.BsPersonVcard style={{ height: "50px", width: "50px" }} />
    // const cards = [

    //     { name: "Platinum cards", qty: platinumcards?.length, type: "Platinum", range:getCardRange(platinumcards?.length),platinumcards },
    //     { name: "Diamond cards", qty: diamondcards?.length, type: "Diamond", range:getCardRange(diamondcards?.length) ,diamondcards},
    //     { name: "Gold cards", qty: goldcards?.length,  type: "Gold", range:getCardRange(goldcards?.length),goldcards },
    //     { name: "Silver cards", qty: silvercards?.length,  type: "Silver", range:getCardRange(silvercards?.length),silvercards },
    //     { name: "Bronze cards", qty: bronzecards?.length, type: "Bronze", range:getCardRange(bronzecards?.length),bronzecards }
    // ]

    const cards = people?.filter((value) => {
        
        return value.HumanIdentification.TagStatus.sname === "Complete"

    }).map((data) => {
        return data.hid
    })


    //function to allow  add to cart 

    const handleAddtocart = () => {
        //setCardCartItems(people);
        if(cards.length>0){
            productsCart.addItem(cards, setLoading).then((message) => {
                productsCart.getItems()
                twit('success', message)
            }).catch((message) => {
                twit('info', message)
            })
        }else{
            twit('info', 'Registration is incomplete')
        }
        

    }











    const loggedin = Cookies.get('loggedin');


    if (!loggedin) {

        navigate('/');
        return null;
    } else {





        if (loading) {
            return (<Loader />);
        } else {


            return (

                <Container className='meetings-container'>
                    {cardForm && <Modal open={true} title={'Register'} onCancel={handleCancel} onOk={handleCancel}
                        footer={[


                        ]}
                    >












                    </Modal>}

                    {(activate && rowdata) && <Modal open={true} title={'Card Activation'} onCancel={() => {
                        setActivate(false);
                    }}
                        footer={[


                        ]}
                        className='allmodals'
                    >
                        <p>Activating card for {rowdata.HumanIdentification.fhname + " " + rowdata.HumanIdentification.lhname}</p>
                        <div>
                            <Button
                                onClick={() => {

                                    const response = patchData(`/api/products/card/activation/${"activate"}`, { hid: rowdata?.hid }, setLoading)
                                    response.then((data) => {
                                        if (data?.data) {
                                            twit("success", data?.data?.data?.data);
                                            setActivate(false);
                                            setRowdata(false);
                                            setRefresh(!refresh);
                                        } else {
                                            
                                            twit("error", "An error occurred during activation.");
                                        }

                                    })



                                }}
                                className='identity-form-button' variant="success" type="button">
                                Activate card
                            </Button>
                        </div>


                    </Modal>}


                    {(deactivate && rowdata) && <Modal open={true} title={'Card Deactivation'} onCancel={() => {
                        setDeactivate(false);
                    }}
                        footer={[


                        ]}

                        className='allmodals'
                    >
                        <p>Deactivate card for {rowdata.HumanIdentification.fhname + " " + rowdata.HumanIdentification.lhname}</p>
                        <div>
                            <Button
                                onClick={() => {
                                    const response = patchData(`/api/products/card/activation/${"deactivate"}`, { hid: rowdata?.hid }, setLoading)
                                    response.then((data) => {
                                        if (data?.data) {
                                            twit("success", data?.data?.data?.data);
                                            setDeactivate(false);
                                            setRowdata(false);
                                            setRefresh(!refresh);
                                        } else {
                                            
                                            twit("error", "An error occurred during deactivation.");
                                        }

                                    })

                                }}
                                className='identity-form-button' variant="danger" type="button">
                                Deactivate card
                            </Button>
                        </div>

                    </Modal>}
                    {rowdata && <Modal open={true} title={rowdata.topic} onCancel={handleCancelerror} onOk={handleCancelerror}
                        footer={[


                        ]}

                        className="allmodals"
                    >



                        {/* <IdentityCard
                            name={rowdata.HumanIdentification.fhname + " " + rowdata.HumanIdentification.lhname}
                            position={rowdata.HumanIdentification.Position}
                            contact={rowdata.HumanIdentification.contactno}
                            email={rowdata.HumanIdentification.Email}
                            address={rowdata.HumanIdentification.Addres}
                            cardNo={rowdata.hid}
                        /> */}

                        <iframe
                            src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${userInfo?.data?.data?.Tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${rowdata?.HumanIdentification?.DOB?.substring(0, 10)}&Nationality=${rowdata?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${rowdata?.HumanIdentification?.Gender?.gender}&Position=${rowdata?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${rowdata?.HumanIdentification?.fhname}&Lname=${rowdata?.HumanIdentification?.lhname}&hid=${rowdata?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pct1'}&image=${rowdata?.HumanIdentification?.Face}`}
                            width="100%"
                            height="300px"

                        />

                        <div>
                            <Button
                                onClick={() => {
                                    navigate(`/identityupdate/${rowdata.hid}`)
                                }}
                                className='identity-form-button' variant="success" type="button">
                                Update
                            </Button>
                            {(rowdata?.HumanIdentification?.IdState.sname !== "Active") && (
                                <Button
                                    onClick={() => {

                                        setActivate(true);
                                    }}
                                    className='identity-form-button' variant="success" type="button">
                                    Activate
                                </Button>
                            )}

                            {(rowdata?.HumanIdentification?.IdState.sname === "Active") && (
                                <Button
                                    onClick={() => {
                                        setDeactivate(true);
                                    }}
                                    className='identity-form-button' variant="danger" type="button">
                                    Deactivate
                                </Button>
                            )}


                        </div>


                    </Modal>}




                    <Card className='border-0'>

                        <Card.Body className='table-responsive' style={{ paddingTop: "0px" }}>

                            {/* <FilterSort currentData={people} tableData={data?.data} setCurrentData={setPeople} /> */}

                            <Navbar bg="secondary" expand="lg" style={{ height: "40px", borderRadius: "4px", marginTop: "0px", minWidth: "480px" }} >



                                <Nav className="mr-auto" style={{ color: "black", display: "flex", flexDirection: "row" }} >
                                    <Nav.Item className="mx-3">
                                        <Dropdown overlay={sortMenu} trigger={['click']}>
                                            <Button  style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary"><BiIcons.BiSort /> Sort</Button>
                                        </Dropdown >
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Dropdown overlay={filterMenu} trigger={['click']}>
                                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary"><CiIcons.CiFilter /> Filter</Button>
                                        </Dropdown>

                                    </Nav.Item>
                                </Nav>


                            </Navbar>









                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Level</th>
                                        <th>Registration Status</th>
                                        <th> Card Status</th>
                                        <th>Card State</th>
                                        <th>Card Type</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPeople?.map((data, index) => (
                                        <tr key={index} onClick={() => { setRowdata(data) }}>
                                            <td>{data.number}</td>
                                            <td>{data.HumanIdentification.fhname}  {data.HumanIdentification.lhname}</td>
                                            <td>{data.HumanIdentification.Position}</td>
                                            <td>{data.HumanIdentification.TagStatus?.sname}</td>
                                            <td>{data.HumanIdentification.IdStatus?.sname}</td>
                                            <td>{data.HumanIdentification.IdState?.sname}</td>
                                            <td>{data.HumanIdentification.CardType?.ctype}</td>



                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={people?.length}
                                paginate={paginate}

                            />


                        </Card.Body>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <BiIcons.BiReset title='reset filter' style={{ height: "20px", width: "20px", margin: "10px" }} onClick={() => {
                                setPeople(data?.data);
                                setOrderButton();
                            }} />
                            <Button

                                title='sort the columns'
                                onClick={() => {
                                    readyTorequest()

                                }}

                                // className='identity-form-button' variant="success" type="button"
                                className='identity-form-button' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary"
                                >

                                <span style={{ fontWeight: "bold" }}> <BiIcons.BiSortAlt2 /> Filter Ready to request </span>
                            </Button>
                            {/* {orderButton && (
                                <div>
                                     {currentPeople?.length>0&&(
                                         <Button

                                         title='sort the columns'
                                         onClick={() => {
                                             if (productsCart?.items?.items?.length > 0) {
                                                 twit("info", "Items already added");
                                             } else {
                                                 handleAddtocart();
                                             }
     
                                         }}
     
                                         className='identity-form-button' variant="success" type="button">
                                         
                                         <span style={{ fontWeight: "bold" }}> <MdIcons.MdOutlineAddShoppingCart />{productsCart?.items?.items?.length > 0 ? "Added" : "Add to Cart"}  </span>
                                     </Button>
                                        )}
                                </div>
                               
                               
                            )} */}
                             {currentPeople?.length>0&&(
                                         <Button

                                         title= {productsCart?.items?.items?.length > 0?'Items already added to cart':'Add ready cards to the cart'}
                                         onClick={() => {
                                             if (productsCart?.items?.items?.length > 0) {
                                                 twit("info", "Items already added");
                                             } else {
                                                 handleAddtocart();
                                             }
                                             
                                         }}
     
                                         className='identity-form-button' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary">
                                         
                                         <span style={{ fontWeight: "bold" }}> <MdIcons.MdOutlineAddShoppingCart />{productsCart?.items?.items?.length > 0 ? "Added" : "Add to Cart"}  </span>
                                     </Button>
                                        )}
                        </div>
                    </Card>
                </Container>
            );
        }
    }
}

export default HumanIdentity;
