import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import { formatPrice, getNairobiTime, getNairobiTimeWithParam, getformattedDate } from '../../Utilities/utilities'
import Loader from '../Loader';
import { fetchData } from '../../Utilities/apiRequests';
import Pagination from '../Pagination';
import { costPerCredit } from '../../Utilities/globals';

const BillsSummary = () => {


    const [starts, setStarts] = useState();
    const [ends, setEnds] = useState();
    const [loading, setLoading] = useState(false);
    const [baccounts, setBaccounts] = useState([]);
    const [activeAccount, setActiveAccount] = useState();
    const [bills, setBills] = useState();
    const [billsData, setBillsData] = useState();
    const [totalBills, setTotalBills] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchData(`/api/billing/accounts`, setLoading).then((response) => {

            setBaccounts(response.data.accounts)
            setActiveAccount(response.data.accounts.filter((data) => {
                return data.name == 'Primary'
            })[0]?.aid)

        }).catch((error) => {
            console.log('accounts loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading accounts')
        })

    }, []);

    useEffect(() => {

        if(activeAccount){
            fetchData(`/api/billing/accounts/bills/${activeAccount}`, setLoading).then((response) => {

            setBillsData(response.data.bills)
            

        }).catch((error) => {
            console.log('bills loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading bills')
        })
        }
        

    }, [activeAccount]);



    useEffect(() => {

        

        if(billsData){
            if((!ends) && (!starts)){
                setBills(billsData)
            }else if ((!ends)&&starts){
                setBills(billsData.filter((data)=>{
                    return (data.tdate).split('T')[0] >= starts
                }))
            }else if((!starts)&& ends){
                setBills(billsData.filter((data)=>{
                    return (data.tdate).split('T')[0] <= ends
                }))
            }else if(starts && ends){
                setBills(billsData.filter((data)=>{
                    return ((data.tdate).split('T')[0] <= ends) && ((data.tdate).split('T')[0] >= starts)
                }))
            }
        }
        

    }, [billsData,ends,starts]);

   
    

    useEffect(() => {

        let billsTotal = 0

        if(bills){
            for(const bill of bills){
                billsTotal += bill.debit
            }

            setTotalBills(billsTotal)
        }
        

    }, [bills]);



    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedBills = bills?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }






    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>
                <Row className='bills-summary-header'>
                    <Col md={6} >
                        <p className='mb-0' style={{ fontSize: '18px', fontWeight: 'bold' }}>PinniTAGS bill summary</p>
                        <p className='text-muted my-2'>Total Charges And Payment Information</p>
                    </Col>
                </Row>

                <Row className='my-2' >
                    <Col md={6} >
                        <p className='mb-0 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>Account</p>
                        <Row className='mt-2'>
                            <Col md={8}>
                            <Form.Group controlId="account">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                <Form.Select
                                    disabled
                                    name="account"
                                    onChange={(e) => {

                                    }}
                                    className='form-selector'>

                                    <option className='input-box' key={1} value={''}>{'Primary'}</option>
                                    {baccounts.map((data) => {
                                        return <option className='input-box' key={data?.aid} value={data?.aid}>{data?.name}</option>
                                    })}


                                </Form.Select>
                            </div>

                        </Form.Group>
                            </Col>
                        </Row>
                       

                    </Col>
                    <Col md={6} className='bill-summary-dates'>
                        <p className='mb-0 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>Billing Period</p>
                        <Row>
                            <Col md={6} className='my-2'>
                                <Form.Group className='my-2' controlId="eventDate">
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                        <div className='mx-2 FormLabels'>
                                            <p style={{ margin: 0 }}>Start</p>
                                        </div>
                                        <Form.Control
                                            className=' input-box-events form-control-sm'
                                            type="date"
                                            name="startDate"
                                            value={starts}
                                            onChange={(e) => {
                                                setStarts(e.target.value)
                                            }}
                                            
                                        />
                                    </div>

                                </Form.Group>
                            </Col>
                            <Col md={6} className='my-2'>

                                <Form.Group className='my-2' controlId="eventDate">

                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                        <div className='mx-2 FormLabels'>
                                            <p style={{ margin: 0 }}>End</p>
                                        </div>
                                        <Form.Control
                                            className=' input-box-events form-control-sm'
                                            type="date"
                                            name="endDate"
                                            value={ends}
                                            onChange={(e) => {
                                                setEnds(e.target.value)
                                            }}
                                            min={starts}
                                        />
                                    </div>

                                </Form.Group>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <Row  style={{ borderTop: '1px solid gray' }}>
                    <p className='mb-0 mt-2 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>Bills</p>
                    <div className='table-responsive'>
                        {paginatedBills?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th style={{ textAlign: "right" }}>#Credits</th>
                                        <th>Date</th>
                                        <th>Concept</th>
                                        <th>Charge Type</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedBills?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.debit)}</td>
                                            <td style={{fontSize:'10px',whiteSpace:'nowrap'}}>{getformattedDate(data.tdate)}</td>
                                            <td>{data.concept}</td>
                                            <td>{data.chargeType}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            
                            :

                            <p>No transactions.</p>

                        }

                        {bills?.length > 0 &&(
                            <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={bills?.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                        ) }

                    </div>
                    
                </Row>
                <Row  style={{ borderTop: '1px solid gray' }}>
                    <Col className='mt-3' md={8} style={{textAlign:'end'}} >
                        <p className='mb-0' style={{ fontSize: '18px', fontWeight: 'bold' }}>Estimated Credits Total:</p>
                    </Col>
                    <Col className='mt-3' md={4} style={{textAlign:'center'}} >
                        <p className='mb-0' style={{ fontSize: '20px', fontWeight: 'bold',borderBottom: '4px dotted #000' }}>{formatPrice(totalBills.toFixed(2))}</p>
                    </Col>
                </Row>
                <div className='my-2' style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%', paddingLeft: '10px' }}>

                            <p className='text-muted' style={{ margin: 0 }}>1 Credit = {costPerCredit} UGX</p>

                        </div>
            </Container>
        )
    }
}

export default BillsSummary
