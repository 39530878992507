
import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { Card, Button, Form, Navbar, Nav, } from 'react-bootstrap';
import * as BiIcons from 'react-icons/bi';
import * as CiIcons from 'react-icons/ci';
import { twit } from './Notificationpopout';





const FilterSort = ({ currentData, tableData, setCurrentData }) => {




    const [sorti, setSorti] = useState("desc");
    const [filterby, setFilterby] = useState('');
    const [filterbyValue, setFilterbyValue] = useState();
    const [condition, setCondition] = useState();
    const [sortby, setSortby] = useState("number");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dataToSort, setDataToSort] = useState(tableData);

    const handleMenuClick = (e) => {
        if (e.key !== "1") {
            setOpen(false);
            setOpen2(false);
        }
    };
    const handleOpenChange = (flag) => {
        setOpen(flag);
    };

    const handleOpenChange2 = (flag) => {
        setOpen2(flag);
    };









    const rowColdata = [{ colName: "Name", colDataKey: "HumanIdentification.fhname" }, { colName: "Id Status", colDataKey: "HumanIdentification.IdStatus?.sname" }, { colName: "Level", colDataKey: "HumanIdentification.Position" }];

    //handle focus
    const handleSelectFocus = (event) => {
        event.target.style.outline = 'none';
        event.target.style.boxShadow = 'none';
    };


    const getColDataObject = (name) => {
        const colDataObject = rowColdata.filter((data) => {

            return data.colName === name;
        });

        return colDataObject;
    }







    const filterData = (cond, filtby) => {
        if (filterbyValue && filtby && filterby !== "default") {
            const filteredData = tableData?.filter((value) => {

                if (cond === "equal") {

                    return eval(`value.${getColDataObject(filtby)[0]?.colDataKey}`)?.toLowerCase() === filterbyValue.toLowerCase();
                } else if (cond === "not equal") {
                    return eval(`value.${getColDataObject(filtby)[0]?.colDataKey}`)?.toLowerCase() !== filterbyValue.toLowerCase();
                } else if (cond === "contains") {
                    return eval(`value.${getColDataObject(filtby)[0]?.colDataKey}`)?.toLowerCase().toLowerCase().includes(filterbyValue.toLowerCase());
                } else {
                    return !eval(`value.${getColDataObject(filtby)[0]?.colDataKey}`)?.toLowerCase().toLowerCase().includes(filterbyValue.toLowerCase());
                }

            });
            setCurrentData(filteredData);
            setDataToSort(filteredData);
            
            

        } else {

            setCurrentData(tableData);
        }

    }

    const sortFunction = () => {
        return 0;
    }

    const sortData = (order, sortByvalue) => {

        if (dataToSort) {
            const sortedData = dataToSort.sort((a, b) => {
                
                if (eval(`a.${getColDataObject(sortByvalue)[0]?.colDataKey}`)?.toLowerCase() < eval(`b.${getColDataObject(sortByvalue)[0]?.colDataKey}`)?.toLowerCase()) {

                    return order === "asc" ? 1 : -1; // a comes before b in the sorted array
                } else if (eval(`a.${getColDataObject(sortByvalue)[0]?.colDataKey}`)?.toLowerCase() > eval(`b.${getColDataObject(sortByvalue)[0]?.colDataKey}`)?.toLowerCase()) {

                    return order === "asc" ? -1 : 1; // a comes after b in the sorted array
                } else {

                    return 0; // the order of a and b doesn't change
                }
            });
            
            
            setCurrentData(sortedData);


        } else {
            return 1

        }




    }




    const filterMenu = (



        <Card className="align-items-center border-0" style={{ fontSize: "12px", padding: "10px", display: "flex", flexDirection: "column", backgroundColor: "#CDCDCD" }}>
            <div style={{ marginBottom: "0px", display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", alignItems: "center" }}>
                <p><span style={{ fontWeight: "bold" }}>Advanced Filters  </span> Showing {currentData?.length} of {tableData?.length} rows. </p>
                <p>|</p>
                <div style={{ width: "10%" }}>
                    <p style={{ width: "100%", fontWeight: "bold" }}>Results : <span style={{}}>{currentData?.length}</span></p>
                </div>
                <p>|</p>
                <p>
                    <BiIcons.BiReset title='reset filter' style={{ height: "20px", width: "20px" }} onClick={() => {
                        setCurrentData(tableData);
                        setFilterby("default");
                        setCondition("default");
                        setFilterbyValue("");
                    }} />
                </p>

            </div>
            <div className="align-items-center" style={{ fontSize: "12px", padding: "10px", display: "flex", flexDirection: "row", paddingTop: "0px" }}>
                <div style={{ width: "30%", margin: "10px" }}>
                    <p style={{ width: "100%", marginTop: "10px" }}>Where</p>
                </div>
                <div style={{ width: "100%", margin: "10px" }}>
                    <Form.Select
                        onFocus={handleSelectFocus}
                        style={{ borderRadius: "0px", width: "170px" }}
                        title='Select column to filter by'
                        className='form-selector'
                        as="select"
                        value={filterby}
                        onChange={(e) => {
                            setFilterby(e.target.value);
                            filterData(condition, e.target.value);
                        }}
                    >
                        <option key={1} value="default">Column </option>
                        {rowColdata?.map((data, index) => (
                            <option key={index} value={data.colName}>{data.colName}</option>
                        ))}


                    </Form.Select>
                </div>
                <div style={{ width: "100%", margin: "10px" }}>
                    <Form.Select
                        onFocus={handleSelectFocus}
                        style={{ borderRadius: "0px", width: "130px" }}
                        title='Select condition'
                        className='form-selector'
                        as="select"
                        value={condition}
                        onChange={(e) => {
                            setCondition(e.target.value);
                            filterData(e.target.value, filterby);
                        }}
                    >
                        <option key={1} value="default">Condition</option>
                        <option key={2} value="equal">Is</option>
                        <option key={3} value="not equal">Is not</option>
                        <option key={4} value="contains">Contains</option>
                        <option key={5} value="not contains">Not contain</option>

                    </Form.Select>
                </div>




                <div style={{ width: "100%", margin: "10px" }}>


                    <Form.Control
                        onFocus={handleSelectFocus}
                        style={{ fontSize: "12px", borderRadius: "0px" }}
                        className='form-control-sm'
                        type="text"
                        value={filterbyValue}
                        onChange={(e) => {

                            setFilterbyValue(e.target.value);







                        }}
                        onKeyUp={() => {
                            filterData(condition, filterby);
                        }}
                        placeholder="Value"
                    />

                </div>



            </div>

        </Card>











    );


    const filterItems = [
        {
            key: '1',
            label: filterMenu
        }

    ];









    const sortMenu = (



        <Card className="align-items-center border-0" style={{ fontSize: "12px", padding: "10px", display: "flex", flexDirection: "row", backgroundColor: "#CDCDCD" }}>


            <div style={{ width: "100%", margin: "10px" }}>
                <Form.Select
                    onFocus={handleSelectFocus}
                    style={{ fontSize: "12px", borderRadius: "0px" }}
                    title='Select column to sort by'
                    className='form-selector'
                    as="select"
                    value={sortby}
                    onChange={(e) => {
                        setSortby(e.target.value);
                        
                        sortData(sorti, e.target.value);
                        
                    }}


                >
                    <option key={1} value="">Column</option>
                    {rowColdata?.map((data, index) => (
                        <option key={index} value={data.colName}>{data.colName}</option>
                    ))}

                </Form.Select>
            </div>
            <div style={{ width: "100%", margin: "10px" }}>
                <Form.Select
                    onFocus={handleSelectFocus}
                    style={{ fontSize: "12px", borderRadius: "0px" }}
                    title='Ascending or Descending ?'
                    className='form-selector'
                    as="select"

                    onChange={(e) => {
                        setSorti(e.target.value);
                        sortData(e.target.value, sortby);
                    }}
                >

                    <option key={2} value="desc" > Ascending </option>
                    <option key={3} value="asc"> Descending</option>


                </Form.Select>
            </div>







        </Card>








    )

    const sortItems = [
        {
            key: '1',
            label: sortMenu
        }

    ];


    return (
        <div>
            <Navbar bg="secondary" expand="lg" style={{ height: "40px", borderRadius: "4px", marginTop: "0px", minWidth: "480px" }} >



                <Nav className="mr-auto" style={{ color: "black", display: "flex", flexDirection: "row" }} >
                    <Nav.Item className="mx-3">
                        <Dropdown

                            trigger={['click']}
                            menu={{
                                items: sortItems,
                                onClick: handleMenuClick,
                            }}
                            placement="bottomRight"
                            arrow={{
                                pointAtCenter: true,

                            }}
                            onOpenChange={handleOpenChange2}
                            open={open2}



                        >
                            <Button style={{ fontSize: "12px", color: "white" }} variant="default"><BiIcons.BiSort /> Sort</Button>
                        </Dropdown >
                    </Nav.Item>
                    <Nav.Item>
                        <Dropdown
                            menu={{
                                items: filterItems,
                                onClick: handleMenuClick
                            }}
                            trigger={['click']}
                            placement="bottomRight"
                            arrow={{
                                pointAtCenter: true,
                            }}

                            onOpenChange={handleOpenChange}
                            open={open}



                        >
                            <Button style={{ fontSize: "12px", color: "white" }} variant="default"><CiIcons.CiFilter /> Filter</Button>
                        </Dropdown>

                    </Nav.Item>
                </Nav>


            </Navbar>
        </div>
    )
}

export default FilterSort;







