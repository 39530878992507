import React from 'react';
import Logo from '../images/level.png'
const CenteredFlagMessage = ({  message }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          backgroundColor: 'black',
          padding: '20px',
          borderRadius: '8px',
          color:'white',
          fontSize:"12px"
        }}
      >
        <img src={Logo} alt="Flag" style={{ width: '150px', marginBottom: '20px' }} />
        <p>{message}</p>
      </div>
    </div>
  );
};

export default CenteredFlagMessage;
