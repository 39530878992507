import {  Dropdown } from 'antd';
import { ListGroup } from 'react-bootstrap';
import { DateTime } from "luxon";


const NotificationDropdown = ({ children, notificationsArray, setTid, handleClick }) => {
    
    const formatted =(data)=>{
    return(
    DateTime
    .fromISO(data, { zone: "utc" }).minus({hours:3})
    .toRelative()
    )    
    
   
    } 
    
    

    

    const items = [
        {
            key: '1',
            label: (
                <ListGroup variant='flush' >

                    <div className='Notification-content'>
                    <p>Notifications</p>
                     
                    </div>
                    <hr/>   
                    {notificationsArray?.length===0&&(
                        <p className='text-muted text-sm' style={{fontSize:"10px"}}>No notifications</p>
                    )}
                    {notificationsArray?.map((data, index) => (
                        
                        <ListGroup.Item style={{height:"60px"}} key={index} onClick={() => {
                            setTid(data.tid)
                            handleClick(data.tid)
                        }}>

                            <p style={{padding:"0px"}} className='mt-0 mb-0' onClick={() => {
                                setTid(data.tid)
                                handleClick(data.tid)
                            }} href='#'>{data.msg}</p>
                            <p className='text-muted'style={{fontSize:"10px"}}>{formatted(data.ndate)}</p>
                        </ListGroup.Item>
                    )

                    )}
                </ListGroup>

            ),
        }

    ];

    

        return (<>

            <Dropdown

                trigger={"click"}
                menu={{
                    items,
                }}
                placement="bottomRight"
                arrow={{
                    pointAtCenter: true,
                }}
            >
                {/* <Button>bottomRight</Button> */}
                {children}
            </Dropdown>
            <br />

        </>)

    

};
export default NotificationDropdown;