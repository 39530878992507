import React, { useState } from "react";
import PaymentCard from "./PhonePaymentCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import usePost from "../hooks/hooks/postData";
import { useEffect } from "react";
import { twit } from "./Notificationpopout";
import Loader from "./Loader";
import LoaderButton from "./LoaderButton";
import axios from "../api/axios";
import { Form } from 'react-bootstrap';
import { BiHide, BiShow } from 'react-icons/bi';


const TagTest = () => {
    const [loading, setLoading] = useState(false)
    const [testTag, setTestTag] = useState('')
    const [apikey, setApikey] = useState()

    const navigate = useNavigate()

    const getTag = async (codeType) => {
        try {
            setLoading(true)
            const response = await axios.get(codeType === 1 ? '/api/documents/qrcode' : codeType === 2 ? '/api/documents/pdf417' : codeType === 3 ? '/api/documents/barcode' : '/api/documents/tag/datamatrixrectangular/10', {
                headers: {
                    'Pinni-Access-Key': apikey
                }

            });

            setLoading(false)
            if (response.status === 200) {

                if (codeType === 1) {
                    return Promise.resolve(response?.data.qrCode)
                } else if (codeType === 2) {
                    return Promise.resolve(response?.data.PDF417)
                } else if(codeType === 3) {
                    return Promise.resolve(response?.data.barcode)
                } else{
                    return Promise.resolve(response?.data.tag)
                }

            }

        } catch (err) {
            setLoading(false)
            
            return Promise.reject(err)
        }
    }






    if (loading) {
        return (<Loader />)
    } else {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '70vh'
            }}>
                <a style={{ color: "green", cursor: "pointer" }} className='identity-form-button mb-3'
                    onClick={() => {
                        navigate('/documents')
                    }}
                >
                    {"<"}back
                </a>
                <div style={{ width: '80%' }}>
                    <Form.Group  >
                        <Form.Control
                            className=' input-box-invoice-form form-control-sm'
                            type={'text'}
                            name="Apikey"
                            value={apikey}
                            placeholder="Api Key"
                            onChange={(e) => {
                                setApikey(e.target.value)
                            }}

                        />

                    </Form.Group>
                    <Form.Group style={{ marginBottom: "14px" }} ></Form.Group>


                </div>
                <img src={testTag} alt="Tag image" />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: "20px"
                }}>
                    <LoaderButton onClick={() => {
                        getTag(1).then((data) => {
                            setTestTag(data)
                        }).catch((error) => {
                            console.log(error)
                            twit('error', 'Error loading qrcode')
                        })
                    }}
                        loading={loading}
                        variant={"primary"}
                        label={'Generate new QrCode'}
                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", paddingRight: "10px" }}
                        className={'mx-1'}
                    />
                    <LoaderButton onClick={() => {
                        getTag(2).then((data) => {
                            setTestTag(data)
                        }).catch((error) => {
                            console.log(error)
                            twit('error', 'Error loading pdf417')
                        })
                    }}
                        loading={loading}
                        variant={"primary"}
                        label={'Generate new PDF417'}
                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        className={'mx-1'}
                    />

                    <LoaderButton onClick={() => {
                        getTag(3).then((data) => {
                            setTestTag(data)
                        }).catch((error) => {
                            console.log(error)
                            twit('error', 'Error loading barcode')
                        })
                    }}
                        loading={loading}
                        variant={"primary"}
                        label={'Generate new barcode'}
                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        className={'mx-1'}
                    />

                    <LoaderButton onClick={() => {
                        getTag(4).then((data) => {
                            setTestTag(data)
                        }).catch((error) => {
                            console.log(error)
                            twit('error', 'Error loading barcode')
                        })
                    }}
                        loading={loading}
                        variant={"primary"}
                        label={'Generate new datamatrix'}
                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        className={'mx-1'}
                    />

                </div>

            </div>

        )
    }


}

export default TagTest;

