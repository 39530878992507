import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Container ,Nav } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import BreadcrumbNav from '../BreadCrumb';



const EventManagementCard = () => {

    const path = useLocation().pathname

    const [activeKey, setActiveKey] = useState(() => {
        if (path.includes('orders')) {
          return 'tags';
        } else if (path.includes('addson')) {
          return 'addson';
        } else if (path.includes('products')) {
          return 'home';
        } else {
          return 'dash';
        }
      });



    
    const navigate = useNavigate();
    
      useEffect(() => {
        
        if (path.includes('orders')) {
          setActiveKey('tags');
        } else if (path.includes('addson')) {
          setActiveKey('addson');
        } else if (path.includes('products')) {
          setActiveKey('home');
        } else {
          setActiveKey('dash');
        }
      }, [useLocation().pathname]);
    


    return (
        

        <Container className="meetingsdash-container mt-2 mb-3" >
            <BreadcrumbNav/>
            <Card style={{minHeight:'100vh'}}>
                <Card.Header>
                    <Nav variant="tabs" activeKey={activeKey} >
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="dash" onClick={()=>{
                                navigate('/events')
                            }}>
                                <MdIcons.MdOutlineDashboardCustomize style={{ height: "20px", width: "20px" }} />
                                &nbsp;
                                Events Dashboard
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="home" onClick={()=>{
                                navigate('/events/products')
                            }}>
                                 <RiIcons.RiProductHuntLine style={{ height: "20px", width: "20px" }} />
                                &nbsp;
                                My Products
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="addson" onClick={()=>{
                                 navigate('/events/addson')
                            }}>
                                <MdIcons.MdAddShoppingCart style={{ height: "20px", width: "20px" }} />
                                &nbsp;
                                Adds On
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="tags" onClick={()=>{
                                 navigate('/events/orders')
                            }}>
                                <FaIcons.FaCartArrowDown style={{ height: "20px", width: "20px" }} />
                                &nbsp;
                                Orders
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    <div >
                        <Outlet />
                    </div>
                </Card.Body>
            </Card>

        </Container>

    );
};

export default EventManagementCard;



// <Container style={{ fontSize: "12px" }} className='my-3'>
        //     <BreadcrumbNav/>
        //     <Card style={{ fontSize: "12px", minHeight: "100vh" }}>
        //         <Card.Header style={{ fontSize: "15px", fontWeight: "bold" }}>Event Management</Card.Header>
        //         <Card.Body>

        //             <div >
        //                 <Outlet />
        //             </div>

        //         </Card.Body>
        //     </Card>
        // </Container>