import React, { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import logo from './images/logo.jpg'
import pinnisoftLogo from './images/pinnisoft.jpg';
import { getNairobiTime, splitArrayIntoChunks } from '../Utilities/utilities';
import * as GiIcons from 'react-icons/gi';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';
import PinnitagsLogo from './images/logo.jpg';
import useFetch from '../hooks/hooks/getData';
import Loader from './Loader';

const QRCodePage = ({ page1Data, dataArray, pageNumber }) => {

  const [loading, setLoading] = useState(false)
  const qrCodesPerRow = 5
  const numberOfRows = 4

  const tagChunks = splitArrayIntoChunks(dataArray, 5)
  const page1chunks = splitArrayIntoChunks(page1Data, 5)

  // const userData = useFetch("/api/auth/user/profile", setLoading);


  const generateTag = (data) => (
    <div className="qrcode">

      <img src={data.tagImage} alt="Logo" className="tag-Image" />
      <p style={{ fontWeight: 'bold' }} className='my-0'>{data.name}</p>
      <img src={logo} alt="Logo" className="tag-logo" />
    </div>
  );
  if (loading) {
    return (<Loader />);
  } else {
    return (
      <div className="qrcode-container">
        <div style={{ minHeight: '1000px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {(page1Data ? page1chunks : tagChunks).map((chunk, rowIndex) => (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={rowIndex}>
              {(rowIndex === 0 && (pageNumber + 1) === 1) && (
                <div
                  style={{

                    color: "black",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "4px",
                    width: '100%',
                    justifyContent: "space-between"

                  }}
                >
                  <div style={{ paddingLeft: "0%", paddingRight: "0%", backgroundColor: "white", maxWidth: "100%" }}>
                    <Image className="tag-Image" src={pinnisoftLogo} alt='Company logo' fluid />
                  </div>
                  <div className='billing-company' style={{ fontSize: '10px' }}>
                    {/* <p style={{fontWeight:"bold",color:"green"}}>BILL FROM:</p>
          <p>{billingCompany.name}</p> */}
                    <p><ImIcons.ImLocation2 />{'Plot 724/5, Mawanda Road,Theta House,Kamwokya,Kampala-Uganda'}</p>
                    <p><GiIcons.GiRotaryPhone /> +{'256 393 969 600'}</p>
                    <p><HiIcons.HiOutlineMailOpen /> {'info@pinnisoft.com'}</p>
                  </div>
                  <div style={{ maxWidth: "50%", display: "flex", flexDirection: "row", justifyContent: "end", backgroundColor: "white" }}>
                    <div style={{ paddingLeft: "40%", paddingRight: "0%", backgroundColor: "white", maxWidth: "80%" }}>
                      <Image src={PinnitagsLogo} alt='Pinnitags logo' fluid />
                    </div>

                  </div>
                </div>
              )}



              <Row className='my-0' style={{ width: '100%', paddingBottom: '0px', paddingTop: '0px' }} key={rowIndex}>
                {chunk.map((tag, colIndex) => (
                  <Col style={{ width: '20%' }} key={colIndex} md={12 / qrCodesPerRow} >
                    {generateTag(tag)}
                  </Col>
                ))}
              </Row>
            </div>

          ))}
        </div>

        <div className='A4footer ' style={{ fontSize: "10px", display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <p className="text-muted my-0" style={{ fontSize: "10px", color: "white", width: '100%', textAlign: 'center' }}>
            Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a href="#" className="link">PinniSOFT</a>  <br />
            All rights reserved.
          </p>
          <p >{pageNumber + 1}</p>
        </div>
      </div>
    );
  }
};

export default QRCodePage;
