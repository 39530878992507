import React from 'react';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import * as RxIcons from 'react-icons/rx';

const FormStatusCard = ({ sections, isRegistrationComplete }) => {
  const handleClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Card className='mb-2 ' style={{width:"100%",minHeight:"20vh" ,borderRadius:"4px",padding:"0px"}}>
      <Card.Header>Registration Sections</Card.Header>
      <Card.Body style={{paddingTop:"0px",paddingBottom:"0px",paddingLeft:"5px",paddingRight:"5px"}}>
        {/* <p className='text-muted mt-2 mb-0' style={{fontSize:"10px"}}>{isRegistrationComplete ? ' Complete.' : ' in Progress...'}</p> */}
        <ListGroup className='my-0 mt-2' variant="flush">
          {sections?.map((section, index) => (
            
            <ListGroupItem
              key={index}
              className={`d-flex justify-content-between ${
                section.completed ? 'sectioncompleted' : 'sectionincomplete'
              }`}
              onClick={() => handleClick(section?.sectionId)}
              style={{ cursor: 'pointer',paddingTop:"1px",paddingBottom:"1px" ,fontSize:"10px",color:"green"}}
              

            >
              {/* <span className="list-number mx-2">{index + 1}</span> */}
             {section.completed ? (
                <>
                  {section.name}
                  {/* <span className="tick mx-2">&#10004;</span> */}
                  <RxIcons.RxCheckCircled className='mx-2' style={{color:"green",height:"15px",width:"15px"}}/>
                </>
              ) : (
                <>
                  {section.name}
                  {/* <span className="cross mx-2">&#10006;</span> */}
                  <RxIcons.RxCrossCircled className='mx-2' style={{color:"red",height:"15px",width:"15px"}}/>
                </>
              )}
            </ListGroupItem>
          ))}
        </ListGroup>
      </Card.Body>
      
    </Card>
  );
};

export default FormStatusCard;
