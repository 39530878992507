import React, { useEffect, useState } from 'react'
import { Container, Card, Col } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../Utilities/apiRequests';
import Loader from '../Loader';
import { twit } from '../Notificationpopout';

const EventManagementHome = () => {

    const [products, setProducts] = useState([]);
    const [userProducts,setUserproducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();



    const categories = [
        {
            title: 'Events',
            icon: <CgIcons.CgEventbrite title='Manage Events' style={{ height: "30px", width: "30px" }} />,
            link: '/events/products/myEvents'
        },

        {
            title: 'Pass',
            icon: <BsIcons.BsPass title='Pass AddsOn' style={{ height: "30px", width: "30px" }} />,
            link: '/events/addson/description?concept=pass&productid=998236E4-A0A8-4953-B042-F459CEDD36AB&sb=1'
        },
        {
            title: 'Coupons',
            icon: <RiIcons.RiCoupon2Fill title='Coupons AddsOn' style={{ height: "30px", width: "30px" }} />,
            link: '/events/addson/description?concept=coupons&productid=DC276D07-F7D9-4CAA-B5E9-62274C366C73&sb=1'
        },
        {
            title: 'Tickets',
            icon: <BsIcons.BsTicketPerforated title=' Tickets AddsOn' style={{ height: "30px", width: "30px" }} />,
            link: '/events/addson/description?concept=tickets&productid=72D1E2B2-22FF-4F76-A0FC-AC8BF090D909&sb=1'
        }
        
    ];


    const getCategoryDetails = (name)=>{
      return {...categories.filter((data)=>{
        return data.title == name
    }).map((data)=>{
        return {icon:data.icon,link:data.link}
    })[0]}  
    }

    useEffect(() => {   

        fetchData(`/api/events/products/myproducts`, setLoading).then((response) => {

            setProducts(response.data.products)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading products')
        })


    }, [])


    useEffect(() => {

        const Products = products.map((data)=>{
            return {...data,...getCategoryDetails(data.productName)}
        })

        setUserproducts(Products)

    }, [products])

    

    if (loading) {
        return (<Loader />);
    } else {

    return (
        <div>
            <Col>
                <div className='card-operations-row'>

                    {userProducts.map((category, index) => (
                        <Card key={index} className='document-card'
                            onClick={() => {
                                navigate(category.link)
                            }}

                        >

                            <Card.Body style={{ textAlign: "center" }}>
                                <div>
                                    {category.icon}
                                </div>

                                <Card.Text>
                                    {category.productName}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}

                </div>

            </Col>
        </div>
    )
}

}

export default EventManagementHome;
