import React from 'react';

const ColorCube = ({ colorCode, size = 20 }) => {
  const style = {
    backgroundColor: colorCode,
    width: size,
    height: size,
    borderRadius: 2, // optional rounded corners
    border: `1px solid ${colorCode === '#ffffff' ? '#ccc' : '#000'}`, 
  };

  return <div style={style} />;
};

export default ColorCube;
