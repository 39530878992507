import React, { useState } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import IdentityAddsOn from "./IdentityAddsOn";
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
import IdentityProducts from "./IdentityProducts";


const IdentityDash = ({ prod, dasht }) => {
    const [dash, setDash] = useState(dasht);
    const [products, setProducts] = useState(prod);
    const [addsOn, setAddsOn] = useState(false);
    const selectDash = () => {
        setDash(true);
        setProducts();
        setAddsOn();

    };
    const selectProducts = () => {
        setDash();
        setAddsOn()
        setProducts(true);

    };

    const selectAddsOn = () => {
        setDash();
        setProducts();
        setAddsOn(true);

    };





    return (
        <Container className="meetingsdash-container">
            <Card>
                <Card.Header>
                    <Nav variant="tabs" defaultActiveKey={() => {
                        if (prod) {
                            return ("products")
                        } else {
                            return ("dash")
                        }
                    }}>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="dash" onClick={selectDash}>
                                <MdIcons.MdOutlineDashboardCustomize style={{ height: "20px", width: "20px" }} />
                                &nbsp;
                                Identity Dashboard
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="products" onClick={selectProducts}>
                                <RiIcons.RiProductHuntLine style={{ height: "20px", width: "20px" }} />
                                &nbsp;
                                My Products
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="addson" onClick={selectAddsOn}>
                                <MdIcons.MdAddShoppingCart style={{ height: "20px", width: "20px" }} />
                                &nbsp;
                                Adds On
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {dash && <p>Dash tab content goes here</p>}
                    {products && <IdentityProducts />}
                    {addsOn && <IdentityAddsOn />}
                </Card.Body>
            </Card>

        </Container>

    );
}

export default IdentityDash;
