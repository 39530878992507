import React, { useEffect, useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import useProducts from '../hooks/getProducts';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card1 from './IdentityCards/Card1'
import useFetch from '../hooks/hooks/getData';
import Loader from './Loader';



const IdentityProducts = () => {


  const [loading, setLoading] = useState(false)

  const rid = sessionStorage.getItem('rid');
  const productsUrl = `/api/products/${rid}`

  const allProducts = useFetch(productsUrl, setLoading);
  
  const navigate = useNavigate()




  if (loading) {
    return (<Loader />);
  } else {
    return (
      <Container className='dashboard-container  justify-content-center' style={{ minHeight: "100vh" }}>



        {/* first row */}
        <div className={allProducts?.data?.data.length < 3 ? "my-4 identity-adds-on-less" : "my-4 identity-adds-on"} >


          {allProducts?.data?.data.filter((data, index) => {
            return index < 3 || index === 3;
          })?.map((products) => (
            <div>
              <Card1 active={true} title={products.ProductType.rname
              } />
            </div>
          ))}

          {allProducts?.error && <div style={{ textAlign: "center" }}>
            *No products loaded*
          </div>
          }
        </div>
        <div className="my-4 identity-adds-on">


          {allProducts?.data?.data.filter((data, index) => {
            return index > 3;
          })?.map((products) => (
            <div>
              <Card1 active={true} title={products.ProductType.rname
              } />
            </div>
          ))}
          
          {allProducts?.error && <div style={{ textAlign: "center" }}>
            *No products loaded*
          </div>
          }
        </div>

      </Container>
    );
  }
}



export default IdentityProducts;
