import React from 'react';
import { Container, Card } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';


const MyDocuments = () => {



    const row1 = [{ docname: "RFQ", icon: <GrIcons.GrDocumentTransfer title='Your RFQs' style={{ height: "30px", width: "30px" }} />, createLink: "", documentLink: "" },
    { docname: "Quotations", icon: <FaIcons.FaFileInvoiceDollar title='Your Quotations' style={{ height: "30px", width: "30px" }} />, createLink: "", documentLink: "" },
    { docname: "Purchase Order", icon: <FaIcons.FaFileInvoice title='Your Purchase Orders' style={{ height: "30px", width: "30px" }} />, createLink: "", documentLink: "" },
    { docname: "Invoice", icon: <TbIcons.TbFileInvoice title='Your Invoices' style={{ height: "30px", width: "30px" }} />, createLink: "/create/invoice", documentLink: "" }

    

    ];

    const row2 = [{ docname: "Receipts", icon: <MdIcons.MdReceiptLong title='Your Receipts' style={{ height: "30px", width: "30px" }} />, createLink: "", documentLink: "" },
    { docname: "Tag Generator", icon: <BsIcons.BsQrCodeScan title='Your Tags' style={{ height: "30px", width: "30px" }} />, createLink: "/ordertags", documentLink: "" }
];




    const navigate = useNavigate();









    return (
        <Container className='' style={{ paddingLeft: "5%", paddingRight: "5%", minHeight: "80vh" }}>
            <div className="my-4 documents-column" >

                {row1?.map((document, index) => (

                    <Dropdown
                        trigger={"hover"}
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: (
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }} >

                                            <div style={{ minWidth: "40px", textAlign: "center" }}>
                                                <div>
                                                    <HiIcons.HiOutlineDocumentAdd onClick={()=>{
                                                       if(document.createLink){
                                                        navigate(document.createLink);
                                                       } 
                                                        
                                                    }} title={`Create new ${document.docname}`} style={{ height: "30px", width: "30px" }} />
                                                </div>


                                            </div>

                                            <div style={{ minWidth: "40px", textAlign: "center" }}>
                                                <div>
                                                    {document.icon}
                                                </div>


                                            </div>





                                        </div>

                                    ),
                                }

                            ]
                        }}
                        placement="bottomRight"
                        arrow={{
                            pointAtCenter: true,
                        }}




                    >
                        <Card key={index} className='document-card'
                            onClick={() => {

                            }}


                        >

                            <Card.Body style={{ textAlign: "center" }}>
                                <div>
                                    {document.icon}
                                </div>

                                <Card.Text>

                                    {document.docname}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Dropdown>



                ))}

            </div>
            <div className="my-4 documents-column" >

                {row2?.map((document, index) => (
                    <Dropdown
                        trigger={"hover"}
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: (
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", }} >


                                            <div style={{ minWidth: "40px", textAlign: "center" }}>
                                                <div>
                                                    
                                                    <HiIcons.HiOutlineDocumentAdd onClick={()=>{
                                                       if(document.createLink){
                                                        navigate(document.createLink);
                                                       } 
                                                        
                                                    }} title={document.docname==='Tag Generator'? 'Create new tags': `Create new ${document.docname}`} style={{ height: "30px", width: "30px" }} />
                                                </div>


                                            </div>

                                            <div style={{ minWidth: "40px", textAlign: "center" }}>
                                                <div>
                                                    {document.icon}
                                                </div>


                                            </div>



                                        </div>

                                    ),
                                }

                            ]
                        }}
                        placement="bottomRight"
                        arrow={{
                            pointAtCenter: true,
                        }}




                    >
                        <Card key={index} className='document-card'
                            onClick={() => {

                            }}


                        >

                            <Card.Body style={{ textAlign: "center" }}>
                                <div>
                                    {document.icon}
                                </div>

                                <Card.Text>

                                    {document.docname}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </Dropdown>


                ))}

            </div>
        </Container>
    )
}

export default MyDocuments;
