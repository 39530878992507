import React, { useEffect, useState } from 'react'
import IdentityCard from '../IdentityCard';
import { Form, Col, Container, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';
import idImage from '../images/Katende_Davis (9).png'
import idImage2 from '../images/Thanah_Daria (3).png'
import useFetch from '../../hooks/hooks/getData';
import usePost from '../../hooks/hooks/postData';
import { twit } from '../Notificationpopout';
import Loader from '../Loader';
import axios from '../../api/axios';
import { SketchPicker } from 'react-color'
import ColorPicker from "react-color";
import { HexColorPicker } from "react-colorful";
import { getRandomNumber } from '../../Utilities/utilities';
import { verifyEngineBaseUrl } from '../../Utilities/globals';

const CardDesign = () => {



    const [templateType, setTemplateType] = useState('identity');
    const [existingBTemplate, setExistingBTemplate] = useState({ Tname: "Select Business Template", btid: "" });
    const [existingCTemplate, setExistingCTemplate] = useState({ Tname: "Select Card Template", ctid: "" });
    const [ctid, setCtid] = useState();
    const [btid, setBtid] = useState();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [customImage, setCustomImage] = useState('');
    const [templateUrl, setTemplateUrl] = useState('');
    const [btemplateUrl, setBtemplateUrl] = useState('');
    const [tcolor, setTcolor] = useState('');


    const navigate = useNavigate();
    const rid = sessionStorage.getItem('rid');
    const companyUsers = useFetch(`/api/products/registered/people/${rid}`, setLoading, refresh);
    const userLogo = useFetch('/api/products/identity/card/user/logo', setLoading);
    const userInfo = useFetch('/api/auth/user/profile', setLoading);
    const userTemplate = useFetch('/api/products/identity/card/user/templates', setLoading, refresh);
    const cardTemplates = useFetch('/api/products/identity/card/ctemplates', setLoading, refresh);
    const businessTemplates = useFetch('/api/products/identity/card/btemplates', setLoading);
    const saveTemplate = usePost('/api/products/identity/card/user/templates', setLoading);
    const updateTemplate = usePost('/api/products/identity/card/user/templates/update', setLoading);
    const saveTextColor = usePost('/api/products/identity/card/user/template/color/c', setLoading);
    const saveBTextColor = usePost('/api/products/identity/card/user/template/color/b', setLoading);
    const uploadTemplate = usePost('/api/products/identity/card/user/templates/upload', setLoading)
    useEffect(() => {

        
        if (userTemplate?.data?.Bcard || userTemplate?.data?.Ccard) {
            if (userTemplate?.data?.Bcard && (!userTemplate?.data?.Ccard)) {
                if (userTemplate?.data?.userSettings) {
                    setExistingCTemplate(userTemplate?.data?.userSettings?.Ctemplate);
                }

                setExistingBTemplate("Custom");
            } else if (userTemplate?.data?.Ccard && (!userTemplate?.data?.Bcard)) {
                if (userTemplate?.data?.userSettings) {
                    setExistingBTemplate(userTemplate?.data?.userSettings?.Btemplate);
                }
                
                setExistingCTemplate("Custom");
                
            } else if (userTemplate?.data?.Ccard && userTemplate?.data?.Bcard) {
                setExistingCTemplate("Custom");
                setExistingBTemplate("Custom");
            }
        } else {

            if (userTemplate?.data?.userSettings) {

                setExistingCTemplate(userTemplate?.data?.userSettings?.Ctemplate);
                setExistingBTemplate(userTemplate?.data?.userSettings?.Btemplate);
                

            }



        }





    }

        , [userTemplate]);
    

    const saveUpdate = () => {

        if (!userTemplate?.data?.userSettings) {
            saveTemplate.post({ ctid, btid }).then((data) => {
                if (data?.type === "data") {

                    setRefresh(!refresh);
                    twit("success", "Template saved.");
                } else {


                    if (data?.error?.response?.data?.data) {
                        twit("info", "An error occurred while updating.");

                    } else {
                        twit("info", 'Network error.');
                    }



                }
            })
        } else {
            if (!ctid && !btid) {
                twit('info', 'Please select a template first.');
            } else {
                updateTemplate.post({ ctid, btid }).then((data) => {

                    if (data?.type === "data") {
                        setRefresh(!refresh);
                        twit("success", "Template updated.");
                    } else {


                        if (data?.error?.response?.data?.data) {
                            twit("info", "An error occurred while updating.");

                        } else {
                            twit("info", 'Network error.');
                        }



                    }


                })
            }
        }





    }

    

    
    const token = sessionStorage.getItem('token');

    const formData = new FormData()
    formData.append("image", customImage);

    const uploadFile = async () => {
        if (tcolor === '') {
            twit('info', 'Please select a text color first.')
        } else {


            try {
               



                if (templateType === "identity") {
                    
                    await saveTextColor.post({ textColor: tcolor }).then((data) => {
                        if (data?.data) {
                            console.log(data.data);
                        } else {
                            console.log(data.error);
                        }
                    });



                    const response = await axios.post('/api/products/identity/card/user/templates/upload/c', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                            , Authorization: `Bearer ${token}`
                        }

                    });
                    return response;
                }

                if (templateType === "business") {

                    await saveBTextColor.post({ textColor: tcolor }).then((data) => {
                        if (data?.data) {
                            console.log(data.data);
                        } else {
                            console.log(data.error);
                        }
                    });


                    const response = await axios.post('/api/products/identity/card/user/templates/upload/b', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                            , Authorization: `Bearer ${token}`
                        }

                    });
                    return response;
                }



            } catch (error) {
                console.log(error);
            }


        }


    }


    
    const randomNumber = getRandomNumber()
    const premiumTextColor =  '#FFFFFF'
    const freeTextColor =  '#000000'

   



    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container className='card-design-container'>
                <div style={{ marginBottom: "20px", paddingLeft: "10px" }}>
                


                    <p style={{ fontSize: "12px" }}> <BsIcons.BsFillCreditCard2FrontFill onClick={() => { navigate('/human/cardoperations') }} style={{ height: "16px", width: "16px" }} /> /Design</p>
                </div>

                <Card >
                    <Card.Header style={{ height: "40px" }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold" }}>Card Style</p>
                    </Card.Header>
                    <div className='card-design-main-card'>
                        <div className='card-design-div1'>
                            <Form>

                                <div style={{ height: "8px", fontSize: "13px", fontWeight: "bold" }}>
                                    <p>Template</p>
                                </div>
                                <hr />
                                <div style={{ display: "flex", flexDirection: "row", }}>
                                    <Form.Check
                                        title='Minor is a person under the age of full legal responsibility.'
                                        inline
                                        label="Identity Card"
                                        type="radio"
                                        id="minorRadio"
                                        value="identity"
                                        onChange={(e) => { setTemplateType(e.target.value) }}
                                        checked={templateType === 'identity'}
                                    />
                                    <Form.Check
                                        title='Adult is a person who is fully grown or developed.'
                                        inline
                                        label="Business Card"
                                        type="radio"
                                        id="majorRadio"
                                        value="business"
                                        onChange={(e) => { setTemplateType(e.target.value) }}
                                        checked={templateType === 'business'}
                                    />

                                </div>




                                <Form.Group as={Col}>
                                    {templateType === "identity" && (
                                        <Form.Select
                                            className='form-selector'
                                            value={ctid}
                                            onChange={(e) => {

                                                setCtid(e.target.value);
                                            }}
                                        >

                                            <option value={existingCTemplate.ctid ? existingCTemplate.ctid : "custom"}>{existingCTemplate.Tname ? existingCTemplate.Tname : existingCTemplate}</option>
                                            {cardTemplates?.data?.CardTemplates?.map((data, index) => (
                                                <option key={index} value={data?.ctid}>{data?.Tname}</option>
                                            ))}
                                            <option value='custom'>Custom</option>


                                        </Form.Select>

                                    )}
                                    {((templateType === "identity" && ctid === 'custom') || ((!ctid) && templateType === "identity" && userTemplate?.data?.Ccard)) && (
                                        <Form.Group className='mt-2' controlId="forSignature">
                                            <Form.Label style={{ marginBottom: "0px" }}>Upload Image</Form.Label>
                                            <Form.Control


                                                onChange={(e) => {



                                                    setCustomImage(e.target.files[0]);

                                                }}
                                                className=' input-box-identity form-control-sm'

                                                type="file" />
                                        </Form.Group>

                                    )}
                                    {templateType === "business" && (
                                        <Form.Select
                                            className='form-selector '
                                            value={btid}
                                            onChange={(e) => {

                                                setBtid(e.target.value);
                                            }}
                                        >
                                            <option value={existingBTemplate.btid ? existingBTemplate?.btid : "custom"}>{existingBTemplate.Tname ? existingBTemplate.Tname : existingBTemplate}</option>
                                            {businessTemplates?.data?.BusinessTemplates?.map((data, index) => (
                                                <option key={index} value={data?.btid}>{data?.Tname}</option>
                                            ))}

                                            <option value='custom'>Custom</option>


                                        </Form.Select>
                                    )}
                                    {((templateType === "business" && btid === 'custom') || ((!btid) && templateType === "business" && userTemplate?.data?.Bcard)) && (
                                        <Form.Group className='mt-2' controlId="forCustomB">
                                            <Form.Label style={{ marginBottom: "0px" }}>Upload Image</Form.Label>
                                            <Form.Control


                                                onChange={(e) => {



                                                    setCustomImage(e.target.files[0]);

                                                }}
                                                className=' input-box-identity form-control-sm'

                                                type="file" />
                                        </Form.Group>

                                    )}

                                    <br />
                                    {(!((ctid === "custom" && templateType === "identity") || (btid === "custom" && templateType === "business")) && !(((!ctid) && templateType === "identity" && userTemplate?.data?.Ccard) || ((!btid) && templateType === "business" && userTemplate?.data?.Bcard))) && (
                                        <Button
                                            onClick={() => {
                                                saveUpdate()
                                            }}
                                            className='identity-form-button' variant="success" type="button">
                                            {userTemplate?.data?.userSettings ? 'Update' : 'Save'}
                                        </Button>
                                    )}
                                    {(((ctid === "custom" && templateType === "identity") || (btid === "custom" && templateType === "business")) || (((!ctid) && templateType === "identity" && userTemplate?.data?.Ccard) || ((!btid) && templateType === "business" && userTemplate?.data?.Bcard))) && (
                                        <div>
                                            <div>



                                                {/* <HexColorPicker  color={tcolor===''? userInfo?.data?.data?.Tcolor:tcolor} className='mb-2' style={{ width: "100%" }} onChange={(color) => {
                                                    setTcolor(color)
                                                }} /> */}
                                                <HexColorPicker  color={tcolor===''? (userInfo?.data?.data?.Tcolor? userInfo?.data?.data?.Tcolor:''):tcolor} className='mb-2' style={{ width: "100%" }} onChange={(color) => {
                                                    setTcolor(color)
                                                }} />
                                            </div>






                                            <Button
                                                onClick={() => {
                                                    if (customImage !== '') {

                                                        uploadFile().then((data) => {

                                                            if (templateType === 'identity') {
                                                                setTemplateUrl(data?.data?.url);

                                                            }

                                                            if (templateType === 'business') {
                                                                setBtemplateUrl(data?.data?.url);

                                                            }

                                                            setRefresh(!refresh);
                                                        })
                                                    } else {
                                                        twit('info', 'Please select a file first.');
                                                    }

                                                }}
                                                className='identity-form-button' variant="success" type="button">
                                                Upload
                                            </Button>
                                        </div>

                                    )}


                                </Form.Group>

                            </Form>
                        </div>
                        {templateType === 'identity' && (
                            <div className='card-design-div2'>



                                {/*When user selects custom card template */}
                                {templateType === "identity" && ctid === 'custom' && (
                                    // <iframe
                                    //     src={`${verifyEngineBaseUrl}/custom/card?template=${userTemplate?.data?.Ccard}&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}`}
                                    //     width="100%"
                                    //     height="500px"

                                    // />
                                    <iframe
                                                src={`${verifyEngineBaseUrl}/custom/card?template=${userTemplate?.data?.Ccard}&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB?.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data?.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}`}
                                                width="100%"
                                                height="500px"

                                            />
                                )}

                                {/*When a user has never selected a card template  */}
                                {(!ctid) && (!userTemplate?.data?.userSettings?.Ctemplate) && !(userTemplate?.data?.Ccard) && (

                                    //<Card.Img variant="top" src={idImage} />
                                    <iframe
                                        src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'fct1'}`}
                                        width="100%"
                                        height="500px"

                                    />

                                )}


                                {/*When user has a saved custom or default card template */}
                                {(!ctid) && (userTemplate?.data?.userSettings?.Ctemplate || (userTemplate?.data?.Ccard)) && (
                                    <div >
                                        {/*When user has a custom card template */}
                                        {(userTemplate?.data?.Ccard) && (

                                            <iframe
                                                src={`${verifyEngineBaseUrl}/custom/card?template=${userTemplate?.data?.Ccard}&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.Tcolor.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}`}
                                                width="100%"
                                                height="500px"

                                            />

                                        )}

                                        {/*When user has  saved default card template */}
                                        {(userTemplate?.data?.userSettings?.Ctemplate.Tname === 'pct1') && (!userTemplate?.data?.Ccard) && (
                                            // <Card.Img variant="top" src={idImage2} />
                                            // <iframe
                                            //     src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'pct1'}`}
                                            //     width="100%"
                                            //     height="500px"

                                            // />

                                            <iframe
                                            src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${premiumTextColor.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber].hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pct1'}`}
                                            width="100%"
                                            height="500px"

                                        />


                                        )}
                                        {(userTemplate?.data?.userSettings?.Ctemplate.Tname === 'pct2') && (!userTemplate?.data?.Ccard) && (
                                            // <Card.Img variant="top" src={idImage2} />
                                            // <iframe
                                            //     src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'pct2'}`}
                                            //     width="100%"
                                            //     height="500px"

                                            // />
                                            <iframe
                                            src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${premiumTextColor.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pct2'}`}
                                            width="100%"
                                            height="500px"

                                        />  

                                        )}
                                        {(userTemplate?.data?.userSettings?.Ctemplate.Tname === 'fct1') && (!userTemplate?.data?.Ccard) && (
                                            //<Card.Img variant="top" src={idImage} />
                                            // <iframe
                                            //     src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'fct1'}`}
                                            //     width="100%"
                                            //     height="500px"

                                            // />
                                            <iframe
                                            src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${freeTextColor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data?.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fct1'}`}
                                            width="100%"
                                            height="500px"

                                        />

                                        )}
                                        {(userTemplate?.data?.userSettings?.Ctemplate.Tname === 'fct2') && (!userTemplate?.data?.Ccard) && (
                                            //<Card.Img variant="top" src={idImage} />
                                            // <iframe
                                            //     src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'fct2'}`}
                                            //     width="100%"
                                            //     height="500px"

                                            // />
                                            <iframe
                                            src={`http://192.168.100.53:5173/custom/card?&logo=${userLogo?.data?.logo}&color=${freeTextColor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fct2'}`}
                                            width="100%"
                                            height="500px"

                                        />

                                        )}


                                    </div>


                                )}


                                {/*When user selects a default card template */}
                                {ctid === "7506A70F-0776-4FAD-868A-220DBABB9FAD" && (


                                    // <Card.Img variant="top" src={idImage2} />
                                    // <iframe
                                    //     src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'pct1'}`}
                                    //     width="100%"
                                    //     height="500px"

                                    // />

                                    <iframe
                                            src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${premiumTextColor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pct1'}`}
                                            width="100%"
                                            height="500px"

                                        />


                                )}


                                {ctid === "42AE76CB-4C5E-486D-B0C3-36008AF35F4D" && (


                                    //<Card.Img variant="top" src={idImage2} />
                                    // <iframe
                                    //     src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'pct2'}`}
                                    //     width="100%"
                                    //     height="500px"

                                    // />
                                    <iframe
                                            src={`${verifyEngineBaseUrl}/custom/card?logo=${userLogo?.data?.logo}&color=${premiumTextColor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pct2'}`}
                                            width="100%"
                                            height="500px"

                                        />

                                )}
                                {ctid === "079556B1-77B3-4AEC-96D7-BCDC5E5F8E78" && (


                                    //<Card.Img variant="top" src={idImage} />
                                    // <iframe
                                    //     src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'fct1'}`}
                                    //     width="100%"
                                    //     height="500px"

                                    // />
                                    <iframe
                                            src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${freeTextColor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fct1'}`}
                                            width="100%"
                                            height="500px"

                                        />


                                )}
                                {ctid === "316F547C-5379-4FAB-A04A-FBBEE4A62DB2" && (


                                    // <Card.Img variant="top" src={idImage} />
                                    // <iframe
                                    //     src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'fct2'}`}
                                    //     width="100%"
                                    //     height="500px"

                                    // />
                                    <iframe
                                            src={`${verifyEngineBaseUrl}/custom/card?&logo=${userLogo?.data?.logo}&color=${freeTextColor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.DOB.substring(0, 10)}&Nationality=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[randomNumber]?.HumanIdentification?.lhname}&hid=${companyUsers?.data?.data[randomNumber]?.hid}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fct2'}`}
                                            width="100%"
                                            height="500px"

                                        />

                                )}
                            </div>
                        )}

                        {templateType === 'business' && (
                            
                            <div className='card-design-div2'>
                               
                                {/* <iframe
                                        src={`${verifyEngineBaseUrl}/custom/business?logo=${userLogo?.data?.logo}&color=${tcolor.split("#")[1]}&fontsize=${8}&view=${'frame'}&tname=${'pct1'}`}
                                        width="100%"
                                        height="500px"

                                    /> */}

                                 {/* <iframe
                                                src={`${verifyEngineBaseUrl}/custom/business?template=${userTemplate?.data?.Bcard}&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.Tcolor.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}`}
                                                width="100%"
                                                height="500px"

                                            />   */}

                                 {/*When user selects custom business template */}             
                                {templateType === "business" && btid === 'custom' && (
                                    // <Card.Img variant="top" src={btemplateUrl} />
                                    <iframe
                                                src={`${verifyEngineBaseUrl}/custom/business?template=${userTemplate?.data?.Bcard}&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}`}
                                                width="100%"
                                                height="500px"

                                            /> 
                                )}

                                 {/*When a user has never selected a business template  */}
                                {(!btid) && (!userTemplate?.data?.userSettings?.Btemplate) && !(userTemplate?.data?.Bcard) && (

                                    // <Card.Img variant="top" src={idImage} />
                                    <iframe
                                    src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fbt1'}`}
                                    width="100%"
                                    height="500px"

                                /> 

                                )}
                                {/*When user has a saved custom or default business template */}
                                {(!btid) && (userTemplate?.data?.userSettings?.Btemplate || (userTemplate?.data?.Bcard)) && (
                                    <div>
                                        {/*When user has a saved custom  business template */}
                                        {(userTemplate?.data?.Bcard) && (
                                          <iframe
                                          src={`${verifyEngineBaseUrl}/custom/business?template=${userTemplate?.data?.Bcard}&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}`}
                                          width="100%"
                                          height="500px"

                                      /> 

                                        )}


                                        {/*When user has  default business template */}
                                        {(userTemplate?.data?.userSettings?.Btemplate.Tname === 'pbt1') && (!userTemplate?.data?.Bcard) && (
                                            // <Card.Img variant="top" src={idImage2} />
                                            <iframe
                                            src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pbt1'}`}
                                            width="100%"
                                            height="500px"

                                        /> 

                                        )}
                                        {(userTemplate?.data?.userSettings?.Btemplate.Tname === 'pbt2') && (!userTemplate?.data?.Bcard) && (
                                            // <Card.Img variant="top" src={idImage2} />
                                            <iframe
                                            src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pbt2'}`}
                                            width="100%"
                                            height="500px"

                                        /> 

                                        )}
                                        {(userTemplate?.data?.userSettings?.Btemplate.Tname === 'fbt1') && (!userTemplate?.data?.Bcard) && (
                                            // <Card.Img variant="top" src={idImage} />
                                            <iframe
                                            src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fbt1'}`}
                                            width="100%"
                                            height="500px"

                                        /> 

                                        )}
                                        {(userTemplate?.data?.userSettings?.Btemplate.Tname === 'fbt2') && (!userTemplate?.data?.Bcard) && (
                                            // <Card.Img variant="top" src={idImage} />
                                            <iframe
                                            src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fbt2'}`}
                                            width="100%"
                                            height="500px"

                                        /> 

                                        )}


                                    </div>


                                )}


                                {/*When user selects a default business template */}
                                {btid === "F3E2F566-E94E-4E71-B423-424A5B0851D7" && (

                                    // <Card.Img variant="top" src={idImage2} />
                                    <iframe
                                            src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pbt1'}`}
                                            width="100%"
                                            height="500px"

                                        /> 


                                )}
                                {btid === "C6261F61-8C08-43F8-88BD-2502F7F5AA05" && (


                                    // <Card.Img variant="top" src={idImage2} />
                                    <iframe
                                            src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'pbt2'}`}
                                            width="100%"
                                            height="500px"

                                        /> 


                                )}
                                {btid === "25681F34-A33B-4DDF-B46F-C5F4BA23F627" && (


                                    // <Card.Img variant="top" src={idImage} />
                                    <iframe
                                            src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fbt1'}`}
                                            width="100%"
                                            height="500px"

                                        /> 


                                )}
                                {btid === "697D0958-2903-4443-8867-7F647E24A5AC" && (


                                    // <Card.Img variant="top" src={idImage} />
                                    <iframe
                                            src={`${verifyEngineBaseUrl}/custom/business?&logo=${userLogo?.data?.logo}&color=${tcolor===''?userInfo?.data?.data?.BTcolor?.split("#")[1]:tcolor?.split("#")[1]}&fontsize=${8}&view=${'frame'}&DOB=${companyUsers?.data?.data[1]?.HumanIdentification?.DOB}&Nationality=${companyUsers?.data?.data[1]?.HumanIdentification?.Nat}&Company=${userInfo?.data?.data.regname}&Gender=${companyUsers?.data?.data[1]?.HumanIdentification?.Gender?.gender}&Position=${companyUsers?.data?.data[1]?.HumanIdentification?.Position}&IssueDate=${'2023-05-10'}&ExpiryDate=${'2023-09-10'}&Fname=${companyUsers?.data?.data[1]?.HumanIdentification?.fhname}&Lname=${companyUsers?.data?.data[1]?.HumanIdentification?.lhname}&CompanyEmail=${userInfo?.data?.data.email}&PersonalEmail=${companyUsers?.data?.data[1]?.HumanIdentification?.Email}&Location=${userInfo?.data?.data.location}&CompanyNumber=${userInfo?.data?.data.phone}&PersonalNumber=${companyUsers?.data?.data[1]?.HumanIdentification?.contactno}&Instagram=${userInfo?.data?.data.instagram}&LinkedIn=${userInfo?.data?.data.linkedIn}&Website=${userInfo?.data?.data.website}&Twitter=${userInfo?.data?.data.twitter}&Youtube=${userInfo?.data?.data.youtube}&LogoShape=${userInfo?.data?.data?.logoShape}&tname=${'fbt2'}`}
                                            width="100%"
                                            height="500px"

                                        /> 


                                )}
                            </div>
                        )}


                    </div>

                </Card>




            </Container>
        );

    }
}

export default CardDesign;
