import { PoweroffOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useState } from 'react';
const LoaderButton = ({loading,variant,onClick,className,label,color,textColor,style}) => {



  return (
    <Space direction="vertical">
      <Space wrap>
        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" className={className} onClick={onClick}  loading={loading}>
         {label}
        </Button>
       
      </Space>

      
    </Space>
  );
};
export default LoaderButton;
  
//   return (
//     <Space direction="vertical">
//       <Space wrap>
//         <Button style={style} className={className} onClick={onClick} type={variant} loading={loading}>
//          {label}
//         </Button>
       
//       </Space>

      
//     </Space>
//   );
// };
// export default LoaderButton;