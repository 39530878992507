import React, { useState } from "react";
import PaymentCard from "./PhonePaymentCard";
import { useLocation, useParams } from "react-router-dom";
import usePost from "../hooks/hooks/postData";
import { useEffect } from "react";
import { twit } from "./Notificationpopout";
import Loader from "./Loader";
import LoaderButton from "./LoaderButton";
import axios from "../api/axios";
import QRCodePage from "./tagPageA4";
import Modal from "antd/es/modal/Modal";
import { downloadPDF, splitArrayIntoChunks } from "../Utilities/utilities";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import MapComponent from "./MapComponent";
import { Switch, Space } from 'antd';

const Testing = ({setLocationDetails}) => {
  const [loading, setLoading] = useState(false);
  const [deliveryNeeded, setDeliveryNeeded] = useState(false);
  const [useExistingLocation, setUseExistingLocation] = useState(false);
  const [newLocation, setNewLocation] = useState(null);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [summaryVisible, setSummaryVisible] = useState(false);



  const handleDeliveryChange = (event) => {
    setDeliveryNeeded(event);
  };

  const handleLocationChange = (event) => {
    setUseExistingLocation(event);
  };

  const handleSave = (event) => {
    event.preventDefault();

    // Calculate the estimated price based on the selected location
    const calculatedPrice = calculateEstimatedPrice(newLocation);
    setEstimatedPrice(calculatedPrice);

    // Show the summary
    setSummaryVisible(true);
  };

  const calculateEstimatedPrice = (location) => {
    // Replace with your own logic to calculate the estimated price
    // based on the selected location
    return Math.random() * 100;
  };

  
  
  
  return (
    <Container style={{fontSize:'12px', width:'420px'}}>
      <h5>Delivery Information</h5>
      <Form >
        <Form.Group as={Col} className="mb-1">
          <Form.Label >
            Do you need delivery?
          </Form.Label>
          <Col >
          <Switch checkedChildren={'Yes'} unCheckedChildren={"No"} defaultChecked onClick={(e)=>{
            
            handleDeliveryChange(e)
          }} />
            
          </Col>
        </Form.Group>
        {deliveryNeeded && (
          <Form.Group as={Col} className="mb-1">
            <Form.Label column >
              Use existing location?
            </Form.Label>
            <Col >
            <Switch checkedChildren={'Yes'} unCheckedChildren={"No"} defaultChecked onClick={(e)=>{
            
            handleLocationChange(e)
            }} />
             
            </Col>
          </Form.Group>
        )}
        {!useExistingLocation && deliveryNeeded && (
          <Form.Group as={Col} className="mb-1" style={{width:'100%'}}>
            <Form.Label >
              Enter delivery location:
            </Form.Label>
            <Col style={{width:'70%'}}>
           
              <Form.Control
                className=' input-box-invoice-form form-control-sm'
                type="text"
                name="location"
                value={newLocation}
                placeholder="Enter precise location"
                onChange={(e) => setNewLocation(e.target.value)}
              />
              
            </Col>
          </Form.Group>
        )}

            <Button variant="primary" type="submit" onClick={handleSave}>
              Save
            </Button>

       
      </Form>
    </Container>
  )
}

  


export default Testing;









// text search example
// function HighlightedText() {
//   const [searchTerm, setSearchTerm] = useState("");

//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//   };
//   const text="hello i am testing this"
//   const regex = new RegExp(searchTerm, "gi");

//   const highlightedText = text.replace(
//     regex,
//     (match) => `<span class="highlight">${match}</span>`
//   );

//   return (
//     <div>
//       <input type="text" value={searchTerm} onChange={handleSearch} />
//       <div
//         dangerouslySetInnerHTML={{
//           __html: highlightedText
//         }}
//       />

      
//       <style>
//         {`
//           .highlight {
//             background-color: yellow;
//           }
//         `}
//       </style>
//     </div>
//   );
// }

// export default HighlightedText;
