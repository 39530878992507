import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Switch} from 'antd';
import LoaderButton from "./LoaderButton";

const DeliveryInfo = ({setDeliveryLocation,setDeliver,setUseRegisteredLocation}) => {
  const [loading, setLoading] = useState(false);
  const [deliveryNeeded, setDeliveryNeeded] = useState(false);
  const [useExistingLocation, setUseExistingLocation] = useState(false);
  const [newLocation, setNewLocation] = useState(null);
  
  



  const handleDeliveryChange = (event) => {
    setDeliveryNeeded(event);
  };

  const handleLocationChange = (event) => {
    setUseExistingLocation(event);
  };

//   const handleSave = (event) => {
//     event.preventDefault();

//     const calculatedPrice = calculateEstimatedPrice(newLocation);
//     setDeliveryPrice(calculatedPrice)
    
   
//   };

  

  
  
  
  return (
    <Container style={{fontSize:'12px', width:'320px'}}>
      <p className="mt-2 mb-0" style={{fontWeight:'bold',padding:'0px'}}>Delivery Information</p>
      <Form >
        <Form.Group as={Col} className="mb-1">
          <Form.Label >
            Do you need delivery?
          </Form.Label>
          <Col >
          <Switch checkedChildren={'Yes'} unCheckedChildren={"No"}  onClick={(e)=>{
            setDeliver(e)
            handleDeliveryChange(e)
          }} />
            
          </Col>
        </Form.Group>
        {deliveryNeeded && (
          <Form.Group as={Col} className="mb-1">
            <Form.Label column >
              Use existing location?
            </Form.Label>
            <Col >
            <Switch checkedChildren={'Yes'} unCheckedChildren={"No"}  onClick={(e)=>{
            setUseRegisteredLocation(e)
            handleLocationChange(e)
            }} />
             
            </Col>
          </Form.Group>
        )}
        {!useExistingLocation && deliveryNeeded && (
          <Form.Group as={Col} className="mb-1" style={{width:'100%'}}>
            <Form.Label >
              Enter delivery location:
            </Form.Label>
            <Col style={{width:'100%'}}>
           
              <Form.Control
                className=' input-box-invoice-form form-control-sm'
                type="text"
                name="location"
                value={newLocation}
                placeholder="Enter precise location"
                onChange={(e) =>{
                    setNewLocation(e.target.value)
                    setDeliveryLocation(e.target.value)
                } }
              />
              
            </Col>
          </Form.Group>
        )}

            
             


       
      </Form>
    </Container>
  )
}

export default DeliveryInfo;

  

