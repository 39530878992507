import axios from '../api/axios';


const useCountries = async() => {
    const  CountryUrl = '/api/countries'


        try {
          const response = await axios.get(CountryUrl);
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useCountries;