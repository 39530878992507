import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Card1 = () => {

  const navigate = useNavigate()
  return (
    <Card className='dash-card'>
      <Card.Body>

        <h6>Meetings</h6>
        <p>This is the content of Card 1</p>
        <Card.Link href='/meetings'>Start</Card.Link>

      </Card.Body>
    </Card>
  );
}

export default Card1