import axios from "../api/axios";
import Cookies from 'js-cookie';
import { twit } from "../components/Notificationpopout";

const logOut = () => {
  Cookies.remove('loggedin');
  Cookies.remove('CollAccess');
  localStorage.clear();
  sessionStorage.clear()
  window.location.href = '/';
  twit("info",'You have been logged out because you are logged in on another device or browser.');
}

export const fetchData = async(Url,setLoading) => {

    const token = sessionStorage.getItem('token');


        try {

        setLoading(true);

          const response = await axios.get(Url,{
            headers: {  Authorization: `Bearer ${token}`
        }

        });
        
        setLoading(false);
        return Promise.resolve(response)
           
        } catch (error) {
          if(error.response.status===401){
            logOut();
          }
          setLoading(false);
          console.log(error)
           return Promise.reject(error)
        }

      
       
}

