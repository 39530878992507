import { Button } from 'antd';
import React, { useState } from 'react';
import { Container, Form, Image } from 'react-bootstrap';
import { useParams,useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/hooks/getData';
import axios from '../../api/axios';
import Loader from '../Loader';
import { twit } from '../Notificationpopout';
import Cookies from 'js-cookie';

const BranchLoginPage = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpRequested, setIsOtpRequested] = useState(false);
    const [loading, setLoading] = useState(false);

    const { bid } = useParams();
    const branchinfo = useFetch(`/api/assetmanagement/branchinfo/${bid}`, setLoading);
    const navigate = useNavigate()

    const requestOtp = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            email,
            bid,
            
        };
        const Url = 'http://192.168.100.32:9091/api/auth/branchotp'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve(response.data.message)
            }


        } catch (err) {
            setLoading(false);
            console.log("Branch OTP error:", err.response.data.message)
            return Promise.reject(err.response.data.message)

        }
    };

    

    const login = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            email,
            otp,
            
        };
        const Url = 'http://192.168.100.32:9091/api/auth/branchlogin'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 200) {
                setLoading(false);
                sessionStorage.setItem('branchToken', response.data.token);
                const expires = new Date();
                expires.setMinutes(expires.getMinutes() + 180)
                Cookies.set('Branchloggedin', true, { expires });
                navigate(`/assetBranch/${bid}/home`)
                return Promise.resolve('logged in successfully.')
            }




        } catch (err) {
            setLoading(false);
            console.log("Branch OTP error:", err.response.data.message)
            return Promise.reject(err.response.data.message? err.response.data.message:'An error occurred while logging you in.')

        }
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            twit('info', `Please fill out all required fields`);

        } else {

            requestOtp(setLoading).then((data) => {
                setIsOtpRequested(true)
                twit('success', data)
            }).catch((error) => {
                twit('info', error)
            })

        }
    };


    const handleOtpSubmit = (e) => {
        e.preventDefault();
        if (!email || !otp) {
            twit('info', `Please fill out all required fields`);

        } else {

            login(setLoading).then((data) => {
                twit('success', data)
            }).catch((error) => {
                twit('info', error)
            })

        }
    };

    if (loading) {
        return (<Loader />);
    } else {
        return (
            <Container className="text-center">

                <Image style={{ width: '40%',margin:'0px' }} src={branchinfo?.data?.branchinfo.Registration.logo} alt="Logo" />
                <div style={{ textAlign: 'center' }}>
                    <p className=' fs-10 fw-bold' style={{ fontFamily: 'Georgia, serif',color:'gray'}}>{branchinfo?.data?.branchinfo.bname} BRANCH</p>
                </div>
                {isOtpRequested ? (
                    <Form onSubmit={handleOtpSubmit}>
                        <Form.Group controlId="otp">
                            {/* <Form.Label>Enter OTP</Form.Label> */}
                            <Form.Control
                                type="text"
                                className=' input-box-identity form-control-sm'
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </Form.Group>
                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: "#569f51", fontSize: '10px' }} variant="primary" type="primary" htmlType='submit'>
                                Login
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <Form onSubmit={handleEmailSubmit}>
                        <Form.Group controlId="email">
                            {/* <Form.Label>Enter your email</Form.Label> */}
                            <Form.Control
                                type="email"
                                className=' input-box-identity form-control-sm'
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: "#569f51", fontSize: '10px' }} variant="primary" type="primary" htmlType='submit'>
                                Request OTP
                            </Button>
                        </div>
                    </Form>
                )}
            </Container>
        );
    };
};

export default BranchLoginPage;
