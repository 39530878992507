import React, { useEffect, useState } from 'react'
import { Container, Card, Col } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../Utilities/apiRequests';
import { twit } from '../Notificationpopout';
import Loader from '../Loader';

const EventManagementOrders = () => {

    
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();



    const categories = [
        {
            title: 'Digital Tags',
            icon: <GiIcons.GiTicket title='Digital Tags' style={{ height: "30px", width: "30px" }} />,
            link: '/events/orders/digital'
        },
        {
            title: 'Physical Tags',
            icon: <BsIcons.BsTicketPerforated title='Physical Tags' style={{ height: "30px", width: "30px" }} />,
            link: '/events/orders/physical'
        }
    ];

    
    

    useEffect(() => {

        
    }, [])



    if (loading) {
        return (<Loader />);
    } else {


    return (
        <div>
            <Col style={{ minHeight: '100vh' }}>
                <div className='card-operations-row' >

                    {categories.map((category, index) => (
                        <Card key={index} className={'document-card'}
                            onClick={() => {
                                
                                    navigate(`${category.link}`)
                               
                               
                            }}

                        >

                            <Card.Body style={{ textAlign: "center" }}>
                                <div>
                                    {category.icon}
                                </div>

                                <Card.Text>
                                    {category.title}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}

                </div>

            </Col>
        </div>
    )
}

}

export default EventManagementOrders;
