import React, { useEffect } from 'react'
import { Form, Table } from 'react-bootstrap'
import Loader from '../Loader';
import { useState } from 'react';
import useFetch from '../../hooks/hooks/getData';
import { Modal, Button } from 'antd';
import EventCard from '../EventCard';
import { useNavigate } from 'react-router-dom';
import Pagination from '../Pagination';
import * as GiIcons from 'react-icons/gi';
import * as GrIcons from 'react-icons/gr';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as BiIcons from 'react-icons/bi';
import * as AiIcons from 'react-icons/ai';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import axios from '../../api/axios';
import { twit } from '../Notificationpopout';
import io from 'socket.io-client';
import { apiBaseUrl, clientEngineBaseUrl } from '../../Utilities/globals';
import Clipboard from 'react-clipboard.js';
import { getNairobiTime, getformattedDate, parseISODate } from '../../Utilities/utilities';
import { FcExpired } from "react-icons/fc";
import { fetchData } from '../../Utilities/apiRequests';
import { FaSitemap } from "react-icons/fa";
import { TbReportMoney } from 'react-icons/tb';

const CreatedEvents = () => {


    const [loading, setLoading] = useState(false);
    const [sendingMails, setSendingMails] = useState(false);
    const [rowdata, setRowdata] = useState(false);
    const [showEventInfo, setShowEventInfo] = useState(false);
    const [showEmailsInput, setShowEmailsInput] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [emails, setEmails] = useState([]);
    const [socket, setSocket] = useState(null);
    const [numberSent, setNumberSent] = useState(0);
    const [filterV, setFilterV] = useState('');
    const [userProducts, setUserProducts] = useState([]);
    const [focused, setFocused] = useState([]);

    const userevents = useFetch("/api/events/", setLoading);
    const navigate = useNavigate();




    useEffect(() => {

        const newSocket = io(apiBaseUrl,{transports: ['websocket', 'polling']});
        setSocket(newSocket);

    }, []);



    useEffect(() => {

        fetchData(`/api/events/products/myproducts`, setLoading).then((response) => {

            setUserProducts(response.data.products.map((data) => {
                return data.productName
            }))

        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading products')
        })

    }, [])


    if (socket) {


        socket.on('PassSent', (data) => {

            setNumberSent(data.sent)

        });
    }

    const filterEvents = (filterby) => {

        const eventsData = userevents?.data?.userEvents


        const onGoing = eventsData?.filter((event) => {
            return (event.eventDate < getNairobiTime()) && (event.endtime > getNairobiTime())
        })

        const expired = eventsData?.filter((event) => {
            return (event.endtime < getNairobiTime())
        })

        const upcoming = eventsData?.filter((event) => {
            return (event.eventDate > getNairobiTime())
        })

        if (filterby == 'Ongoing') {
            return (onGoing)
        } else if (filterby == 'Expired') {
            return (expired)
        } else if (filterby == 'Upcoming') {
            return (upcoming)
        } else {
            return eventsData
        }






    }

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    //const currentEvents = userevents?.data?.userEvents?.slice(indexOfFirstPost, indexOfLastPost)
    const currentEvents = filterEvents(filterV)?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }


    const eventFilters = ['Ongoing', 'Expired', 'Upcoming']





    const sendEventPassEmails = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            mails: emails,
            eid: rowdata.eid
        };
        const Url = '/api/events/emails'
        try {
            setLoading(true);
            setSendingMails(true)
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });


            if (response.status === 201) {
                setLoading(false);
                setSendingMails(false)
                return Promise.resolve(response.data.message)

            }


        } catch (err) {
            setLoading(false);
            setSendingMails(false)
            console.log("Event creation Error:", err)
            return Promise.reject('An error occured while sending event pass emails.')

        }
    };


    const handlePassEmailSend = (e) => {
        e.preventDefault();




        if (emails.length <= 0) {
            twit('info', `Please provide atleast one email`);

        } else {

            sendEventPassEmails(setLoading).then((data) => {
                setShowEmailsInput(false)
                twit('success', data)
                setEmails([])
            }).catch((error) => {
                twit('info', error)
            })

        }



    };






    if (loading) {
        return (<Loader message={sendingMails ? `Sent ${numberSent} of ${emails.length}` : null} />);
    } else {
        return (
            <div >


                {rowdata && showEventInfo && <Modal open={true} title={rowdata.topic} onCancel={() => {
                    setRowdata(false)
                    setShowEventInfo(false)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ marginTop: '25px' }}>


                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <EventCard
                                eventName={rowdata.name}
                                eventDate={rowdata.eventDate}
                                endDate={rowdata.endtime}
                                eventDescription={rowdata.eventdescription}
                                eventVenue={rowdata.venue}
                                imageUrl={rowdata.bannerURL}
                                location={rowdata.location}
                                eventNumber={`Event ID: ${rowdata.eventNumber}`}
                            />
                        </div>

                        <div style={{ textAlign: 'center' }}>
                            {/* <Button title='Add event speaker details' style={{ fontSize: '10px', margin: '4px' }} variant="primary">
                                <GiIcons.GiSpeaker style={{ height: "15px", width: "15px" }} />| Add Speaker
                            </Button> */}

                            {(rowdata.endtime > getNairobiTime()) && ((rowdata.passVerification)) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/passCheckers/${rowdata.eid}`) }} title='Add Event Pass Checkers' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary" >
                                    <BsIcons.BsKey style={{ height: "15px", width: "15px" }} />| Pass Checkers
                                </Button>
                            )}

                            {(rowdata.endtime > getNairobiTime()) && userProducts.includes('Pass') && ((rowdata.ticketPass)) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/zoneManagement/${rowdata.eid}`) }} title='Create Event Zones' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary" >
                                    <BsIcons.BsTextarea style={{ height: "15px", width: "15px" }} />| Zone Management
                                </Button>
                            )}

                            {(rowdata.endtime > getNairobiTime()) && userProducts.includes('Coupons') && ((rowdata.includeCoupon)) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/couponManagement/${rowdata.eid}`) }} title='Create Event Coupons' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary">
                                    <RiIcons.RiCoupon2Fill style={{ height: "15px", width: "15px" }} />| Coupon Management
                                </Button>
                            )}

                            {(rowdata.endtime > getNairobiTime()) && userProducts.includes('Pass') && ((rowdata.prive)?((rowdata.inviteOnly)?((rowdata.sendPassAfterReg)||(rowdata.includePass)):((rowdata.includePass))):(rowdata.includePass)) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/passManagement/${rowdata.eid}`) }} title='Create Event Passes' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary">
                                    <BsIcons.BsPass style={{ height: "15px", width: "15px" }} />|  Pass Management
                                </Button>
                            )}

                            {(rowdata.endtime > getNairobiTime()) && userProducts.includes('Pass') &&(rowdata.tSponsors) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/sponsorManagement/${rowdata.eid}`) }} title='Create Event Sponsors' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary">
                                    <GiIcons.GiTakeMyMoney style={{ height: "15px", width: "15px" }} />|  Sponsor Management
                                </Button>
                            )}
                            {(rowdata.endtime > getNairobiTime()) && userProducts.includes('Pass') && ((rowdata.ticketPass)) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/batchManagement/${rowdata.eid}`) }} title='Manage Ticket Batches' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary" >
                                    <FaSitemap style={{ height: "15px", width: "15px" }} />| Batch Management
                                </Button>
                            )}

                            {(rowdata.endtime > getNairobiTime()) && userProducts.includes('Pass') && ((rowdata.ticketPass)) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/sellerManagement/${rowdata.eid}`) }} title='Manage Box Office' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary" >
                                    <TbReportMoney style={{ height: "15px", width: "15px" }} />| Box Office Management
                                </Button>
                            )}

                            {(rowdata.endtime > getNairobiTime()) && userProducts.includes('Pass') && ((rowdata.ticketPass)) && ((rowdata.allowVendorTicketSell)) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/vendorManagement/${rowdata.eid}`) }} title='Manage Vendors' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary" >
                                    <GiIcons.GiVendingMachine style={{ height: "15px", width: "15px" }} />| Vendor Management
                                </Button>
                            )}

                            {(rowdata.endtime > getNairobiTime()) && userProducts.includes('Pass') && ((rowdata.prive) ? ((rowdata.inviteOnly) ? ((rowdata.sendPassAfterReg) || (rowdata.includePass)) : ((rowdata.includePass))) : (rowdata.includePass)) && (
                                <Button onClick={() => { navigate(`/events/products/myEvents/complimentaryTicketManagement/${rowdata.eid}`) }} title='Create Event Passes' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary">
                                    <BsIcons.BsPass style={{ height: "15px", width: "15px" }} />|  Complimentary Tickets Management
                                </Button>
                            )}


                            <Button onClick={() => { navigate(`/events/products/myEvents/statistics/${rowdata.eid}`) }} title='View Event Statistics' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px' }} variant="primary" type="primary" >
                                <BiIcons.BiStats style={{ height: "15px", width: "15px" }} /> | View Statistics
                            </Button>

                        </div>


                    </div>


                </Modal>}


                {rowdata && showEmailsInput && <Modal open={true} title={<div className='mb-2' style={{ width: '100%', textAlign: 'start' }}>

                    Event Sharing | <BsIcons.BsFillShareFill style={{ height: "15px", width: "15px" }} />

                </div>} onCancel={() => {
                    setRowdata(false)
                    setShowEmailsInput(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div>
                        <Form onSubmit={handlePassEmailSend}>
                            <div className='my-1' style={{ textAlign: 'start' }}>
                                <Clipboard style={{ borderRadius: '5px', border: '0' }} option-text={() => (`${clientEngineBaseUrl}/events/selfreg/${rowdata.eid}`)} onSuccess={() => { twit('success', 'URL copied.') }}>
                                    <HiIcons.HiClipboardCopy style={{ height: "15px", width: "15px" }} />   Copy event URL
                                </Clipboard>

                            </div>
                            <ReactMultiEmail
                                placeholder='Input your email'
                                emails={emails}
                                style={{ fontSize: '12px' }}
                                onChange={(_emails) => {
                                    setEmails(_emails);
                                }}
                                autoFocus={true}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div data-tag key={index}>
                                            <div data-tag-item>{email}</div>
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                                ×
                                            </span>
                                        </div>
                                    );
                                }}
                            />

                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black', fontSize: '10px' }} variant="primary" type="primary" htmlType='submit'>
                                    <AiIcons.AiOutlineSend style={{ height: "15px", width: "15px" }} />| send mails
                                </Button>
                            </div>

                        </Form>





                    </div>


                </Modal>}

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>


                    <Form.Select
                        style={{ width: '20%', fontSize: '10px' }}

                        name="ageCategory"
                        onChange={(e) => {
                            setFilterV(e.target.value)
                        }}
                        className='form-selector mx-2'>
                        <option className='input-box ' key={1} value={''}>All Events</option>
                        {eventFilters?.map((data) => {
                            return <option className='input-box' key={data} value={data}>{data}</option>
                        })}


                    </Form.Select>





                    <Button onClick={() => { navigate('/events/products/myEvents/create') }} title='Create New Event ' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" >
                        + | Create Event
                    </Button>

                </div>
                <div className='table-responsive'>
                {currentEvents?.length > 0 ?
                    <Table striped hover style={{ cursor: "pointer" }}>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Name</th>
                                <th>Theme</th>
                                <th>Starts</th>
                                <th>Ends</th>
                                <th>Venue</th>
                                <th>Status</th>
                                <th>Operations</th>


                            </tr>
                        </thead>
                        <tbody>
                            {currentEvents?.map((data, index) => (
                                <tr key={index} onClick={() => { }}>
                                    <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                    <td>{data.name}</td>
                                    <td>{data.eventdescription}</td>
                                    <td style={{fontSize:'10px',whiteSpace:'nowrap'}}>{getformattedDate(data.eventDate)}</td>
                                    <td style={{fontSize:'10px',whiteSpace:'nowrap'}}>{getformattedDate(data.endtime)}</td>
                                    <td>{data.venue}</td>
                                    <td>{(data.endtime < getNairobiTime()) ? <FcExpired title='Expired' style={{ height: "15px", width: "15px", color: 'red' }} /> : (data.eventDate < getNairobiTime()) && (data.endtime > getNairobiTime()) ? <MdIcons.MdOutlinePlayCircle title='Ongoing' style={{ height: "15px", width: "15px", color: 'green' }} /> : <MdIcons.MdTimelapse title='Upcoming' style={{ height: "15px", width: "15px", color: '#454545' }} />}</td>
                                    <td>

                                        <MdIcons.MdPreview className='mx-1' title='View Event Details' style={{ height: "15px", width: "15px" }} onClick={() => {
                                            setRowdata(data)
                                            setShowEventInfo(true)
                                        }} />

                                        {data.inviteOnly && !(data.endtime < getNairobiTime()) && false && (
                                            <BsIcons.BsFillShareFill className='mx-1' title='Sharing' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                setRowdata(data)
                                                setShowEmailsInput(true)
                                            }} />
                                        )}

                                        <GrIcons.GrUploadOption className='mx-1' title='Update Event Details' style={{ height: "15px", width: "15px" }} onClick={() => {

                                            navigate(`/events/products/myEvents/update/${data?.eid}`)
                                        }} />




                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    :
                    <div className='my-4' style={{width:'100%',textAlign:'center'}}>
                        <p>No events created yet.</p>
                    </div>
                    

                }

                {currentEvents?.length > 0 && (
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={filterEvents(filterV)?.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                )}
                </div>

            </div>
        )
    }
};

export default CreatedEvents;
