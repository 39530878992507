import { useState, useRef, useEffect } from 'react';
import { Form, Button, Alert, Container, InputGroup, FormControl, InputGroupText } from 'react-bootstrap';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import Loader from '../Loader';
import TextTyper from 'text-type-animation-effect-react'
import logo from '../images/logo.jpg'
import { twit } from '../Notificationpopout';
import LoaderButton from '../LoaderButton';
import usePost from '../../hooks/hooks/postData';
import { useParams } from 'react-router-dom';
import successGif from '../images/success.gif';
import successImage from '../images/success.jpg';
import { getNairobiTime } from '../../Utilities/utilities';



const ConsentPage = () => {


    const navigate = useNavigate()


    const [loading, setLoading] = useState(false);
    const [latitude, setLatitude] = useState(" ");
    const [longitude, setLongitude] = useState(" ");
    const [pauseGif, setPauseGif] = useState(false);
    const [showGif, setShowGif] = useState(false);

    const { hid, rid } = useParams();

    const consent = usePost(`/api/products/identity/transfer/${hid}/${rid}`, setLoading);
    const reject = usePost(`/api/products/identity/transfer/reject/${hid}/${rid}`, setLoading);













    useEffect(() => {




    }, []);







    if (loading) {
        return (<Loader />)
    } else {


        return (
            <Container className='main-verify-container'>
                <div className="back-wrapper1"  ></div>
                <div className="back-wrapper2"  ></div>
                <div className="back-wrapper3" ></div>
                <Modal closable={false} width={400} open={true}
                    footer={[


                    ]}
                    className="allmodals"
                >

                    <Container className='verify-container' style={{ display: "flex", flexDirection: "column" }}>
                        <img src={logo} className='email-verification-image' alt='logo' />


                        {showGif && (
                            <div style={{ paddingLeft: "25%", paddingRight: "25%", marginTop: "25%" }} >
                                <img src={pauseGif ? successImage : successGif} alt='gif' className='email-verification-image' />
                            </div>
                        )}

                        {!showGif && (
                            <div style={{ width: "100%", marginTop: "20%" }}>
                                <TextTyper text={"You are being transfered..."} interval={50} Markup={"p"} />
                                <div style={{ width: "100%", marginTop: "20%" }}>

                                    <LoaderButton onClick={() => {
                                        consent.post({}).then((data) => {
                                            if (data?.type === "data") {

                                                twit("success", data?.data?.data);
                                                setShowGif(true);
                                                setTimeout(function () {
                                                    setPauseGif(true);

                                                }, 1000);
                                            } else {
                                                if (data?.error?.response?.data?.data) {
                                                    twit("error", data?.error?.response?.data?.data);
                                                } else {
                                                    twit("error", "Network error.");
                                                }

                                            }

                                        });
                                    }} loading={false}
                                        variant={"primary"}
                                        label="Accept"
                                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", width: "100px" }}
                                        className={"mx-2"}
                                    />
                                    <LoaderButton onClick={() => {
                                        reject.post({}).then((data) => {
                                            if (data?.type === "data") {

                                                twit("success", data?.data?.data);
                                                setShowGif(true);
                                                setTimeout(function () {
                                                    setPauseGif(true);

                                                }, 1000);
                                            } else {

                                                twit("error", data?.error?.response?.data?.data);
                                            }
                                        });


                                    }} loading={false}
                                        variant={"primary"}
                                        label="Reject"
                                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", width: "100px" }}
                                        className={"mx-2"}
                                    />
                                </div>
                            </div>
                        )}

                        <div className='footer'>
                            <p className="text-muted" style={{ fontSize: "8px", marginTop: "auto" }}>
                                Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a style={{ color: "green" }} href="https://pinnisoft.com/">PinniSOFT</a> <br />
                                All rights reserved.
                            </p>
                        </div>


                    </Container>
                </Modal>

            </Container>



        );
    }
}

export default ConsentPage;
