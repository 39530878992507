import React, { useState } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as IoIcons from 'react-icons/io';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import MyDocuments from "./MyDocument";
import MyMemberships from "./MyMemberships";

const Memberships = ({origin}) => {

    const navigate = useNavigate()
    const [dash, setDash] = useState(()=>{
        if(origin==='mymemberships'){
            return false;
        }else if (origin==="dash"){
            return true;
        }else if(origin==="operations"){
            return false;
        }else{
             return false;
        }});
    const [mymemberships, setMymemberships] = useState(()=>{
        if(origin==='mymemberships'){
            return true;
        }else if (origin==="dash"){
            return false;
        }else if(origin==="operations"){
            return false;
        }else{
             return true;
        }});
    const [operations, setOperations] = useState(()=>{
        if(origin==='mymemberships'){
            return false;
        }else if (origin==="dash"){
            return false;
        }else if(origin==="operations"){
            return true;
        }else{
             return false;
        }});

    const selectDash = () => {
        setDash(true);
        setMymemberships();
        setOperations();

    };
    const selectMydocuments = () => {
        setDash();
        setOperations();
        setMymemberships(true);

    };

    const selectOperations = () => {
        setDash();
        setMymemberships();
        setOperations(true);

    };




    return (
        <Container className="meetingsdash-container">
            <div style={{marginBottom:"20px"}}>
                <RiIcons.RiProductHuntLine title='Back to products' onClick={()=>{navigate('/identitydashProducts')}} style={{height:"20px",width:"20px"}}/>
                <TbIcons.TbMathGreater style={{height:"12px",width:"12px"}}/>
                <MdIcons.MdOutlineCardMembership style={{height:"18px",width:"18px"}} />
                </div>
            <Card>
                <Card.Header>
                    <Nav className="custom-nav-tabs" variant="tabs" defaultActiveKey= {()=>{
                        if(origin==='mymemberships'){
                            return("mymemberships");
                        }else if (origin==="dash"){
                            return("dashboard");
                        }else if(origin==="operations"){
                            return("operations");
                        }else{
                             return("mymemberships")
                        }
                    }  }>
                        {/* <Nav.Item>
                            <Nav.Link className="tabs" eventKey="dashboard" onClick={selectDash}>
                            <MdIcons.MdOutlineDashboardCustomize style={{height:"18px",width:"18px"}} />
                            &nbsp;
                                Dashboard
                            </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="mymemberships" onClick={selectMydocuments}>
                            <MdIcons.MdOutlineCardMembership style={{height:"18px",width:"18px"}} />
                            &nbsp;
                                My Memberships
                            </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link className="tabs" eventKey="operations" onClick={selectOperations}>
                            <MdIcons.MdSwitchAccessShortcutAdd style={{height:"18px",width:"18px"}} />
                            &nbsp;
                                Operations
                            </Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {/* {dash && <p>//Dashboard content will appear here</p>} */}
                    {mymemberships && <MyMemberships/>}
                    {/* {operations && <p>//Operations will appear here</p>} */}
                </Card.Body>
            </Card>

        </Container>

    );
}

export default Memberships;

