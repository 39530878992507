import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Image } from 'react-bootstrap';
import { Button, Modal } from 'antd';
import Cookies from 'js-cookie';
import neldLogo from '../images/SRHR.png'
import neldAgenda from '../images/neldAgenda2.jpg'
import Bwongo2 from '../images/bwongoAgenda1.png'
import Bwongo1 from '../images/bwongoAgenda2.png'
import Loader from '../Loader';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { useNavigate, useParams } from 'react-router-dom';
import pinnitags from '../images/plogotransparent.png'
import { fetchData } from '../../Utilities/apiRequests';
import { pinnitagsCookiePolicy, pinnitagsLandingUrl } from '../../Utilities/globals';
import * as RxIcon from 'react-icons/rx';
import * as GiIcon from 'react-icons/gi';
import * as MdIcon from 'react-icons/md';



const SrhrAgenda = () => {
    const [showAgenda, setShowAgenda] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState();
    const [loading, setLoading] = useState(false);
    const [tagStatus, setTagStatus] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [cookypolicy, setCookypolicy] = useState(true);
    const [showEmail, setShowEmail] = useState(false);
    const [userInfo, setUserInfo] = useState(false);


    const { tag } = useParams();
    const navigate = useNavigate()


    const submitUserInfo = async (setLoading, name, email, company) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name,
            email,
            tag,
            company
        };

        const Url = '/api/events/register/neld/1'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve(response.data.message)
            }


        } catch (error) {
            setLoading(false);
            console.log("Info submission error:", error)
            return Promise.reject(error.response.data.message)

        }
    };



    const checkTagStatus = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            tagcode: tag
        };

        const Url = '/api/events/neld/tag/status'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            setLoading(false)

            return Promise.resolve(response)



        } catch (error) {
            setLoading(false);
            console.log("check tag status error:", error)
            return Promise.reject(error)

        }
    };


    const EventCookiePolicy = Cookies.get('EventCookyPolicy');

    useEffect(() => {
        if (EventCookiePolicy) {
            setCookypolicy(false);
        }

    }, [])

    useEffect(() => {

        fetchData(`/api/events/neld/regstat/${tag}`, setLoading).then((response) => {
            const alreadyRegistered = Cookies.get('neld');
            const cookieValue = alreadyRegistered ? JSON.parse(alreadyRegistered) : false

            if (response.status == 202 && !cookieValue?.assigned) {
                setUserInfo(response.data.user)
                setShowEmail(true)

            }

        }).catch((error) => {
            // twit('info', 'failed to validate self registration.')
            if (error?.response.status == 403) {
                window.location.href = pinnitagsLandingUrl
            }

            console.log('Self registration validation error:', error.response?.data)

        })
    }, [])



    useEffect(() => {
        fetchData(`/api/events/neld/tag/expiry/${tag}`, setLoading).then((response) => {

            if (response.data.isExpired) {
                window.location.href = pinnitagsLandingUrl
            } else {
                if (response.data.showFeedback) {
                    setShowFeedback(true)
                }
            }
        }).catch((error) => {
            twit('info', 'failed to validate event status.')
            window.location.href = pinnitagsLandingUrl
            console.log('Event expiry validation error:', error.response?.data)

        })
    }, [])



    useEffect(() => {
        checkTagStatus(setLoading).then((response) => {

            if (response.status == 200) {
                setTagStatus(true)

            }

        }).catch((error) => {
            console.log(error.response?.data)
            if (error.response.status == 403) {
                twit('info', 'Unknown Tag')
                navigate('/')
            }
        })
    }, []);

    useEffect(() => {
        const alreadyRegistered = Cookies.get('neld');
        const cookieValue = alreadyRegistered ? JSON.parse(alreadyRegistered) : false


        if (!tagStatus && cookieValue && !cookieValue?.assigned) {
            setShowAgenda(true);
        } else if (tagStatus && cookieValue && !cookieValue?.assigned) {


            submitUserInfo(setLoading, cookieValue.name, cookieValue.email, cookieValue.company).then((data) => {
                twit('success', 'You have been registered successfully.')
                const expires = new Date();
                expires.setMinutes(expires.getMinutes() + 525600)
                Cookies.set('neld', JSON.stringify({ name: cookieValue.name, company: cookieValue.company, email: cookieValue.email, assigned: 1 }), { expires });
                setShowAgenda(true);
                setName('');
                setEmail('');
                setCompany('')
            }).catch((error) => {
                twit('info', error)
            })




        } else if (tagStatus && cookieValue && cookieValue?.assigned) {
            setShowAgenda(true);
        } else if (!tagStatus && cookieValue && cookieValue?.assigned) {
            setShowAgenda(true);
        }

    }, [tagStatus]);





    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (!company || !name || !email) {
            twit('info', 'Please provide all form fields.')
        } else if (!tagStatus) {
            twit('success', 'Thanks for registering but the tag you scanned is already assigned. please request for another tag and scan again to complete registration', 12)
            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 525600)
            Cookies.set('neld', JSON.stringify({ name, company, email, assigned: 0 }), { expires });
            setShowAgenda(true);
        } else {
            submitUserInfo(setLoading, name, email, company).then((data) => {
                twit('success', 'You have been registered successfully.')
                const expires = new Date();
                expires.setMinutes(expires.getMinutes() + 525600)
                Cookies.set('neld', JSON.stringify({ name, company, email, assigned: 1 }), { expires });
                setShowAgenda(true);
                setName('');
                setEmail('');
                setCompany('')
            }).catch((error) => {
                twit('info', error)
            })


        }

    };



    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container className='eventAgendaContainer' style={{ fontSize: '12px' }}>
                {cookypolicy && <Modal open={true}

                    closable={false}
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                    footer={[


                    ]}
                >

                    <Col  >
                        <Row>
                            <RxIcon.RxCookie style={{ width: '150px', height: '150px', color: "green" }} />
                        </Row>
                        <Row>
                            <h6>Your privacy</h6>
                            <p>By clicking "Accept all cookies",
                                you agree <span style={{ fontWeight: "bold" }}><a style={{ color: "green" }} href='https://pinnisoft.com/'>PinniSOFT</a> </span>can store cookies on your device
                                and disclose information in accordance with our
                                &nbsp;
                                <a style={{ color: "green" }} href={pinnitagsCookiePolicy}>Cookie Policy</a>

                            </p>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={() => {
                                Cookies.set('EventCookyPolicy', true, { expires: 7 });
                                setCookypolicy(false);
                            }} style={{ fontSize: '10px' }} variant="primary" htmlType='submit'>
                                <MdIcon.MdCookie className='mx-1' style={{ width: '15px', height: '15px' }} /> Accept all Cookies
                            </Button>

                        </Row>

                    </Col>




                </Modal>}

                {showEmail && !cookypolicy && <Modal open={true}
                    title='Confirm Your Email to proceed'
                    closable={false}
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                    footer={[


                    ]}
                >



                    <Form className=' neldRegForm' onSubmit={(e) => {
                        e.preventDefault()
                        const expires = new Date();
                        expires.setMinutes(expires.getMinutes() + 525600)
                        Cookies.set('neld', JSON.stringify({ name:userInfo.name, company: userInfo.company, email: userInfo.email, assigned: 1 }), { expires });
                        setShowAgenda(true)
                        setShowEmail(false)
                    }}>

                        <Form.Group controlId="email">
                            
                            <Form.Control
                                className="my-0 input-box-identity form-control-sm"
                                type="email"
                                value={userInfo?.email}
                                disabled
                            />
                        </Form.Group>
                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ fontSize: '10px' }} variant="primary" htmlType='submit'>
                                <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> Confirm
                            </Button>
                        </div>

                    </Form>





                </Modal>}


                <Row>
                    <Col>
                        {!showAgenda ? (
                            <div>
                                <div className='my-0 mx-0' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <a href='https://srhrallianceug.org/' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}><img src={neldLogo} alt="Logo" className=' neldlogo ' /></a>
                                    <p className=' neldTheme ' style={{ fontWeight: 'bold' }}>IT TAKES TWO FLINTS TO MAKE A FIRE.</p>
                                </div>
                                <Form className=' neldRegForm' onSubmit={handleFormSubmit}>
                                    <Form.Group controlId="name">

                                        <Form.Control
                                            className="my-2 input-box-identity form-control-sm"
                                            type="text"
                                            placeholder="Enter your name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="email">

                                        <Form.Control
                                            className="my-2 input-box-identity form-control-sm"
                                            type="email"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="company">

                                        <Form.Control
                                            className="my-2 input-box-identity form-control-sm"
                                            type="text"
                                            placeholder="Enter your company"
                                            value={company}
                                            onChange={(e) => setCompany(e.target.value)}
                                        />
                                    </Form.Group>
                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ fontSize: '10px' }} variant="primary" htmlType='submit'>
                                            <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> Register
                                        </Button>
                                    </div>
                                    <div className='mt-5' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <p className=' neldTheme ' style={{ fontWeight: 'bold' }}>Powered by</p>
                                        <img src={pinnitags} alt="Logo" className='neldpinnitagsLogo' />

                                    </div>
                                </Form>
                            </div>
                        ) : (
                            <div className='neldAgendaContainer' >
                                <div className='my-0 mx-0' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <a href='https://srhrallianceug.org/' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}><img src={neldLogo} alt="Logo" className=' neldlogo ' /></a>
                                    <p className=' neldTheme ' style={{ fontWeight: 'bold' }}>IT TAKES TWO FLINTS TO MAKE A FIRE</p>
                                </div>

                                <Image
                                    src={Bwongo1}
                                    alt="Event Agenda"
                                    fluid
                                    style={{ border: '1px solid #ddd', borderRadius: '10px' }}
                                    className=' neldAgenda mb-3 '
                                />

                                    <Image
                                        src={Bwongo2}
                                        alt="Event Agenda"
                                        fluid
                                        style={{ border: '1px solid #ddd', borderRadius: '10px' }}
                                        className=' neldAgenda  '
                                    />

                                <div className='mt-3' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <p className=' neldTheme ' style={{ fontWeight: 'bold', margin: '0px' }}>Powered by</p>
                                    <a style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }} href={pinnitagsLandingUrl}><img src={pinnitags} alt="Logo" className='neldpinnitagsLogo' /></a>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    };
};

export default SrhrAgenda;
