import { Navigate } from "react-router-dom";



const ProtectedBranchRoute = ({
    loggedin,
    redirectPath = '/assetBranch/0',
    children,
  }) => {
    if (loggedin) {
        return children; 
    }
    return <Navigate to={redirectPath} replace />;
    
  };

export default ProtectedBranchRoute;