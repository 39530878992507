import { notification, message } from 'antd';





export const twit = (type,information,durationL) => {


  message.config({
    
    maxCount: 1,
   
  });

  if(type==="info"){
   
    message.info({
      content:information,
      style:{fontSize:"12px"},
      duration: durationL? durationL:2,
      
    });
  }else if(type==="success"){
    message.success({
      content:information,
      style:{fontSize:"12px"},
      duration: durationL? durationL:2,
      
      
    });

  }else if(type==="error"){
    message.error({
      content:information,
      style:{fontSize:"12px"},
      duration: durationL? durationL:2,
      
    });
  
  }else if(type==="warning"){
    message.warning({
      content:information,
      style:{fontSize:"12px"},
      duration: durationL? durationL:2,
      maxCount: 1
    });
    
  }


  
};


//notifications

export const ntwit = (type,information,durationL) => {


  if(type==="info"){
   
    notification.info({
      description:information,
      style:{fontSize:"10px"},
      duration: durationL? durationL:2
    });
  }else if(type==="success"){

    
    notification.success({
      description:information,
      style:{fontSize:"10px"},
      duration: durationL? durationL:2,
      
      
    });

  }else if(type==="error"){
    notification.error({
      description:information,
      style:{fontSize:"10px"},
      duration: durationL? durationL:2
    });
  
  }else if(type==="warning"){
    notification.warning({
      description:information,
      style:{fontSize:"10px"},
      duration: durationL? durationL:2
    });
    
  }


  
};





























// const Mesage = () => (
//   <Button type="primary" onClick={()=>{
//     twit("success","testing....");
//   }}>
//     Display normal message
//   </Button>
// );
// export default Mesage;









// import React from 'react'
// import { Modal } from 'antd';
// import ColoredD from './ColorSport';

// const Notificationpopout = ({message,success}) => {
//   return (
//     <div>
//        <Modal open={true} title={success?"Success":"Error"} onCancel={handleCancel} onOk={handleCancel}
//           footer={[


//           ]}
//         >
//           <ColoredD color={success? "green":"red"}>{message}</ColoredD>

//         </Modal>
//     </div>
//   )
// }

// export default Notificationpopout;
