import React, { useEffect, useState } from 'react'
import Loader from '../Loader';
import LoaderButton from '../LoaderButton';
import { Form } from 'react-bootstrap';
import useFetch from '../../hooks/hooks/getData';
import { BiHide, BiShow } from 'react-icons/bi';
import usePost from '../../hooks/hooks/postData';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { useNavigate } from "react-router-dom";


const DocumentsApi = () => {

    const [loading, setLoading] = useState(false);
    const [signUpPage, setSignUpPage] = useState(false);
    const [active, setActive] = useState(false)
    const [url, setUrl] = useState()
    const [actype, setActype] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [apikey, setApikey] = useState();
    const [clientData, setClientData] = useState();
    const [newURl, setNewURL] = useState(null)
    const [refresh, setRefresh] = useState(false)






    const navigate = useNavigate()
    const accountTypes = useFetch('/api/documents/accountTypes', setLoading);
    const createAccount = usePost("/api/documents/account/create", setLoading);
    const requestNewKey = usePost("/api/documents/account/key", setLoading);
    const clientInfo = useFetch('/api/documents/account/user', setLoading, refresh);

    const updateUrl = async () => {
        const token = sessionStorage.getItem('token')
        try {
            setLoading(true)
            const response = await axios.patch('/api/documents/account/url',
                JSON.stringify({ url: newURl }),
                {
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                    }

                }

            );



            setLoading(false)
            if (response.status === 201) {


                return Promise.resolve(response?.data.message)


            }

        } catch (err) {
            setLoading(false)
            console.log(err)
            return Promise.reject(err)
        }
    }



    useEffect(() => {
        if (clientInfo?.data) {
            setActive(true)
            setClientData(clientInfo?.data?.client)
            setApikey(clientInfo?.data?.key)
        }

    }, [clientInfo])


    if (loading) {
        return (<Loader />)
    } else {

        return (
            <div>

                {((!active) && (!signUpPage)) && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', width: '100%' }}>
                        <LoaderButton onClick={() => {
                            setSignUpPage(true);
                        }} loading={loading}
                            variant={"primary"}
                            label={'Activate'}
                            style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        />

                    </div>
                )}
                {(signUpPage) && (
                    <div>
                        <Form.Group >
                            <Form.Control
                                className=' input-box-invoice-form form-control-sm'
                                type="text"
                                name="url"
                                value={url}
                                placeholder="Enter redirect url"
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group style={{ marginBottom: "14px" }} >


                            <Form.Select


                                onChange={(e) => {
                                    setActype(e.target.value);
                                }}

                                className='form-selector'>
                                <option key={1} value={""}>Select account type</option>
                                {accountTypes?.data?.accountTypes?.map((data) => {
                                    return <option className='input-box' key={data.atid} value={data.atid}>{data.atname}</option>
                                })}

                            </Form.Select>

                        </Form.Group>

                        {((actype) && (url)) && (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <LoaderButton onClick={() => {

                                    createAccount.post({ url, accountType: actype }).then((info) => {
                                        if (info?.data) {
                                            setApikey(info?.data?.key)
                                            twit('success', info?.data?.message)
                                            setSignUpPage(false)
                                            setActive(true)

                                        } else {
                                            twit('info', 'An error occurred while registering.')
                                        }


                                    })
                                }} loading={loading}
                                    variant={"primary"}
                                    label={'Save & Activate'}
                                    style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                                />

                            </div>
                        )}
                    </div>
                )}
                {(active) && (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'normal', alignItems: 'center', minHeight: '100vh', width: '100%', marginTop: '10%' }}>
                        <a style={{ color: "green", cursor: "pointer" }} className='identity-form-button mb-5'
                            onClick={() => {
                                navigate('/tagsTest')
                            }}
                        >
                            Try it out...
                        </a>
                        <p className='text-muted mb-0'>Redirect URL</p>
                        <div style={{ width: '100%' }}>
                            <Form.Group  >
                                <Form.Control
                                    className=' input-box-invoice-form form-control-sm'
                                    type={'text'}
                                    name="key"
                                    value={newURl !== null ? newURl : clientData?.redirectUrl}
                                    onChange={(e) => {

                                        setNewURL(e.target.value)


                                    }}
                                />

                            </Form.Group>
                            <Form.Group style={{ marginBottom: "14px" }} ></Form.Group>


                        </div>
                        {newURl !== null && (
                            <LoaderButton onClick={() => {
                                updateUrl().then((message) => {
                                    twit('success', message)
                                    setNewURL(null)
                                    setRefresh(!refresh)
                                }).catch(() => {
                                    twit('info', 'An error occurred while updating.')
                                })
                            }} loading={loading}
                                variant={"primary"}
                                label={'Update URL'}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                            />
                        )}

                        <p className='text-muted mb-0'>API KEY</p>
                        <div style={{ width: '100%' }}>
                            <Form.Group  >
                                <Form.Control
                                    className=' input-box-invoice-form form-control-sm'
                                    type={showPassword ? 'text' : 'password'}
                                    name="key"
                                    value={apikey}
                                    readOnly
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '45.3%',
                                        right: '32px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setShowPassword(!showPassword)
                                    }}
                                >
                                    {showPassword ? <BiHide size={20} /> : <BiShow size={20} />}
                                </span>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: "14px" }} ></Form.Group>


                        </div>

                        <LoaderButton onClick={() => {
                            requestNewKey.post({}).then((response) => {
                                if (response.data) {
                                    twit('success', 'New key generated.')
                                    setRefresh(!refresh)
                                } else {
                                    twit('info', 'An error occured while requesting new key.')
                                }

                            })
                        }} loading={loading}
                            variant={"primary"}
                            label={'Generate new key'}
                            style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        />

                    </div>
                )}


            </div>
        )
    }
}

export default DocumentsApi;
