import { Card, Button,Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as GiIcons from 'react-icons/gi';
import * as MdIcons from 'react-icons/md';
import * as SiIcons from 'react-icons/si';
import * as AiIcons from 'react-icons/ai';
import { downloadPDF } from '../../Utilities/utilities';
import Invoice from '../Invoice';
import { useState } from 'react';
import { Modal } from 'antd';
import usePost from '../../hooks/hooks/postData';
import { twit } from '../Notificationpopout';
import LoaderButton from '../LoaderButton';
import useCart from '../../hooks/hooks/useCart';
import { truncateString } from '../../Utilities/utilities';

//MdOutlineCardMembership


//MdLoyalty
//SiLastpass
//HiCheckBadge



const Card1 = ({ title, active, productData ,addsOn,refresh,refreshFunction}) => {

  const [printable, setPrintable] = useState(false);
  const [showActivateInvoice, setShowActivateInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [endDate,setEndDate] = useState("");
  const [addToCart,setAddToCart] = useState(false);

  const subscribe = usePost("/api/products/identity/subscription", setLoading);
  const {addItem,state} = useCart();


  const addMonths = (months) => {
    const now = new Date();
    now.setMonth(now.getMonth() + months);
    return now;
  };


  const getIcon = (icon) => {
    if (icon === "Identification") {
      return (<GiIcons.GiHumanTarget style={{ height: "30px", width: "30px" }} />)
    } else if (icon === "Membership") {
      return (<MdIcons.MdOutlineCardMembership style={{ height: "30px", width: "30px" }} />)
    } else if (icon === "Loyalty") {
      return (<MdIcons.MdLoyalty style={{ height: "30px", width: "30px" }} />)
    } else if (icon === "Pass") {
      return (<SiIcons.SiLastpass style={{ height: "30px", width: "30px" }} />)
    } else if (icon === "Asset Tagging") {
      return (<AiIcons.AiOutlineTags style={{ height: "30px", width: "30px" }} />)
    } else {
      return (<MdIcons.MdBadge style={{ height: "30px", width: "30px" }} />)
    }
  }

  const getPath = (path) => {
    if (path === "Identification") {
      return ("/human");
    } else if (path === "Membership") {
      return ("/memberships");
    } else if (path === "Loyalty") {
      return ("/loyalty");
    } else if (path === "Pass") {
      return ("#");
    } else if (path === "Asset Tagging") {
      return ("/assets");
    } else {
      return ("#");
    }


  }

  const navigate = useNavigate()
  return (
    <Card style={{ minWidth: "150px", cursor: "pointer" }} className={active ? 'identity-dash-card my-1' : "identity-dash-card-inactive my-1"}>

  {addToCart&& <Modal open={true} title={'Subscribe To Product'} onCancel={() => {
                setAddToCart(false);
            }}
                footer={[


                ]}
                className='allmodals'
            >

                <div>
                <Form.Group controlId="date">
                        <Form.Label style={{ marginBottom: "0px" }}>Subscription Period </Form.Label>
                        <Form.Control

                            placeholder='Months'
                            className=' input-box-identity form-control-sm'
                            type="text"
                            name="date"
                            value={endDate}
                            onChange={(e) => {

                                setEndDate( e.target.value  );
                            }}

                        />
                    </Form.Group>


                    <div>

                        <LoaderButton onClick={() => {
                            if (endDate === "") {
                                twit("info", "Please input a subscription period.");
                            } else {
                              setAddToCart(false);
                              //addItem({type:"identityProductSubscription",name:title,period:3,icon:getIcon(title),ptid:productData?.ptid,endDate: addMonths(endDate).toISOString().slice(0,10) });
                              setShowActivateInvoice(true);
                            }

                        }} loading={false}
                            variant={"primary"}
                            label={ "Proceed To Pay"}
                            style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}

                        />


                    </div>








                </div>



            </Modal>}














      {showActivateInvoice && <Modal open={true} onCancel={() => { setShowActivateInvoice(false) }}
        footer={[


        ]}
        className='allmodals'
      >
        <div id="invoice">
          <Invoice

            items={[{ name: title+" Product Activation", unitPrice: 50000, qty:1,amount:50000 }]}
            invoiceNumber="010206"
            printable={printable}
          />

        </div>


       
        <div style={{ width: "100%" }}>

          <LoaderButton onClick={() => {
            setPrintable(true);
            downloadPDF("invoice", setPrintable);
          }} loading={false}
            variant={"primary"}
            label="Download"
            style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", width: "100px" }}
            className={"mx-2"}
          />
          <LoaderButton onClick={() => {
            subscribe.post({ ptid: productData?.ptid ,dto:addMonths(endDate).toISOString().slice(0,10) }).then((data) => {
              if (data?.type === "data") {
                setShowActivateInvoice(false);
                refreshFunction(!refresh);
                twit("success", data?.data?.data);
              } else {

                twit("info", data?.error?.response?.data?.data);
              }
            })



          }} loading={loading}
            variant={"primary"}
            label={loading ? "loading" : "Pay and Activate"}
            style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", width: "100px" }}
            className={"mx-2"}
          />
        </div>







      </Modal>}

      <Card.Body onClick={() => {

        if (active&&!addsOn) {
          navigate(getPath(title));
        } else if(active&&addsOn) {
          //setShowActivateInvoice(true);
          setAddToCart(true);
          
        }else{
          
        }

      }}>

        {getIcon(title)}



        <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{title}</p>

        {/* <Card.Link onClick={()=>{navigate('/human')}} href='#'>Start</Card.Link> */}

      </Card.Body>
    </Card>
  );
}

export default Card1