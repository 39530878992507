import { Button } from 'antd';
import React, { useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import * as GiIcon from 'react-icons/gi';
import ColoredD from './ColorSport';
import ColorCube from './ColorCube';
import * as MdIcons from 'react-icons/md';
import { ImCross } from "react-icons/im";
import * as TiIcons from 'react-icons/ti';
import { formatPrice, getformattedDate } from '../Utilities/utilities';


const CompTicketSearch = ({ email, tickets = [] }) => {
    const [searchTerm, setSearchTerm] = useState('');



    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };





    const filteredTickets = tickets?.filter((ticket) =>
        ticket?.tagName?.toLowerCase().includes(searchTerm.toLowerCase())
    );



    const tableStyles = {
        backgroundColor: '#f8f9fa',
        fontSize: '10px',
    };

    const headerStyles = {
        backgroundColor: '#343a40',
        color: '#fff',
    };

    const rowStyles = {
        height: '1px',
        padding: '0px',
        margin: '0px'
    };

    const rowCellStyles = {
        paddingTop: '2px',
        paddingBottom: '2px'
    }

    return (
        <div className="user-selection-component">

            <div style={{ width: '100%', textAlign: 'center' }}>
                <p style={{ margin: 0, fontWeight: 'bold' }}>{`${email}`}</p>
            </div>

            <Form.Control
                className=' input-box-events form-control-sm my-2'
                type="text"
                placeholder="Search transactions..."
                value={searchTerm}
                onChange={handleSearch}

            />
            <div className='mt-2 table-responsive  user-list ' >
                <Table striped bordered hover style={tableStyles}>
                    <thead>
                        <tr style={headerStyles}>
                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Ticket</th>
                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Sent</th>
                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Status</th>
                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Entered</th>
                            <th style={{ textAlign: "center", ...rowCellStyles }}> Date </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTickets?.sort((a, b) => {
                            return new Date(b.createdAt) - new Date(a.createdAt);
                        }).map((item, index) => (
                            <tr key={index} style={{ ...rowStyles }}>
                                {/* <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td> */}
                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.tagName}</td>
                                <td style={{ textAlign: "center", ...rowCellStyles }}>{!(item.sent) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                <td style={{ textAlign: "center", ...rowCellStyles }}>{!(item.status) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                <td style={{ textAlign: "center", ...rowCellStyles }}>{!(item.entered) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                <td style={{ textAlign: "center", fontSize: '10px', whiteSpace: 'nowrap', ...rowCellStyles }}>{getformattedDate(item?.createdAt)}</td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>



            <style jsx>{`
        .user-selection-component {
          font-family: Arial, sans-serif;
          max-width: 500px;
          margin: 0 auto;
          padding: 0px;
          
        }
        .search-input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .user-list {
          
          max-height:200px;
          overflow-y: scroll;
        }
        .user-item {
          margin-bottom: 10px;
        }
        .user-label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .user-checkbox {
          margin-right: 10px;
        }
        .user-image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .user-name {
          font-size: 14px;
        }
        .submit-button {
          width: 100%;
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          margin-top: 10px;
        }
        .submit-button:hover {
          background-color: #0056b3;
        }
        .email-toggle {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .email-toggle label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .email-toggle input {
          margin-right: 10px;
        }
      `}</style>
        </div>
    );
};

export default CompTicketSearch;