import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Table, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { getformattedDate, removeSpacesInString } from '../../Utilities/utilities';
import { fetchData } from '../../Utilities/apiRequests';
import * as MdIcons from 'react-icons/md';
import * as GrIcons from 'react-icons/gr';
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import * as GiIcon from 'react-icons/gi';
import { ImCross } from "react-icons/im";
import Pagination from '../Pagination';

const EventCredentialForm = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [doorcheckers, setDoorcheckers] = useState([]);
    const [scans, setScans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [eventInfo, setEventInfo] = useState([]);
    const [selectedRights, setSelectedRights] = useState([]);
    const [selectedRrights, setSelectedRrights] = useState([]);
    const [rights, setRights] = useState([]);
    const [includedRights, setIncludedRights] = useState([]);
    const [updateChecker, setUpdateChecker] = useState();
    const [did, setDid] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [allRights, setAllRights] = useState([]);

    const { eid } = useParams();



    useEffect(() => {

        fetchData(`/api/events/doorcheckers/${eid}`, setLoading).then((response) => {

            setDoorcheckers(response.data.doorCheckers)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event door checkers.')
        })

        // fetchData(`/api/events/scans`, setLoading).then((response) => {

        //     setScans(response.data.scans)
        // }).catch((error) => {
        //     twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event scans.')
        // })

        fetchData(`/api/events/statistics/passCheckers/${eid}`, setLoading).then((response) => {

            setScans(response.data.passCheckerData)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event scans.')
        })

        fetchData(`/api/events/details/${eid}`, setLoading).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

        
    }, [refresh])

    useEffect(()=>{
        fetchData(`/api/events/doorchecker/rights`, setLoading).then((response) => {
            
            setAllRights(response.data.rights)
            const rightsData = response.data.rights?.filter((data)=>{
                if((eventInfo?.includeCoupon) &&(eventInfo?.includePass)){
                    return true
                }else if((!(eventInfo?.includeCoupon)) &&(eventInfo?.includePass)){
                    return ((data.name != 'Coupon Redeem'))
                }else if ((eventInfo?.includeCoupon) &&(!(eventInfo?.includePass))){
                    return ((data.name != 'Pass'))
                }else{
                    return ((data.name != 'Pass')&&(data.name != 'Coupon Redeem'))
                }
                
           })

            if(eventInfo?.prive){
                if(eventInfo.inviteOnly){
                    setRights(rightsData?.filter((data)=>{
                         return ((data.name != 'Pass')&&(data.name != 'SellTickets')&&(data.name != 'ActivateBatch') )
                    }))
                }else{
                    
                   
                        setRights(rightsData)
                    
                    
                }
            }else{
                if(eventInfo.gateSale){
                    setRights(rightsData)
                }else{
                    setRights(rightsData?.filter((data)=>{
                        return ((data.name != 'SellTickets') )
                   }))
                     
                }
            }
            
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

    },[refresh,eventInfo])



    const createEventCredentials = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name,
            email,
            eid,
            rights: includedRights,

        };

        const Url = '/api/events/create/doorchecker'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Door Checker added successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Door Checker creation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while creating the event credentials.')

        }
    };

    const revokePassChecker = async (setLoading, did) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            did
        };

        const Url = '/api/events/doorchecker/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Pass checker status updated successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Pass Checker revoke Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating status.')

        }
    };


    const getPassCheckerScans = (did) => {
        return scans.filter((data) => {
            return data.did == did
        })?.length
    }




    const handleSubmit = (e) => {
        e.preventDefault();

        if (!eid || !name || !email) {
            if (!name) {
                twit('info', 'Please provide the name.')
            } else if (!email) {
                twit('info', 'Please provide the email.')
            }



        } else {
            createEventCredentials(setLoading).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setName('');
                setEmail('');
                setIncludedRights([])

            }).catch((error) => {
                twit('info', error)
            })


        }



    };

    const updatePassChecker = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name,
            email,
            rrights: selectedRrights,
            rights: selectedRights,
            did
        };

        const Url = '/api/events/doorchecker/update/user'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Pass checker updated successfully.')



            }


        } catch (err) {
            setLoading(false);
            console.log("Event Pass  Error:", err.response?.message)
            return Promise.reject(err.response?.data?.message ? err.response?.data?.message : 'An error occured while updating event pass.')

        }
    };

    const handlePassCheckerUpdate = (e) => {
        e.preventDefault();


        updatePassChecker(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            setName('');
            setEmail('');
            setUpdateChecker(false)
        }).catch((error) => {
            twit('info', error)
        })






    };



    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedDoorCheckers = doorcheckers?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }

    //get right name from id
    const getRightName = (rtid)=>{
        return allRights.filter((data)=>{
            return (data.id == rtid)
        })[0]?.name
    }


    //function to get correct user rights
    const getUSerRights = (rightsArray)=>{
        
        const rightsData = rightsArray?.filter((data)=>{
            const dataName = getRightName(data)
            if((eventInfo?.includeCoupon) &&(eventInfo?.includePass)){
                return true
            }else if((!(eventInfo?.includeCoupon)) &&(eventInfo?.includePass)){
                return ((dataName != 'Coupon Redeem'))
            }else if ((eventInfo?.includeCoupon) &&(!(eventInfo?.includePass))){
                return ((dataName != 'Pass'))
            }else{
                return ((dataName != 'Pass')&&(dataName != 'Coupon Redeem'))
            }
            
       })

        if(eventInfo?.prive){
            if(eventInfo.inviteOnly){
                return(rightsData?.filter((data)=>{
                    const dataName = getRightName(data)
                     return ((dataName != 'Pass')&&(data.name != 'SellTickets')&&(dataName != 'ActivateBatch') )
                }))
            }else{
                
               
                    return(rightsData)
                
                
            }
        }else{
            if(eventInfo.gateSale){
                return(rightsData)
            }else{
                
                return(rightsData?.filter((data)=>{
                    const dataName = getRightName(data)
                    return ((dataName != 'SellTickets') )
               }))
                 
            }
        }
    }
    

    const passRightId = 'BE0BA3EE-9384-4928-8A96-A058C126C861'
    const couponRightId = '9A1A4C7D-FBC8-4859-A5BC-C6286A9F0589'
    const posSaleRightId = '4D58BF58-81F9-413A-A2AD-D95AAF1E4635'


    const getPassCheckerStats = (did)=>{
        return scans.filter((data)=>{
            return data.did == did
        })[0]
    }


    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>

                {updateChecker && <Modal open={true} title={'Pass Checker Management'} onCancel={() => {

                    setUpdateChecker(false)
                    setSelectedRrights([])
                    setName('')
                    setEmail('')
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        <Form onSubmit={handlePassCheckerUpdate}>

                            <Card>
                                <Card.Body >
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col className='my-1' md={6}>
                                            <Form.Group controlId="name">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className=' FormLabelsModel'>
                                                        <p style={{ margin: 0 }}>Name</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={6}>
                                            <Form.Group controlId="email">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='FormLabelsModel'>
                                                        <p style={{ margin: 0 }}>Email</p>
                                                    </div>

                                                    <Form.Control
                                                        disabled
                                                        className=' input-box-events form-control-sm'
                                                        type="email"
                                                        placeholder="Enter email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="access rights">
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0, }}>Stelify PASS App Rights</p>
                                                    </div>
                                                    <div>
                                                        <div className='d-flex flex-row   w-100'>

                                                            <Form.Check

                                                                type="checkbox"
                                                                label={'All Rights'}
                                                                name="includedZones"
                                                                checked={selectedRights?.length == rights?.length}
                                                                onChange={(e) => {
                                                                    if (selectedRights?.length == rights?.length) {
                                                                        setSelectedRights([])
                                                                        setSelectedRrights(rights.map((rt) => {
                                                                            return rt.id
                                                                        }))
                                                                    } else {
                                                                        setSelectedRights(rights.map((rt) => {
                                                                            return rt.id
                                                                        }))
                                                                        setSelectedRrights([])
                                                                    }
                                                                }}
                                                                className="my-0 mx-1  input-box-events form-control-sm"
                                                            />

                                                            {rights.slice(0, 2).map((data) => (

                                                                <Form.Check

                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedRights"
                                                                    checked={selectedRights?.includes(data.id)}
                                                                    onChange={(e) => {
                                                                        if (selectedRights?.includes(data.id)) {
                                                                            setSelectedRights(selectedRights.filter((right) => {
                                                                                return right != data.id
                                                                            }))
                                                                            if (!selectedRrights?.includes(data.id)) {
                                                                                selectedRrights.push(data.id)
                                                                            }
                                                                        } else {
                                                                            setSelectedRights([...selectedRights, data.id])
                                                                            if (selectedRrights?.includes(data.id)) {
                                                                                setSelectedRrights(selectedRrights.filter((right) => {
                                                                                    return right != data.id
                                                                                }))
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                        <div className='d-flex flex-row   w-100'>



                                                            {rights.slice(2, 5).map((data) => (

                                                                <Form.Check

                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedRights"
                                                                    checked={selectedRights?.includes(data.id)}
                                                                    onChange={(e) => {
                                                                        if (selectedRights?.includes(data.id)) {
                                                                            setSelectedRights(selectedRights.filter((right) => {
                                                                                return right != data.id
                                                                            }))
                                                                            if (!selectedRrights?.includes(data.id)) {
                                                                                selectedRrights.push(data.id)
                                                                            }
                                                                        } else {
                                                                            setSelectedRights([...selectedRights, data.id])
                                                                            if (selectedRrights?.includes(data.id)) {
                                                                                setSelectedRrights(selectedRrights.filter((right) => {
                                                                                    return right != data.id
                                                                                }))
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />




                                                            ))}


                                                        </div>

                                                        <div className='d-flex flex-row   w-100'>



                                                            {rights.slice(5).map((data) => (

                                                                <Form.Check

                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedRights"
                                                                    checked={selectedRights?.includes(data.id)}
                                                                    onChange={(e) => {
                                                                        if (selectedRights?.includes(data.id)) {
                                                                            setSelectedRights(selectedRights.filter((right) => {
                                                                                return right != data.id
                                                                            }))
                                                                            if (!selectedRrights?.includes(data.id)) {
                                                                                selectedRrights.push(data.id)
                                                                            }
                                                                        } else {
                                                                            setSelectedRights([...selectedRights, data.id])
                                                                            if (selectedRrights?.includes(data.id)) {
                                                                                setSelectedRrights(selectedRrights.filter((right) => {
                                                                                    return right != data.id
                                                                                }))
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />




                                                            ))}


                                                        </div>
                                                    </div>



                                                </div>

                                            </Form.Group>
                                        </Col>
                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GrIcons.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Pass Checker
                                </Button>
                            </div>
                        </Form>

                    </div>


                </Modal>}
                <Row>
                    <Col>
                        <p style={{ fontWeight: 'bold' }}> Event Pass Checker Management </p>
                        <Form onSubmit={handleSubmit}>

                            <Card>
                                <Card.Body >
                                    <Row>
                                        <Col className='my-1' md={6}>
                                            <Form.Group controlId="name">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Name</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={6}>
                                            <Form.Group controlId="email">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Email</p>
                                                    </div>

                                                    <Form.Control

                                                        className=' input-box-events form-control-sm'
                                                        type="email"
                                                        placeholder="Enter email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="access-rights">
                                                <div className='pass-access-zones'  >
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0,whiteSpace:'nowrap' }}>Stelify PASS App Rights</p>
                                                    </div>
                                                    <div style={{maxWidth:'100%',}} >
                                                        <div  className='scrollable-checkbox-div ' >

                                                            <Form.Check

                                                                type="checkbox"
                                                                label={'All Rights'}
                                                                name="includedRights"
                                                                checked={includedRights?.length == rights?.length}
                                                                onChange={(e) => {
                                                                    if (includedRights?.length == rights?.length) {
                                                                        setIncludedRights([])
                                                                    } else {
                                                                        setIncludedRights(rights.map((rt) => {
                                                                            return rt.id
                                                                        }))
                                                                    }
                                                                }}
                                                                className="my-0 mx-1  input-box-events form-control-sm"
                                                            />

                                                            {rights.map((data) => (

                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedrights"
                                                                    checked={includedRights?.includes(data.id)}
                                                                    onChange={(e) => {
                                                                        if (includedRights?.includes(data.id)) {
                                                                            setIncludedRights(includedRights.filter((right) => {
                                                                                return right != data.id
                                                                            }))
                                                                        } else {
                                                                            setIncludedRights([...includedRights, data.id])
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                        {/* <div className='d-flex flex-row   w-100'>



                                                            {rights.slice(2, 5).map((data) => (

                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedrights"
                                                                    checked={includedRights?.includes(data.id)}
                                                                    onChange={(e) => {
                                                                        if (includedRights?.includes(data.id)) {
                                                                            setIncludedRights(includedRights.filter((right) => {
                                                                                return right != data.id
                                                                            }))
                                                                        } else {
                                                                            setIncludedRights([...includedRights, data.id])
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}



                                                        </div>

                                                        <div className='d-flex flex-row   w-100'>



                                                            {rights.slice(5).map((data) => (

                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedrights"
                                                                    checked={includedRights?.includes(data.id)}
                                                                    onChange={(e) => {
                                                                        if (includedRights?.includes(data.id)) {
                                                                            setIncludedRights(includedRights.filter((right) => {
                                                                                return right != data.id
                                                                            }))
                                                                        } else {
                                                                            setIncludedRights([...includedRights, data.id])
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}



                                                        </div> */}
                                                    </div>



                                                </div>

                                            </Form.Group>
                                        </Col>
                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> |  Add  pass checker
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <p style={{ fontWeight: 'bold' }}> Event Pass Checkers </p>
                    <div className='table-responsive'>
                        {doorcheckers?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Last Login</th>
                                        {(eventInfo?.includeCoupon)&&(<th>Coupon Redeems</th>)}
                                        {(eventInfo?.passVerification)&&(<th>Pass & CheckIns</th>)}
                                        {(!(eventInfo?.prive)&&(eventInfo?.gateSale)&&(eventInfo?.paid))&&(<th>POS sale</th>)}
                                        <th>Operations</th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedDoorCheckers?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{!(data.dstatus) ? <ImCross style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                            <td>{getformattedDate(data.lastlogin)}</td>
                                            {(eventInfo?.includeCoupon)&&(<td>{
                                                data?.doorCheckerAssignedRights?.map((rt) => {
                                                    return rt.rtid
                                                }).includes(couponRightId)?getPassCheckerStats(data?.did)?.redeems:'N/A'
                                                
                                                }</td>)}
                                            {(eventInfo?.passVerification)&&(<td>{getPassCheckerStats(data.did)?.scans}</td>)}
                                            {(!(eventInfo?.prive)&&(eventInfo?.gateSale)&&(eventInfo?.paid))&&(<td>
                                                {
                                                    data?.doorCheckerAssignedRights?.map((rt) => {
                                                        return rt.rtid
                                                    }).includes(posSaleRightId)?getPassCheckerStats(data?.did)?.sales:'N/A'
                                                }
                                            </td>)}
                                            <td>

                                                <GrIcons.GrUploadOption className='mx-1' title='Update Pass Checker' style={{ height: "15px", width: "15px" }} onClick={() => {

                                                    const passCheckerRights = data?.doorCheckerAssignedRights?.map((rt) => {
                                                        return rt.rtid
                                                    })
                                                     //setSelectedRights(passCheckerRights)
                                                    setSelectedRights(getUSerRights(passCheckerRights))
                                                    setName(data.name)
                                                    setEmail(data.email)
                                                    setDid(data.did)
                                                    setUpdateChecker(true)


                                                }} />
                                                {data.dstatus ? (
                                                    <HiIcons.HiUserRemove className='mx-1' title='Revoke Pass Checker' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                        revokePassChecker(setLoading, data.did).then((data) => {
                                                            setRefresh(!refresh)
                                                            twit('success', data)
                                                        }).catch((error) => {
                                                            twit('info', error)
                                                        })
                                                    }} />
                                                ) :

                                                    (
                                                        <HiIcons.HiUserAdd className='mx-1' title='Restore Pass Checker' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                            revokePassChecker(setLoading, data.did).then((data) => {
                                                                setRefresh(!refresh)
                                                                twit('success', data)
                                                            }).catch((error) => {
                                                                twit('info', error)
                                                            })
                                                        }} />
                                                    )

                                                }


                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No pass checkers added.</p>

                        }
                        {doorcheckers?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={doorcheckers?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }
};

export default EventCredentialForm;
