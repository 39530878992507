import axios from '../api/axios';


const useSectors = async() => {
    const SectorUrl =  '/api/sectors'

        try {
          const response = await axios.get(SectorUrl);
          
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }
      
       
}

export default useSectors;