import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card, Table, InputGroup } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as MdIcons from 'react-icons/md';
import { fetchData } from '../../Utilities/apiRequests';
import { ImCross } from "react-icons/im";
import * as GrIcon from 'react-icons/gr';
import Pagination from '../Pagination';
import { Button, Modal } from 'antd';


const CollectionAccountSettings = () => {
    const [sponsorName, setSponsorName] = useState('');
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showAccountUpdate, setShowAccountUpdate] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [bankInfo, setBankInfo] = useState([]);
    const [collectionAcs, setCollectionAcs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [bid, setBid] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const [acno, setAcno] = useState('');
    const [branch, setBranch] = useState('');
    const [swiftCode, setSwiftCode] = useState('');
    const [initialBank, setInitialBank] = useState('');
    const [name, setName] = useState('');






    useEffect(() => {

        fetchData(`/api/billing/accounts/collection`, setLoading).then((response) => {

            setCollectionAcs(response.data.accounts)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading collection accounts.')
        })

        fetchData(`/api/billing/banks`, setLoading).then((response) => {
            setBankInfo(response.data.banks)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading banks.')
        })





    }, [refresh])

    const createCollectionAccount = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            bid,
            swiftCode,
            branch,
            acno,
            isDefault,
            name
        };
        const Url = '/api/billing/accounts/collection/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Account created successfully.')

            }


        } catch (error) {
            setLoading(false);
            console.log("Collection Account Creation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while creating Collection Account.')

        }
    };








    const handleSubmit = (e) => {
        e.preventDefault();

        if (false) {

        } else {
            createCollectionAccount(setLoading).then((data) => {

                setBid('');
                setIsDefault(false)
                setBranch('')
                setAcno('')
                setSwiftCode('')
                setRefresh(!refresh)
                setName('')
                twit('success', data)

            }).catch((error) => {
                twit('info', error)
            })
        }



    };

    const updateCollectionAccount = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const caid = selectedAccount?.id
        const postData = {
            bid,
            swiftCode,
            branch,
            acno,
            isDefault,
            caid,
            name
        };
        const Url = '/api/billing/accounts/collection/update'
        try {
            setLoading(true);
            const response = await axios.patch(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Account updated successfully.')


            }


        } catch (error) {
            setLoading(false);
            console.log("Collection account update Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating Collection account.')

        }
    };




    const handleUpdateSubmit = (e) => {
        e.preventDefault();



        updateCollectionAccount(setLoading).then((data) => {

            setBid('');
            setIsDefault(false)
            setBranch('')
            setAcno('')
            setSwiftCode('')
            setSelectedAccount()
            setRefresh(!refresh)
            setShowAccountUpdate(false)
            twit('success', data)

        }).catch((error) => {
            twit('info', error)
        })

    };

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedCollectionAccounts = collectionAcs?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }



    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>





                {showAccountUpdate && <Modal open={true} title={'Collection Account Management'} onCancel={() => {
                    setIsDefault(false)
                    setSelectedAccount()
                    setShowAccountUpdate(false)
                    setName('')
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        <Row>
                            <Col>

                                <Form onSubmit={handleUpdateSubmit}>

                                    <Card>
                                        <Card.Body>
                                            <Row>

                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="age category">

                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                            <div className='mx-2 FormLabels' >
                                                                <p style={{ margin: 0 }}>Bank
                                                                    <span style={{ color: "red" }}> *</span></p>
                                                            </div>

                                                            <Form.Select

                                                                name="banks"
                                                                onChange={(e) => {
                                                                    setBid(e.target.value)
                                                                }}
                                                                className='form-selector'>
                                                                <option className='input-box' key={1} value={''}>{initialBank ? initialBank : 'Select Bank'}</option>
                                                                {bankInfo?.map((data) => {
                                                                    return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                                                })}


                                                            </Form.Select>

                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>


                                                    <Form.Group className='my-2' controlId="collection-ac1">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Branch<span style={{ color: "red" }}> *</span></p>
                                                            </div>
                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter Branch name"
                                                                value={branch}
                                                                onChange={(e) => {

                                                                    setBranch(e.target.value);

                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>


                                                </Col>
                                                <Col className='my-1' md={12}>


                                                    <Form.Group className='my-2' controlId="collection-ac3">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Swift Code<span style={{ color: "red" }}> *</span></p>
                                                            </div>
                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter Swift Code"
                                                                value={swiftCode}
                                                                onChange={(e) => {

                                                                    setSwiftCode(e.target.value);

                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>


                                                </Col>
                                                <Col className='my-1' md={12}>


                                                    <Form.Group className='my-2' controlId="collection-ac1">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Account No.<span style={{ color: "red" }}> *</span></p>
                                                            </div>
                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter account number"
                                                                value={acno}
                                                                onChange={(e) => {

                                                                    setAcno(e.target.value);

                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>


                                                </Col>

                                                <Col className='my-1' md={12}>


                                                    <Form.Group className='my-2' controlId="acc-name">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Account Name<span style={{ color: "red" }}> *</span></p>
                                                            </div>
                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter Names On Account"
                                                                value={name}
                                                                onChange={(e) => {

                                                                    setName(e.target.value);

                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>


                                                </Col>


                                                <Col className='my-1' md={12}>
                                                    <Form.Check
                                                        disabled={!(collectionAcs?.length > 0)}
                                                        type="checkbox"
                                                        label={'Set As Default Account'}
                                                        name={`defAc`}
                                                        checked={isDefault}
                                                        onChange={() => {
                                                            setIsDefault(!isDefault)
                                                        }}
                                                        className="my-0 mx-1 input-box-events form-control-sm"
                                                    />

                                                </Col>




                                            </Row>
                                        </Card.Body>
                                    </Card>



                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                            <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Account
                                        </Button>
                                    </div>
                                </Form>
                            </Col>

                        </Row>


                    </div>


                </Modal>}
                <Row>
                    <Col>
                        <p className='mt-3' style={{ fontWeight: 'bold' }}>Collection Accounts Management</p>
                        <Form onSubmit={handleSubmit}>

                            <Card>
                                <Card.Body>
                                    <Row>

                                        <Col className='my-1' md={12}>
                                            <Form.Group className='my-2' controlId="age category">

                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                    <div className='mx-2 FormLabels' >
                                                        <p style={{ margin: 0 }}>Bank
                                                            <span style={{ color: "red" }}> *</span></p>
                                                    </div>

                                                    <Form.Select

                                                        name="banks"
                                                        onChange={(e) => {
                                                            setBid(e.target.value)
                                                        }}
                                                        className='form-selector'>
                                                        <option className='input-box' key={1} value={''}>{'Select A Bank'}</option>
                                                        {bankInfo?.map((data) => {
                                                            return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                                        })}


                                                    </Form.Select>

                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>


                                            <Form.Group className='my-2' controlId="collection-ac1">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Branch<span style={{ color: "red" }}> *</span></p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter Branch name"
                                                        value={branch}
                                                        onChange={(e) => {

                                                            setBranch(e.target.value);

                                                        }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>


                                        </Col>
                                        <Col className='my-1' md={12}>


                                            <Form.Group className='my-2' controlId="collection-ac3">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Swift Code<span style={{ color: "red" }}> *</span></p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter Swift Code"
                                                        value={swiftCode}
                                                        onChange={(e) => {

                                                            setSwiftCode(e.target.value);

                                                        }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>


                                        </Col>
                                        <Col className='my-1' md={12}>


                                            <Form.Group className='my-2' controlId="collection-ac1">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Account No.<span style={{ color: "red" }}> *</span></p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter Account Number"
                                                        value={acno}
                                                        onChange={(e) => {

                                                            setAcno(e.target.value);

                                                        }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>


                                        </Col>


                                        <Col className='my-1' md={12}>


                                            <Form.Group className='my-2' controlId="acc-name">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Account Name<span style={{ color: "red" }}> *</span></p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter Names On Account"
                                                        value={name}
                                                        onChange={(e) => {

                                                            setName(e.target.value);

                                                        }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>


                                        </Col>


                                        <Col className='my-1' md={12}>
                                            <Form.Check
                                                disabled={!(collectionAcs?.length > 0)}
                                                type="checkbox"
                                                label={'Set As Default Account'}
                                                name={`defAc`}
                                                checked={isDefault}
                                                onChange={() => {
                                                    setIsDefault(!isDefault)
                                                }}
                                                className="my-0 mx-1 input-box-events form-control-sm"
                                            />

                                        </Col>




                                    </Row>
                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Create Account
                                </Button>
                            </div>
                        </Form>
                    </Col>

                </Row>
                <Row>
                    <p style={{ fontWeight: 'bold' }}> Collection Accounts</p>
                    <div className='table-responsive'>
                        {collectionAcs?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Bank</th>
                                        <th>Branch</th>
                                        <th>Swift Code</th>
                                        <th>Default</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedCollectionAccounts?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.Bank.name}</td>
                                            <td>{data.branchName}</td>
                                            <td>{data.swiftCode}</td>
                                            <td>{(data.isDefault) ? <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} /> : <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} />}</td>
                                            <td>


                                                <GrIcon.GrUploadOption className='mx-1' title='Update Sponsor' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setAcno('')
                                                    setSelectedAccount(data)
                                                    setBid(data.bid)
                                                    setAcno(data.acno)
                                                    setBranch(data.branchName)
                                                    setSwiftCode(data.swiftCode)
                                                    setInitialBank(bankInfo.filter((bks) => {
                                                        return bks.id == data.bid
                                                    })[0]?.name)
                                                    setIsDefault(data.isDefault)
                                                    setName(data.holderName)
                                                    setShowAccountUpdate(true)

                                                }} />








                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No Accounts added.</p>

                        }
                        {collectionAcs?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={collectionAcs?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }

}

export default CollectionAccountSettings;


