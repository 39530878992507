import React, { useState } from 'react'
import { Container, ListGroup, Button, Image } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import styled from 'styled-components';
import { IconContext } from 'react-icons/lib';
import { Link, useNavigate } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import useNotifications from '../hooks/getNotifications';
import { Drawer } from 'antd';
import { useEffect } from 'react';
import io from 'socket.io-client';
import axios from '../api/axios';
import Chat from './Chat'
import useAuth from '../hooks/useAuth';
import usePost from '../hooks/hooks/postData';
import Loader from './Loader';
import Lpo from './PurchaseOrder';
import { Modal } from 'antd';
import Invoice from './Invoice';
import Cookies from 'js-cookie';
import html2pdf from 'html2pdf.js';
import LoggedinDropdown from './LogedinDropdown';
import useFetch from '../hooks/hooks/getData';
import NotificationDropdown from './NotificationDropdown';
import PaymentCard from './PhonePaymentCard';
import useCart from '../hooks/hooks/useCart';
import { twit } from './Notificationpopout';
import DeliveryInfo from './DeliveryInfo';
import { apiBaseUrl, cardTypeNames } from '../Utilities/globals';
import { getInvoiceNumber } from '../Utilities/utilities';
import dashLogo from './images/pinni.png'
import { fetchData } from '../Utilities/apiRequests';




const Nav = styled.div`
  background: #fff;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  width:100%;
  border-radius:10px;
  
  
  
 
  
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 15px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none
`;

const NavImage = styled.div`
  margin-left: 15px;
  font-size: 6px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const NavBar = ({ showSidebar,isopen,profile }) => {

  const [notificationNo, setNotificationNo] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [notificationsArray, setNotificationsArray] = useState([]);
  const [socket, setSocket] = useState(null);
  const [switcher, setSwitcher] = useState();
  const [tid, setTid] = useState();
  const [cartDrawer, setCartDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [cardItemsNo, setCardItemsNo] = useState(0);
  const [orderID, setOrderID] = useState(0);
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [printable, setPrintable] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [unitPrices, setUnitPrices] = useState();
  const [deliver, setDeliver] = useState();
  const [deliveryLocation, setDeliveryLocation] = useState();
  const [useRegisteredLocation, setUseRegisteredLocation] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [userData, setUserData] = useState();
  const [firstLetter, setFirstLetter] = useState('');
    

    

    
    

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  


//   useEffect(() => {
//     setLoadingProfile(true)
//     fetchData(`/api/auth/user/profile`, setLoading).then((response) => {
//         setLoadingProfile(false)
//         setUserData(response.data.data)
//         setFirstLetter(response.data.data.regname?.charAt(0).toUpperCase())
        
//     }).catch((error) => {
//         setLoadingProfile(false)
//         console.log('User profile loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading user profile')
//     })

// }, []);

useEffect(() => {
  
      
      setUserData(profile)
      setFirstLetter(profile?.regname?.charAt(0).toUpperCase())
      
  

}, []);






  //const identityProductSubscription = usePost("/api/products/identity/subscription", setLoading);

  const productsCart = useCart();
  const navigate = useNavigate()
  const token = sessionStorage.getItem('token');
  const { noti } = useAuth();
  //const { post } = usePost('/api/products/order/cards', setLoading);
  //const getCardInvoice = usePost('/api/products/card/pricing', setLoading);

  // const orderItems = cardCartItems?.map((value) => {
  //   return value.hid;
  // });

  // const cardOrderPayload = { items: orderItems, qty: orderItems?.length, totalFee: 2000 }

  const handleClick = async (tid) => {
    try {

      const response = await axios.patch(`/api/tickets/notification/${tid}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }



      });


      setSwitcher(!switcher);
      setDrawer()





    } catch (err) {
      console.log(err)
    }
  }

  const HandleDrawer = () => {
    setDrawer(!drawer)
  }


  const notifications = useNotifications()



  useEffect(() => {

    const newSocket = io(apiBaseUrl,{transports: ['websocket', 'polling']});
    setSocket(newSocket);

  }, []);

  

  if (socket) {


    socket.on('ticketNotification', (data) => {

      setSwitcher(!switcher)


    });
  }


  useEffect(() => {

    productsCart.getItems()

  }, []);

  


  const handleClose = () => {
    setTid();
  }


  useEffect(() => {
    notifications.then((data) => {

      setNotificationNo(data.length);
      setNotificationsArray(data);
    })
  }, [switcher, noti])



  //downloading invoice pdf
  const downloadPDF = () => {
    const element = document.getElementById('invoice');
    html2pdf(element, {
      output: '/invoice folder',
      filename: 'invoice.pdf',
    });

    setTimeout(function () {
      setPrintable(false);
    }, 2000);
  }





  // handling all subscriptions after payment

  const getCardsByType = (Items, cardType) => {
    return Items?.filter((cards) => {
      return cards.CardType?.ctype === cardType;
    });
  }

  const cardItems = productsCart?.items?.items
  const goldcards = getCardsByType(cardItems, "PinniBUD")?.length;
  const bronzecards = getCardsByType(cardItems, "PinniSEED")?.length;
  const diamondcards = getCardsByType(cardItems, "PinniBLOSSOM")?.length;
  const silvercards = getCardsByType(cardItems, "PinniSPROUT")?.length;
  const platinumcards = getCardsByType(cardItems, "PinniPOLLEN")?.length;

  
  const cards = [

    { name: `${cardTypeNames.platinum_Card} Cards`, qty: platinumcards, amount: (unitPrices?.PinniPOLLEN * platinumcards),unitPrice:unitPrices?.PinniPOLLEN},
    { name: `${cardTypeNames.diamond_Card} Cards`, qty: diamondcards, amount: (unitPrices?.PinniBLOSSOM* diamondcards),unitPrice:unitPrices?.PinniBLOSSOM },
    { name: `${cardTypeNames.gold_Card} Cards`, qty: goldcards, amount: (unitPrices?.PinniBUD * goldcards) ,unitPrice:unitPrices?.PinniBUD},
    { name: `${cardTypeNames.silver_Card} Cards`, qty: silvercards, amount: (unitPrices?.PinniSPROUT * silvercards),unitPrice:unitPrices?.PinniSPROUT },
    { name: `${cardTypeNames.bronze_Card} Cards`, qty: bronzecards, amount: (unitPrices?.PinniSEED * bronzecards) ,unitPrice:unitPrices?.PinniSEED}
  ]



  const handleSubscription = () => {

    if(deliver&&(!useRegisteredLocation)&&(!deliveryLocation)){
      twit('info','Please input the delivery location.')
    }else{
      productsCart.getUnitPrices(productsCart?.items?.orderId,deliveryLocation,deliver).then((prices) => {
        setUnitPrices(prices)
        setShowInvoice(true);
        setCartDrawer(false);
      }).catch((message) => {
        twit('info', message)
      })
    }

  }


  const calculateDeliveryPrice = () => {
    if(deliver&&deliveryLocation){
      return 5000
    }else if(deliver&&!deliveryLocation){
      return 5000
    }else{
      return 0
    }
    
  };

  const getCharges = ()=>{
    const handlingFees = 5000
    return handlingFees+calculateDeliveryPrice()
  }


  const proceedTopay = () => {
    productsCart.checkout(setLoading,getCharges()).then((url) => {
      twit('info',"Payment Initiated redirecting...")
      window.location.href = url

    }).catch((message) => {
      twit('info', message)
    })

  }

 



  return (
    <Container style={{ fontSize: "12px" }}>

      {tid && (<Chat tid={tid} handleClose={handleClose} />)}


      {showInvoice && <Modal open={true} onCancel={() => {
        setShowInvoice();
        setDeliver(false)
        setUseRegisteredLocation()
      }}
        footer={[


        ]}


        closable={false}
        className='allmodals'
      >



        <div id='invoice' >
          <Invoice items={cards} invoiceNumber={3}
            printable={printable}
            deliveryCost={calculateDeliveryPrice()}

          />
        </div>


        <div>
          <Button

            title='Download a copy.'
            onClick={() => {
              setPrintable(true);
              downloadPDF();


            }}

            className='identity-form-button ' variant="success" type="button">

            <span style={{ fontWeight: "bold" }}>Download </span>
          </Button>

          <Button

            title='Pay for the items'
            onClick={() => {

              proceedTopay();
              setShowInvoice();



            }}

            className='identity-form-button ' variant="success" type="button">

            <span style={{ fontWeight: "bold" }}>Proceed to pay </span>
          </Button>


        </div>

        





      </Modal>}
      {showPaymentCard && <Modal open={true} onCancel={() => {
        setShowPaymentCard();
      }}
        footer={[


        ]}
        style={{ maxWidth: "400px" }}
        closable={false}
        className='allmodals'

      >

        <PaymentCard orderId={orderID} close={setShowPaymentCard} />












      </Modal>}

      {drawer && (

        <Drawer title="Notifications" placement="right" onClose={HandleDrawer} open={true}>

          <ListGroup variant='flush'>
            {notificationsArray?.map((data, index) => (
              <ListGroup.Item key={index}>

                <a onClick={() => {
                  setTid(data.tid)
                  handleClick(data.tid)
                }} href='#'>{data.msg}</a>
              </ListGroup.Item>
            )

            )}
          </ListGroup>
        </Drawer>
      )}

      {cartDrawer && (
        <Drawer title={<p style={{ margin: "0px" }}><BsIcons.BsCart4 style={{ height: "20px", width: "20px" }} />     Your Cart</p>} placement="right" closable={false} onClose={() => {
          setCartDrawer(false);
        }} open={true}

          extra={
            <AiIcons.AiOutlineClose title='Close cart' onClick={() => {
              setCartDrawer(false);

            }} />

          }



        >
          {loading && (<Loader />)}
          {!loading && (<div>

            <ListGroup variant='flush'>

              {productsCart?.items?.items?.length > 0 && (
                <div>
                  {productsCart?.items?.items?.map((data, index) => (
                    <ListGroup.Item key={index} >
                      <div className='cart-items' >
                        <div>

                          {data?.icon}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                          <p style={{ margin: "0px", fontSize: "12px" }} >

                            Name: <span style={{ color: "green", fontWeight: "bold" }}>{data?.fhname + " " + data?.lhname}</span>

                          </p>
                          <p style={{ margin: "0px", fontSize: "12px" }}>
                            CARD TYPE: <span style={{ color: "green", fontWeight: "bold" }} >{data?.CardType?.ctype}</span>
                          </p>

                        </div>
                        <div>

                          <AiIcons.AiOutlineClose title='remove item' onClick={() => {
                            productsCart.removeItem([data.hid], setLoading).then((message) => {
                              productsCart.getItems()
                              twit("success", message)
                              setDeliver(false)
                              setUseRegisteredLocation()
                            }).catch((message) => {
                              twit("info", message)
                              setDeliver(false)
                              setUseRegisteredLocation()
                            })

                          }} />
                        </div>
                      </div>


                    </ListGroup.Item>
                  ))}
                  {/* <div>
                    <Lpo tax={0} discount={0} subtotal={cardCartItems?.length * 2000} />
                  </div> */}
                </div>


              )}

            </ListGroup>
            {(productsCart?.items?.items?.length > 0) && (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>

                <DeliveryInfo setDeliveryLocation={setDeliveryLocation} setUseRegisteredLocation={setUseRegisteredLocation} setDeliver={setDeliver}/>
                <Button

                  title='Place order for items in cart'
                  onClick={() => {
                    // navigate("/identitydashProducts");


                    // setCartDrawer(false);
                    // setShowInvoice(true);
                    if (productsCart?.items?.items?.length > 0) {
                      handleSubscription();
                    }

                  }}



                  className='identity-form-button ' variant="success" type="button">

                  <span style={{ fontWeight: "bold" }}> Checkout </span>
                </Button>
              </div>


            )}
            {(!productsCart?.items?.items?.length > 0) && (
              <p className='text-muted text-sm' style={{ fontSize: "10px" }}>No items</p>
            )}

          </div>)}

        </Drawer>
      )}





      <IconContext.Provider value={{ color: '#15171c' }}>
        <Nav>


          <NavIcon  to='#'>

          {/* <FaIcons.FaBars title="Expand or Minimize sidebar" onClick={showSidebar} /> */}
           
            
               <FaIcons.FaBars title="Expand or Minimize sidebar" onClick={showSidebar} />
           

            {!isopen && (
              <NavImage title='Expand or Minimize sidebar' onClick={showSidebar}>
              <img src={dashLogo} alt="sidebar image" style={{ width:screenWidth <= 700?'100px':'15%' }} />
            </NavImage>
              )}
            
          </NavIcon>
          
          <div className='nav-icons' >

            <div style={{ position: 'relative', display: 'inline-block' }}>
              {productsCart?.items?.items?.length > 0 && (
                <Badge
                  style={{ position: 'absolute', top: '-10px', right: '-10px', textDecoration: "none", outline: "none" }}
                  bg="danger"
                >
                  {productsCart?.items?.items?.length}
                </Badge>
              )}

              <FaIcons.FaShoppingCart
                title="Your Cart"
                style={{ height: "14px", width: "14px" }}
                onClick={() => {
                  setCartDrawer(!cartDrawer);
                }}
              />
            </div>

            <div style={{paddingTop:'4px'}}>
              <IoIcons.IoIosSettings title="Parameters & Settings" style={{ height: "15px", width: "15px", margin: "10px" }}

                onClick={() => {
                  navigate('/settings');
                }}


              />
            </div>

            



            <div style={{ position: 'relative', display: 'inline-block' }}>
              <NotificationDropdown
                handleClick={handleClick}
                setTid={setTid}
                notificationsArray={notificationsArray}

              >
                <IoIcons.IoIosNotifications
                  title="Notifications"
                  style={{ height: "15px", width: "15px" }}
                  onClick={() => { }}
                />
              </NotificationDropdown>

              {notificationNo > 0 && (
                <Badge
                  style={{ position: 'absolute', top: '-10px', right: '-10px', textDecoration: "none", outline: "none" }}
                  bg="danger"
                >
                  {notificationNo}
                </Badge>
              )}

            </div>




            <LoggedinDropdown userData={userData} firstLetter={firstLetter} loadingProfile={loadingProfile}>

              {userData?.src ? (
                <Image src="user-image.jpg" roundedCircle className="user-image mx-2" />
              ) : (
                <div className="user-image mx-2">{firstLetter ? firstLetter : "L"}</div>

              )}


            </LoggedinDropdown>



          </div>
        </Nav>
      </IconContext.Provider>
    </Container>
  )
}

export default NavBar
