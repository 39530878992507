import React from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import Loader from '../Loader';
import { useState } from 'react';
import useFetch from '../../hooks/hooks/getData';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { twit } from '../Notificationpopout';
import Clipboard from 'react-clipboard.js';
import axios from '../../api/axios';

const UserBranches = () => {


    const [loading, setLoading] = useState(false);
    const [rowdata, setRowdata] = useState(false);
    const [managerInput, setManagerInput] = useState(false);
    const [managerID, setManagerID] = useState('');


    const userbranches = useFetch("/api/assetmanagement/branch", setLoading);
    const navigate = useNavigate();

    const assignBranchManager = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            hid: managerID,
            bid: rowdata.bid,
            
        };
        const Url = '/api/assetmanagement/assignmanager'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Manager assigned successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log("Manager assignment Error:", err)
            return Promise.reject('An error occured while assigning manager.')

        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        

        if (!managerID) {
            twit('info', `Please fill out all required fields`);

        } else {

            assignBranchManager(setLoading).then((data) => {
                setManagerID('')
                setManagerInput(false)
                setRowdata(false)
                twit('success', data)
            }).catch((error) => {
                twit('info', error)
            })

        }



    };


    if (loading) {
        return (<Loader />);
    } else {
        return (
            <div className='table-responsive'>


                {rowdata && <Modal open={true} title={rowdata.topic} onCancel={() => { setRowdata(false) }}
                    footer={[


                    ]}

                    className="allmodals"
                >







                    <div>





                        <div style={{ textAlign: 'center' }}>
                            <p className='text-muted fs-3 fw-bold' style={{ fontFamily: 'Georgia, serif' }}>{rowdata.bname} BRANCH</p>
                        </div>

                        <div style={{ textAlign: 'center' }}>
                            <p className='text-muted fs-10 fw-bold' style={{ fontFamily: 'Georgia, serif' }}> MANAGER: {rowdata.HumanBranchRoles[0]? (`${rowdata.HumanBranchRoles[0]?.HumanIdentification?.fhname} ${rowdata.HumanBranchRoles[0]?.HumanIdentification?.lhname}`):'Not assigned'}  </p>
                        </div>

                        <div style={{ textAlign: 'center' }}>
                        <Clipboard option-text={()=>(`http://192.168.100.32:3000/assetBranch/${rowdata.bid}`)} onSuccess={()=>{twit('success','URL copied.')}}>
                            Copy branch url
                        </Clipboard>

                        </div>

                        
                        { managerInput&& (
                             <Container>
                             <Row>
                                 <Col>
                                     
                                     <Form onSubmit={handleSubmit}>
                                         <Form.Group controlId="manager id">
                                             <Form.Label>Manager ID</Form.Label>
                                             <Form.Control
                                                 className=' input-box-identity form-control-sm'
                                                 type="text"
                                                 placeholder="Enter ID"
                                                 value={managerID}
                                                 onChange={(e) => setManagerID(e.target.value)}
                                                 
                                             />
                                         </Form.Group>
             
                                         
             
                                         
                                         <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                             <Button style={{ backgroundColor: "#569f51" }} variant="primary" type="primary"   htmlType='submit'>
                                                 Submit
                                             </Button>
                                         </div>
                                     </Form>
                                 </Col>
                             </Row>
                         </Container>
                        )}

                        
                        {!managerInput&& (
                            <div style={{ textAlign: 'center' }}>

                            <Button onClick={() => { setManagerInput(true) }} title='Assign branch manager' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                                Assign Manager
                            </Button>

                        </div>
                        )}

                        


                    </div>


                </Modal>}
                <Table striped hover style={{ cursor: "pointer" }}>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Mobile No.</th>
                            <th>Email</th>
                            <th>Location</th>


                        </tr>
                    </thead>
                    <tbody>
                        {userbranches?.data?.branches.map((data, index) => (
                            <tr key={index} onClick={() => { setRowdata(data) }}>
                                <td>{index + 1}</td>
                                <td>{data.bname}</td>
                                <td>{data.phoneNumber}</td>
                                <td>{data.email}</td>
                                <td>{data.location}</td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
};

export default UserBranches;
