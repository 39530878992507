import React, { useState } from 'react';
import { getformattedDate2 } from '../Utilities/utilities';
import pinnitags from './images/plogotransparent.png'
import visualPlacer from './images/imagePlaceholder.png'

const EventPass = ({ eventDetails,passZones, qrcode, tagName }) => {

  const [imageLoaded, setImageLoaded] = useState(true);
  const pcolor = eventDetails?.eventPassCategory?.pcolor
  
  const textColors = [{color:'#d8e309',tcolor:'black'},{color:'#1a7d04',tcolor:'white'},{color:'#b50505',tcolor:'white'},{color:'Black',tcolor:'white'},{color:'#036780',tcolor:'white'},{color:'black',tcolor:'white'}]

  const getTextColor = ()=>{
    const scolor = textColors.filter((data)=>{
          return data.color == pcolor
    })

    return scolor[0]?.tcolor
  }


  const styles = {
    passContainer: {
      margin: '0 auto',
      marginTop: '20px',
      border: `3px solid ${pcolor?pcolor:'black'}`,
      marginBottom: '20px',
      borderRadius: '10px',
      padding: '0',
     
    },
    passHeader: {
      width: '100%',
      backgroundColor: pcolor?pcolor:'black',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      paddingTop: '10px',
      paddingBottom: '10px',
      color:getTextColor()?getTextColor():'black'
    },
  
    headerLogo:{
      width: '40%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    headerInfo:{
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      textAlign:'start'
    },

    passBody: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    passDetails: {
      width: '95%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      marginTop: '10px',
      marginBottom: '10px',
    },
    detailsLogo: {
      width: '30%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    detailsInfo: {
      width: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    passZones: {
      width: '95%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '10px',
    },
    zoneBox: {
      width: '100px',
      height: '100px',
      backgroundColor: 'black',
      color: 'white',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px',
    },
    zoneDescription: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    zoneDesc: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      marginBottom: '10px',
    },
    zoneDesc1: {
      width: '50%',
      borderRight: '1px solid gray',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding:'10px'
    },
    zoneDesc2: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding:'10px'
    },
    zoneDescTitle: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    passFooter: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    footerTag: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    footerLogo: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '10px',
      marginBottom: '10px',
    },
  };

  return (
    <div className='pass-template-container' style={styles.passContainer}>
      <div style={styles.passHeader}>
        <div style={styles.headerLogo}>{eventDetails?.event?.evLogo?<img style={{ width: '30%' }} src={eventDetails?.event?.evLogo} alt="Powered By Logo" />:''}</div>
        <div style={styles.headerInfo}>
          <h3>{eventDetails?.event?.name}</h3>
          <p style={{margin:'2px',fontWeight:'bold'}}>{eventDetails?.event?.eventdescription}</p>
          <p style={{margin:'2px',fontWeight:'bold'}}>
            {getformattedDate2(eventDetails?.event.eventDate)} - {getformattedDate2(eventDetails?.event.endtime)}
          </p>
          <p style={{margin:'2px',fontWeight:'bold'}}>
            {eventDetails?.event?.venue}, {eventDetails?.event?.location}
          </p>
        </div>
      </div>
      <div style={styles.passBody}>
        <div style={styles.passDetails}>
          <div style={styles.detailsLogo}>
            <img
              style={{ width: '100%' }}
              src={imageLoaded ? `${eventDetails.face}` : visualPlacer}
              alt="Event Logo"
              onError={() => {
                setImageLoaded(false)
            }}
            />
          </div>
          <div style={styles.detailsInfo}>
            <h4>{eventDetails?.name}</h4>
            <h5 style={{ color: pcolor?pcolor:'black' }}>{eventDetails?.eventPassCategory?.name}</h5>
            <h5>ADMITS:&nbsp;{eventDetails?.admits}</h5>
          </div>
        </div>
        <div style={styles.passZones} id="passZones">
          {/* Render pass zones dynamically */}
          {passZones.map((zone, index) => (
            <div key={index} style={styles.zoneBox}>
              <p style={{margin:'2px'}}>
                <strong style={{textTransform:'uppercase'}}>{zone.name}</strong>
              </p>
            </div>
          ))}
        </div>
        <div style={styles.zoneDescription}>
          <div style={styles.zoneDescTitle}>
            <p style={{fontSize:'14px', fontWeight:'bold'}}>ACCREDITATION ZONES</p>
          </div>
          <div style={styles.zoneDesc}>
            <div style={styles.zoneDesc1} id="zoneDesc1">
              {/* Render zone descriptions dynamically for column 1 */}
              {passZones
                .filter((_, index) => index % 2 === 0)
                .map((zone, index) => (
                  <p style={{margin:'2px'}} key={index}>
                    <span style={{fontSize:'12px', fontWeight:'bold',textTransform:'uppercase'}}>{zone?.name}</span> - {zone?.desc}
                  </p>
                ))}
            </div>
            <div style={styles.zoneDesc2} id="zoneDesc2">
              {/* Render zone descriptions dynamically for column 2 */}
              {passZones
                .filter((_, index) => index % 2 !== 0)
                .map((zone, index) => (
                  <p style={{margin:'2px'}} key={index}>
                    <span style={{fontSize:'12px', fontWeight:'bold',textTransform:'uppercase'}}>{zone?.name}</span> - {zone?.desc}
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div style={styles.passFooter}>
        <div style={styles.footerTag}>
          <img style={{width:'40%'}} src={qrcode} alt="QR Code" />
          <p style={{fontSize:'14px', fontWeight:'bold'}}>{tagName}</p>
        </div>
        <div style={styles.footerLogo}>
          <p style={{ margin: '0%', fontWeight: 'bold' }}>Powered By</p>
          <img style={{ width: '20%' }} src={pinnitags} alt="Powered By Logo" />
        </div>
      </div>
    </div>
  );
};

export default EventPass;
