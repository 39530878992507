import { useEffect } from 'react';
import axios from '../api/axios';


const token = sessionStorage.getItem('token');
const useMarital = async() => {


    const  maritalUrl = '/api/marital-status'

    


        try {
          const response = await axios.get(maritalUrl,{
            headers: {  Authorization: `Bearer ${token}`
        }

        });
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useMarital;