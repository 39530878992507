import React from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card1 from './Dashboard-cards/Card1';
import Card2 from './Dashboard-cards/Card2';
import Card3 from './Dashboard-cards/Card3';
import Card4 from './Dashboard-cards/Card4';


const Dashboard = () => {
  const navigate = useNavigate()
  const logout = () => {
    sessionStorage.removeItem('token');
    navigate("/");

  }
  return (
    <Container className='dashboard-container  justify-content-center'>
      {/* <Button variant='danger' onClick={logout}>LogOut</Button> */}
      <Row className="my-4">
        <Col>
          <Card1 />
        </Col>
        <Col>
          <Card2 />
        </Col>
        <Col>
          <Card2 />
        </Col>
        <Col>
          <Card2 />
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Card3 />
        </Col>
        <Col>
          <Card4 />
        </Col>
        <Col>
          <Card4 />
        </Col>
        <Col>
          <Card4 />
        </Col>
      </Row>
    </Container>
  );
}



export default Dashboard;
