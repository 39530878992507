import React from 'react'
import { Container, Card, Col } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';

const PassCategory = () => {


    const navigate = useNavigate();



    const categories = [
        {
            title: 'Event Pass',
            icon: <CgIcons.CgEventbrite title='Event passes' style={{ height: "30px", width: "30px" }} />,
            link: '/pass/event'
        }
    ];
    




    return (
        <div>
            <Col>
                <div className='card-operations-row'>

                    {categories.map((category, index) => (
                        <Card key={index} className='document-card'
                            onClick={() => {
                                navigate(category.link)
                            }}

                        >

                            <Card.Body style={{ textAlign: "center" }}>
                                <div>
                                    {category.icon}
                                </div>

                                <Card.Text>

                                    {category.title}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}

                </div>

            </Col>
        </div>
    )
}

export default PassCategory;
