import React, { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import logo from './images/pinnitagsTG.png'
import pinnisoftLogo from './images/pinnisoft.jpg';
import { splitArrayIntoChunks } from '../Utilities/utilities';
import * as GiIcons from 'react-icons/gi';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';
import NeldLogo from './images/NELDLOGO2.png';
import Bwongo from './images/bwongo.png';
import SRHR from './images/SRHRG.png';
import useFetch from '../hooks/hooks/getData';
import Loader from './Loader';
import cinat from  './images/cinatBNW.png'
import marintime from  './images/marintimeBNW.png'
import richPromo from  './images/richPromoBNW.png'
import newpinnitagsLogo from './images/pinnitagsBNW2.png'
import bulemeezi from './images/BulemeeziBNW.png'
import buganda from './images/bugandaBNW.png'
import mikka from './images/MIKKA LOGO.png'

const LabelPage = ({ labelData, labelType, clientData, tagNumber, labelFormat }) => {

  const [loading, setLoading] = useState(false)




//original
  const generateTag = (data) => (
    <div className="label-code">

      {/* <img src={data.tagImage} alt="Logo" className={[4, 6].includes(labelType) ? 'label-Image-aztec-datamatrix ' : "label-Image"} /> */}
      <img src={data.tagImage} style={{marginTop:'10%'}} alt="Logo" className={[4, 6].includes(labelType) ? 'label-Image-aztec-datamatrix ' : "label-Image"} />
      {labelType != 0 && (
        <p style={{ fontWeight: 'bold', margin:0 }} className='my-0'>{data.name}</p>
        // <p className='my-0'>{data.name}</p>
      )}
      {/* <img src={logo} alt="Logo" style={{marginLeft:'85%', marginTop:'5%'}} className="label-logo" /> */}
      <p  style={{margin:'0px', marginTop:'1%', fontSize:'8px'}}  >Powered by</p>
      <p  style={{margin:'0px', marginTop:'1%', fontWeight:'bold'}}  >PinniTAGS</p>
      {/* <img src={logo} alt="Logo" className="label-logo" /> */}
      <div className={[1, 4, 6].includes(labelType) ? 'label-info-QR' : 'label-info'}>
        {/* <p style={{ margin: '0px', fontSize: '8px' }}>{clientData?.Registration?.regname}</p> */}
        {/* <p style={{ margin: '0px', fontSize: '8px', width: '55px' }}>{tagNumber}</p> */}
        {/* <p style={{ margin: '0px', fontSize: '12px', width:'100px', fontWeight:'bold' }}>{'ALL ACCESS PASS'}</p>
        <p style={{ margin: '0px', fontSize: '9px', width: '100px' }}>{'ORGANIZER'}</p> */}
      </div>
      {/* <img src={logo} alt="Logo" className="label-logo mt-2" /> */}
    </div>
  );

  //original
  
  //for pass

  
  // const generateTag = (data) => (
  //   <div className="label-code">

  //     <img src={data.tagImage} alt="Logo" className={[4, 6].includes(labelType) ? 'label-Image-aztec-datamatrix ' : "label-Image"} />
  //     {labelType != 3 && (
  //       <p style={{ fontWeight: 'bold' }} className='my-0'>{data.name}</p>
  //       // <p className='my-0'>{data.name}</p>
  //     )}
  //     <img src={logo} alt="Logo" className="label-logo" />
  //     <div className={[1, 4, 6].includes(labelType) ? 'label-info-QR' : 'label-info'}>
  //       <p style={{ margin: '0px', fontSize: '8px' }}>{data.category}</p>
  //       <p style={{ margin: '0px', fontSize: '8px', width: '55px' }}>{tagNumber}</p>
  //       {/* <p style={{ margin: '0px', fontSize: '12px', width:'100px', fontWeight:'bold' }}>{'ALL ACCESS PASS'}</p>
  //       <p style={{ margin: '0px', fontSize: '9px', width: '100px' }}>{'ORGANIZER'}</p> */}
  //     </div>
  //     {/* <img src={logo} alt="Logo" className="label-logo mt-2" /> */}
  //   </div>
  // );

  //for pass

//srhr
  const generateSrhrSampleTag = (data) => (
    <div className="Custom-label-code" >

      <div className='my-0 mx-0'  style={{width:"70px",display: 'flex', flexDirection: 'column', alignItems: 'center',padding:'0px'}}>
        <img src={data.tagImage} alt="Logo" className='label-Image-aztec-datamatrix my-0 ' style={{width:'100%'}} />
        <p style={{ fontWeight: 'bold' ,fontSize:'10px'}} className='my-0'>{data.name}</p>
        <img src={logo} alt="Logo" className="label-logo" style={{margin:'0px',width:'100%'}} />
      </div>
      <div className='label-info-brother-strip ' >
        <div className='label-info-brother-strip-logo' style={{paddingTop:'6px',justifyContent:'space-around'}}>
          <div style={{width:'30%'}}>
            <img src={SRHR} alt="Logo" className="label-logo-brother " style={{width:'100%'}} />
          </div>
          <div  style={{width:'40%'}}>
          <img src={Bwongo} alt="Logo" className="label-logo-brother " style={{width:'100%',margin:'0'}} />
          </div>
        </div>

        <div style={{textAlign:'center',width:'300px'}} className=''>
        <p style={{ margin: '0px', fontSize: '10px' }}>SRHR ALLIANCE RETREAT </p>
        <p style={{ margin: '0px', fontSize: '9px' }}>2<sup class="date-ordinal">nd</sup>- 4<sup class="date-ordinal">th</sup> NOVEMBER 2023 | MIIKA ECO RESORT HOIMA</p>
        </div>

      </div>
    </div>
  );

  //neld

  const generateNELDSampleTag = (data) => (
    <div className="Custom-label-code" >

      <div className='my-0 mx-0'  style={{width:"70px",display: 'flex', flexDirection: 'column', alignItems: 'center',padding:'0px'}}>
        <img src={data.tagImage} alt="Logo" className='label-Image-aztec-datamatrix my-0 ' style={{width:'100%'}} />
        <p style={{ fontWeight: 'bold' ,fontSize:'10px'}} className='my-0'>{data.name}</p>
        <img src={logo} alt="Logo" className="label-logo" style={{margin:'0px',width:'100%'}} />
      </div>
      <div className='label-info-brother-strip ' >
        <div className='label-info-brother-strip-logo' style={{paddingTop:'8px'}}>
          <div>
            <p style={{ margin: '0px', fontSize: '10px', width: '100px' }}>2<sup class="date-ordinal">nd</sup> NOVEMBER 2023</p>
            <p style={{ margin: '0px', fontSize: '10px', width: '100px' }}>0800HRS - 1600HRS</p>
          </div>
          <div>
            <img src={NeldLogo} alt="Logo" className="label-logo-brother " style={{width:'80%'}} />
          </div>
          <div>
            <p style={{ margin: '0px', fontSize: '10px', width: '100px' }}>SHERATON HOTEL KAMPALA</p>
          </div>
        </div>

        <div style={{textAlign:'center',width:'300px'}} className=''>
        <p style={{ margin: '0px', fontSize: '11px' }}>Unlocking Export Logistics Financing & Subsidization.</p>
        </div>

      </div>
    </div>
  );


  //bulemeezi

  const generateBulemeeziSampleTag = (data) => (
    <div className="Custom-label-code" >

      <div className='my-0 mx-0'  style={{width:"70px",display: 'flex', flexDirection: 'column', alignItems: 'center',padding:'0px'}}>
        <img src={data.tagImage} alt="Logo" className='label-Image-aztec-datamatrix my-0 ' style={{width:'100%'}} />
        <p style={{ fontWeight: 'bold' ,fontSize:'10px'}} className='my-1'>{data.name}</p>
        <img src={newpinnitagsLogo} alt="Logo" className="label-logo" style={{margin:'0px',width:'100%'}} />
      </div>
      <div className='label-info-brother-strip ' >
        <div className='label-info-brother-strip-logo' style={{paddingTop:'0px'}}>
          <div>
            <p style={{ margin: '0px', fontSize: '10px', width: '100px' }}>11<sup class="date-ordinal">th</sup> NOVEMBER 2023</p>
           
          </div>
          <div>
            <img src={bulemeezi} alt="Logo" className="label-logo-brother " style={{width:'50%'}} />
          </div>
          <div>
            <p style={{ margin: '0px', fontSize: '10px', width: '100px' }}>LUWEERO KASANA MUKISAAWE</p>
          </div>
        </div>

        <div style={{textAlign:'center',width:'300px'}} className=''>
        <p style={{ margin: '0px', fontSize: '10px' }}>ENTUJJO Y'OBWA SSALONGO BWA BANNA BULEMEEZI 2023</p>
        </div>

        <div className='label-info-brother-strip-logo' style={{paddingTop:'0px',marginLeft:'14%'}}>
          <div>
          <img src={marintime} alt="Logo" className="label-logo-brother " style={{width:'40%'}} />
          </div>
          <div>
            <img src={richPromo} alt="Logo" className="label-logo-brother " style={{width:'40%'}} />
          </div>
          <div>
          <img src={cinat} alt="Logo" className="label-logo-brother " style={{width:'40%'}} />
          </div>
        </div>

      </div>
      <div className='my-0 mx-0'  style={{width:"70px",display: 'flex', flexDirection: 'column', alignItems: 'center',padding:'0px',marginLeft:'14%',paddingTop:'10px'}}>
      <p style={{ fontWeight: 'bold' ,fontSize:'10px'}} className='my-0'>MASAZA CUP</p> 
      <img src={buganda} alt="Logo" className='label-Image-aztec-datamatrix my-0 ' style={{width:'70%'}} />   
      </div>
    </div>
  );

  //MIKKA

  const generateMIKKASampleTag = (data) => (
    <div className="Custom-label-code" >

      <div className='my-0 mx-0'  style={{width:"70px",display: 'flex', flexDirection: 'column', alignItems: 'center',padding:'0px'}}>
        <img src={data.tagImage} alt="Logo" className='label-Image-aztec-datamatrix my-0 ' style={{width:'100%'}} />
        <p style={{ fontWeight: 'bold' ,fontSize:'10px'}} className='my-0'>{data.name}</p>
        <img src={logo} alt="Logo" className="label-logo" style={{margin:'0px',width:'100%'}} />
      </div>
      <div className='label-info-brother-strip ' >
        <div className='label-info-brother-strip-logo' style={{paddingTop:'8px'}}>
          
          <div>
            <img src={mikka} alt="Logo" className="label-logo-brother " style={{width:'55%'}} />
          </div>
          
        </div>

        

      </div>
    </div>
  );



  if (loading) {
    return (<Loader />);
  } else {
    return (

      <>
      {labelFormat&&(
        <div className={ "taglabel-container-NELD "} >
        <div style={{  margin: '0px', minHeight: '94px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <div style={{  margin: '0px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',padding:0 }} >

            <Row className='mx-0 my-0' style={{  width: '100%', padding:'0px' }} >

              <Col style={{  width: '100%',padding:'0px' }}  >
                {labelFormat ? generateBulemeeziSampleTag(labelData) : generateTag(labelData)}
              </Col>

            </Row>
          </div>


        </div>


      </div>
      )}
      {!labelFormat&&(
        <div className={[1, 4, 6].includes(labelType) ? 'taglabel-container-qrcode' : "taglabel-container "} >
        <div style={{ margin: '0px', minHeight: '94px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <div style={{ margin: '0px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

            <Row className='my-0' style={{ width: '100%', paddingBottom: '0px', paddingTop: '0px' }} >

              <Col style={{ width: '100%' }}  >
                {labelFormat ? generateMIKKASampleTag(labelData) : generateTag(labelData)}
              </Col>

            </Row>
          </div>


        </div>


      </div>
      )}
      
      </>
      
    );
  }
};

export default LabelPage;
