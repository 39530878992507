import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { twit } from '../Notificationpopout';
import Loader from '../Loader';
import useFetch from '../../hooks/hooks/getData';
import axios from '../../api/axios';
import { Button } from 'antd';

const EventForm = () => {
    const initialFormData = {
        name: '',
        eventdescription: '',
        eventDate: '',
        eventCategory: '',
        otherEventCategory: '',
        bannerURL: '',
        contactInfo: '',
        eventURL: '',
        venue: '',
    };


    const [formData, setFormData] = useState(initialFormData);
    const [loading, setLoading] = useState(false);
    const [bannerImage, setBannerImage] = useState(false);


    const cardCategories = useFetch("/api/events/categories", setLoading);


    const bannerformData = new FormData()
    bannerformData.append("image", bannerImage);


    const uploadBanner = async (eid) => {
        const token = sessionStorage.getItem('token');

        try {



            const response = await axios.post(`/api/events/banner/${eid}`, bannerformData, {
                headers: {
                    'content-type': 'multipart/form-data'
                    , Authorization: `Bearer ${token}`
                }

            });
            return Promise.resolve(response);
        } catch (error) {

            return Promise.reject(error);
        }



    }

    const createEvent = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name: formData.name,
            eventdescription: formData.eventdescription,
            eventDate: formData.eventDate,
            eventCategory: formData.eventCategory,
            otherEventCategory: formData.otherEventCategory,
            bannerURL: formData.bannerURL,
            contactInfo: formData.contactInfo,
            eventURL: formData.eventURL,
            venue: formData.venue,
        };
        const createEventUrl = '/api/events/create'
        try {
            setLoading(true);
            const response = await axios.post(createEventUrl, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                const bannerUpload = await uploadBanner(response.data.createdEvent.eid)

                if (bannerUpload.status === 201) {
                    setLoading(false);
                    return Promise.resolve('Event created successfully.')
                }

            }


        } catch (err) {
            setLoading(false);
            console.log("Event creation Error:", err)
            return Promise.reject('An error occured while creating the event.')

        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        const requiredFields = ['name', 'eventdescription', 'eventDate', 'eventCategory'];
        const missingFields = requiredFields.filter((field) => !formData[field]);




        if (missingFields.length > 0 || !bannerImage) {
            twit('info', `Please fill out all required fields: ${missingFields.join(', ')}`);

        } else {

            createEvent(setLoading).then((data) => {
                resetForm();
                twit('success', data)
            }).catch((error) => {
                twit('info', error)
            })

        }



    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetForm = () => {
        setFormData(initialFormData);
    };


    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>




                            <Form.Group controlId="name">
                                <Form.Label>Event Name <span style={{ color: "red" }}> *</span></Form.Label>
                                <Form.Control
                                    className=' input-box-identity form-control-sm'
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            


                            <Form.Group controlId="eventDate">
                                <Form.Label>Event Date <span style={{ color: "red" }}> *</span></Form.Label>
                                <Form.Control
                                    className=' input-box-identity form-control-sm'
                                    type="datetime-local"
                                    name="eventDate"
                                    value={formData.eventDate}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="venue">
                                <Form.Label>Venue <span style={{ color: "red" }}> *</span></Form.Label>
                                <Form.Control
                                    className=' input-box-identity form-control-sm'
                                    type="text"
                                    name="venue"
                                    value={formData.venue}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="contactInfo">
                                <Form.Label>Contact Information <span style={{ color: "red" }}> *</span></Form.Label>
                                <Form.Control
                                    className=' input-box-identity form-control-sm'
                                    type="text"
                                    name="contactInfo"
                                    value={formData.contactInfo}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>

                            <Form.Group controlId="eventdescription">
                                <Form.Label>Event Description <span style={{ color: "red" }}> *</span></Form.Label>
                                <Form.Control
                                    className=' input-box-identity form-control-sm'
                                    as="textarea"
                                    name="eventdescription"
                                    value={formData.eventdescription}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>


                            <Form.Group controlId="eventCategory">
                                <Form.Label  >
                                    Event Category
                                    <span style={{ color: "red" }}> *</span>
                                </Form.Label>

                                <Form.Select

                                    name="eventCategory"
                                    onChange={handleInputChange}
                                    className='form-selector'>

                                    {cardCategories?.data?.eventCategories.map((data) => {
                                        return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                    })}


                                </Form.Select>

                            </Form.Group>
                            {formData.eventCategory == '5E67834C-2D69-4562-809A-83C3E2BBD064' && (
                                <Form.Group controlId="otherEventCategory">
                                    <Form.Label>Other Event Category</Form.Label>
                                    <Form.Control
                                        className=' input-box-identity form-control-sm'
                                        type="text"
                                        name="otherEventCategory"
                                        value={formData.otherEventCategory}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            )}


                            {/* <Form.Group controlId="bannerURL">
                                <Form.Label>Banner URL</Form.Label>
                                <Form.Control
                                    className=' input-box-identity form-control-sm'
                                    type="text"
                                    name="bannerURL"
                                    value={formData.bannerURL}
                                    onChange={handleInputChange}
                                />
                            </Form.Group> */}

                            <Form.Group controlId="bannerURL">
                                <Form.Label >Event Banner Image</Form.Label>
                                <Form.Control


                                    name="bannerImage"
                                    onChange={(e) => {

                                        setBannerImage(e.target.files[0]);

                                    }}
                                    className=' input-box-identity form-control-sm'

                                    type="file" />
                            </Form.Group>
                            


                            <Form.Group controlId="eventURL">
                                <Form.Label>Event URL</Form.Label>
                                <Form.Control
                                    className=' input-box-identity form-control-sm'
                                    type="text"
                                    name="eventURL"
                                    value={formData.eventURL}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>


                        </Col>
                    </Row>

                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                        <Button style={{ backgroundColor: "#569f51" }} variant="primary" type="primary" htmlType='submit'>
                            Submit
                        </Button>
                    </div>

                </Form>
            </Container>
        );
    };
};

export default EventForm;
