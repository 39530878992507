import React, { useState } from 'react';
import { Container, Table, Form } from 'react-bootstrap';
import LoaderButton from '../LoaderButton';
import { Modal } from 'antd';
import usePost from '../../hooks/hooks/postData';
import { twit } from '../Notificationpopout';
import useFetch from '../../hooks/hooks/getData';
import { getData } from '../../Utilities/utilities';
import { patchData } from '../../Utilities/utilities';
import Loader from '../Loader';
import TextTyper from 'text-type-animation-effect-react';
import PhoneInput from 'react-phone-input-2';
import { validateEmail } from '../../Utilities/emailVerification';

const MyMemberships = () => {

    const [rowdata, setRowdata] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newMember, setNewMember] = useState(false);
    const [newClass, setNewClass] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [mclassName, setMclassName] = useState("");
    const [membershipClass, setMembershipClass] = useState("");
    const [cardValidity, setCardValidity] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [step1, setStep1] = useState(true);
    const [useCardNo, setUseCardNo] = useState(false);
    const [useDetails, setUseDetails] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [fname, setFname] = useState("");




    const createClass = usePost("/api/products/identity/membership/create", setLoading);
    const myClasses = useFetch('/api/products/identity/membership/get', () => { }, refresh);
    const createMember = usePost("/api/products/identity/membership/people/add", setLoading);
    const myMembers = useFetch("/api/products/identity/membership/people", () => { }, refresh);
    const createMemberByDeatails = usePost("/api/products/identity/membership/human/register", setLoading);







    const compareDates = (date1, date2) => {
        // Convert the date strings to Date objects.
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        // Compare the Date objects.
        if (d1 < d2) {
            return -1;
        } else if (d1 == d2) {
            return 0;
        } else {
            return 1;
        }
    }



    const validateCard = (id) => {


        const response = getData(`/api/products/card/${id}`, () => { });



        response?.then((data) => {

            if (data?.data) {



                if (data?.data?.data?.data?.CardType.ctype === "silver" || data?.data?.data?.data?.CardType.ctype === "bronze") {


                    setCardValidity("invalid")
                } else {
                    setCardValidity("valid")
                }
            } else {
                if (data?.error?.response?.request?.status === 500) {
                    setCardValidity("invalidCard")
                } else {
                    setCardValidity("Network error");
                }

            }
        })






    }


    //handling details member registration

    const HandleAddByDetails = () => {
        if (fname === '') {

            twit('info', "Please enter your name.");

        } else if (phoneNumber?.length < 12) {
            twit('info', "Please enter a valid phone number.");

        } else if (!validateEmail(email)) {
            twit('info', "Please enter a valid email.");

        } else {
            const response = createMemberByDeatails.post({ name: fname, phone: phoneNumber, email: email });
            response.then((data) => {

                if (data?.type === "data") {

                    setNewMember(false);
                    twit("success", "Member Added");
                } else {


                    if (data?.error?.response?.data?.data) {
                        twit("info", data?.error?.response?.data?.data);

                    } else {
                        twit("info", 'Network error.');
                    }



                }
            })
        }

    }





    if (loading) {
        return (<Loader />);
    } else {


        return (

            <Container>

                {rowdata && <Modal open={true} title={"Member Information"} onCancel={() => {
                    setRowdata(false);
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div>
                        <p>Name : {rowdata.HumanIdentification.fhname + " " + rowdata.HumanIdentification.lhname} </p>
                        <p>Contact : {rowdata.HumanIdentification.contactno} </p>
                        <p>Start Date : {rowdata.dfrom}</p>
                        <p>End Date: {rowdata.dto}</p>
                    </div>




                    <div>

                        {!rowdata.status && (
                            <LoaderButton onClick={() => {

                                patchData(`/api/products/identity/membership/${"activate"}`, {
                                    hid: rowdata.HumanIdentification.hid
                                }, setLoading).then((data) => {
                                    if (data?.data) {

                                        setRefresh(!refresh);
                                        setRowdata(false);
                                        twit("success", data?.data?.data?.data);
                                    } else {
                                        twit("error", "An error occurred while processing.")
                                    }
                                    
                                });

                            }} loading={false}
                                variant={"primary"}
                                label={" Activate "}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                                className={"mx-1"}
                            />
                        )}

                        {rowdata.status && (
                            <LoaderButton onClick={() => {

                                patchData(`/api/products/identity/membership/${"deactivate"}`, {
                                    hid: rowdata.HumanIdentification.hid
                                }, setLoading).then((data) => {
                                    if (data?.data) {
                                        setRefresh(!refresh);
                                        setRowdata(false);
                                        twit("success", data?.data?.data?.data);
                                    } else {
                                        twit("error", "An error occurred while processing.")
                                    }
                                    
                                });

                            }} loading={false}
                                variant={"primary"}
                                label={" Deactivate "}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                                className={"mx-1"}
                            />
                        )}


                    </div>


                </Modal>}


                {newMember && <Modal open={true} title={'Add New Member'} onCancel={() => {
                    setNewMember(false);
                    setStep1(true);
                    setUseCardNo(false);
                    setUseDetails(false);
                }}
                    footer={[


                    ]}
                    className='allmodals'
                >

                    {step1 && (
                        <div style={{ textAlign: "center" }}>
                            <Form.Label className="mt-3" >
                                <TextTyper text={"Have an active card number ?"} interval={50} Markup={"p"} />
                            </Form.Label>

                            <div style={{ width: "100%" }}>

                                <LoaderButton onClick={() => {
                                    setStep1(false);
                                    setUseCardNo(true);

                                }} loading={false}
                                    variant={"primary"}
                                    label="Yes"
                                    style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", width: "100px" }}
                                    className={"mx-2"}
                                />
                                <LoaderButton onClick={() => {
                                    setUseDetails(true);
                                    setUseCardNo(false);
                                    setStep1(false);

                                }} loading={false}
                                    variant={"primary"}
                                    label="No"
                                    style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px", width: "100px" }}
                                    className={"mx-2"}
                                />
                            </div>
                        </div>

                    )}

                    {useCardNo && (
                        <div>
                            <Form.Group controlId="formBasicLocation">
                                <Form.Label id="cardNo" style={{ marginBottom: "0px" }}>Card No.</Form.Label>
                                <Form.Control

                                    value={cardNumber}
                                    onChange={(e) => {
                                        setCardNumber(e.target.value);
                                        validateCard(e.target.value);

                                    }}

                                    className=' input-box-identity form-control-sm' type="text" id="cardNo" placeholder=" Card number" />
                            </Form.Group>

                            <Form.Group style={{ marginBottom: "14px" }} >
                                <Form.Label style={{ marginBottom: "0px" }}>
                                    Membership Class
                                </Form.Label>

                                <Form.Select


                                    onChange={(e) => {
                                        setMembershipClass(e.target.value);
                                    }}

                                    className='form-selector'>
                                    <option key={1} value={""}>Select Membership Class</option>
                                    {myClasses?.data?.data?.map((data) => {
                                        return <option className='input-box' key={data.mid} value={data.mid
                                        }>{data.mcname}</option>
                                    })}

                                </Form.Select>

                            </Form.Group>
                            <Form.Group controlId="date">
                                <Form.Label style={{ marginBottom: "0px" }}>Start Date</Form.Label>
                                <Form.Control


                                    className=' input-box-identity form-control-sm'
                                    type="date"
                                    name="date"
                                    value={startDate}
                                    onChange={(e) => {

                                        setStartDate(e.target.value);
                                    }}

                                />
                            </Form.Group>
                            <Form.Group controlId="date">
                                <Form.Label style={{ marginBottom: "0px" }}>End Date</Form.Label>
                                <Form.Control


                                    className=' input-box-identity form-control-sm'
                                    type="date"
                                    name="date"
                                    value={endDate}
                                    onChange={(e) => {

                                        setEndDate(e.target.value);
                                    }}

                                />
                            </Form.Group>

                            <div>

                                <LoaderButton onClick={() => {
                                    
                                    if (cardNumber === "" || membershipClass === "" || startDate === "" || endDate === "" || compareDates(startDate, endDate) === 1) {
                                        if (cardNumber === "") {
                                            twit("info", "Please input the card number.");
                                        } else if (membershipClass === "") {
                                            twit("info", "Please select a membership class.");
                                        } else if (startDate === "") {
                                            twit("info", "Please select a start date.");
                                        } else if (endDate === "") {
                                            twit("info", "Please select an end date.");
                                        } else if (compareDates(startDate, endDate) === 1) {
                                            twit("info", "End date should be greater than start date");
                                        }

                                    } else {


                                        if (cardValidity === "valid") {

                                            createMember.post({ mid: membershipClass, hid: cardNumber, dfrom: startDate, dto: endDate }).then((data) => {
                                                if (data?.type === "data") {
                                                    setNewMember(false);
                                                    setRefresh(!refresh);
                                                    twit("success", data?.data?.message);
                                                } else {
                                                    twit("info", data?.error?.response?.data?.data);
                                                }

                                            });
                                        } else if (cardValidity === "invalid") {

                                            twit("info", "Membership not supported on entered card.");

                                        } else if (cardValidity === "invalidCard") {
                                            twit("info", "Card number is invalid. ");
                                        } else {
                                            twit("error", "Network error ");
                                        }

                                    }

                                }} loading={loading}
                                    variant={"primary"}
                                    label={" Add "}
                                    style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}

                                />


                            </div>








                        </div>
                    )}

                    {useDetails && (
                        <div>
                            <Form.Label className="mt-3" style={{ textAlign: "center" }}>
                                <TextTyper text={"Please enter the following details to proceed..."} interval={50} Markup={"p"} />
                            </Form.Label>

                            <Form.Group >
                                <Form.Label style={{ marginBottom: "0px" }}> Full Name <span style={{ color: "red" }}>*</span></Form.Label>
                                <Form.Control
                                    required
                                    value={fname}
                                    onChange={(e) => {
                                        setFname(e.target.value);
                                    }}

                                    className=' input-box-identity form-control-sm' type="text" name="fname" placeholder="Enter full name" />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: "14px" }} controlId="phoneNumber">

                                <Form.Label>Phone Number<span style={{ color: "red" }}>*</span></Form.Label>
                                <PhoneInput
                                    country={'ug'}
                                    value={phoneNumber}
                                    localization={{ ug: 'Uganda' }}
                                    onChange={(e) => {

                                        setPhoneNumber(e);
                                    }}

                                    disableDropdown
                                    countryCodeEditable={false}
                                    masks={{ ug: '... ... ...' }}



                                    inputProps={{
                                        name: 'phone',
                                        required: true,

                                    }}


                                    inputStyle={{
                                        width: "100%",
                                        height: "30px",
                                        fontSize: "12px"
                                    }}

                                />

                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label style={{ marginBottom: "0px" }}> Email<span style={{ color: "red" }}>*</span></Form.Label>
                                <Form.Control

                                    required
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}

                                    className=' input-box-identity form-control-sm' type="email" name="email" placeholder="Enter email" />
                            </Form.Group>





                            <LoaderButton onClick={() => {
                                HandleAddByDetails();
                            }} loading={false}
                                variant={"primary"}
                                label={"Register"}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}

                            />
                        </div>
                    )}






                </Modal>}
                {newClass && <Modal open={true} title={'Create new Class'} onCancel={() => {
                    setNewClass(false);
                }}
                    footer={[


                    ]}
                    className='allmodals'
                >









                    <div>
                        <Form.Group controlId="membershipClass">
                            <Form.Label id="MembershipClass" style={{ marginBottom: "0px" }}>Membership Class Name</Form.Label>
                            <Form.Control
                                name='mclass'
                                value={mclassName}
                                onChange={(e) => {
                                    setMclassName(e.target.value);

                                }}

                                className=' input-box-identity form-control-sm' type="text" id="cardNo" placeholder=" Membership Class Name" />
                        </Form.Group>

                        <div>

                            <LoaderButton onClick={() => {
                                if (mclassName === "") {
                                    twit("info", "Please input a valid name.")
                                } else {
                                    createClass.post({ name: mclassName }).then((data) => {
                                        if (data?.type === "data") {
                                            setRefresh(!refresh);
                                            setNewClass(false);
                                            twit("success", data?.data?.message);
                                        } else {

                                            twit("info", data?.error?.response?.data?.data);
                                        }
                                    });
                                }

                            }} loading={loading}
                                variant={"primary"}
                                label={" Create "}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}

                            />


                        </div>








                    </div>



                </Modal>}



                <div style={{ textAlign: "end" }} >

                    <LoaderButton onClick={() => {
                        setNewMember(true);
                    }} loading={false}
                        variant={"primary"}
                        label={"Add Member"}
                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        className={"mx-1 my-1"}
                    />
                    <LoaderButton onClick={() => {
                        setNewClass(true);
                    }} loading={false}
                        variant={"primary"}
                        label={"Add New Class"}
                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        className={"mx-1 my-1"}
                    />

                </div>




                <div className='table-responsive'>

                    <Table striped hover style={{ cursor: "pointer" }}>
                        <thead>
                            <tr>
                                <th>Card No.</th>
                                <th>Name</th>
                                <th>Contact</th>
                                <th>Validity</th>
                                <th>Class</th>
                                <th>Status</th>




                            </tr>
                        </thead>
                        <tbody>
                            {myMembers?.data?.data?.map((member, index) => (
                                <tr key={index} onClick={() => {

                                    setRowdata(member);



                                }}>
                                    <td>{member.HumanIdentification.hid
                                    }</td>
                                    <td>{member.HumanIdentification.fhname + " " + member.HumanIdentification.lhname}</td>
                                    <td>{member.HumanIdentification.contactno}</td>
                                    <td>{member.dto}</td>
                                    <td>{member.MemberShipClass.mcname}</td>
                                    <td>{member.status ? "Active" : "Inactive"}</td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </div>
            </Container>



        )
    }
}
export default MyMemberships;
