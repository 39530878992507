
import React, { useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import * as GiIcon from 'react-icons/gi';
import ColoredD from './ColorSport';
import ColorCube from './ColorCube';
import * as MdIcons from 'react-icons/md';
import { ImCross } from "react-icons/im";
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import { twit } from './Notificationpopout';
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";

const BatchesPreviewSellers = ({ batchId, handleBatchView, batches = [],SellerName,refresh,setRefresh }) => {
  const [searchTerm, setSearchTerm] = useState('');
  

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  

  const tableStyles = {
    backgroundColor: '#f8f9fa',
    fontSize: '10px',
  };

  const headerStyles = {
    backgroundColor: '#343a40',
    color: '#fff',
  };

  const rowStyles = {
    height: '1px',
    padding: '0px',
    margin: '0px'
  };

  const rowCellStyles = {
    paddingTop: '2px',
    paddingBottom: '2px'
  }



  const filteredBatches = batches?.filter((batch) =>
    batch?.DocumentTag?.tagName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  

  return (
    <div className="batch-selection-component">

      <div style={{ width: '100%', textAlign: 'center' }}>
        <p style={{ margin: 0, fontWeight: 'bold' }}>{`${SellerName}`}</p>
      </div>

      <Form.Control
        className=' input-box-events form-control-sm my-2'
        type="text"
        placeholder="Search batches..."
        value={searchTerm}
        onChange={handleSearch}

      />


      <div className='mt-2 table-responsive  batch-list ' >
        <Table striped bordered hover style={tableStyles}>
          <thead>
            <tr style={headerStyles}>
              <th style={{ textAlign: "center", ...rowCellStyles }}> No.</th>
              <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Batch</th>
              <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Status</th>
              <th style={{ textAlign: "center", ...rowCellStyles }}>Operations</th>
            </tr>
          </thead>
          <tbody>
            {filteredBatches?.map((batch, index) => (
              <tr key={index} style={{ ...rowStyles }}>
                <td style={{ textAlign: "center", ...rowCellStyles }}>  {index + 1}</td>
                <td style={{ backgroundColor: batchId == batch?.bid ? 'green' : 'white', fontWeight: 'bold', ...rowCellStyles }}>{batch.DocumentTag?.tagName}</td>
                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{!(batch.isActive) ? <ImCross title='Inactive' style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "15px", width: "15px", color: 'green' }} />}</td>
                <td style={{ textAlign: "start", ...rowCellStyles }}>

                  <>

                    {(batch.isActive) ?
                      <MdIcons.MdPreview
                        className="mx-1"
                        title="View Batch Details"
                        style={{ height: "15px", width: "15px" }}
                        onClick={() => {
                          handleBatchView(batch)

                        }}
                      />
                      :
                      <></>

                    }


                  </>



                </td>

              </tr>
            ))}

          </tbody>
        </Table>
      </div>


      <style jsx>{`
        .batch-selection-component {
          font-family: Arial, sans-serif;
          max-width: 500px;
          margin: 0 auto;
          
          
        }
        .search-input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .batch-list {
          max-height:410px;
          overflow-y: scroll;
        }
        .user-item {
          margin-bottom: 10px;
        }
        .user-label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .user-checkbox {
          margin-right: 10px;
        }
        .user-image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .user-name {
          font-size: 14px;
        }
        .submit-button {
          width: 100%;
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          margin-top: 10px;
        }
        .submit-button:hover {
          background-color: #0056b3;
        }
        .email-toggle {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .email-toggle label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .email-toggle input {
          margin-right: 10px;
        }
      `}</style>
    </div>
  );
};

export default BatchesPreviewSellers;