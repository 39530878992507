import { useEffect } from 'react';
import axios from '../api/axios';


const token = sessionStorage.getItem('token');
const useDoctypes = async() => {


    const  docUrl = '/api/government/documents'

    


        try {
          const response = await axios.get(docUrl,{
            headers: {  Authorization: `Bearer ${token}`
        }

        });
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useDoctypes;