import React, { useState } from 'react';
import { Card, Container, Table, Button, Form } from 'react-bootstrap';
import { Modal } from 'antd';
import Loader from './Loader';
import ColoredD from './ColorSport';




const Meetings = () => {

    const [scheduleForm, setScheduleForm] = useState()
    const [time, setTime] = useState('')
    const [date, setDate] = useState('')
    const [topic, setTopic] = useState('')
    const [location, setLocation] = useState('')
    const [loading, setLoading] = useState(false)
    const [scheduleView, setScheduleView] = useState(true)
    const [infoView, setInfoView] = useState()
    const [participants, setParticipants] = useState(0);
    const [participantEmail, setParticipantEmail] = useState();
    const [rowdata, setRowdata] = useState();





    const [successMsg, setSuccessMsg] = useState()
    const [errorMsg, setErrorMsg] = useState()


    let participantsArray = []
    const scheduledMeetings = [{ id: 1, topic: "wedding", date: "1-3-23", time: '9:30 am', participants: 10 }
        , { id: 2, topic: "funding", date: "2-4-23", time: '9:30 am', participants: 11 }
        , { id: 3, topic: "wedding", date: "1-6-23", time: '9:30 am', participants: 14 }]



    const completedMeetings = [{ id: 4, topic: "wedding", date: "1-3-23", time: '9:30 am', participants: 10 }
        , { id: 5, topic: "funding", date: "2-4-23", time: '9:30 am', participants: 11 }
        , { id: 6, topic: "wedding", date: "1-6-23", time: '9:30 am', participants: 14 }]


    const handleSubmit = (event) => {
        event.preventDefault();

    };

    const handleCancel = () => {
        setScheduleForm();
        setSuccessMsg();
        setInfoView();
        setScheduleView(true);
    }

    const handleCancelerror = () => {
        setErrorMsg(false);
        setRowdata(false);

    }

    const handleView = () => {
        setInfoView(!infoView)
        setScheduleView(!scheduleView)
    }

    const handleParticipants = (e) => {
        e.preventDefault();



        for (let i = 0; i < participants; i++) {
            participantsArray.push(e.target[i]?.value);

        }
        setParticipantEmail(participantsArray)


        





        handleView()
    }





    const renderParticipantFields = () => {
        const participantFields = [];
        for (let i = 0; i < participants; i++) {
            const participantNum = i + 1;
            const participantNameId = `participantName-${participantNum}`;
            const participantEmailId = `participantEmail-${participantNum}`;
            participantFields.push(
                <div key={participantNum}>
                    {/* <p>Email {participantNum}</p> */}
                    {/* <Form.Group controlId={participantNameId}>
                <Form.Label> Name</Form.Label>
                <Form.Control
                  className=' input-box-ticket form-control-sm'
                  type="text"
                  name={participantNameId}
                  
                  
                  required
                />
              </Form.Group> */}

                    <Form.Group controlId={participantEmailId}>
                        {/* <Form.Label> Email</Form.Label> */}
                        <Form.Control
                            className=' input-box-ticket form-control-sm'
                            type="email"
                            name={participantEmailId}
                            placeholder={`Email ${participantNum}`}
                            required
                        />
                    </Form.Group>
                </div>
            );
        }
        return participantFields;
    };





    if (loading) {
        return (<Loader />);
    } else {


        return (

            <Container className='meetings-container'>
                {scheduleForm && <Modal open={true} title={'New Meeting'} onCancel={handleCancel} onOk={handleCancel}
                    footer={[


                    ]}
                >









                    {scheduleView && (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="date">
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    className=' input-box-ticket form-control-sm'
                                    type="date"
                                    name="date"
                                    value={date}
                                    onChange={(e) => { setDate(e.target.value) }}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="time">
                                <Form.Label>Time</Form.Label>
                                <Form.Control
                                    className=' input-box-ticket form-control-sm'
                                    type="time"
                                    name="time"
                                    value={time}
                                    onChange={(e) => { setTime(e.target.value) }}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="topic">
                                <Form.Label>Topic</Form.Label>
                                <Form.Control
                                    className=' input-box-ticket form-control-sm'
                                    type="text"
                                    name="topic"
                                    value={topic}
                                    onChange={(e) => { setTopic(e.target.value) }}
                                    required
                                    placeholder='topic'
                                />
                            </Form.Group>

                            <Form.Group controlId="Location">
                                <Form.Label>Venue</Form.Label>
                                <Form.Control
                                    className=' input-box-ticket form-control-sm'
                                    type="text"
                                    name="topic"
                                    value={location}
                                    onChange={(e) => { setLocation(e.target.value) }}
                                    required
                                    placeholder='Venue'
                                />
                            </Form.Group>
                            <Form.Group controlId="Location">
                                <Form.Label>No. of Participants</Form.Label>
                                <Form.Control
                                    className=' input-box-ticket form-control-sm'
                                    type="text"
                                    name="participant"
                                    value={participants}
                                    onChange={(e) => { setParticipants(e.target.value) }}
                                    required
                                    placeholder='No. of participants'
                                />
                            </Form.Group>
                            <div>
                                <Button variant='primary' type="button" className='schedule-button' onClick={() => {
                                    if (!participants < 1) {
                                        handleView()
                                    } else {
                                        setErrorMsg('enter a valid number of participants')
                                    }

                                }



                                }>Add Participants</Button>
                                <Button variant='success' type="submit" className='schedule-button'>Schedule</Button>
                            </div>

                        </Form>




                    )}
                    {infoView && (
                        <Form onSubmit={handleParticipants}>
                            {renderParticipantFields()}
                            <Button variant='success' type="submit" className='schedule-button'>Add</Button>
                        </Form>
                    )}


                </Modal>}

                {successMsg && <Modal open={true} title={'Success'} onCancel={handleCancel} onOk={handleCancel}
                    footer={[


                    ]}
                >
                    <ColoredD color="green">{successMsg}</ColoredD>

                </Modal>}


                {errorMsg && <Modal open={true} title={'Failed'} onCancel={handleCancelerror} onOk={handleCancelerror}
                    footer={[


                    ]}
                >
                    <ColoredD color="red">{errorMsg}</ColoredD>

                </Modal>}
                {rowdata && <Modal open={true} title={rowdata.topic} onCancel={handleCancelerror} onOk={handleCancelerror}
                    footer={[


                    ]}
                >

                    <p>Topic : {rowdata.topic}</p>
                    {participantEmail && (
                        participantEmail.map((value, index) => {
                            return (<p key={index}>Participant{index + 1} : {value}</p>)
                        }

                        )
                    )}





                </Modal>}



                <Card>
                    <Card.Header className='mass-header'>
                        <h6>Our Meetings</h6>
                        <div >

                            <Button variant='success' className='mass-button' onClick={() => { setScheduleForm(true) }} >Schedule meeting</Button>

                        </div>
                    </Card.Header>
                    <Card.Body>

                        <h6>Scheduled Meetings</h6>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>Topic</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Location</th>
                                    <th>Participants</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scheduledMeetings.map((meeting, index) => (
                                    <tr key={index} onClick={() => { setRowdata(meeting) }}>
                                        <td>{meeting.id}</td>
                                        <td>{meeting.topic}</td>
                                        <td>{meeting.date}</td>
                                        <td>{meeting.location}</td>
                                        <td>{meeting.participants}</td>
                                        <td>{meeting.participants}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <h6>Closed Meetings</h6>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>Topic</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Location</th>
                                    <th>Participants</th>
                                </tr>
                            </thead>
                            <tbody>
                                {completedMeetings.map((meeting, index) => (
                                    <tr key={index} onClick={() => { setRowdata(meeting) }} >
                                        <td>{meeting.id}</td>
                                        <td>{meeting.topic}</td>
                                        <td>{meeting.date}</td>
                                        <td>{meeting.location}</td>
                                        <td>{meeting.participants}</td>
                                        <td>{meeting.participants}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

export default Meetings;
