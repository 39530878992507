import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { twit } from './Notificationpopout';
import LoaderButton from './LoaderButton';
import Loader from './Loader';
import usePost from '../hooks/hooks/postData';
import axios from "../api/axios";
import { validateImageDimensions } from '../Utilities/utilities';
import useFetch from '../hooks/hooks/getData';
import PhoneInput from 'react-phone-input-2';
import { Button } from 'antd';
import * as GiIcon from 'react-icons/gi';
import useGeneralState from '../hooks/hooks/generalState';
import { MdUpload } from "react-icons/md";

const GeneralSettings = () => {
    const [mclassName, setMclassName] = useState("");
    const [loading, setLoading] = useState(false);
    const [logoImage, setLogoImage] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [logoFrame, setLogoFrame] = useState();
    const [initialLogoFrame, setInitialLogoFrame] = useState();
    const [contactsChanged, setContactsChanged] = useState(false);
    const [location, setLocation] = useState('');
    const [email, setEmail] = useState('');
    const [currency, setCurrency] = useState('');
    const [socialMediaLinks, setSocialMediaLinks] = useState({
        facebook: '',
        twitter: '',
        instagram: '',
        linkedIn: '',
        youtube: '',
        website: '',

    });


    // const currencyData = [
    //     "USD", "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN",
    //     "BAM", "BBD", "BDT", "BGN", "BHD", "BIF", "BMD", "BND", "BOB", "BRL", "BSD",
    //     "BTN", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY", "COP", "CRC",
    //     "CUP", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP", "ERN", "ETB", "EUR",
    //     "FJD", "FKP", "FOK", "GBP", "GEL", "GGP", "GHS", "GIP", "GMD", "GNF", "GTQ",
    //     "GYD", "HKD", "HNL", "HRK", "HTG", "HUF", "IDR", "ILS", "IMP", "INR", "IQD",
    //     "IRR", "ISK", "JEP", "JMD", "JOD", "JPY", "KES", "KGS", "KHR", "KID", "KMF",
    //     "KRW", "KWD", "KYD", "KZT", "LAK", "LBP", "LKR", "LRD", "LSL", "LYD", "MAD",
    //     "MDL", "MGA", "MKD", "MMK", "MNT", "MOP", "MRU", "MUR", "MVR", "MWK", "MXN",
    //     "MYR", "MZN", "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN",
    //     "PGK", "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR",
    //     "SBD", "SCR", "SDG", "SEK", "SGD", "SHP", "SLE", "SLL", "SOS", "SRD", "SSP",
    //     "STN", "SYP", "SZL", "THB", "TJS", "TMT", "TND", "TOP", "TRY", "TTD", "TVD",
    //     "TWD", "TZS", "UAH", "UGX", "UYU", "UZS", "VES", "VND", "VUV", "WST", "XAF",
    //     "XCD", "XDR", "XOF", "XPF", "YER", "ZAR", "ZMW", "ZWL"
    // ]


    const generalAppState = useGeneralState();

    const handleSocialsChange = (platform, link) => {
        setSocialMediaLinks(prevLinks => ({
            ...prevLinks,
            [platform]: link
        }));
    };



    const createClass = usePost("/api/products/identity/membership/create", setLoading);
    const updateContacts = usePost("/api/products/identity/card/user/contacts", setLoading);
    const userLogo = useFetch('/api/products/identity/card/user/logo', setLoading, refresh);
    const contacts = useFetch('/api/products/identity/card/user/contacts', setLoading, refresh);
    const userData = useFetch("/api/auth/user/profile", setLoading);
    const currencyData = useFetch("/api/currencies", setLoading);

    const token = sessionStorage.getItem('token');

    const formData = new FormData()
    formData.append("image", logoImage);



    useEffect(() => {

        
        setInitialLogoFrame(userLogo?.data?.shape)
    }, [userLogo]);

    useEffect(() => {



        if (socialMediaLinks.facebook !== '' || socialMediaLinks.instagram !== '' || socialMediaLinks.linkedIn !== '' || socialMediaLinks.twitter !== '' || socialMediaLinks.youtube !== '' || socialMediaLinks.website !== '' || location !== '') {
            setContactsChanged(true);
        } else {
            //setContactsChanged(false);
        }




    }, [socialMediaLinks, location]);

    // useEffect(()=>{
    // if(contacts?.data?.contacts){
    //     if(socialMediaLinks.facebook===''){
    //         setSocialMediaLinks((prevLinks)=>({
    //             ...prevLinks,
    //             facebook:contacts?.data?.contacts?.facebook
    //             }))
    //     }
    //     if(socialMediaLinks.instagram===''){
    //         setSocialMediaLinks((prevLinks)=>({
    //             ...prevLinks,
    //             instagram:contacts?.data?.contacts?.instagram
    //             }))  
    //     }
    //     if(socialMediaLinks.linkedIn===''){
    //         setSocialMediaLinks((prevLinks)=>({
    //             ...prevLinks,
    //             linkedIn:contacts?.data?.contacts?.linkedIn
    //             }))
    //     }
    //     if(socialMediaLinks.twitter===''){
    //         setSocialMediaLinks((prevLinks)=>({
    //             ...prevLinks,
    //             twitter:contacts?.data?.contacts?.twitter
    //             }))
    //     }
    //     if(socialMediaLinks.website===''){
    //         setSocialMediaLinks((prevLinks)=>({
    //             ...prevLinks,
    //             website:contacts?.data?.contacts?.website
    //             }))
    //     }
    //     if(socialMediaLinks.youtube===''){
    //         setSocialMediaLinks((prevLinks)=>({
    //         ...prevLinks,
    //         youtube:contacts?.data?.contacts?.youtube
    //         }))
    //     }

    // }


    // },[contacts]);




    const uploadFile = async () => {


        try {

            // if (initialLogoFrame && !logoFrame) {
            //     await validateImageDimensions(logoImage, initialLogoFrame === "rectangular" ? 'rectangularLogo' : 'squareLogo');
            // } else {
            //     await validateImageDimensions(logoImage, logoFrame === "rectangular" ? 'rectangularLogo' : 'squareLogo');
            // }
            if(!logoFrame && (initialLogoFrame ||(initialLogoFrame == 'undefined')) ){
                twit('info','Please select logo shape.')
                return Promise.reject('Please select logo shape.')
            }else{
                const response = await axios.post(`/api/products/identity/card/user/logo/upload/${logoFrame?logoFrame:initialLogoFrame}`, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                        , Authorization: `Bearer ${token}`
                    }
    
                });
                return response;
            }

            
        } catch (error) {

            return Promise.reject(error);
        }



    }


    const saveContacts = () => {
        updateContacts.post(
            {
                instagram: socialMediaLinks.instagram === '' ? contacts?.data?.contacts?.instagram : socialMediaLinks.instagram,
                twitter: socialMediaLinks.twitter === '' ? contacts?.data?.contacts?.twitter : socialMediaLinks.twitter,
                linkedIn: socialMediaLinks.linkedIn === '' ? contacts?.data?.contacts?.linkedIn : socialMediaLinks.linkedIn,
                youtube: socialMediaLinks.youtube === '' ? contacts?.data?.contacts?.youtube : socialMediaLinks.youtube,
                facebook: socialMediaLinks.facebook === '' ? contacts?.data?.contacts?.facebook : socialMediaLinks.facebook,
                website: socialMediaLinks.website === '' ? contacts?.data?.contacts?.website : socialMediaLinks.website,
                location: location === '' ? contacts?.data?.contacts?.location : location

            }).then((data) => {
                if (data.data) {

                    twit("success", 'Contacts updated successfully.')
                } else {

                    twit("info", 'An error occured while updating contact information.')
                }


            })
    }

    const updateUserCurrency = async (setLoading, passid) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            curr: currency
        };

        const Url = '/api/currencies/set'
        if (!currency) {
            return Promise.reject('Please select a currency.')
        } else {
            try {
                setLoading(true);
                const response = await axios.post(Url, JSON.stringify(postData), {
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                    }

                });

                if (response.status === 201) {
                    setLoading(false);
                    return Promise.resolve('Currency updated successfully.')
                }


            } catch (err) {
                setLoading(false);
                console.log("Currency update error:", err)
                return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while updating currency.')

            }
        }

    };


    if (loading) {
        return (<Loader />);
    } else {
        return (
            <Container className='text-muted'>
                <div>
                    {/* <div>
                        <p className=' text-muted' style={{ fontWeight: "bold", marginBottom: "0px" }}>Membership.</p>
                        <hr className='mt-0' />
                        <Form.Group >
                            <Form.Label id="MembershipClass" style={{ marginBottom: "0px" }}>New Membership Class</Form.Label>
                            <Form.Control
                                name='mclass'
                                value={mclassName}
                                onChange={(e) => {
                                    setMclassName(e.target.value);

                                }}

                                className=' input-box-identity form-control-sm' type="text" id="cardNo" placeholder=" Membership Class Name" />
                        </Form.Group>

                        <div>

                            <LoaderButton onClick={() => {
                                if (mclassName === "") {
                                    twit("info", "Please input a valid name.")
                                } else {
                                    createClass.post({ name: mclassName }).then((data) => {
                                        if (data?.type === "data") {

                                            setMclassName("");
                                            twit("success", data?.data?.message);
                                        } else {

                                            twit("info", data?.error?.response?.data?.data);
                                        }
                                    });
                                }

                            }} loading={loading}
                                variant={"primary"}
                                label={" Create "}
                                style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}

                            />


                        </div>
                    </div> */}


                    <div className='mt-3'>
                        <p className='mt-0 mb-0 text-muted' style={{ fontWeight: "bold" }}>Logo</p>
                        <hr className='mt-0' />
                        <p className='mt-0 mb-0 text-muted' style={{ fontSize: "8px" }}>Your logo will appear exactly as shown below.</p>
                        <Card style={{
                            overflow: 'hidden',
                            maxHeight: '151px',
                            maxWidth: (logoFrame || initialLogoFrame) === 'rectangular' ? "560px" : "151px",
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',

                        }}>
                            {!userLogo?.data?.logo && (
                                <p>no logo uploaded!</p>
                            )}
                            {userLogo?.data?.logo && logoFrame && (
                                <Card.Img variant="top" style={{ width: logoFrame === 'rectangular' ? "560px" : "151px", height: '151px' }} src={userLogo?.data?.logo} />
                            )}
                            {userLogo?.data?.logo && (!logoFrame) && (
                                <Card.Img variant="top" style={{ width: initialLogoFrame === 'rectangular' ? "560px" : "151px", height: '151px' }} src={userLogo?.data?.logo} />
                            )}
                        </Card>

                        <Row>
                            <Col md={4}>
                                <Form.Group className='mt-1' controlId="forCustomB">
                                    {/* <Form.Label style={{ marginBottom: "0px" }}>Upload Image</Form.Label> */}
                                    <Form.Control

                                        accept='.jpg,.jpeg,.png'


                                        onChange={(e) => {



                                            setLogoImage(e.target.files[0]);

                                        }}
                                        className=' input-box-identity form-control-sm'

                                        type="file" />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <div className='mt-1' style={{ display: "flex", flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
                                    <Form.Select
                                        className='form-selector '
                                        style={{ fontSize: '10px' }}
                                        value={logoFrame ? logoFrame : initialLogoFrame}
                                        onChange={(e) => {

                                            setLogoFrame(e.target.value);
                                        }}
                                    >
                                        <option value={initialLogoFrame ? initialLogoFrame : ''}>{(initialLogoFrame && (initialLogoFrame != 'undefined')) ? initialLogoFrame : 'Select Image Dimensions'} </option>
                                        <option value='rectangular'>Rectangular</option>

                                        <option value='square'>Square</option>


                                    </Form.Select>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div className='mt-1' style={{ width: '100%', textAlign: 'center' }}>

                                    <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={() => {
                                        if (logoImage === "") {
                                            twit("info", "Please select a photo first.")
                                        } else {
                                            uploadFile().then((data) => {

                                                twit('success', 'File Uploaded successfully.');
                                                setRefresh(!refresh);
                                                window.location.reload();


                                            }).catch((error) => {
                                                console.log(error)
                                                twit('info', 'Invalid image dimensions.');
                                            })
                                        }

                                    }}>
                                        <MdUpload className='mx-1' style={{ width: '15px', height: '15px' }} /> | Upload
                                    </Button>



                                </div>
                            </Col>
                        </Row>





                    </div>

                    <div style={{ }}>
                        <p className=' text-muted mt-1' style={{ fontWeight: "bold", marginBottom: "0px" }}>Currency</p>
                        <hr className='mt-0' />
                        <Row>
                            <Col md={3}>
                                <Form.Group >

                                    <Form.Control
                                        name='curr'
                                        value={`${generalAppState?.currency ? generalAppState?.currency : 'UGX'}`}
                                        disabled
                                        className=' input-box-identity form-control-sm' type="text" id="curr" />
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <div style={{ display: "flex", flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
                                    <Form.Select
                                        style={{ fontSize: '10px' }}

                                        name="Currency"
                                        onChange={(e) => {
                                            setCurrency(e.target.value)
                                        }}
                                        className='form-selector mx-0'>
                                        <option className='input-box ' key={1} value={''}>Select New Currency</option>
                                        {currencyData.data?.currencies?.filter((data)=>{
                                            return data.Iso == 'UGX'
                                        }).map((data) => {
                                            return <option className='input-box' key={data?.Iso} value={data?.Iso}>{data?.Name}</option>
                                        })}


                                    </Form.Select>

                                </div>

                            </Col>

                            <Col md={4}>
                                <div className='mt-0' style={{ width: '100%', textAlign: 'center' }}>
                                    <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={() => {
                                        updateUserCurrency(setLoading).then((data) => {
                                            generalAppState?.actions.setRefresh(!generalAppState?.refresh)
                                            twit('success', data)
                                        }).catch((error) => {
                                            twit('info', error)
                                        })
                                    }}>
                                        <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Save Currency
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div>
                        <p className=' text-muted mt-1' style={{ fontWeight: "bold", marginBottom: "0px" }}>Contacts</p>
                        <hr className='mt-0' />
                        <Form.Group  >
                            <Form.Label style={{ marginBottom: "0px" }}>Mobile No.</Form.Label>
                            <PhoneInput
                                country={'ug'}
                                value={contacts?.data?.contacts?.phone}
                                localization={{ ug: 'Uganda' }}
                                onChange={(e) => {


                                }}

                                disableDropdown
                                countryCodeEditable={false}
                                masks={{ ug: '... ... ...' }}

                                inputProps={{
                                    name: 'phone',
                                    required: true,

                                }}
                                disabled

                                inputStyle={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px"
                                }}

                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label id="twitter" style={{ marginBottom: "0px" }}>Email</Form.Label>
                            <Form.Control
                                name='twitter'
                                value={email === '' ? contacts?.data?.contacts?.email : email}
                                onChange={(e) => {

                                    if (e.target.value !== '') {
                                        setEmail(e.target.value);
                                    } else {
                                        setEmail(null);
                                    }

                                }}
                                disabled
                                className=' input-box-identity form-control-sm' type="text" id="twitter" placeholder="link" />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label id="linkedin" style={{ marginBottom: "0px" }}>LinkedIn</Form.Label>
                            <Form.Control
                                name='twitter'
                                value={socialMediaLinks.linkedIn === '' ? contacts?.data?.contacts?.linkedIn : socialMediaLinks.linkedIn}
                                onChange={(e) => {
                                    if (e.target.value !== '') {
                                        handleSocialsChange("linkedIn", e.target.value);
                                    } else {
                                        handleSocialsChange("linkedIn", null);
                                    }


                                }}

                                className=' input-box-identity form-control-sm' type="text" id="linked" placeholder=" link" />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label id="twitter" style={{ marginBottom: "0px" }}>Twitter</Form.Label>
                            <Form.Control
                                name='twitter'
                                value={socialMediaLinks.twitter === '' ? contacts?.data?.contacts?.twitter : socialMediaLinks.twitter}
                                onChange={(e) => {

                                    if (e.target.value !== '') {
                                        handleSocialsChange('twitter', e.target.value);
                                    } else {
                                        handleSocialsChange('twitter', null);
                                    }

                                }}

                                className=' input-box-identity form-control-sm' type="text" id="twitter" placeholder="link" />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label id="facebook" style={{ marginBottom: "0px" }}>Facebook</Form.Label>
                            <Form.Control
                                name='facebook'
                                value={socialMediaLinks.facebook === '' ? contacts?.data?.contacts?.facebook : socialMediaLinks.facebook}
                                onChange={(e) => {

                                    if (e.target.value !== '') {
                                        handleSocialsChange('facebook', e.target.value);
                                    } else {
                                        handleSocialsChange('facebook', null);
                                    }

                                }}

                                className=' input-box-identity form-control-sm' type="text" id="facebook" placeholder="link" />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label id="MembershipClass" style={{ marginBottom: "0px" }}>Instagram</Form.Label>
                            <Form.Control
                                name='instagram'
                                value={socialMediaLinks.instagram === '' ? contacts?.data?.contacts?.instagram : socialMediaLinks.instagram}
                                onChange={(e) => {

                                    if (e.target.value !== '') {
                                        handleSocialsChange('instagram', e.target.value);
                                    } else {
                                        handleSocialsChange('instagram', null);
                                    }
                                }}

                                className=' input-box-identity form-control-sm' type="text" id="instagram" placeholder="link" />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label id="youtube" style={{ marginBottom: "0px" }}>Youtube</Form.Label>
                            <Form.Control
                                name='youtube'
                                value={socialMediaLinks.youtube === '' ? contacts?.data?.contacts?.youtube : socialMediaLinks.youtube}
                                onChange={(e) => {

                                    if (e.target.value !== '') {
                                        handleSocialsChange('youtube', e.target.value);
                                    } else {
                                        handleSocialsChange('youtube', null);
                                    }
                                }}

                                className=' input-box-identity form-control-sm' type="text" id="youtube" placeholder="link" />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label id="website" style={{ marginBottom: "0px" }}>Website</Form.Label>
                            <Form.Control
                                name='website'
                                value={socialMediaLinks.website === '' ? contacts?.data?.contacts?.website : socialMediaLinks.website}
                                onChange={(e) => {

                                    if (e.target.value !== '') {
                                        handleSocialsChange('website', e.target.value);
                                    } else {
                                        handleSocialsChange('website', null);
                                    }

                                }}

                                className=' input-box-identity form-control-sm' type="text" id="cardNo" placeholder=" link" />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label id="website" style={{ marginBottom: "0px" }}>Location</Form.Label>
                            <Form.Control
                                name='location'
                                value={location === '' ? contacts?.data?.contacts?.location : location}
                                onChange={(e) => {

                                    if (e.target.value !== '') {
                                        setLocation(e.target.value);
                                    } else {
                                        setLocation(null);
                                    }

                                }}

                                className=' input-box-identity form-control-sm' type="text" id="cardNo" placeholder=" link" />
                        </Form.Group>

                        <div className='mt-1 mb-1' style={{ width: '100%', textAlign: 'center' }}>
                            {contactsChanged && (
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={() => {
                                    saveContacts()
                                }}>
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Save Changes
                                </Button>
                            )}



                        </div>
                    </div>








                </div>
            </Container>
        )
    }
}

export default GeneralSettings;
