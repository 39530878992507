import React from 'react'
import { useNavigate } from 'react-router-dom';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { Card, Col } from 'react-bootstrap';

const AssetOperationsMenu = () => {


    const navigate = useNavigate();


    const categories = [
        
        {
            title: 'Create Branch',
            icon: <MdIcons.MdOutlineCreate title='Create new Branch' style={{ height: "30px", width: "30px" }} />,
            clickFunction: ()=>{  navigate('/assets/operations/branchform')}
        },
        {
            title: 'Branches',
            icon: <AiIcons.AiOutlineBranches title='View your Branches' style={{ height: "30px", width: "30px" }} />,
            clickFunction: ()=>{  navigate('/assets/operations/branches')}
        },
        // {
        //     title: 'Departments',
        //     icon: <MdIcons.MdOutlineCreate title='View your event passes' style={{ height: "30px", width: "30px" }} />,
        //     clickFunction: ()=>{  navigate('/pass/event/passes')}
        // },
    ];




  return (
    <div>
     <Col>
                <div className='card-operations-row'>

                    {categories.map((category, index) => (
                        <Card key={index} className='document-card'
                            onClick={category.clickFunction}

                        >

                            <Card.Body style={{ textAlign: "center" }}>
                                <div>
                                    {category.icon}
                                </div>

                                <Card.Text>

                                    {category.title}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}

                </div>

            </Col> 
    </div>
  )
}

export default AssetOperationsMenu;
