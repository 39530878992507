import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

const ImagePreview = ({ selectedImage, placeholderMessage,size , imagelink, containerWidth='100%',containerHeight='100%',containerWidthMobile='100%',containerHeightMobile='100%'}) => {
  
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  
  const containerStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
  };

  const imageStyle = {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  };

  

  return (
    <div style={containerStyle}>
      {(selectedImage||imagelink )? (
        <Card style={{ width: '100%', height: '100%', borderRadius:0 }}>
          <Card.Img variant="top" src={selectedImage?URL.createObjectURL(selectedImage):imagelink} style={imageStyle} />
          {/* <Card.Body>
          </Card.Body> */}
        </Card>
      ) : (
        <Card style={{ width:(screenWidth <= 700)?containerWidthMobile:containerWidth , height: (screenWidth <= 700)?containerHeightMobile:containerHeight}}>
          <Card.Body>
            <div style={{display: "flex", flexDirection: "row", alignItems: 'center' , justifyContent:'center', width:'100%',height:'100%'}}>
               
               <div style={{display: "flex", flexDirection: "column", alignItems: 'center' , justifyContent:'center', width:'100%',textAlign:'center'}}>
               <p className='text-muted my-0'>{placeholderMessage}</p>
               <p className='text-muted my-0'>{`Format: (${size})`}</p>
               </div>
               
               
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default ImagePreview;
