import React from 'react'
import logo from './images/logo.jpg'
import { Carousel , Container, Card } from 'react-bootstrap';
import { useNavigate,Link } from 'react-router-dom';
const RegisterCarousel = () => {

const navigate = useNavigate()

const clickHandler = ()=>{
  navigate('/register')
}


  return (
    <div>
<Container className='carousel-container'>
<Carousel variant='dark'>
      <Carousel.Item className='carousel'>
        <Card className='border-0'>
          <Card.Img variant="top" src={logo} className="carousel-logo" />
          <Card.Body className='carousel-text'>
            <Card.Title>We are Pinnitags</Card.Title>
            <Card.Text>
              Enter a new age of smart Redirect
            </Card.Text>
          </Card.Body>
          
        </Card>
      </Carousel.Item>
      <Carousel.Item>
        <Card>
          <Card.Img variant="top" src={logo} className="carousel-logo" />
          <Card.Body className='carousel-text'>
            <Card.Title>Tag it smarter</Card.Title>
            <Card.Text>
              For authenticity Just Tag it
            </Card.Text>
          </Card.Body>
        </Card>
      </Carousel.Item>
      <Carousel.Item>
        <Card>
          <Card.Img variant="top" src={logo} className="carousel-logo" />
          <Card.Body className='carousel-text'>
            <Card.Title>Pinnitags</Card.Title>
            <Card.Text>
              You tag , we verify
            </Card.Text>
          </Card.Body>
        </Card>
      </Carousel.Item>
      
      
    </Carousel>
    <Link to='/form' className='link'>next</Link>
      
</Container>
    

    </div>
  )
}

export default RegisterCarousel
