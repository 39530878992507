import React from 'react';
import { Alert,Spin } from 'antd';


const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};
const content = <div style={contentStyle} />;
const ContainedLoader = ({message}) => (
  <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
    <Spin tip={message?message:''}>
      {/* <Alert
        message="Alert message title"
        description="Further details about the context of this alert."
        type="info"
      /> */}
    </Spin>
  </div>
);
export default ContainedLoader;