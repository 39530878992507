import React, { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import logo from './images/logo.jpg'
import pinnisoftLogo from './images/pinnisoft.jpg';
import { getNairobiTime, splitArrayIntoChunks } from '../Utilities/utilities';
import * as GiIcons from 'react-icons/gi';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';
import PinnitagsLogo from './images/logo.jpg';
import useFetch from '../hooks/hooks/getData';
import Loader from './Loader';
import PassTemplate2 from './PassTemplate2';
import PassTemplateBuganda from './PassTemplateBuganda';
import PassTemplateV2 from './PassTemplateV2';

const PassPage = ({ dataArray, pageNumber,passZones,eventDetails,allZones,sponsors,template }) => {

  const [loading, setLoading] = useState(false)
  const qrCodesPerRow = 2
  

  const passChunks = splitArrayIntoChunks(dataArray, 2)
  

  


  const generatePass = (data) => (
    <div  style={{ display:'flex',flexDirection:'row',justifyContent:'center', width:'100%'}}>

        {/* <PassTemplate2 passDetails={data} eventDetails={eventDetails} passZones={passZones} /> */}
        

        {template=='Template A'?
        <PassTemplateBuganda passDetails={data} eventDetails={eventDetails} passZones={passZones} allZones={allZones} sponsors={sponsors}/>
        :
        template=='Template B'?
        <PassTemplateV2 passDetails={data} eventDetails={eventDetails} passZones={passZones} allZones={allZones} sponsors={sponsors}/>
        :
        <PassTemplateBuganda passDetails={data} eventDetails={eventDetails} passZones={passZones} allZones={allZones} sponsors={sponsors}/>
        }
        
      
    </div>
  );
  if (loading) {
    return (<Loader />);
  } else {
    return (
      <div className="qrcode-container" style={{padding:'0px'}}>
        <div style={{ minHeight: '1000px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
          {( passChunks).map((chunk, rowIndex) => (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={rowIndex}>
              {/* {(rowIndex === 0 && (pageNumber + 1) === 1) && (
                <div
                  style={{

                    color: "black",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "4px",
                    width: '100%',
                    justifyContent: "space-between"

                  }}
                >
                  <div style={{ paddingLeft: "0%", paddingRight: "0%", backgroundColor: "white", maxWidth: "100%" }}>
                    <Image className="tag-Image" src={pinnisoftLogo} alt='Company logo' fluid />
                  </div>
                  <div className='billing-company' style={{ fontSize: '10px' }}>
                    <p><ImIcons.ImLocation2 />{'Plot 724/5, Mawanda Road,Theta House,Kamwokya,Kampala-Uganda'}</p>
                    <p><GiIcons.GiRotaryPhone /> +{'256 393 969 600'}</p>
                    <p><HiIcons.HiOutlineMailOpen /> {'info@pinnisoft.com'}</p>
                  </div>
                  <div style={{ maxWidth: "50%", display: "flex", flexDirection: "row", justifyContent: "end", backgroundColor: "white" }}>
                    <div style={{ paddingLeft: "40%", paddingRight: "0%", backgroundColor: "white", maxWidth: "80%" }}>
                      <Image src={PinnitagsLogo} alt='Pinnitags logo' fluid />
                    </div>

                  </div>
                </div>
              )} */}



              <Row className='my-0' style={{ width: '100%', paddingBottom: '0px', paddingTop: '0px' }} key={rowIndex}>
                {chunk.map((tag, colIndex) => (
                  <Col style={{padding:'0px',margin:'0px' }} key={colIndex} xs={12 / qrCodesPerRow} sm={12 / qrCodesPerRow} md={12 / qrCodesPerRow} >
                    {generatePass(tag)}
                  </Col>
                ))}
              </Row>
            </div>

          ))}
        </div>

        <div className='A4footer ' style={{ fontSize: "10px", display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <p className="text-muted my-0" style={{ fontSize: "10px", color: "white", width: '100%', textAlign: 'center' }}>
            Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a href="#" className="link">PinniSOFT</a>  <br />
            All rights reserved.
          </p>
          <p >{pageNumber + 1}</p>
        </div>
      </div>
    );
  }
};

export default PassPage;
