import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

const EventsManagementOutlet = () => {


  

  useEffect(() => {
    
    
    const storedItems = sessionStorage.getItem('recentlyVisited');



    if (storedItems) {
      const currentPage = window.location.pathname;
      const items = JSON.parse(storedItems);
      if (!items.includes(currentPage)) {
        sessionStorage.setItem('recentlyVisited', JSON.stringify([currentPage, ...items.slice(0, 9)]));
      }
    }else{
      const currentPage = window.location.pathname;
      sessionStorage.setItem('recentlyVisited', JSON.stringify([currentPage]));
    }

    

    
    
  }, []);




  return (
    <div>
      <Outlet />
    </div>
  )
}

export default EventsManagementOutlet;
