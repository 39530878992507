import axios from '../api/axios';


const usePriority = async() => {
    const  PriorityUrl = '/api/priorities'


        try {
          const response = await axios.get(PriorityUrl);
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default usePriority;