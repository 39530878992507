import React from 'react';
import Card from 'react-bootstrap/Card';
import { getformattedDate, getformattedDate2, parseISODate } from '../Utilities/utilities';

const EventCard = ({ eventName, eventDate, endDate ,eventDescription, eventVenue, imageUrl , location,eventNumber}) => {

    const eventDateInfo = parseISODate(eventDate)

  return (
    <Card style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',width: '100%',fontFamily:''}}>
      <Card.Img variant="top" style={{}} src={imageUrl} />
      <Card.Body style={{textAlign:'start',width:'100%'}}>
        <Card.Subtitle className='mb-2 text-muted '> {eventNumber}</Card.Subtitle>
        <Card.Text style={{fontSize:'12px'}} className="my-1 ">{eventName}</Card.Text>
        <Card.Text style={{fontSize:'9px'}} className="mb-2 text-muted ">{eventDescription}</Card.Text>
        {/* <Card.Subtitle className="mb-2 text-muted">TIME: {`${eventDateInfo.hours-3}:${eventDateInfo.minutes}`}</Card.Subtitle> */}
        
        <div className=" text-muted" style={{display:'flex',flexDirection:'row',justifyContent:'space-between', fontSize:'12px'}}>
        <Card.Text style={{fontSize:'10px'}} className='my-0'>{eventVenue}</Card.Text>
        <Card.Text style={{fontSize:'10px'}} className='my-0'> {location}</Card.Text>
        
        </div>
        <div className='text-muted' style={{display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:'12px'}}>
        <Card.Text style={{fontSize:'10px'}} className='my-0'>{`${getformattedDate2(eventDate).split(' ')[0]} ${getformattedDate2(eventDate).split(' ')[1]} ${getformattedDate2(eventDate).split(' ')[5]} ${getformattedDate2(eventDate).split(' ')[3]}, ${getformattedDate2(eventDate).split(' ')[2]}`}</Card.Text>
        {endDate&&(
          <Card.Text style={{fontSize:'10px'}} className='my-0'>{`${getformattedDate2(endDate).split(' ')[0]} ${getformattedDate2(endDate).split(' ')[1]} ${getformattedDate2(endDate).split(' ')[5]} ${getformattedDate2(endDate).split(' ')[3]}, ${getformattedDate2(endDate).split(' ')[2]}  `}</Card.Text>
        )}
        
        </div>

        {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
        <Card.Text className='my-0'>{`${getformattedDate2(eventDate).split(' ')[3]}`}</Card.Text>
        <Card.Text className='my-0'>{`${getformattedDate2(endDate).split(' ')[0]} ${getformattedDate2(endDate).split(' ')[1]} ${getformattedDate2(endDate).split(' ')[5]} ${getformattedDate2(endDate).split(' ')[3]}, ${getformattedDate2(eventDate).split(' ')[2]}  `}</Card.Text>
        <Card.Text className='my-0'>{`${getformattedDate2(endDate).split(' ')[3]} `}</Card.Text>
        </div> */}
        
      </Card.Body>
    </Card>
  );
};

export default EventCard;
