import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { twit } from "../Notificationpopout";
import Loader from "../Loader";
import LoaderButton from "../LoaderButton";
import axios from "../../api/axios";
import QRCodePage from "../tagPageA4";
import Modal from "antd/es/modal/Modal";
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import { Card, Form, Container } from "react-bootstrap";
import { downloadPDF, generateLabelNumber, splitArrayIntoChunks } from "../../Utilities/utilities";
import TextTyper from 'text-type-animation-effect-react';
import io from 'socket.io-client';
import LabelPage from "../tagLabels";
import { apiBaseUrl } from "../../Utilities/globals";




const OrderTags = () => {
    const [loading, setLoading] = useState(false);
    const [testTags, setTestTags] = useState([])
    const [tagNumber, setTagNumber] = useState(0)
    const [tagType, setTagType] = useState()
    const [tagsModal, setTagsModal] = useState(false)
    const [tagNumberModal, setTagNumberModal] = useState(false)
    const [page1data, setPage1data] = useState([])
    const [socket, setSocket] = useState(null);
    const [numberCreated, setNumberCreated] = useState(0);
    const [pageFormat, setPageFormat] = useState()
    const [tagsData, setTagsData] = useState([])
    const [clientData, setClientData] = useState()

    const date = new Date()
    const navigate = useNavigate()
    const token = sessionStorage.getItem('token');
    const eid = 'CE4650EA-F9D8-4B83-9C57-784B3EAC8383'


    const getTags = async (codeType, tagNo) => {
        try {
            setLoading(true)
            // const response = await axios.post(codeType === 1 ? '/api/documents/qrcodes' : codeType === 2 ? '/api/documents/pdf417s' : codeType === 3 ? '/api/documents/barcodes' :codeType === 4 ? '/api/documents/tags/datamatrix/18':codeType === 5 ?'/api/documents/tags/datamatrixrectangular/10':'/api/documents/tags/azteccode/20', JSON.stringify({ codeNo: tagNo }), {
            //     headers: {
            //         Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'
            //     }

            // });

            //print pass
            const response = await axios.post(codeType === 1 ? '/api/documents/qrcodes' : codeType === 2 ? '/api/documents/event/passes' : codeType === 3 ? '/api/documents/barcodes' :codeType === 4 ? '/api/documents/tags/datamatrix/18':codeType === 5 ?'/api/documents/tags/datamatrixrectangular/10':'/api/documents/tags/azteccode/20', JSON.stringify({ codeNo: tagNo,eid }), {
                headers: {
                    Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'
                }

            });

            setLoading(false)
            if (response.status === 201) {

                setClientData(response?.data.clientData)
                return Promise.resolve(response?.data.generatedTags)


            }

        } catch (err) {
            setLoading(false)
            console.log(err)
            return Promise.reject(err)
        }
    }


    useEffect(() => {
        if (tagsModal) {
            if (pageFormat == 1) {
                downloadPDF('page', () => { }, 'bulkTags')
            } else if (pageFormat == 2) {
                downloadPDF('page', () => { }, 'bulkTags', [1, 4, 6].includes(tagType)? [28, 54] : [51, 27], [1, 4, 6].includes(tagType) ? null : 'landscape')
            } else if (pageFormat == 3) {
                downloadPDF('page', () => { }, 'bulkTags',  [110, 26], 'landscape')
            }
        }
    }, [tagsModal])

    useEffect(() => {

        const newSocket = io(apiBaseUrl,{transports: ['websocket', 'polling']});
        setSocket(newSocket);

    }, []);


    if (socket) {


        socket.on('TagCreated', (data) => {

            setNumberCreated(data.tags)

        });
    }

    const docFormat = [{ name: 'A4', id: 1 }, { name: '51mm*25mm', id: 2 },{ name: 'Brother(25mm*100mm)', id: 3 }]


    const tagsRow1 = [
        { name: "QRCODE", icon: <BsIcons.BsQrCode title='Generate qrcodes' style={{ height: "30px", width: "30px" }} />, type: 1 },
        { name: "BARCODE", icon: <FaIcons.FaBarcode title='Generate barcodes' style={{ height: "30px", width: "30px" }} />, type: 3 },
        { name: "PDF417", icon: <AiIcons.AiOutlineBarcode title='Generate pdf417s' style={{ height: "30px", width: "30px" }} />, type: 2 },
        { name: "DATAMATRIX", icon: <AiIcons.AiOutlineBarcode title='Generate datamatrix' style={{ height: "30px", width: "30px" }} />, type: 4 },
      

    ]

    const tagsRow2 = [
        { name: "DATAMATRIX (rectangular)", icon: <AiIcons.AiOutlineBarcode title='Generate datamatrix' style={{ height: "30px", width: "30px" }} />, type: 5 },
        { name: "AZTEC", icon: <AiIcons.AiOutlineBarcode title='Generate aztec code' style={{ height: "30px", width: "30px" }} />, type: 6}

    ]


    if (loading) {
        return (<Loader message={`Created ${numberCreated} of ${tagNumber}`} />)

    } else {
        return (

            <Container className='invoice-create-container' >
                <div style={{ marginBottom: "20px", paddingLeft: "10px" }}>
                    <p style={{ fontSize: "12px" }}> <MdIcons.MdDocumentScanner onClick={() => { navigate('/documents') }} style={{ height: "16px", width: "16px" }} /> /tags</p>
                </div>

                <Card style={{ fontSize: "12px" }} >
                    <Card.Header style={{ height: "40px" }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold" }}> <IoIcons.IoIosAdd style={{ width: '25px', height: '25px' }} /> Create new Tags</p>
                    </Card.Header>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', height: '10vh' }}>
                            <TextTyper text={"Select the type of tags to generate."} interval={50} Markup={"p"} />
                        </div>
                        <div >
                        <div className="tag-types">
                            {tagsRow1.map((tag, index) => (
                                <Card key={index} className='document-card'
                                    onClick={() => {
                                        setTagType(tag.type)
                                        setTagNumberModal(true)
                                    }}


                                >

                                    <Card.Body style={{ textAlign: "center" }}>
                                        <div>
                                            {tag.icon}
                                        </div>

                                        <Card.Text>

                                            {tag.name}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))}

                        </div>
                        
                        <div className="tag-types">
                            {tagsRow2.map((tag, index) => (
                                <Card key={index} className='document-card'
                                    onClick={() => {
                                        setTagType(tag.type)
                                        setTagNumberModal(true)
                                    }}


                                >

                                    <Card.Body style={{ textAlign: "center" }}>
                                        <div>
                                            {tag.icon}
                                        </div>

                                        <Card.Text>

                                            {tag.name}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))}

                        </div>



                        </div>
                        



                        <Modal open={tagsModal} title={'Powered by PinniTAGS'} onCancel={() => {
                            setTagsModal(false)
                        }}
                            footer={[


                            ]}
                            style={{}}
                            closable={true}
                            className='allmodals pageA4'

                        >

                            {pageFormat == 1 && (
                                <div id="page">
                                    <QRCodePage pageNumber={0} page1Data={page1data} />
                                    {testTags?.map((chunk, index) => (
                                        <div key={index}>
                                            <QRCodePage dataArray={chunk} pageNumber={(index + 1)} />
                                        </div>

                                    ))}

                                </div>
                            )}

                            {pageFormat == 2 && (
                                <div id="page">

                                    {tagsData.map((data, index) => (
                                        <div className=" html2pdf__page-break" key={index}>
                                            <LabelPage clientData={clientData} labelData={data} labelType={tagType} tagNumber={generateLabelNumber(date.toISOString(), (index + 1))} />
                                        </div>

                                    ))}

                                </div>
                            )}

                            {pageFormat == 3 && (
                                <div id="page">

                                    {tagsData.map((data, index) => (
                                        <div  className=" html2pdf__page-break" key={index}>
                                            <LabelPage clientData={clientData} labelData={data} labelType={tagType} tagNumber={generateLabelNumber(date.toISOString(), (index + 1))} labelFormat={pageFormat} />
                                           </div>

                                    ))}

                                </div>
                            )}

                            




                            <div>
                                <LoaderButton onClick={() => {
                                    if (pageFormat == 1) {
                                        downloadPDF('page', () => { }, 'bulkTags')
                                    } else if (pageFormat == 2) {
                                        downloadPDF('page', () => { }, 'bulkTags', [1, 4, 6].includes(tagType)? [28, 54] : [51, 27], [1, 4, 6].includes(tagType) ? null : 'landscape')
                                    } else if (pageFormat == 3) {
                                        downloadPDF('page', () => { }, 'bulkTags',  [110, 26], 'landscape')
                                    }

                                }}
                                    loading={loading}
                                    variant={"primary"}
                                    label={'Download'}
                                    style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                                    className={'mx-1'}
                                />

                            </div>


                        </Modal>

                        <Modal open={tagNumberModal} title={'Number of tags(10-850)'} onCancel={() => {
                            setTagNumberModal(false)
                        }}
                            footer={[


                            ]}
                            style={{}}
                            closable={false}
                            className='allmodals'

                        >
                            <div>
                                <Form.Group >
                                    <Form.Control
                                        className=' input-box-invoice-form form-control-sm'
                                        type="text"
                                        name="tagNo"
                                        value={tagNumber}
                                        placeholder="Enter number of tags"
                                        onChange={(e) => setTagNumber(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group style={{ marginBottom: "14px" }} >


                                    <Form.Select


                                        onChange={(e) => {
                                            setPageFormat(e.target.value);
                                            
                                        }}

                                        className='form-selector'>
                                        <option key={1} >Select Document format</option>
                                        {docFormat?.map((data, index) => {
                                            return <option className='input-box' key={index} value={data.id}>{data.name}</option>
                                        })}

                                    </Form.Select>

                                </Form.Group>


                                <div>
                                    {!(tagNumber < 1) && (tagNumber > 9) && (tagNumber < 851) && (pageFormat) && (
                                        <LoaderButton onClick={() => {
                                            getTags(tagType, tagNumber).then((data) => {
                                                const arraySize = data?.length
                                                const page1size =[1, 4, 6].includes(tagType) ? 25 : 45;
                                                const nextPageSize = [1, 4, 6].includes(tagType)? 30 : 50
                                                const firstChunk = data?.slice(0, page1size);
                                                const lastChunk = data?.slice(page1size, arraySize);

                                                setTagsData(data)
                                                setTestTags(splitArrayIntoChunks(lastChunk, nextPageSize))
                                                setPage1data(firstChunk)
                                                setTagNumberModal(false)
                                                setTagNumber(0)
                                                setTagsModal(true)

                                            }).catch((error) => {
                                                console.log('tags Error', error)
                                                twit('info', 'Failed to load tags.')
                                            })
                                        }}
                                            loading={loading}
                                            variant={"primary"}
                                            label={'Generate'}
                                            style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                                            className={'mx-1'}
                                        />

                                    )}

                                </div>

                            </div>


                        </Modal>

                    </div>

                </Card>




            </Container>






        )
    }



}

export default OrderTags;









