import React, { useState } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as IoIcons from 'react-icons/io';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import MyDocuments from "./MyDocument";
import DocumentsApi from "./DocumentsApi";

const Documents = ({ origin }) => {

    const navigate = useNavigate()
    const [dash, setDash] = useState(() => {
        if (origin === 'mydocuments') {
            return false;
        } else if (origin === "dash") {
            return true;
        } else if (origin === "operations") {
            return false;
        } else {
            return true;
        }
    });
    const [mydocuments, setMydocuments] = useState(() => {
        if (origin === 'mydocuments') {
            return true;
        } else if (origin === "dash") {
            return false;
        } else if (origin === "operations") {
            return false;
        } else {
            return false;
        }
    });
    const [operations, setOperations] = useState(() => {
        if (origin === 'mydocuments') {
            return false;
        } else if (origin === "dash") {
            return false;
        } else if (origin === "operations") {
            return true;
        } else {
            return false;
        }
    });

    const selectDash = () => {
        setDash(true);
        setMydocuments();
        setOperations();

    };
    const selectMydocuments = () => {
        setDash();
        setOperations();
        setMydocuments(true);

    };

    const selectOperations = () => {
        setDash();
        setMydocuments();
        setOperations(true);

    };




    return (
        <Container className="meetingsdash-container">

            <Card>
                <Card.Header>
                    <Nav className="custom-nav-tabs" variant="tabs" defaultActiveKey={() => {
                        if (origin === 'mydocuments') {
                            return ("mydocuments");
                        } else if (origin === "dash") {
                            return ("dashboard");
                        } else if (origin === "operations") {
                            return ("operations");
                        } else {
                            return ("dashboard")
                        }
                    }}>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="dashboard" onClick={selectDash}>
                                <MdIcons.MdOutlineDashboardCustomize style={{ height: "18px", width: "18px" }} />
                                &nbsp;
                                Dashboard
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="mydocuments" onClick={selectMydocuments}>
                                <MdIcons.MdDocumentScanner style={{ height: "18px", width: "18px" }} />
                                &nbsp;
                                My Documents
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="operations" onClick={selectOperations}>
                                <HiIcons.HiDocumentSearch style={{ height: "18px", width: "18px" }} />
                                &nbsp;
                                API
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {dash && <p>//Dashboard content will appear here</p>}
                    {mydocuments && <MyDocuments />}
                    {operations && <DocumentsApi/>}
                </Card.Body>
            </Card>

        </Container>

    );
}

export default Documents;

