import React from 'react';
import { Card } from 'react-bootstrap';
import moment from "moment";
import { DateTime } from "luxon";
const ChatBubble = ({ message, isMine, date }) => {
  const formatted = DateTime
    .fromISO(date, { zone: "utc" }).minus({ hours: 3 })
    .toRelative()


  const time = DateTime.now()
 
  
  return (
    <div className={isMine ? 'my-bubble' : 'their-bubble'} >
      <Card className={isMine ? 'my-card' : 'their-card'} >
        <Card.Body style={{ maxWidth: "300px", fontSize: "8px", paddingBottom: "0px" }}>

          <p style={{ fontWeight: "bold", fontSize: "10px", marginBottom: "2px " }}>{message}</p>
          <p style={{ marginTop: "0px" }}>{formatted} </p>

        </Card.Body>
      </Card>
    </div>
  );
};

export default ChatBubble;
