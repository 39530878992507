import {  Card } from 'react-bootstrap';

const Card4=()=> {
    return (
      <Card className='dash-card'>
        <Card.Body>
          <Card.Title>Card 4</Card.Title>
          <Card.Text>This is the content of Card 4</Card.Text>
        </Card.Body>
      </Card>
    );
  }
  
  export default Card4;