import React, { useRef } from 'react';
import { Table, Card, Container,  Form, Col, Row } from 'react-bootstrap';
import { Modal, Button } from 'antd';
import { useState } from 'react';
import useCategory from '../hooks/getCategory';
import usePriority from '../hooks/getPriority';
import useTickets from '../hooks/getTickets';
import useAuth from '../hooks/useAuth';
import { useEffect } from 'react';
import Loader from './Loader';
import axios from '../api/axios';
import ColoredD from './ColorSport';
import Pagination from './Pagination';
import io from 'socket.io-client';
import ChatBubble from './Chatbubble';
import { GrAttachment } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import * as IoIcons from 'react-icons/io';
import Cookies from 'js-cookie';
import useFetch from '../hooks/hooks/getData';
import { twit } from './Notificationpopout';
import { apiBaseUrl } from '../Utilities/globals';

const Ticket = () => {

    const [createForm, setCreateForm] = useState(false);
    const [subject, setSubject] = useState('');
    const [category, setCategory] = useState('');
    const [priority, setPriority] = useState('');
    const [description, setDescription] = useState('');

    const [priorityData, setPrioritydata] = useState()
    const [categoryData, setCategorydata] = useState()
    const [loading, setLoading] = useState(false)
    const [inputError, setInputError] = useState()
    const [successMsg, setSuccessMsg] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [ticketdata, setTicketdata] = useState()
    const [switcher, setSwitcher] = useState(false)
    const [rowdata, setRowdata] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [postsPerPage, setPostPerPage] = useState(5);
    const [imageString, setImageString] = useState(5);
    const [messages, setMessages] = useState();
    const [messageInput, setMessageInput] = useState('');
    const [socket, setSocket] = useState(null);
    const [message, setMessage] = useState('');
    const [attachments, setAttachments] = useState();
    const [refresh,setRefresh] = useState(false);





    const usecategory = useCategory()
    const usepriority = usePriority()
    const usetickets = useTickets()
    const rid = "30378AEA-C046-405B-BCB8-3E8A9A42E9C2"
    const token = sessionStorage.getItem('token')
    const myDivRef = useRef(null);
    const { noti, setNoti } = useAuth();


    useEffect(() => {
        // create Socket.io instance and connect to server

        const newSocket = io(apiBaseUrl,{transports: ['websocket', 'polling']});
        setSocket(newSocket);
        // clean up function to disconnect Socket.io instance when component unmounts
        // return () => newSocket.disconnect();
    }, []);





    useEffect(() => {
        if (socket) {
            // listen for 'message' event from server and update state with new message
            socket.on('message', (datax) => {
                
                const tid = sessionStorage.getItem('tid')
                getMessages(tid)
                


                // setMessages(prevMessages => [...prevMessages, message]);
            });
        }
    }, [socket]);

    const handleMessageSubmit = async (e, tid) => {
        e.preventDefault();
        
        // socket.emit('message', {
        //              message: message,
        //              tid: tid, 
        //             tsid:'0FF48E4A-A6D7-4B07-8EA7-BDB0834C8610'
        //          });

        try {
            //setLoading(true)
            const response = await axios.post('/api/tickets/chat',
                JSON.stringify({
                    message: message,
                    tid: tid,
                    tsid: '0FF48E4A-A6D7-4B07-8EA7-BDB0834C8610'
                }),
                {
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                    }

                }
            )
            if (response?.request?.status === 201) {
                // setLoading(false);
                getMessages(tid);
                setMessage('');
            }
            



            //emit 'ticketChat' event to server with message input value


        } catch (error) {
            setLoading(false)
            setErrorMsg(error)
        }



        // clear message input value
        setMessageInput('');
    };

    useEffect(() => {
        if (myDivRef.current) {
            myDivRef.current.scrollTop = myDivRef.current.scrollHeight;
        }

    }, [messages]);

    useEffect(() => {

        usecategory.then((data) => {
            
            setCategorydata(data)
        })

        usepriority.then((data) => {
            
            setPrioritydata(data)
        })

        // usetickets.then((data) => {
        //     console.log(data);
        //   setTicketdata(data)


        // })







    }







        , [switcher])

    //get ticket data       

    const { data, error } = useFetch("/api/tickets/user", setLoading,refresh)

    useEffect(() => {
        
        setTicketdata(data?.data);
    }, [data]);






    const openTickets = ticketdata?.filter((tickets) => {
        return tickets?.TicketProcesses[0]?.TStatus?.sname !== 'Closed'
    })

    const closedTickets = ticketdata?.filter((tickets) => {
        return tickets?.TicketProcesses[0]?.TStatus?.sname === 'Closed'

    })

    const handleCreate = () => {
        setCreateForm(true)
    }

    const handleCancel = () => {
        setCreateForm(false)
        setSuccessMsg(false)

    }
    const handleCancelerror = () => {
        setErrorMsg(false);
        setRowdata();
    }

    const handleAttachmentClose = () => {
        setAttachments();
    }


    const getAttachments = () => {
        setAttachments(true);
    }


    const handleFileChange = (event) => {


        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const result = reader.result;
            const base64 = result.split(',')[1];
            setImageString(base64);
        };

        reader.readAsDataURL(file);
        
    };

    const getMessages = async (tid) => {
        try {
            const response = await axios.get(`/api/tickets/chat/${tid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }


            });

            
            setMessages(response.data.messages)
            sessionStorage.setItem("tid", tid);
        } catch (err) {
            console.log(err)
        }
    }

    const CreateUrl = '/api/tickets'
    const payload = { cid: category, pid: priority, sub: subject, rid: rid, description: description }
    const createTicket = async () => {
        setLoading(true)
        try {
            const response = await axios.post(CreateUrl,
                JSON.stringify(payload),
                {
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                    }

                }

            );
            if (response.request.status === 201) {
                setLoading(false)
                setCreateForm(false)
                setCategory('')
                setDescription('')
                setSubject('')
                setPriority('')
                setSwitcher(!switcher)
                twit("success", response.data.message);
                
                setRefresh(!refresh);

            }
            
            
        } catch (err) {
            setLoading(false)

            if (!err?.response) {
                twit("error", 'No Server Response');
            } else if (err.response?.status === 400) {
                twit("info", err.response.data.message);
            } else if (err.response?.status === 401) {
                twit("info", err.response.data.message);
            } else {
                twit("error", err.response.data.message);
            }
            
        }

    }



    const handleSubmit = (event) => {
        event.preventDefault();
        if (subject === '' || description === '' || subject.length > 25) {
            if (description === '') {
                twit("info", 'Please provide a description.')
            } else {
                if (subject.length > 25) {
                    twit("info", 'Subject is max 25 characters.');
                } else {
                    twit("info", 'please input a subject');
                }

            }

        } else {
            createTicket()
        }
    };

    if (rowdata) {
        
    }





    const paginate = (number) => {
        setCurrentPage(number);

    }

    const paginate2 = (number) => {
        setCurrentPage2(number);

    }

    const handleTicketClose = async (tid, tsid) => {
        try {
            setLoading(true);
            const response = await axios.patch(`/api/tickets/ticket/${tid}`, JSON.stringify({ tsid: 'D9A6ADE0-FDE8-4621-91D1-198C02F5412E' }), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.data.message === 'Ticket updated successfully') {
                setLoading(false);
                setRowdata()
                twit("success", response.data.message);
                setSwitcher(!switcher);
            }
            
        } catch (err) {
            setLoading(false)

            if (!err?.response) {
                twit("error", 'No Server Response.');
            } else if (err.response?.status === 400) {
                twit("info", err.response.data.message);
            } else if (err.response?.status === 401) {
                twit("info", err.response.data.message);
            } else {
                twit("error", err.response.data.message);
            }
        }
    }

    const handleClick = async (tid) => {
        try {

            const response = await axios.patch(`/api/tickets/notification/${tid}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }



            });


            //setSwitcher(!switcher);
            setNoti(!noti);




        } catch (err) {
            console.log(err)
        }
    }


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentOpenTickets = openTickets?.slice(indexOfFirstPost, indexOfLastPost)
    // if(currentOpenTickets?.length<5){
    //     const remainder = 5-(currentOpenTickets.length);
    //     for(let r=0;r<remainder;r++){
    //         currentOpenTickets.push({});
    //     }
    // }
    
    
    

    const indexOfLastPost2 = currentPage2 * postsPerPage;
    const indexOfFirstPost2 = indexOfLastPost2 - postsPerPage;
    const currentClosedTickets = closedTickets?.slice(indexOfFirstPost2, indexOfLastPost2)
    

    const navigate = useNavigate()
    const loggedin = Cookies.get('loggedin');


    if (!loggedin) {

        navigate('/');
        return null;
    } else {



        if (loading) {
            return (<Loader />);
        } else {



            return (
                <Container className='ticket-Container'>
                    {createForm && <Modal open={true} title={'Ticket Form'} onCancel={handleCancel} onOk={handleCancel}
                        footer={[


                        ]}
                        className="allmodals"
                    >










                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formSubject">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control
                                    className=' input-box-ticket form-control-sm'
                                    type="text"
                                    placeholder="Enter subject"
                                    value={subject}
                                    onChange={(event) => setSubject(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formCategory">
                                <Form.Label>Category</Form.Label>
                                <Form.Select
                                    className=' input-box-ticket form-control-sm'
                                    as="select"
                                    value={category}
                                    onChange={(event) => setCategory(event.target.value)}
                                >
                                    <option>select category</option>
                                    {categoryData?.map((data) => {
                                        return <option className='input-box' key={data.cid} value={data.cid}>{data.cname}</option>
                                    })}

                                </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="formPriority">
                                <Form.Label>Priority</Form.Label>
                                <Form.Select
                                    className=' input-box-ticket form-control-sm'
                                    as="select"
                                    value={priority}
                                    onChange={(event) => setPriority(event.target.value)}
                                >
                                    <option>select priority</option>
                                    {priorityData?.map((data) => {
                                        return <option className='input-box' key={data.pid} value={data.pid}>{data.pname}</option>
                                    })}

                                </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="formDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    className=' input-box-ticket form-control-sm'
                                    as="textarea"
                                    placeholder="Enter description"
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                />

                            </Form.Group>
                            <Form.Group controlId="formImage">
                                <Form.Label>Attach image (optional)</Form.Label>
                                <Form.Control
                                    className=' input-box-ticket form-control-sm'

                                    type="file" onChange={handleFileChange} />
                            </Form.Group>
                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' className='ticket-button'>
                                Create
                            </Button>
                        </Form>





                    </Modal>}
                    {successMsg && <Modal open={true} title={'Success'} onCancel={handleCancel} onOk={handleCancel}
                        footer={[


                        ]}
                    >
                        <ColoredD color="green">{successMsg}</ColoredD>

                    </Modal>}


                    {errorMsg && <Modal open={true} title={'Failed'} onCancel={handleCancelerror} onOk={handleCancelerror}
                        footer={[


                        ]}
                    >
                        <ColoredD color="red">{errorMsg}</ColoredD>

                    </Modal>}

                    {attachments && <Modal open={true} title={'Attachments'} onCancel={handleAttachmentClose} onOk={handleAttachmentClose}
                        footer={[


                        ]}
                        className="allmodals"

                    >
                        <ColoredD color="green">Your Attachments appear here</ColoredD>

                    </Modal>}

                    {rowdata && <Modal open={true} onCancel={handleCancelerror} onOk={handleCancelerror}


                        footer={[


                        ]}

                        className="allmodals"
                    >
                        <div className='ticket-popup-header'>


                            <p> <span>#Ticket ID :</span> {rowdata.tickid}</p>


                            <p ><span style={{ color: 'black' }}> Priority :</span> {rowdata.Tpriority.pname}</p>

                            <p style={{ color: rowdata?.TicketProcesses[0]?.TStatus?.scolor }}><span style={{ color: 'black' }}> Status :</span> {rowdata?.TicketProcesses[0]?.TStatus?.sname}</p>

                            {/* <div style={{ display: 'flex', flexDirection: "row",  }} >
                            <span > Status :</span> &nbsp;
                            <ColoredD color=>
                            </ColoredD>
                        </div> */}
                            <div style={{ marginRight: "18px", paddingTop: "4px" }}>
                                <p style={{ paddingTop: "0px", fontSize: '10px' }}> <GrAttachment onClick={getAttachments} /> Attachments </p>
                            </div>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <p style={{ paddingTop: "8px", fontSize: "11px" }}><span style={{ fontWeight: 'bold', color: "Black", fontSize: '12px' }}>Subject :</span> {rowdata?.sub}</p>
                            {/* <p style={{paddingTop:"8px",fontSize:'10px' }}> <GrAttachment  /> View Attachments </p> */}
                            {/* <Button  onClick={getAttachments} style={{marginTop:"5px",fontSize:'8px',height:"40px" }} variant="success" type="button" >
                    <GrAttachment  />Attachments
                        </Button> */}
                        </div>

                        <div ref={myDivRef} className='message-area-tickets'>
                            {messages && (messages?.map((message, index) => {
                                return (
                                    <Row key={index} className='message-row'>
                                        {message?.TStatus.sname === "New" && (<ChatBubble date={message?.ttdate} message={message?.cmt} isMine={true} />)}
                                        {message?.TStatus.sname !== "New" && (<ChatBubble date={message?.ttdate} message={message?.cmt} isMine={false} />)}



                                        {/* <Col sm={2} className='username-column'>
                                        <div>Username:</div>
                                    </Col>
                                    <Col sm={10} className='chat-column'>
                                        <div className="chat-box">


                                            <Form.Group>

                                                <Form.Control disabled className='input-box-view form-control-sm' type="text " value={message?.cmt} placeholder="Type a message" />
                                            </Form.Group>

                                        </div>
                                    </Col> */}
                                    </Row>
                                )
                            }))}
                        </div>



                        <Form onSubmit={(e) => {
                            handleMessageSubmit(e, rowdata.tid)
                        }}>
                            {rowdata?.TicketProcesses[0]?.TStatus?.sname !== 'Closed' && (
                                <div style={{ display: "flex", flexDirection: 'row', justifyContent: "center" }}>
                                    <Form.Group>
                                        <Form.Control   onChange={(e) => { setMessage(e.target.value) }} className='input-box-view ticket-input form-control-sm' type="text " value={message} placeholder="Type new message" />
                                    </Form.Group>
                                    <Button variant="default" type="submit" className='ticket-button' >
                                        <IoIcons.IoMdSend style={{ height: "20px", width: "20px", color: "#2a7824" }} />
                                    </Button>
                                </div>

                            )}


                        </Form>



                        {rowdata?.TicketProcesses[0]?.TStatus?.sname !== 'Closed' && (

                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} type="primary" variant="primary"  className='ticket-button' onClick={() => { handleTicketClose(rowdata.tid, rowdata.TicketProcesses[0]?.tsid) }}>
                                Close Ticket
                            </Button>
                        )}

                    </Modal>

                    }



                    <Card>
                        <Card.Header className='tickets-header'>
                            <h6>My Tickets</h6>
                            <div>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} type="primary" variant="primary"   className='ticket-button'>Knowledge Base</Button>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} type="primary" variant="primary"  className='ticket-button' onClick={handleCreate}>Create Ticket</Button>

                            </div>
                        </Card.Header>
                        <Card.Body className='table-responsive'>
                            <div className='in-progress'>
                                <p style={{ fontWeight: "bold", color: "blue" }}>Open Tickets</p>
                                <div className='statuses'>
                                    <ColoredD color="Red">New</ColoredD>
                                    <ColoredD color="yellow">In Progress</ColoredD>
                                    <ColoredD color="purple">In review</ColoredD>
                                    <ColoredD color="black">Completed</ColoredD>
                                    <ColoredD color="green">Closed</ColoredD>



                                </div>


                            </div>

                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Ticket ID #</th>
                                        <th>Priority</th>
                                        <th>Subject</th>
                                        <th> Date Created</th>
                                        <th>Status</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {currentOpenTickets?.length === 0 && (
                                        <p>No open tickets.</p>
                                    )}
                                    {currentOpenTickets?.map((ticket) => (

                                        <tr key={ticket.tickid} onClick={() => {
                                            getMessages(ticket.tid)
                                            setRowdata(ticket)
                                            handleClick(ticket.tid)

                                        }} >

                                            <td>{ticket.tickid}</td>
                                            <td style={{ color: ticket.Tpriority?.pcolor }}>{ticket.Tpriority.pname}</td>
                                            <td>{ticket.sub}</td>
                                            <td>{ticket?.TicketProcesses[0]?.tdate
                                            }</td>
                                            <td><ColoredD color={ticket?.TicketProcesses[0]?.TStatus?.scolor}>{ticket?.TicketProcesses[0]?.TStatus?.sname}</ColoredD></td>
                                            <td>
                                                {/* <Button variant='default' className='view-ticket-button' onClick={() => { setRowdata(ticket) }}>view</Button> */}
                                                <p style={{ color: 'blue' }}>view</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={openTickets?.length}
                                paginate={paginate}

                            />
                            <p style={{ fontWeight: "bold", color: "green" }}>Closed Tickets</p>


                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th> Ticket ID #</th>
                                        <th>Priority</th>
                                        <th>Subject</th>
                                        <th> Date Closed</th>
                                        <th>Status</th>
                                        <th></th>


                                    </tr>
                                </thead>
                                <tbody>

                                    {currentClosedTickets?.length === 0 && (
                                        <p>No closed tickets.</p>
                                    )}
                                    {currentClosedTickets?.map((ticket) => (
                                        <tr key={ticket.tickid} onClick={() => { setRowdata(ticket) }} >
                                            <td>{ticket.tickid}</td>
                                            <td style={{ color: ticket.Tpriority.pcolor }}>{ticket.Tpriority.pname}</td>
                                            <td>{ticket.sub}</td>
                                            <td>{ticket.TicketProcesses[0].tdate
                                            }</td>
                                            <td><ColoredD color={ticket.TicketProcesses[0].TStatus.scolor}>{ticket.TicketProcesses[0].TStatus.sname}</ColoredD></td>
                                            <td>
                                                {/* <Button variant='default'  type='link' className='view-ticket-button' onClick={() => { setRowdata(ticket) }}></Button> */}
                                                <p style={{ color: 'blue' }}>view</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={closedTickets?.length}
                                paginate={paginate2}

                            />
                        </Card.Body>
                    </Card>
                </Container>

            );
        }
    }
};


export default Ticket;

