import { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { twit } from '../../components/Notificationpopout';
import Cookies from 'js-cookie';

const useFetch = (url,setLoading,refresh) => {
  const [data, setData] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem('token');

  const logOut = () => {
    Cookies.remove('loggedin');
    sessionStorage.clear();
    window.location.href = '/';
    twit("info",'You have been logged out because you are logged in on another device or browser.');
}

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(url,{
            headers: {  Authorization: `Bearer ${token}`
        }

        });
        
        setData(response.data);
      } catch (error) {
        if(error.response.status===401){
          logOut();
        }

        setError(error);
        console.log(error.response.status)
        
      }
      setLoading(false);
    };
    fetchData();
  }, [url,refresh]);

  return { data, error };
};

export default useFetch;
