import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card, Table, InputGroup } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as MdIcons from 'react-icons/md';
import { fetchData } from '../../Utilities/apiRequests';
import { getNairobiTime, getNairobiTimeWithParam, getNumberOnly, getformattedDate } from '../../Utilities/utilities';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import * as GrIcon from 'react-icons/gr';
import useGeneralState from '../../hooks/hooks/generalState';
import Pagination from '../Pagination';


const EventCouponForm = () => {
    const [couponName, setCouponName] = useState('');
    const [expiryDate, setExpiryDate] = useState()
    const [cost, setCost] = useState('')
    const [redeems, setRedeems] = useState(1)
    const [renews, setRenews] = useState(false)
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [showCouponUpdate, setShowCouponUpdate] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState('');
    const [eventInfo, setEventInfo] = useState([]);
    const [includedZones, setIncludedZones] = useState([]);
    const [zones, setZones] = useState([]);
    const [selectedRzones, setSelectedRzones] = useState([]);
    const [selectedZones, setSelectedZones] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);


    const handleInputChange = (e) => {
        const value = e.target.value;
        setCouponName(value);
    };



    const { eid } = useParams();
    const generalAppState = useGeneralState();
    const userCurrency = (generalAppState?.currency)

    useEffect(() => {

        fetchData(`/api/events/allcoupons/${eid}`, setLoading).then((response) => {

            setCoupons(response.data.coupons)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event coupons.')
        })

        fetchData(`/api/events/details/${eid}`, setLoading).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

        fetchData(`/api/events/zones/${eid}`, setLoading).then((response) => {

            setZones(response.data.zones)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event zones.')
        })



    }, [refresh])

    const createCoupon = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name: couponName,
            eid,
            expdate: expiryDate,
            cost,
            renews,
            redeems,
            zones: includedZones,
        };
        const Url = '/api/events/coupon'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Coupon created successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Coupon creation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while creating Coupons.')

        }
    };

    const revokeActivateCoupon = async (setLoading, ecid) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            ecid
        };

        const Url = '/api/events/coupon/status/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Coupon status updated successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Coupon update Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating coupon status.')

        }
    };


    const disableALLaccess = () => {
        const containsdisabledZones = zones.filter((data) => {
            return !(data.isactive)
        })

        if (containsdisabledZones.length > 0) {
            return true
        } else {
            return false
        }
    }



    const handleSubmit = (e) => {
        e.preventDefault();

        if (!couponName || !expiryDate || redeems == 0) {

            if (!couponName) {
                twit('info', 'Please provide the coupon name.')
            }

            if (!expiryDate) {
                twit('info', 'Please provide the coupon expiry date.')
            }

            if (redeems == 0) {
                twit('info', 'Please provide the number of coupon redeems.')
            }


        }

        createCoupon(setLoading).then((data) => {

            setCouponName('');
            setExpiryDate()
            setCost(0)
            setRedeems(0)
            setRenews(false)
            setRefresh(!refresh)
            twit('success', data)
            setIncludedZones([])
        }).catch((error) => {
            twit('info', error)
        })

    };

    const updateCoupon = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name: couponName ? couponName : selectedCoupon.name,
            eid,
            expdate: expiryDate ? expiryDate : selectedCoupon.expdate.slice(0, 16),
            cost: cost ? cost : selectedCoupon.cost,
            renews,
            redeems: redeems ? redeems : selectedCoupon.maxRedeems,
            ecid: selectedCoupon.ecid,
            zones: selectedZones,
            rzones: selectedRzones,
        };
        const Url = '/api/events/eventcoupons/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Coupon updated successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Coupon update Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating Coupons.')

        }
    };




    const handleUpdateSubmit = (e) => {
        e.preventDefault();



        updateCoupon(setLoading).then((data) => {

            setCouponName('');
            setExpiryDate()
            setCost(0)
            setRedeems(0)
            setRenews(false)
            setShowCouponUpdate(false)
            setRefresh(!refresh)
            twit('success', data)
        }).catch((error) => {
            twit('info', error)
        })

    };

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedCoupons = coupons?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }


    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>


                {showCouponUpdate && <Modal open={true} title={'Coupon Management'} onCancel={() => {

                    setShowCouponUpdate(false)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        <Row>
                            <Col>

                                <Form onSubmit={handleUpdateSubmit}>

                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col className='my-1' md={12}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}></p>
                                                        </div>
                                                    </div>

                                                    <Form.Group className='my-2' controlId="couponName">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name</p>
                                                            </div>
                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter coupon name"
                                                                value={(couponName != '') ? couponName : selectedCoupon.name}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setCouponName(e.target.value);
                                                                    } else {
                                                                        setCouponName(null);
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>
                                                    <Form.Group className='my-2' controlId="expiryDate">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}> Expires</p>
                                                            </div>
                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="datetime-local"
                                                                name="expiryDate"
                                                                value={expiryDate ? expiryDate : selectedCoupon.expdate.slice(0, 16)}
                                                                onChange={(e) => { setExpiryDate(e.target.value) }}
                                                                max={eventInfo?.endtime?.slice(0, 16)}
                                                                min={eventInfo?.eventDate?.slice(0, 16)}
                                                            />

                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="cost">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Cost</p>
                                                            </div>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type="text"
                                                                    className=' input-box-events form-control-sm'
                                                                    placeholder="Enter coupon cost"
                                                                    value={(cost != '') ? cost : selectedCoupon.cost}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            setCost(e.target.value);
                                                                        } else {
                                                                            setCost(null);
                                                                        }
                                                                    }}
                                                                    required
                                                                />
                                                                <InputGroup.Text style={{ fontSize: '10px' }} >
                                                                    {userCurrency?userCurrency:'UGX'}
                                                                </InputGroup.Text>
                                                            </InputGroup>

                                                        </div>

                                                    </Form.Group>
                                                    <Form.Group className='my-2' controlId="redeems">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Redeems</p>
                                                            </div>
                                                            <Form.Control
                                                                type="number"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter number of redeems"
                                                                value={(redeems != '') ? redeems : selectedCoupon.maxRedeems}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setRedeems(e.target.value);
                                                                    } else {
                                                                        setRedeems(null);
                                                                    }

                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                            <Form.Group controlId="access zones">
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0, }}>Zones</p>
                                                    </div>
                                                    <div>
                                                        <div className='d-flex flex-row   w-100'>

                                                            <Form.Check
                                                                disabled={disableALLaccess()}
                                                                type="checkbox"
                                                                label={'All Zones'}
                                                                name="includedZones"
                                                                checked={selectedZones.length == zones.length}
                                                                onChange={(e) => {
                                                                    if (includedZones.length == zones.length) {
                                                                        setSelectedZones([])
                                                                        setSelectedRzones(zones.map((zd) => {
                                                                            return zd.zid
                                                                        }))
                                                                    } else {
                                                                        setSelectedZones(zones.map((zd) => {
                                                                            return zd.zid
                                                                        }))
                                                                        setSelectedRzones([])
                                                                    }
                                                                }}
                                                                className="my-0 mx-1  input-box-events form-control-sm"
                                                            />

                                                            {zones.slice(0, 2).map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={selectedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (selectedZones.includes(data.zid)) {
                                                                            setSelectedZones(selectedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                            if (!selectedRzones.includes(data.zid)) {
                                                                                selectedRzones.push(data.zid)
                                                                            }
                                                                        } else {
                                                                            setSelectedZones([...selectedZones, data.zid])
                                                                            if (selectedRzones.includes(data.zid)) {
                                                                                setSelectedRzones(selectedRzones.filter((zones) => {
                                                                                    return zones != data.zid
                                                                                }))
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                        <div className='d-flex flex-row   w-100'>



                                                            {zones.slice(2).map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={selectedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (selectedZones.includes(data.zid)) {
                                                                            setSelectedZones(selectedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                            if (!selectedRzones.includes(data.zid)) {
                                                                                selectedRzones.push(data.zid)
                                                                            }
                                                                        } else {
                                                                            setSelectedZones([...selectedZones, data.zid])
                                                                            if (selectedRzones.includes(data.zid)) {
                                                                                setSelectedRzones(selectedRzones.filter((zones) => {
                                                                                    return zones != data.zid
                                                                                }))
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                    </div>



                                                </div>

                                            </Form.Group>
                                        </Col>
                                                {((eventInfo?.eventDate?.split('T')[0]) !== (eventInfo?.endtime?.split('T')[0])) && (
                                                    <Col className='my-1' md={12}>
                                                        <Form.Group controlId="renews">
                                                            <Form.Check
                                                                type="checkbox"
                                                                label="Coupon Renews Daily"
                                                                name="Coupon Renews"
                                                                checked={renews}
                                                                onChange={() => {
                                                                    setRenews(!renews)
                                                                }}
                                                                className="my-0 mx-1 input-box-events form-control-sm"
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                )}

                                            </Row>
                                        </Card.Body>
                                    </Card>



                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                            <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Coupon
                                        </Button>
                                    </div>
                                </Form>
                            </Col>

                        </Row>


                    </div>


                </Modal>}
                <Row>
                    <Col>
                        <p style={{ fontWeight: 'bold' }}> Coupon Management</p>
                        <Form onSubmit={handleSubmit}>

                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col className='my-1' md={6}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}></p>
                                                </div>
                                            </div>

                                            <Form.Group className='my-2' controlId="couponName">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Name</p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={10}
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter coupon name"
                                                        value={couponName}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>
                                            <Form.Group className='my-2' controlId="expiryDate">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}> Expires</p>
                                                    </div>
                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="datetime-local"
                                                        name="expiryDate"
                                                        value={expiryDate}
                                                        onChange={(e) => { setExpiryDate(e.target.value) }}
                                                        max={eventInfo?.endtime?.slice(0, 16)}
                                                        min={eventInfo?.eventDate?.slice(0, 16)}
                                                    />

                                                </div>


                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={6}>
                                            <Form.Group className='my-2' controlId="cost">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Cost Per Redeem</p>
                                                    </div>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="text"
                                                            className=' input-box-events form-control-sm'
                                                            placeholder="Enter coupon cost"
                                                            value={cost}
                                                            onChange={(e) => {
                                                                setCost(getNumberOnly(e.target.value))

                                                            }}

                                                            required
                                                        />
                                                        <InputGroup.Text style={{ fontSize: '10px' }} >
                                                            {userCurrency?userCurrency:'UGX'}
                                                        </InputGroup.Text>
                                                    </InputGroup>

                                                </div>

                                            </Form.Group>
                                            <Form.Group className='my-2' controlId="redeems">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Max Redeems</p>
                                                    </div>
                                                    <Form.Control
                                                        type="number"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter number of redeems"
                                                        value={redeems}
                                                        min={1}
                                                        onChange={(e) => { setRedeems(getNumberOnly(e.target.value)) }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="access-zones">
                                                <div className='pass-access-zones' >
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}> Zones</p>
                                                    </div>
                                                    <div style={{maxWidth:'100%',}}>
                                                        <div className='scrollable-checkbox-div '>

                                                            <Form.Check
                                                                disabled={disableALLaccess()}
                                                                type="checkbox"
                                                                label={'All Zones'}
                                                                name="includedZones"
                                                                checked={includedZones.length == zones.length}
                                                                onChange={(e) => {
                                                                    if (includedZones.length == zones.length) {
                                                                        setIncludedZones([])
                                                                    } else {
                                                                        setIncludedZones(zones.map((zd) => {
                                                                            return zd.zid
                                                                        }))
                                                                    }
                                                                }}
                                                                className="my-0 mx-1  input-box-events form-control-sm"
                                                            />

                                                            {zones.map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={includedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (includedZones.includes(data.zid)) {
                                                                            setIncludedZones(includedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                        } else {
                                                                            setIncludedZones([...includedZones, data.zid])
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                        {/* <div className='d-flex flex-row   w-100'>



                                                            {zones.slice(2).map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={includedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (includedZones.includes(data.zid)) {
                                                                            setIncludedZones(includedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                        } else {
                                                                            setIncludedZones([...includedZones, data.zid])
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div> */}
                                                    </div>



                                                </div>

                                            </Form.Group>
                                        </Col>

                                        {((eventInfo?.eventDate?.split('T')[0]) !== (eventInfo?.endtime?.split('T')[0])) && (
                                            <Col className='my-1' md={12}>
                                                <Form.Group controlId="renews">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Coupon Renews Daily"
                                                        name="Coupon Renews"
                                                        checked={renews}
                                                        onChange={() => {
                                                            setRenews(!renews)
                                                        }}
                                                        className="my-0 mx-1 input-box-events form-control-sm"
                                                    />

                                                </Form.Group>
                                            </Col>
                                        )}


                                    </Row>
                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add Coupon
                                </Button>
                            </div>
                        </Form>
                    </Col>

                </Row>
                <Row>
                    <p style={{ fontWeight: 'bold' }}> Event Coupons </p>
                    <div className='table-responsive'>
                        {coupons?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Renews</th>
                                        <th>Max Redeems</th>
                                        <th>Expires</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedCoupons?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.name}</td>
                                            <td>{(data.expdate < getNairobiTime()) ? <FcExpired title='Expired' style={{ height: "15px", width: "15px", color: 'red' }} /> : !(data.isactive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                            <td>{!(data.renews) ? <ImCross style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                            <td>{data.maxRedeems}</td>
                                            <td>{getformattedDate(data.expdate)}</td>
                                            <td>


                                                <GrIcon.GrUploadOption className='mx-1' title='Update Coupon' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setCouponName('')
                                                    setCost('')
                                                    setRedeems('')
                                                    setSelectedCoupon(data)
                                                    setRenews(data.renews)
                                                    setShowCouponUpdate(true)
                                                    const couponZones = data?.ZoneCoupons?.map((data) => {
                                                        return data.zid
                                                    })
                                                    setSelectedZones(couponZones)
                                                    

                                                }} />

                                                {!(data.expdate < getNairobiTime()) && (
                                                    <>
                                                        {data.isactive ? (
                                                            <IoIosRemoveCircleOutline className='mx-1' title='Deactivate Coupon' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                                revokeActivateCoupon(setLoading, data.ecid).then((data) => {
                                                                    setRefresh(!refresh)
                                                                    twit('success', data)
                                                                }).catch((error) => {
                                                                    twit('info', error)
                                                                })
                                                            }} />
                                                        ) : (
                                                            <IoIosAddCircleOutline className='mx-1' title='Activate Coupon' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                                revokeActivateCoupon(setLoading, data.ecid).then((data) => {
                                                                    setRefresh(!refresh)
                                                                    twit('success', data)
                                                                }).catch((error) => {
                                                                    twit('info', error)
                                                                })
                                                            }} />
                                                        )}
                                                    </>
                                                )}






                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No coupons added.</p>

                        }
                        {coupons?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={coupons?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }

}

export default EventCouponForm;
