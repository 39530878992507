import { useCallback, useState } from "react";
// import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import {Button} from 'antd';
import * as MdIcons from 'react-icons/md';
import { twit } from "./Notificationpopout";
import { Form } from "react-bootstrap";
import { maxImageFileSize } from "../Utilities/globals";
import { isFileSizeExceed } from "../Utilities/utilities";



const ImageCropper = ({ image,setImage, setCropResultFile, setOpen, windowHeight, windowWidth, aspectRatio }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);




    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
            image.src = url;
        });

    const getRadianAngle = (degreeValue) => {
        return (degreeValue * Math.PI) / 180;
    }


    const rotateSize = (width, height, rotation) => {
        const rotRad = getRadianAngle(rotation);

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        };
    }


    const getCroppedImg = async (
        imageSrc,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return null;
        }

        const rotRad = getRadianAngle(rotation);

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        );

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
        ctx.translate(-image.width / 2, -image.height / 2);

        // draw rotated image
        ctx.drawImage(image, 0, 0);

        // croppedAreaPixels values are bounding box relative
        // extract the cropped image using these values
        const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
        );

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0);

        // As Base64 string
        // return canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                resolve({ image: URL.createObjectURL(file), file });
            }, "image/jpeg");
        });
    }


    const showCroppedImage = async () => {
        try {
            const ImageData = await getCroppedImg(
                image,
                croppedAreaPixels,
                rotation
            );

            setCroppedImage(ImageData?.image);
            setCropResultFile(ImageData?.file)
            setImage(null)
            setOpen(false)
            
        } catch (e) {
            console.error(e);
        }
    }

    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, []);


    return (
        <div>



            <div
                className="crop-app-container"
                style={{
                    display: image === null || croppedImage !== null ? "none" : "block",
                }}
            >
                <div style={{ width: windowWidth, height: windowHeight }}>
                    <Cropper
                        image={image}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        zoomSpeed={4}
                        maxZoom={3}
                        minZoom={1}
                        zoomWithScroll={true}
                        showGrid={true}
                        aspect={aspectRatio}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        onRotationChange={setRotation}
                    />
                </div>

            </div>
            <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                {/* {<button
                    style={{
                        display: image === null || croppedImage !== null ? "none" : "block",
                    }}
                    onClick={showCroppedImage}
                >
                    Save
                </button>} */}

                <Button onClick={showCroppedImage} title='Save Cropped Image' style={{ backgroundColor: "#FFFFFF", color: 'black', margin: '4px',display: image === null || croppedImage !== null ? "none" : "block" }} variant="primary" type="primary" >
                    <MdIcons.MdCropRotate style={{ height: "15px", width: "15px" }} />| Save
                </Button>


            </div>
            <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                {/* <div className="controls">
                    <label>
                        Rotate
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="rotate"
                            onChange={(e, rotation) => setRotation(rotation)}
                            className="range"
                        />
                    </label>
                    <label>
                        Zoom
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="zoom"
                            onChange={(e, zoom) => setZoom(zoom)}
                            className="range"
                        />
                    </label>
                </div> */}
            </div>
            {/* <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                {croppedImage && (
                    <img className="cropped-image" src={croppedImage} alt="cropped" />
                )}
            </div> */}
            {/* <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                {croppedImage && <button onClick={onClose}>close</button>}
            </div> */}
            {/* <div style={{ width: '100%' }}>
                <Form.Control

                    accept='.jpeg,.jpg,.png'
                    name="bannerImage"
                    onChange={(e) => {
                        if (!isFileSizeExceed(maxImageFileSize, [e.target.files[0]])) {
                            setImage(URL.createObjectURL(e.target.files[0]));
                        } else {
                            twit('info', `Max File Size is ${maxImageFileSize}mb`)
                        }


                    }}
                    className=' input-box-events form-control-sm'

                    type="file" />
            </div> */}
        </div>
    );
};

export default ImageCropper;