import { useEffect } from 'react';
import axios from '../api/axios';


const useNotifications = async() => {
   const rid = sessionStorage.getItem('rid');
   const token = sessionStorage.getItem('token');

    const  notificationUrl = `/api/tickets/notifications/${rid}`

    
      
         try {
            const response = await axios.get(notificationUrl,{
              headers: {  Authorization: `Bearer ${token}`
          }
  
          });
            
             return response.data.data
             
          } catch (err) {
             console.log(err)
          }
  
      }

       
      
       


export default useNotifications;