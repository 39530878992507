import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { twit } from '../Notificationpopout';
import { useState } from 'react';
import BreadcrumbNav from '../BreadCrumb';



const PassCard = () => {
   
    const navigate = useNavigate();

    return (
        <Container style={{ fontSize: "12px" }} className='my-3'>
            <BreadcrumbNav/>
            <Card style={{ fontSize: "12px", minHeight: "100vh" }}>
                <Card.Header style={{ fontSize: "15px", fontWeight: "bold" }}>Passes</Card.Header>
                <Card.Body>

                    <div >
                        <Outlet />
                    </div>

                </Card.Body>
            </Card>
        </Container>

    );
};

export default PassCard;
