import React, { useEffect, useState } from 'react'
import { Form, Button, Card, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import * as IoIcons from 'react-icons/io';
import Loader from '../Loader';
import LoaderButton from '../LoaderButton';

const CreateInvoice = () => {

   

    const [loading, setLoading] = useState(false);
    const [showProduct, setShowProduct] = useState(true);
    const [showLabel, setShowLabel] = useState(true);
    const [showTax, setShowTax] = useState(true);
    const [showDiscount, setShowDiscount] = useState(true);
    const [showAmount, setShowAmount] = useState(true);
    const [lines, setLines] = useState([
        { product: '', label: '', description: '', amount: 0, tax: 0, discount: 0 },
    ]);
    const [invoiceDetails, setInvoiceDetails] = useState({
        invoiceNumber: '',
        dueDate: '',
        customerName: '',
        invoiceDate: '',
    });

    const navigate = useNavigate();





    const handleAddLine = () => {
        setLines([
            ...lines,
            { product: '', label: '', description: '', amount: 0, tax: 0, discount: 0 },
        ]);
    };

    const handleRemoveLine = (index) => {
        const newLines = [...lines];
        newLines.splice(index, 1);
        setLines(newLines);
    };

    const handleLineChange = (index, event) => {
        const { name, value } = event.target;
        const newLines = [...lines];
        newLines[index][name] = value;
        setLines(newLines);
    };

    const handleInvoiceDetailsChange = (event) => {
        const { name, value } = event.target;
        setInvoiceDetails({
            ...invoiceDetails,
            [name]: value,
        });
    };

    const handleToggleProduct = () => {
        setShowProduct(!showProduct);
    };

    const handleToggleLabel = () => {
        setShowLabel(!showLabel);
    };

    const handleToggleTax = () => {
        setShowTax(!showTax);
    };

    const handleToggleDiscount = () => {
        setShowDiscount(!showDiscount);
    };


    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container className='invoice-create-container' >
                <div style={{ marginBottom: "20px", paddingLeft: "10px" }}>



                    <p style={{ fontSize: "12px" }}> <MdIcons.MdDocumentScanner onClick={() => { navigate('/documents') }} style={{ height: "16px", width: "16px" }} /> /invoice</p>
                </div>

                <Card style={{ fontSize: "12px" }} >
                    <Card.Header style={{ height: "40px" }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold" }}> <IoIcons.IoIosAdd style={{width:'25px',height:'25px'}}/> Create new invoice</p>
                    </Card.Header>
                    <div>

                    </div>

                    <Form style={{ padding: "15px" }}>
                    <p className='text-muted' style={{fontWeight:'bold'}}>Invoice Details</p>
                        <div style={{display:'flex',flexDirection:'row',width:"100%",justifyContent:'space-evenly'}}>
                            <div style={{width:"49%"}}>
                                <Form.Group controlId="formInvoiceNumber">
                                    <Form.Label>Invoice Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="invoiceNumber"
                                        value={invoiceDetails.invoiceNumber}
                                        placeholder="Enter invoice number"
                                        className="input-box-invoice-form form-control-sm"
                                        onChange={handleInvoiceDetailsChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formDueDate">
                                    <Form.Label>Due Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dueDate"
                                        value={invoiceDetails.dueDate}
                                        className="input-box-invoice-form form-control-sm"
                                        onChange={handleInvoiceDetailsChange}
                                    />
                                </Form.Group>
                            </div>
                            <div style={{width:"49%"}}>
                                <Form.Group controlId="formCustomerName">
                                    <Form.Label>Customer Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="customerName"
                                        value={invoiceDetails.customerName}
                                        placeholder="Enter customer name"
                                        className="input-box-invoice-form form-control-sm"
                                        onChange={handleInvoiceDetailsChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formInvoiceDate">
                                    <Form.Label>Invoice Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="invoiceDate"
                                        value={invoiceDetails.invoiceDate}
                                        className="input-box-invoice-form form-control-sm"
                                        onChange={handleInvoiceDetailsChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <p className='text-muted mt-3' style={{fontWeight:'bold'}}>Invoice Items</p>
                        <div style={{ display: "flex", flexDirection: "row", width: '100%', justifyContent: 'end' }}>
                            <Form.Group className='mx-1' controlId="formShowProduct">
                                <Form.Check
                                    type="checkbox"
                                    label="Show Product"
                                    checked={showProduct}
                                    onChange={handleToggleProduct}
                                />
                            </Form.Group>

                            <Form.Group className='mx-1' controlId="formShowLabel">
                                <Form.Check
                                    type="checkbox"
                                    label="Show Label"
                                    checked={showLabel}
                                    onChange={handleToggleLabel}
                                />
                            </Form.Group>

                            <Form.Group className='mx-1' controlId="formShowTax">
                                <Form.Check
                                    type="checkbox"
                                    label="Show Tax"
                                    checked={showTax}
                                    onChange={handleToggleTax}
                                />
                            </Form.Group>

                            <Form.Group className='mx-1' controlId="formShowDiscount">
                                <Form.Check
                                    type="checkbox"
                                    label="Show Discount"
                                    checked={showDiscount}
                                    onChange={handleToggleDiscount}
                                />
                            </Form.Group>

                        </div>
                        {lines.map((line, index) => (
                            <div key={index}>
                                <Form.Label>Invoice Item {index + 1} </Form.Label>
                                {showProduct && (
                                    <Form.Group controlId={`formProduct${index}`}>
                                        <Form.Control
                                            className=' input-box-invoice-form form-control-sm'
                                            type="text"
                                            name="product"
                                            value={line.product}
                                            placeholder="Enter product"
                                            onChange={(e) => handleLineChange(index, e)}
                                        />
                                    </Form.Group>
                                )}

                                {showLabel && (
                                    <Form.Group controlId={`formLabel${index}`}>
                                        <Form.Control
                                            className=' input-box-invoice-form form-control-sm'
                                            type="text"
                                            name="label"
                                            value={line.label}
                                            placeholder="Enter label"
                                            onChange={(e) => handleLineChange(index, e)}
                                        />
                                    </Form.Group>
                                    
                                )}

                                <Form.Group controlId={`formDescription${index}`}>
                                    <Form.Control
                                        className=' input-box-invoice-form form-control-sm'
                                        type="text"
                                        name="description"
                                        value={line.description}
                                        placeholder="Enter description"
                                        onChange={(e) => handleLineChange(index, e)}
                                    />
                                </Form.Group>

                                {showAmount && (
                                    <Form.Group controlId={`formAmount${index}`}>
                                        <Form.Control
                                            className=' input-box-invoice-form form-control-sm'
                                            type="number"
                                            step="0.01"
                                            name="amount"
                                            value={line.amount}
                                            placeholder="Enter amount"
                                            onChange={(e) => handleLineChange(index, e)}
                                        />
                                    </Form.Group>
                                )}

                                {showTax && (
                                    <Form.Group controlId={`formTax${index}`}>
                                        <Form.Control
                                            className=' input-box-invoice-form form-control-sm'
                                            type="number"
                                            step="0.01"
                                            name="tax"
                                            value={line.tax}
                                            placeholder="Enter tax"
                                            onChange={(e) => handleLineChange(index, e)}
                                        />
                                    </Form.Group>
                                )}

                                {showDiscount && (
                                    <Form.Group controlId={`formDiscount${index}`}>
                                        <Form.Control
                                            className=' input-box-invoice-form form-control-sm'
                                            type="number"
                                            step="0.01"
                                            name="discount"
                                            value={line.discount}
                                            placeholder="Enter discount"
                                            onChange={(e) => handleLineChange(index, e)}
                                        />
                                    </Form.Group>
                                )}
                                {lines.length > 1 && (
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", width: "100%" }}>
                                        <LoaderButton onClick={() => {
                                            handleRemoveLine(index)
                                        }} loading={loading}
                                            variant={"primary"}
                                            label={'- Remove item'}
                                            style={{ backgroundColor: "#FF0000", color: "white", fontSize: "10px" }}

                                        />
                                    </div>
                                )}
                                {index === lines.length - 1 && (

                                    <LoaderButton onClick={() => {
                                        handleAddLine()
                                    }} loading={loading}
                                        variant={"primary"}
                                        label={'+ Add Item'}
                                        style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                                    />

                                )}




                            </div>
                        ))}
                        <LoaderButton onClick={() => {

                        }} loading={loading}
                            variant={"primary"}
                            label={'Create Invoice'}
                            style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                            className={'mt-2'}
                        />
                    </Form>

                </Card>




            </Container>
        );

    }
}

export default CreateInvoice;

