import React from 'react'
import './loader.css'
import loaderImage from './images/pinnitags Loader.gif'
import { Image } from 'react-bootstrap';


const Loader = ({message}) => {
  return (
      <div style={{display:'flex',flexDirection:'column'}} className="loading-page">
        <div className="loading-animation"></div>
        <div >{message}</div>
      </div>
    );
}

export default Loader