import React, { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import logo from './images/pinnitagsTG.png'
import pinnisoftLogo from './images/pinnisoft.jpg';
import { splitArrayIntoChunks } from '../Utilities/utilities';
import * as GiIcons from 'react-icons/gi';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';
import NeldLogo from './images/NELDLOGO2.png';
import Bwongo from './images/bwongo.png';
import SRHR from './images/SRHRG.png';
import useFetch from '../hooks/hooks/getData';
import Loader from './Loader';
import cinat from  './images/cinatBNW.png'
import marintime from  './images/marintimeBNW.png'
import richPromo from  './images/richPromoBNW.png'
import newpinnitagsLogo from './images/pinnitagsBNW2.png'
import bulemeezi from './images/BulemeeziBNW.png'
import buganda from './images/bugandaBNW.png'
import mikka from './images/MIKKA LOGO.png'

const TicketTagPage = ({ labelData, labelType, clientData, tagNumber, labelFormat }) => {

  const [loading, setLoading] = useState(false)




//original
  const generateTag = (data) => (
    <div className="label-code">

      {/* <img src={data.tagImage} alt="Logo" className={[4, 6].includes(labelType) ? 'label-Image-aztec-datamatrix ' : "label-Image"} /> */}
      <img src={data.tagImage} style={{marginTop:'10%'}} alt="Logo" className={[4, 6].includes(labelType) ? 'label-Image-aztec-datamatrix ' : "label-Image"} />
      {labelType != 0 && (
        <p style={{ fontWeight: 'bold', margin:0 }} className='my-0'>{data.name}</p>
        // <p className='my-0'>{data.name}</p>
      )}
      {/* <img src={logo} alt="Logo" style={{marginLeft:'85%', marginTop:'5%'}} className="label-logo" /> */}
      <p  style={{margin:'0px', marginTop:'1%', fontSize:'8px'}}  >Powered by</p>
      <p  style={{margin:'0px', marginTop:'1%', fontWeight:'bold'}}  >PinniTAGS</p>
      {/* <img src={logo} alt="Logo" className="label-logo" /> */}
      <div className={[1, 4, 6].includes(labelType) ? 'label-info-QR' : 'label-info'}>
        {/* <p style={{ margin: '0px', fontSize: '8px' }}>{clientData?.Registration?.regname}</p> */}
        {/* <p style={{ margin: '0px', fontSize: '8px', width: '55px' }}>{tagNumber}</p> */}
        {/* <p style={{ margin: '0px', fontSize: '12px', width:'100px', fontWeight:'bold' }}>{'ALL ACCESS PASS'}</p>
        <p style={{ margin: '0px', fontSize: '9px', width: '100px' }}>{'ORGANIZER'}</p> */}
      </div>
      {/* <img src={logo} alt="Logo" className="label-logo mt-2" /> */}
    </div>
  );

  

  
  


  if (loading) {
    return (<Loader />);
  } else {
    return (

      <>
      
      {!labelFormat&&(
        <div className={[1, 4, 6].includes(labelType) ? 'taglabel-container-qrcode' : "taglabel-container "} >
        <div style={{ margin: '0px', minHeight: '94px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <div style={{ margin: '0px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

            <Row className='my-0' style={{ width: '100%', paddingBottom: '0px', paddingTop: '0px' }} >

              <Col style={{ width: '100%' }}  >
                { generateTag(labelData)}
              </Col>

            </Row>
          </div>


        </div>


      </div>
      )}
      
      </>
      
    );
  }
};

export default TicketTagPage;
