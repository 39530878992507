import React, { useState } from 'react';
import { Form, Card,  Row, Col, Container, ProgressBar } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import useDoctypes from '../../hooks/getDocTypes';
import useMarital from '../../hooks/getMaritalst';
import useGender from '../../hooks/getGender';
import { useEffect } from 'react';
import { Modal,Button } from 'antd';
import ColoredD from '../ColorSport';
import useCountries from '../../hooks/getCountry';
import axios from '../../api/axios';
import Loader from '../Loader';
import useFetch from '../../hooks/hooks/getData';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';
import dashLogo from '../images/level.png';
import ReCAPTCHA from 'react-google-recaptcha';
import * as MdIcons from 'react-icons/md';
import * as GrIcon from 'react-icons/gr';
import { twit } from '../Notificationpopout';
import { validateEmail } from '../../Utilities/emailVerification';
import { getNairobiTime } from '../../Utilities/utilities';


const IdentityRegistration = () => {

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [doctype, setDoctype] = useState('');
    const [docNo, setDocNo] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [marital, setMarital] = useState('');
    const [position, setPosition] = useState('');
    const [signature, setSignature] = useState('');
    const [fingerprint, setFingerprint] = useState('');
    const [face, setFace] = useState('');
    const [eye, setEye] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [nationality, setNationality] = useState('');
    const [kfname, setKfname] = useState('');
    const [klname, setKlname] = useState('');
    const [kemail, setKemail] = useState('');
    const [krelation, setKrelation] = useState('');
    const [kphone, setKphone] = useState('');
    const [kaddress, setKaddress] = useState('');
    const [kgender, setKgender] = useState('');
    const [doctypedata, setDoctypdata] = useState();
    const [maritaldata, setMaritaldata] = useState();
    const [genderdata, setGenderdata] = useState();
    const [countrydata, setCountrydata] = useState();
    const [successMsg, setSuccessMsg] = useState();
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [initialGender, setInitialGender] = useState('Select Gender');
    const [initialKgender, setInitialKgender] = useState('Select Gender');
    const [initialMarital, setInitialMarital] = useState('Select Marital Status');
    const [initialDoctype, setInitialDoctype] = useState('Select Document Type');
    const [completion, setCompletion] = useState(0);
    const [regstatus, setRegstatus] = useState('');
    const [recaptcha, setRecapture] = useState(false);
    const [cardType, setCardType] = useState("");
    const [initialCardType, setInitialCardType] = useState("select Card type");
    const [phoneLength, setPhonelength] = useState(true);






    const doctypes = useDoctypes();
    // const maritals = useMarital();
    // const genders = useGender();
    const countries = useCountries();
    const { id } = useParams();
    const { data, error } = useFetch(`/api/products/card/${id}`, setLoading);
    
    const path = useLocation().pathname
    const cardTypeData = useFetch("/api/products/card/types", () => { });
    const allmaritalData = useFetch('/api/marital-status', () => { });
    const allgenderData = useFetch('/api/gender', () => { });

    

    const pathNow = path.includes('/identityupdate/');
    const navigate = useNavigate();

    useEffect(() => {
        doctypes.then((data) => {
            setDoctypdata(data);
        });


        countries.then((data) => {

            setCountrydata(data)
        })




    }, []);

    const handleCancelerror = () => {
        setErrorMsg(false);

    }

    const handleCancel = () => {

        setSuccessMsg(false)

    }

    //setting initial marital value

    const getInitialMarital = (value) => {
        if (value === "F3350FAE-8EB6-4612-ACA8-4AC1427F1CCF") {
            setInitialMarital("Single");
        } else if (value === "29F07A87-B2A7-460F-85DC-535122EFCB90") {
            setInitialMarital("Married");
        } else if (value) {
            setInitialMarital("Divorced");
        } else {
            setInitialMarital("Select Marital Status");
        }
    }

    //setting initial gender value
    const getInitialGender = (value) => {
        if (value === "B93EFE94-024C-4ABF-8260-DD6B4419FDE7") {

            setInitialGender("male");

        } else if (value === "50BAB1E6-0840-43AD-B4C1-E4AE83291E55") {
            setInitialGender("female");
        } else {
            setInitialGender("Select Gender");
        }
    }

    //setting initial next of kin gender value
    const getInitialKgender = (value) => {
        if (value === "B93EFE94-024C-4ABF-8260-DD6B4419FDE7") {

            setInitialKgender("male");

        } else if (value === "50BAB1E6-0840-43AD-B4C1-E4AE83291E55") {
            setInitialKgender("female");
        } else {
            setInitialKgender("Select Gender");
        }
    }

    //setting initial doctype value
    const getInitialDoctype = (value) => {
        if (value === "B121BC30-E932-4BE1-8E89-1118CC9A181D") {
            setInitialDoctype('National Identity');
        } else if (value === "3B0CB0E1-BA72-4569-9D1F-AB52CF0E367A") {
            setInitialDoctype("PassPort");
        } else {
            setInitialDoctype("Driving Permit");
        }
    }




    useEffect(() => {

        if (data) {
            //setting personal details initially
            setFname(data.data.fhname);
            setLname(data.data.lhname);
            setPosition(data.data.Position);
            setEmail(data.data.Email);
            setDocNo(data.data.dcno);
            setNationality(data.data.Nat);
            setPhone(data.data.contactno);
            setAddress(data.data.Addres);
            setGender(data.data.Ged);
            setMarital(data.data.Msid);
            setDoctype(data.data.gid);
            setDob(data.data.DOB?.slice(0, 10));
            setCardType(data.data?.CardType?.id);
            setInitialCardType(data.data.CardType?.ctype);
            //setting next of kin details initially
            setKfname(data.nextofkin?.kfname);
            setKlname(data.nextofkin?.klname);
            setKaddress(data.nextofkin?.addres);
            setKrelation(data.nextofkin?.krelation);
            setKemail(data.nextofkin?.kemail);
            setKgender(data.nextofkin?.Gid);
            setKphone(data.nextofkin?.kcontact);
            //setting initial dropdown values
            getInitialDoctype(data.data?.gid);
            getInitialGender(data.data?.Ged);
            getInitialMarital(data.data?.Msid);
            getInitialKgender(data.nextofkin?.Gid);


        }




    }, [data]);

    //percentage completion calculation
    const Percentage = (numerator, denominator) => {

        const percent = (numerator / denominator) * 100;

        return percent;
    };



    //handle recapture 
    const handleRecaptchaChange = async (token) => {
        // Send the token to your server for verification
        const data = {
            token: token
        };

        const url = '/api/auth/re-captcha';

        try {
            const response = await axios.post(url, JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
           
            setRecapture(response.data.data.success);
        } catch (error) {
            console.log(error);
        }


    }










    //All form submission logic


    const updateUrl = `/api/products/card/${id}`;
    const token = sessionStorage.getItem('token');
    const payload = {

        maritalStatus: marital,
        gender: gender,
        email: email,
        address: address,
        fhname: fname,
        lhname: lname,
        gid: doctype,
        docno: docNo,
        contactno: phone,
        nationalityNo: nationality,
        position: position,
        dob: dob,
        regstatus: 'F0B7C01D-F120-43A7-A843-AFEDC624A7BA',
        cardType: cardType,

        fName: kfname,
        lName: klname,
        contact: kphone,
        kemail: kemail,
        relationship: krelation,
        genderId: kgender,
        nextofkinaddress: kaddress




    }

    const clearAll = () => {
        setFname("");
        setLname("");
        setDoctype("");
        setDocNo("");
        setEmail("");
        setDob("");
        setMarital("");
        setPosition("");
        setKfname("");
        setAddress("");
        setKlname("");
        setKemail("");
        setKrelation("");
        setKphone("");
        setKaddress("");
        setPhone("");
    }

    const formData = new FormData()
    formData.append("image", face);

    const update = async () => {

        setLoading(true);

        try {
            const response = await axios.patch(updateUrl,
                JSON.stringify(payload),
                {
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                    }

                }

            );
            
            if(face!==''){
                const faceresponse = await axios.patch(`/api/products/card/face/${id}`, formData, {
                  headers: {
                      'content-type': 'multipart/form-data'
                      , Authorization: `Bearer ${token}`
                  }
        
              });
        
              if(faceresponse.status === 201){
                setLoading(false);
                twit("success", "Updated successfully", 2);
                if (pathNow) {
                    navigate('/human/myidentities')
                }

                clearAll();
              }
        
              }else{
                if (response.request.status === 201) {
                    setLoading(false);
                    twit("success", "Updated successfully", 2);
                    if (pathNow) {
                        navigate('/human/myidentities')
                    }
    
                    clearAll();
                }
              }
              
        



            

        } catch (err) {
            setLoading(false);

            if (!err?.response) {
                twit("error", 'No Server Response');
            } else if (err.response?.status === 400) {
                twit("info", err.response.data.message);
            } else if (err.response?.status === 401) {
                twit("info", err.response.data.message);
            } else {
                twit("error", "Internal server error.");
            }
            
        }

    }




    //handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (lname === "N/A" || kfname === "N/A" || klname === "N/A" || kemail === "N/A" || kaddress === "N/A" || krelation === "N/A" || docNo === "00000000000000000") {
            if (docNo === "00000000000000000") {
                twit("info", "Please input your document number.", 5);
            } else {
                twit("info", "Please change all fields that contain N/A .", 5);
            }


        } else if (gender === "" || doctype === "" || kgender === "" || completion !== 100 || !validateEmail(email) || !validateEmail(kemail)) {
            if (completion !== 100) {
                if (gender === "" || !gender) {
                    twit("info", 'Please select your gender.');
                } else if (doctype === "" || !doctype) {
                    twit("info", 'Please select document type.');
                } else if (dob === "" || !dob) {
                    twit("info", 'Please select your date of birth.');
                } else if (marital === "" || !marital) {
                    twit("info", 'Please select your marital status.');
                } else if (kgender === "" || !kgender) {
                    twit("info", 'Please select the next of kin gender.');

                } else {
                    twit("info", 'Please input all required fields.');
                }


            } else {
                twit("info", 'Invalid email format.');
            }






        } else if (!phoneLength) {
            twit("info", "Invalid phone number.", 3);
        } else if (phone.length < 12 || kphone.length < 12) {
            twit("info", "Invalid phone number.", 3);


        } else {
            if (!pathNow && recaptcha) {
                update();
            } else if (pathNow) {
                update();
            } else {
                twit("info", "Please verify you are human.");
            }

        }

    }

    //get percentage

    useEffect(() => {
        if (data) {
            const dataObject = {
                fname, lname, gender, phone, doctype, docNo, dob, nationality, email, address, marital, kfname, klname,
                kphone, kgender, kemail, kaddress, krelation
            }
            const objectLength = Object.keys(dataObject).length;
            const nullValuesCount = Object.values(dataObject).filter(val => val !== null && val !== "").length;
            

            setCompletion(Percentage(nullValuesCount, objectLength));
        }

    }, [fname, lname, gender, phone, doctype, docNo, dob, nationality, email, address, marital, kfname, klname,
        kphone, kgender, kemail, kaddress, krelation]);








    if (data?.data.Regstat === "F0B7C01D-F120-43A7-A843-AFEDC624A7BA" && !pathNow) {

        return (
            <Modal open={true} title={'Already Registered'}
                footer={[


                ]}
            >



                <ColoredD color="green">Your registration is already complete</ColoredD>

            </Modal>
        )
    } else {





        if (loading) {
            return (<Loader />);
        } else {

            return (


                <Container style={{ fontSize: "12px", marginTop: pathNow ? " 20px" : "0px" }}>
                    {pathNow && (
                        <div style={{ marginBottom: "20px", paddingLeft: "10px" }}>



                            <p> <BsIcons.BsPersonCheckFill onClick={() => { navigate('/human/myidentities') }} style={{ height: "16px", width: "16px" }} />/Update/{id}</p>
                        </div>

                    )}

                    <div>
                        <Card className='border-0' >
                            {successMsg && <Modal open={true} title={'Success'} onCancel={handleCancel} onOk={handleCancel}
                                footer={[


                                ]}
                            >
                                <ColoredD color="green">{successMsg}</ColoredD>

                            </Modal>}


                            {errorMsg && <Modal open={true} title={'Failed'} onCancel={handleCancelerror} onOk={handleCancelerror}
                                footer={[


                                ]}
                            >
                                <ColoredD color="red">{errorMsg}</ColoredD>

                            </Modal>}
                            {!pathNow && (
                                <Card.Header style={{ backgroundColor: "#000000" }}>
                                    <Card.Img variant="top" src={dashLogo} className='identity-registration-logo' />
                                </Card.Header>
                            )}

                            <Form onSubmit={handleSubmit}>
                                <Card className='border-0' style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px" }}>

                                    <div >
                                        <ProgressBar style={{ fontSize: "12px" }} now={Math.trunc(completion)} label={`${Math.trunc(completion)}% Completed!!`} variant='success' />
                                    </div>



                                    <Card.Header style={{ fontWeight: "bold" }} >Personal Information</Card.Header>
                                    <Card.Body>


                                        <Row>
                                            <Col xs={12} md={4}>

                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label style={{ marginBottom: "0px" }}> First Name <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        value={fname}
                                                        onChange={(e) => {
                                                            setFname(e.target.value);
                                                        }}

                                                        className=' input-box-identity form-control-sm' type="text" name="name" placeholder="Enter first name" />
                                                </Form.Group>

                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Last Name <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <Form.Control

                                                        required
                                                        value={lname}
                                                        onChange={(e) => {
                                                            setLname(e.target.value);
                                                        }}

                                                        className=' input-box-identity form-control-sm' type="text" name="name" placeholder="Enter last name" />
                                                </Form.Group>

                                                <Form.Group style={{ marginBottom: "14px" }}>
                                                    <Form.Label style={{ marginBottom: "0px" }} >
                                                        Gender

                                                        <span style={{ color: "red" }}> *</span>
                                                    </Form.Label>

                                                    <Form.Select


                                                        onChange={(e) => {
                                                            setGender(e.target.value);
                                                        }}
                                                        className='form-selector'>
                                                        <option key={1} value={gender}>{initialGender}</option>
                                                        {allgenderData?.data?.data?.map((data) => {
                                                            return <option className='input-box' key={data.gid} value={data.gid}>{data.gender}</option>
                                                        })}


                                                    </Form.Select>

                                                </Form.Group>


                                                <Form.Group style={{ marginBottom: "17px" }} controlId="forFace">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Mobile No. <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <PhoneInput
                                                        country={'ug'}
                                                        value={phone}
                                                        localization={{ ug: 'Uganda' }}
                                                        onChange={(e) => {
                                                            setPhone(e);

                                                        }}
                                                        isValid={(inputNumber, country) => {

                                                            if (country.countryCode === "256" && (inputNumber.length > 12)) {
                                                                setPhonelength(false);
                                                                return false
                                                            } else {
                                                                setPhonelength(true);
                                                                return true
                                                            }
                                                        }}

                                                        disableDropdown
                                                        countryCodeEditable={false}
                                                        masks={{ ug: '... ... ...' }}

                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,

                                                        }}


                                                        inputStyle={{
                                                            width: "100%",
                                                            height: "30px",
                                                            fontSize: "12px"
                                                        }}

                                                    />
                                                </Form.Group>




                                                {pathNow && (
                                                    <>
                                                        <Form.Group style={{ marginBottom: "14px" }}>
                                                            <Form.Label style={{ marginBottom: "0px" }}> Recognized Government Document <span style={{ color: "red" }}>*</span>  </Form.Label>
                                                            <Form.Select

                                                                onChange={(e) => {
                                                                    setDoctype(e.target.value);
                                                                }}

                                                                className='form-selector'>
                                                                <option key={1} value={""}>{initialDoctype} </option>
                                                                {doctypedata?.map((data) => {
                                                                    return <option className='input-box' key={data.gid} value={data.gid}>{data.dname}</option>
                                                                })}


                                                            </Form.Select>
                                                        </Form.Group>

                                                        <Form.Group >
                                                            <Form.Label style={{ marginBottom: "0px" }} >
                                                                Document No.
                                                                <span style={{ color: "red" }}>*</span>
                                                            </Form.Label>

                                                            <Form.Control

                                                                required
                                                                value={docNo}
                                                                onChange={(e) => {
                                                                    setDocNo(e.target.value);
                                                                }}

                                                                className=' input-box-identity form-control-sm' type="text" placeholder="Enter your document number" />

                                                        </Form.Group>
                                                    </>
                                                )}








                                            </Col>
                                            <Col  xs={12} md={4}>

                                                {!pathNow && (
                                                    <>
                                                        <Form.Group style={{ marginBottom: "14px" }}>
                                                            <Form.Label style={{ marginBottom: "0px" }}> Recognized Government Document <span style={{ color: "red" }}>*</span>  </Form.Label>
                                                            <Form.Select

                                                                onChange={(e) => {
                                                                    setDoctype(e.target.value);
                                                                }}

                                                                className='form-selector'>
                                                                <option key={1} value={""}>{initialDoctype} </option>
                                                                {doctypedata?.map((data) => {
                                                                    return <option className='input-box' key={data.gid} value={data.gid}>{data.dname}</option>
                                                                })}


                                                            </Form.Select>
                                                        </Form.Group>

                                                        <Form.Group >
                                                            <Form.Label style={{ marginBottom: "0px" }} >
                                                                Document No.
                                                                <span style={{ color: "red" }}>*</span>
                                                            </Form.Label>

                                                            <Form.Control

                                                                required
                                                                value={docNo}
                                                                onChange={(e) => {
                                                                    setDocNo(e.target.value);
                                                                }}

                                                                className=' input-box-identity form-control-sm' type="text" placeholder="Enter your document number" />

                                                        </Form.Group>
                                                    </>
                                                )}
                                                <Form.Group controlId="date">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Date of Birth</Form.Label>
                                                    <Form.Control


                                                        className=' input-box-identity form-control-sm'
                                                        type="date"
                                                        name="date"
                                                        value={dob}
                                                        onChange={(e) => {

                                                            setDob(e.target.value);
                                                        }}

                                                    />
                                                </Form.Group>
                                                <Form.Group style={{ marginBottom: "14px" }} controlId="formBasicLocation">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Nationality</Form.Label>
                                                    <Form.Select


                                                        onChange={(e) => {
                                                            setNationality(e.target.value);
                                                        }}
                                                        className='form-selector'>
                                                        <option key={1} value={"222"}>UGANDA</option>
                                                        {countrydata?.map((data) => {
                                                            return <option className='input-box' key={data.Id} value={data.Id}>{data.Name}</option>
                                                        })}


                                                    </Form.Select>
                                                </Form.Group>
                                                {pathNow && (
                                                    <>
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label style={{ marginBottom: "0px" }}>Email Address</Form.Label>
                                                            <Form.Control

                                                                value={email}
                                                                onChange={(e) => {
                                                                    setEmail(e.target.value);
                                                                }}

                                                                className=' input-box-identity form-control-sm' type="email" name="email" placeholder="domain@example.com" />
                                                        </Form.Group>




                                                        <Form.Group controlId="formBasicLocation">
                                                            <Form.Label style={{ marginBottom: "0px" }}>Address</Form.Label>
                                                            <Form.Control

                                                                value={address}
                                                                onChange={(e) => {
                                                                    setAddress(e.target.value);
                                                                }}

                                                                className=' input-box-identity form-control-sm' type="text" name="email" placeholder="Enter your address" />
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: "14px" }} >
                                                            <Form.Label style={{ marginBottom: "0px" }}>
                                                                Marital Status
                                                            </Form.Label>

                                                            <Form.Select


                                                                onChange={(e) => {
                                                                    setMarital(e.target.value);
                                                                }}

                                                                className='form-selector'>
                                                                <option key={1} value={""}>{initialMarital}</option>
                                                                {allmaritalData?.data?.data?.map((data) => {
                                                                    return <option className='input-box' key={data.msid} value={data.msid}>{data.mstatus}</option>
                                                                })}

                                                            </Form.Select>

                                                        </Form.Group>


                                                    </>
                                                )}



                                                {pathNow && (
                                                    <Form.Group >
                                                        <Form.Label style={{ marginBottom: "0px" }} >
                                                            Level
                                                        </Form.Label>

                                                        <Form.Control

                                                            value={position}
                                                            onChange={(e) => {
                                                                setPosition(e.target.value);
                                                            }}

                                                            className=' input-box-identity form-control-sm' type="text" placeholder="Enter your role" />

                                                    </Form.Group>
                                                )}









                                            </Col>

                                            <Col xs={12} md={4}>

                                                {!pathNow && (
                                                    <>
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label style={{ marginBottom: "0px" }}>Email Address</Form.Label>
                                                            <Form.Control

                                                                value={email}
                                                                onChange={(e) => {
                                                                    setEmail(e.target.value);
                                                                }}

                                                                className=' input-box-identity form-control-sm' type="email" name="email" placeholder="domain@example.com" />
                                                        </Form.Group>




                                                        <Form.Group controlId="formBasicLocation">
                                                            <Form.Label style={{ marginBottom: "0px" }}>Address</Form.Label>
                                                            <Form.Control

                                                                value={address}
                                                                onChange={(e) => {
                                                                    setAddress(e.target.value);
                                                                }}

                                                                className=' input-box-identity form-control-sm' type="text" name="email" placeholder="Enter your address" />
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: "14px" }} >
                                                            <Form.Label style={{ marginBottom: "0px" }}>
                                                                Marital Status
                                                            </Form.Label>

                                                            <Form.Select


                                                                onChange={(e) => {
                                                                    setMarital(e.target.value);
                                                                }}

                                                                className='form-selector'>
                                                                <option key={1} value={""}>{initialMarital}</option>

                                                                {allmaritalData?.data?.data?.map((data) => {
                                                                    return <option className='input-box' key={data.msid} value={data.msid}>{data.mstatus}</option>
                                                                })}

                                                            </Form.Select>

                                                        </Form.Group>

                                                    </>

                                                )}




                                                                

                                                {pathNow && (
                                                    <>
                                                        <Form.Group style={{ marginBottom: "14px" }} >
                                                            <Form.Label style={{ marginBottom: "0px" }}>
                                                                Card Type
                                                            </Form.Label>

                                                            <Form.Select

                                                               
                                                                onChange={(e) => {
                                                                    setCardType(e.target.value);
                                                                }}

                                                                className='form-selector'>
                                                                <option key={1} value={""}>{initialCardType}</option>
                                                                {cardTypeData?.data?.data?.map((data) => {
                                                                    return <option className='input-box' key={data.id} value={data.id}>{data.ctype}</option>
                                                                })}

                                                            </Form.Select>

                                                        </Form.Group>


                                                        <Form.Group controlId="forFace">
                                                            <Form.Label style={{ marginBottom: "0px" }}>Facial Image</Form.Label>
                                                            <Form.Control


                                                                onChange={(e) => {
                                                                    setFace(e.target.files[0]);
                                                                }}
                                                                className=' input-box-identity form-control-sm'

                                                                type="file" />
                                                        </Form.Group>


                                                        <Form.Group controlId="forFingerprint">
                                                            <Form.Label style={{ marginBottom: "0px" }}>Fingerprint</Form.Label>
                                                            <Form.Control



                                                                onChange={(e) => {
                                                                    setFingerprint(e.target.value);
                                                                }}
                                                                className=' input-box-identity form-control-sm'

                                                                type="file" />
                                                        </Form.Group>

                                                        <Form.Group controlId="forFace">
                                                            <Form.Label style={{ marginBottom: "0px" }}>Iris </Form.Label>
                                                            <Form.Control


                                                                onChange={(e) => {
                                                                    setEye(e.target.value);
                                                                }}
                                                                className=' input-box-identity form-control-sm'

                                                                type="file" />
                                                        </Form.Group>
                                                        <Form.Group controlId="forSignature">
                                                            <Form.Label style={{ marginBottom: "0px" }}>Signature</Form.Label>
                                                            <Form.Control


                                                                onChange={(e) => {
                                                                    setSignature(e.target.value);
                                                                }}
                                                                className=' input-box-identity form-control-sm'

                                                                type="file" />
                                                        </Form.Group>








                                                    </>


                                                )}























                                            </Col>


                                        </Row>



                                    </Card.Body>
                                </Card>

                                <Card className='my-1 border-0' style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px" }}>
                                    <Card.Header style={{ fontWeight: "bold" }}>Next of Kin Information</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col  xs={12} md={4}>
                                                <Form.Group controlId="formBasickName">
                                                    <Form.Label style={{ marginBottom: "0px" }}> First Name <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        value={kfname}
                                                        onChange={(e) => {
                                                            setKfname(e.target.value);
                                                        }}

                                                        className=' input-box-identity form-control-sm' type="text" name="name" placeholder="Enter name" />
                                                </Form.Group>

                                                <Form.Group controlId="formkBasicName">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Last Name <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        value={klname}
                                                        onChange={(e) => {
                                                            setKlname(e.target.value);
                                                        }}
                                                        className=' input-box-identity form-control-sm' type="text" name="name" placeholder="Enter name" />
                                                </Form.Group>

                                                <Form.Group controlId="forFace">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Mobile No. <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <PhoneInput
                                                        country={'ug'}
                                                        value={kphone}
                                                        localization={{ ug: 'Uganda' }}
                                                        onChange={(e) => {

                                                            setKphone(e);
                                                        }}
                                                        isValid={(inputNumber, country) => {

                                                            if (country.countryCode === "256" && (inputNumber.length > 12)) {
                                                                setPhonelength(false);
                                                                return false
                                                            } else {
                                                                setPhonelength(true);
                                                                return true
                                                            }
                                                        }}

                                                        disableDropdown
                                                        countryCodeEditable={false}
                                                        masks={{ ug: '... ... ...' }}
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,

                                                        }}


                                                        inputStyle={{
                                                            width: "100%",
                                                            height: "30px",
                                                            fontSize: "12px"
                                                        }}

                                                    />


                                                </Form.Group>








                                            </Col>

                                            <Col  xs={12} md={4}>


                                                <Form.Group style={{ marginBottom: "14px" }}>
                                                    <Form.Label style={{ marginBottom: "0px" }} >
                                                        Gender
                                                        <span style={{ color: "red" }}> *</span>
                                                    </Form.Label>

                                                    <Form.Select


                                                        onChange={(e) => {
                                                            setKgender(e.target.value);
                                                        }}

                                                        className='form-selector'>
                                                        <option key={1} value={kgender}>{initialKgender}</option>
                                                        {allgenderData?.data?.data?.map((data) => {
                                                            return <option className='input-box' key={data.gid} value={data.gid}>{data.gender}</option>
                                                        })}
                                                    </Form.Select>

                                                </Form.Group>

                                                <Form.Group controlId="formBasicKEmail">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Email Address</Form.Label>
                                                    <Form.Control

                                                        value={kemail}
                                                        onChange={(e) => {
                                                            setKemail(e.target.value);
                                                        }}
                                                        className=' input-box-identity form-control-sm' type="text" name="email" placeholder="domain@example.com" />
                                                </Form.Group>




                                                <Form.Group controlId="formBasicLocation">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Address</Form.Label>
                                                    <Form.Control

                                                        value={kaddress}
                                                        onChange={(e) => {
                                                            setKaddress(e.target.value);
                                                        }}

                                                        className='  input-box-identity form-control-sm' type="text" name="email" placeholder="Enter your address" />
                                                </Form.Group>




                                            </Col>

                                            <Col xs={12} md={4}>
                                                <Form.Group controlId="formBasicKrelation">
                                                    <Form.Label style={{ marginBottom: "0px" }}>Relationship</Form.Label>
                                                    <Form.Control

                                                        value={krelation}
                                                        onChange={(e) => {
                                                            setKrelation(e.target.value);
                                                        }}
                                                        className=' input-box-identity form-control-sm' type="text" name="email" placeholder="Enter relationship type" />
                                                </Form.Group>
                                                {!pathNow && (
                                                    <ReCAPTCHA
                                                        sitekey='6LfoSbwlAAAAAEzFleR_N_eWL6zNpUrvfTWs_Ozd'

                                                        onChange={handleRecaptchaChange}
                                                    />
                                                )}





                                            </Col>






                                        </Row>



                                    </Card.Body>
                                    {/* <div style={{ textAlign: "center" }}>
                                        <Button className='identity-form-button-update' variant="success" type="submit">
                                            Complete Registration
                                        </Button>
                                    </div> */}

                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                            <GrIcon.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Complete Registration
                                        </Button>
                                    </div>

                                </Card>


                            </Form>
                            {!pathNow && (
                                <Card.Footer style={{ backgroundColor: "#000000", textAlign: "center" }}>
                                    <p className='text-muted  '>&nbsp;You agree to our <a href="/terms" className="link">Terms and Conditions</a> , <a href="/terms" className="link">Privacy Policy</a> & <a href="/terms" className="link">Cookie Policy</a></p>
                                    <p className="text-muted" style={{ fontSize: "10px" }}>
                                        Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a href="#" className="link">PinniSOFT</a>  <br />
                                        All rights reserved
                                    </p>

                                </Card.Footer>
                            )}

                        </Card>
                    </div>
                </Container>

            );

        };
    };
};
export default IdentityRegistration;
