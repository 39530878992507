import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetters, downloadImage, getformattedDate2 } from '../Utilities/utilities';
import pinnitags from './images/plogotransparent.png'
import hologram from './images/holog.png'
import bugandaLogo from './images/buganda-shield.png'
import passBg from './images/bg1.jpg'
import visualPlacer from './images/imagePlaceholder.png'
import generateBarcode from "pdf417";
import Loader from './Loader';
import { clientEngineBaseUrl } from '../Utilities/globals';

const PassTemplateBuganda = ({ passDetails, eventDetails, passZones, allZones, sponsors }) => {

  const [imageLoaded, setImageLoaded] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [imageData, setImageData] = useState(true);
  const [logoData, setLogoData] = useState(true);
  // const [sponsors, setSponsors] = useState([]);
  const pcolor = passDetails?.eventPassCategory?.pcolor

  const textColors = [{ color: '#d8e309', tcolor: 'black' }, { color: '#1a7d04', tcolor: 'white' }, { color: '#b50505', tcolor: 'white' }, { color: 'Black', tcolor: 'white' }, { color: '#036780', tcolor: 'white' }, { color: 'black', tcolor: 'white' }, { color: '#00000000', tcolor: 'black' }, { color: 'purple', tcolor: 'white' }, { color: 'orange', tcolor: 'white' }]

  const getTextColor = () => {
    const scolor = textColors.filter((data) => {
      return data.color == pcolor
    })

    return scolor[0]?.tcolor
  }

  const sponsorData = eventDetails?.EventSponsors ? eventDetails?.EventSponsors.filter((data) => {
    return data.active
  }) : []

  useEffect(() => {
    if (passDetails?.face) {
      setLoading(true)
      downloadImage(passDetails.face).then((data) => {
        setLoading(false)
        setImageData(data)
      }).catch((error) => {
        setLoading(false)
        console.log('Pass template image load error:', error)
      })
    }
    if (eventDetails?.ticketPassLogo) {
      setLoading1(true)
      downloadImage(eventDetails?.ticketPassLogo).then((data) => {
        setLoading1(false)
        setLogoData(data)
      }).catch((error) => {
        setLoading1(false)
        console.log('Pass template image load error:', error)
      })
    }

    // for (const sp of sponsorData){
    //   if(sp?.url){
    //     setLoading(true)
    //     downloadImage(sp?.url).then((data)=>{
    //       setLoading(false)
    //       setSponsors((prev)=>{if(!sponsors.includes({src:data})) {return [...prev,{src:data}]} else { return sponsors }})
    //     }).catch((error)=>{
    //       setLoading(false)
    //       console.log('Pass template image load error:',error)
    //     })
    //   }
    // }





  }, [])


  const useURL = ((eventDetails?.listingImageOnTicket) || (eventDetails.selfReg))


  // const tagURL = (eventDetails?.attendeeVer && eventDetails?.contactSharing) ? `${clientEngineBaseUrl}/pass/verify/${passDetails?.DocumentTag?.tagName}?mode=3` : eventDetails?.attendeeVer ? `${clientEngineBaseUrl}/pass/verify/${passDetails?.DocumentTag?.tagName}?mode=2` : `${clientEngineBaseUrl}/pass/verify/${passDetails?.DocumentTag?.tagName}?mode=1`

  const tagURL = useURL ? `${clientEngineBaseUrl}/passticket/registration/2/${passDetails?.DocumentTag?.tagName}` : passDetails?.DocumentTag?.tagName


  const isAllAccess = (passZones?.length == allZones?.length)

  const styles = {
    passContainer: {
      width: '390px',
      height: '517px',
      margin: '0 auto',
      marginTop: '10px',
      border: `3px solid ${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}`,
      marginBottom: '10px',
      borderRadius: '10px',
      //   padding: '10px',
      //   backgroundImage: `url(${passBg})`,
      backgroundSize: 'contain',
      position: 'relative',
      backgroundColor: 'white'
      

    },
    passHeader: {
      width: '100%',
      backgroundColor: pcolor ? pcolor : 'black',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      paddingTop: '2px',
      paddingBottom: '2px',
      color: getTextColor() ? getTextColor() : 'black'
    },

    headerLogo: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // borderRadius: '50px',
      // backgroundColor: '#335fa9',
      flexDirection: 'column',
    },
    headerInfo: {
      width: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '8px'
    },

    passBody: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: (sponsors.length > 0) ? '55%' : '58%',
      backgroundColor: `white`
    },
    passDetails: {
      width: '95%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      marginTop: '2px',
      marginBottom: '2px',
    },
    detailsLogo: {
      width: '40%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    detailsInfo: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '11px',
      justifyContent: 'center'
    },
    passZones: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '10px',
      paddingTop:'0px',
      paddingBottom:'0px',
      margin: '0px'
    },
    zoneBox: {
      width: '30px',
      height: '30px',
      backgroundColor: 'black',
      color: 'white',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '1px',
    },
    zoneDescription: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '70px'
    },
    zoneDesc: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '2px',
      marginBottom: '2px',
    },
    zoneDesc1: {
      width: '50%',
      borderRight: '1px solid gray',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1px'
    },
    zoneDesc2: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1px'
    },
    zoneDescTitle: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '0px'
    },
    passFooter: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: `white`
    },
    footerTag: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '2px'
    },
    footerLogo: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '1px',
      marginBottom: '1px',
    },
    overlayHoloGramStyle: {
      position: 'absolute',
      top: '73px',
      left: '306px',
      width: '10px',
      backgroundColor: 'transparent',
      zIndex: 10,
      textAlign: 'center',
      color: 'white'
    },
    centeredTextStyle: {
      textAlign: 'center',
      backgroundColor: 'black',
      color: 'white',
      padding: '1px',
      paddingLeft: '2px',
      paddingRight: '2px',
      borderRadius: '5px',
      fontSize: '10px'
    },
    sponsorImageWrapperStyle: {
      display: 'flex',
      width: '25%',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '0px',
    },
    sponsorContainerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: '5px',
      alignItems: 'center',
      padding: '0px',

    },
    sponsorImageStyle: {
      width: '70%',
      margin: '0px',
      maxHeight: '45px'
    }
  };

  if (loading || loading1) {
    return (<Loader />);
  } else {

    return (
      <div className='pass-template-container' style={styles.passContainer}>
        <div style={styles.overlayHoloGramStyle}>
          <img style={{ width: '50px' }} src={hologram} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={styles.headerLogo}
          >
            <img
              style={{ width: '36%' }}
              src={logoData}
              alt="Logo"
            />
            {/* <p style={{ margin: '0px',fontSize:'10px',fontWeight: 'bold', color: '#eac473' }}>
              OBWAKABAKA BWA BUGANDA
            </p> */}
          </div>
          <div
            className="header-info"
            style={{
              backgroundColor: `${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}`,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '8px',
              paddingTop: '2px',
              paddingBottom: '2px'
            }}
          >
            <h1 style={{ margin: '0px', fontSize: '18px', color: getTextColor() ? getTextColor() : 'black' }}>
              {`${eventDetails?.name
                }`}
            </h1>

          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div

            className='bugandaPassTheme'
            style={{
              borderRadius: '10px',
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              textAlign: 'center',
              width: '3%',
              backgroundColor: `${(pcolor && (pcolor != '#00000000')) ? pcolor : 'black'}`,
              transform: 'rotate(180deg)',
              border:'none'

            }}
          >
            <p style={{ fontSize: '10px', marginLeft: '5px', color: '#eac473', height: '280px', writingMode: 'vertical-lr' }}>

            </p>
          </div>
          <div style={{ width: '97%' }}>
            <div style={styles.passBody}>
              <div style={styles.passDetails}>
                <div style={styles.detailsLogo}>
                  <img
                    style={{ width: '140px', maxHeight: '140px', marginTop: '2px',boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.5)', borderRadius:'2px' }}
                    src={imageLoaded ? `${imageData}` : visualPlacer}
                    alt="Event Logo"
                    onError={() => {
                      setImageLoaded(false)
                    }}
                  />

                </div>
                <div style={styles.detailsInfo}>
                  <p style={{ margin: '1px', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>{passDetails?.EventPassTitle?.name ? passDetails?.EventPassTitle?.name == 'Other' ? passDetails?.otherTitle : passDetails?.EventPassTitle?.name : ''} {passDetails?.name}</p>
                  {passDetails?.profession ? <div style={styles.centeredTextStyle}>{capitalizeFirstLetters(passDetails?.profession)}</div> : ''}
                  <p style={{ color: (pcolor && (pcolor != '#00000000')) ? pcolor : 'black', margin: '1px', fontWeight: 'bold', fontSize: '16px' }}>{passDetails?.eventPassCategory?.name}</p>

                </div>
              </div>
              <div style={styles.passZones} id="passZones">
                {/* Render pass zones dynamically */}
                {/* //passZones */}
                {/* {isAllAccess ?
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

                    <p style={{ fontSize: '20px', fontWeight: 'bold', color: (pcolor && (pcolor != '#00000000')) ? pcolor : 'black', margin: '0px' }} >All Access</p>

                  </div>

                  : passZones.map((zone, index) => (
                    <div key={index} style={styles.zoneBox}>
                      <p style={{ margin: '1px', fontSize: '6px', textTransform: 'uppercase' }}>
                        {zone.name}
                      </p>
                    </div>
                  ))} */}
                  {isAllAccess ?
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' , margin: '0px'}}>

                    <p style={{ fontSize: '12px', fontWeight: 'bold', color: (pcolor && (pcolor != '#00000000')) ? pcolor : 'black', margin: '0px' }} >All ACCESS</p>

                  </div>
                  : 
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%',margin: '0px' }}>

                    <p style={{ fontSize: '12px', fontWeight: 'bold', color: (pcolor && (pcolor != '#00000000')) ? pcolor : 'black', margin: '0px' }} >RESTRICTED ACCESS</p>

                  </div>
                  }
              </div>
              <div style={styles.zoneDescription}>
                <div style={styles.zoneDescTitle}>
                  <p style={{ fontSize: '8px', fontWeight: 'bold', margin: '0px' }}>ACCREDITATION ZONES</p>
                </div>
                <div style={styles.zoneDesc}>
                  <div style={styles.zoneDesc1} id="zoneDesc1">
                    {/* Render zone descriptions dynamically for column 1 */}
                    {passZones
                      .filter((_, index) => index % 2 === 0)
                      .map((zone, index) => (
                        <p style={{ margin: '0px', fontSize: '8px' }} key={index}>
                          <span style={{ fontSize: '8px', fontWeight: 'bold', textTransform: 'uppercase' }}>{zone?.name}</span> - {zone?.desc}
                        </p>
                      ))}
                  </div>
                  <div style={styles.zoneDesc2} id="zoneDesc2">
                    {/* Render zone descriptions dynamically for column 2 */}
                    {passZones
                      .filter((_, index) => index % 2 !== 0)
                      .map((zone, index) => (
                        <p style={{ margin: '0px', fontSize: '8px' }} key={index}>
                          <span style={{ fontSize: '8px', fontWeight: 'bold', textTransform: 'uppercase' }}>{zone?.name}</span> - {zone?.desc}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div style={styles.passFooter}>

              <div style={styles.sponsorContainerStyle} id="sp">
                {
                  sponsors.map((sp, index) => (
                    <div style={styles.sponsorImageWrapperStyle} key={index}>
                      <img style={styles.sponsorImageStyle} src={sp.src} />
                    </div>
                  ))}
              </div>

              {/* <div style={styles.sponsorContainerStyle} id="sp">
                {
                  sponsors.map((sp, index) => (
                    <div style={styles.sponsorImageWrapperStyle} key={index}>
                      <img style={styles.sponsorImageStyle} src={sp.src}/>
                    </div>
                  ))}
              </div> */}

              <div

                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontSize: '8px',
                  color: 'black',
                  // height:'20px'
                }}
              >


                <p style={{ margin: '0px', color: 'black', fontWeight: 'bold' }}>
                  {`${getformattedDate2(eventDetails?.eventDate)}| ${eventDetails?.venue}, ${eventDetails?.location}`}
                </p>
                <p style={{ margin: '0px', color: 'black', fontWeight: 'bold' }}>
                  {eventDetails?.eventdescription}
                </p>
              </div>

              <div style={styles.footerTag}>
                <img style={{ width: '40%' }} src={generateBarcode(tagURL, 6, 2)} alt="QR Code" />
                <p style={{ fontSize: '8px', fontWeight: 'bold', margin: '0px' }}>{passDetails?.DocumentTag?.tagName}</p>
              </div>
              <div style={{ width: '100%', textAlign: 'center', margin: '0' }}>
                <p style={{ fontSize: '8px', fontWeight: 'bold', margin: '0px' }}>SerialNo.<span style={{ fontWeight: 'lighter', margin: '0px' }}>{passDetails?.id}</span></p>
              </div>
              <div style={styles.footerLogo}>
                <p style={{ margin: '0%', fontWeight: 'bold', fontSize: '6px' }}>Powered By</p>
                <img style={{ width: '18%' }} src={pinnitags} alt="Powered By Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default PassTemplateBuganda;
