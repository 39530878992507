import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { Outlet,useNavigate,useLocation } from 'react-router-dom';
import { twit } from '../Notificationpopout';
import { useState } from 'react';


const settings = [
  {
    title: 'General Settings',
    description: 'Configure general system preferences.',
    link: '/settings',
  },
  {
    title: 'Security Settings',
    description: 'Manage security and privacy options.',
    link: '/settings/general',
  },
  {
    title: 'Notification Settings',
    description: 'Customize system notification preferences.',
    link: '/settings/notifications',
  },
  {
    title: 'Collection Account Settings',
    description: 'Configure collection account.',
    link: '/settings/collections',
  }
  
];

const SettingsCard = () => {

    const [active,setActive] = useState(false);

    const navigate = useNavigate();

    const path = useLocation().pathname;



    const isActive = (pathNow)=>{
        if(pathNow===path){
            return true;
        }else{
            return false;
        }
    }

  return (
    <Container style={{fontSize:"12px"}} className='my-3'>
        <Card style={{fontSize:"12px",minHeight:"100vh"}}>
      <Card.Header style={{fontSize:"15px", fontWeight:"bold"}}>System Settings</Card.Header>
      <Card.Body>
        <Row>
          <Col md={4}>
            <ListGroup variant="flush">
              {settings.map((setting, index) => (
                <ListGroup.Item style={{cursor:"pointer"}} key={index} onClick={()=>{
                   
                    navigate(setting.link);
                }}>
                  <p className='my-0' style={{fontSize:"13px",fontWeight:"bold", color:isActive(setting.link)? "#569f51":"black" }}>{setting.title}</p>
                  <p className='my-0' style={{fontSize:"10px"}}>{setting.description}</p>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={8}>
            <div className="linked-settings">
              
              <Outlet/>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    </Container>
    
  );
};

export default SettingsCard;
