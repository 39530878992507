import React from 'react'
import { Table } from 'react-bootstrap'
import Loader from '../Loader';
import { useState } from 'react';
import useFetch from '../../hooks/hooks/getData';
import { Modal, Button } from 'antd';
import EventCard from '../EventCard';
import { useNavigate } from 'react-router-dom';

const UserEvents = () => {


    const [loading, setLoading] = useState(false);
    const [rowdata, setRowdata] = useState(false);

    const userevents = useFetch("/api/events/", setLoading);
    const navigate = useNavigate();


    if (loading) {
        return (<Loader />);
    } else {
        return (
            <div className='table-responsive'>


                {rowdata && <Modal open={true} title={rowdata.topic} onCancel={() => { setRowdata(false) }}
                    footer={[


                    ]}

                    className="allmodals"
                >







                    <div>





                        <div>
                            <EventCard
                                eventName={rowdata.number}
                                eventDate={rowdata.eventDate}
                                eventDescription={rowdata.eventdescription}
                                eventVenue={rowdata.venue}
                                imageUrl={rowdata.bannerURL}
                            />
                        </div>

                        <div style={{ textAlign: 'center' }}>
                            <Button title='Add event speaker details' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                                Add Speaker
                            </Button>

                            <Button onClick={() => { navigate(`/pass/event/credentialform/${rowdata.eid}`) }} title='Create event scanner credentials' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                                Scanner Credentials
                            </Button>

                            <Button onClick={() => { navigate(`/pass/event/eventzoneform/${rowdata.eid}`) }} title='Create event scanner credentials' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                                Create Zones
                            </Button>

                            <Button onClick={() => { navigate(`/pass/event/eventcouponform/${rowdata.eid}`) }} title='Create event scanner credentials' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                                Create Coupons
                            </Button>

                            <Button onClick={() => { navigate(`/pass/event/statistics/${rowdata.eid}`) }} title='Create event scanner credentials' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                             View Statistics
                            </Button>

                        </div>


                    </div>


                </Modal>}
                <Table striped hover style={{ cursor: "pointer" }}>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Venue</th>
                            <th> Contact</th>


                        </tr>
                    </thead>
                    <tbody>
                        {userevents?.data?.userEvents.map((data, index) => (
                            <tr key={index} onClick={() => { setRowdata(data) }}>
                                <td>{index + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.eventDate}</td>
                                <td>{data.venue}</td>
                                <td>{data.contactInfo}</td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
};

export default UserEvents;
