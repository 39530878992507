import Cookies from 'js-cookie';
import React from 'react'
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

const AssetsBranchOutlet = () => {


  const Branchloggedin = Cookies.get('Branchloggedin');
  return (
    <Container className={Branchloggedin? 'assets-outlet-inner':' assets-outlet '}  >
      <Outlet/>
    </Container>
  )
}

export default AssetsBranchOutlet;
