import React, { useState } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import IdentityForm from "./IdentityForm";
import HumanIdentity from "./Management";
import CardOperations from "./Views/CardOperations";

const Identity = ({ origin }) => {

    const navigate = useNavigate()
    const [dash, setDash] = useState(() => {
        if (origin === 'products') {
            return false;
        } else if (origin === "registration") {
            return true;
        } else if (origin === "operations") {
            return false;
        } else {
            return true;
        }
    });
    const [products, setProducts] = useState(() => {
        if (origin === 'products') {
            return true;
        } else if (origin === "registration") {
            return false;
        } else if (origin === "operations") {
            return false;
        } else {
            return false;
        }
    });
    const [operations, setOperations] = useState(() => {
        if (origin === 'products') {
            return false;
        } else if (origin === "registration") {
            return false;
        } else if (origin === "operations") {
            return true;
        } else {
            return false;
        }
    });

    const selectDash = () => {
        setDash(true);
        setProducts();
        setOperations();

    };
    const selectProducts = () => {
        setDash();
        setOperations();
        setProducts(true);

    };

    const selectOperations = () => {
        setDash();
        setProducts();
        setOperations(true);

    };




    return (
        <Container className="meetingsdash-container">
            <div style={{ marginBottom: "20px" }}>
                <RiIcons.RiProductHuntLine title='Back to products' onClick={() => { navigate('/identitydashProducts') }} style={{ height: "20px", width: "20px" }} />
                <TbIcons.TbMathGreater style={{ height: "12px", width: "12px" }} />
                <GiIcons.GiHumanTarget style={{ height: "18px", width: "18px" }} />

            </div>
            <Card>
                <Card.Header>
                    <Nav className="custom-nav-tabs" variant="tabs" defaultActiveKey={() => {
                        if (origin === 'products') {
                            return ("products");
                        } else if (origin === "registration") {
                            return ("registration");
                        } else if (origin === "operations") {
                            return ("operations");
                        } else {
                            return ("registration")
                        }
                    }}>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="registration" onClick={selectDash}>
                                <BsIcons.BsFillPersonPlusFill style={{ height: "18px", width: "18px" }} />
                                &nbsp;
                                Registration
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="products" onClick={selectProducts}>
                                <BsIcons.BsPersonCheckFill style={{ height: "18px", width: "18px" }} />
                                &nbsp;
                                My Identities
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="tabs" eventKey="operations" onClick={selectOperations}>
                                <BsIcons.BsFillCreditCard2FrontFill style={{ height: "18px", width: "18px" }} />
                                &nbsp;
                                Card Operations
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {dash && <IdentityForm />}
                    {products && <HumanIdentity />}
                    {operations && <CardOperations />}
                </Card.Body>
            </Card>

        </Container>

    );
}

export default Identity;
