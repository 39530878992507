import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'antd';
import useTickets from '../hooks/getTickets';
import axios from '../api/axios';
import { Table, Card, Container, Button, Form, Col, Row } from 'react-bootstrap';
import { GrAttachment } from "react-icons/gr";
import * as IoIcons from 'react-icons/io';
import ChatBubble from './Chatbubble';

const Chat = ({ tid, handleClose }) => {

    const [messages, setMessages] = useState();
    const [message, setMessage] = useState();
    const [rowdata, setRowdata] = useState();
    const [ticketdata, setTicketdata] = useState();


    const usetickets = useTickets()
    const myDivRef = useRef(null);
    const token = sessionStorage.getItem('token');




    useEffect(() => {
        usetickets.then((data) => {
            
            setTicketdata(data)

            getMessages(tid);
            const arrayData = Promise.resolve(data.filter((value) => {
                return value.tid === tid
            }))
            arrayData.then((result) => {
                setRowdata(result[0])
                
            })



        })
    }, [])


    useEffect(() => {
        if (myDivRef.current) {
            myDivRef.current.scrollTop = myDivRef.current.scrollHeight;
        }

    }, [messages]);






    const getAttachments = () => {

    }











    const handleTicketClose = async (tid, tsid) => {
        try {
            // setLoading(true);
            const response = await axios.patch(`/api/tickets/ticket/${tid}`, JSON.stringify({ tsid: 'D9A6ADE0-FDE8-4621-91D1-198C02F5412E' }), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.data.message === 'Ticket updated successfully') {

                setRowdata()
                // setSuccessMsg(response.data.message)
                // setSwitcher(!switcher);
            }
            
        } catch (err) {



        }
    }


    const getMessages = async (tid) => {
        try {
            const response = await axios.get(`/api/tickets/chat/${tid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }


            });

            
            setMessages(response.data.messages)

        } catch (err) {
            console.log(err)
        }
    }


    const handleMessageSubmit = async (e, tid) => {
        e.preventDefault();



        try {
            //setLoading(true)
            const response = await axios.post('/api/tickets/chat',
                JSON.stringify({
                    message: message,
                    tid: tid,
                    tsid: '0FF48E4A-A6D7-4B07-8EA7-BDB0834C8610'
                }),
                {
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                    }

                }
            )
            if (response?.request?.status === 201) {

                getMessages(tid);
                setMessage('');
            }
            






        } catch (error) {

            
        }




    };



    const handleCancelerror = () => {

        setRowdata();
    }
    return (
        <div>
            {rowdata && <Modal open={true} onCancel={handleClose} onOk={handleClose}


                footer={[


                ]}

                className="allmodals"

            >
                <div className='ticket-popup-header'>


                    <p> <span>#Ticket ID :</span> {rowdata.tickid}</p>


                    <p ><span style={{ color: 'black' }}> Priority :</span> {rowdata?.Tpriority?.pname}</p>

                    <p style={{ color: rowdata?.TicketProcesses[0]?.TStatus?.scolor }}><span style={{ color: 'black' }}> Status :</span> {rowdata?.TicketProcesses[0]?.TStatus?.sname}</p>


                    <div style={{ marginRight: "18px", paddingTop: "4px" }}>
                        <p style={{ paddingTop: "0px", fontSize: '10px' }}> <GrAttachment onClick={getAttachments} /> Attachments </p>
                    </div>

                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p style={{ paddingTop: "8px", fontSize: "11px" }}><span style={{ fontWeight: 'bold', color: "Black", fontSize: '12px' }}>Subject :</span> {rowdata?.sub}</p>

                </div>

                <div ref={myDivRef} className='message-area-tickets'>
                    {messages && (messages?.map((message, index) => {
                        return (
                            <Row key={index} className='message-row'>
                                {message?.TStatus.sname === "New" && (<ChatBubble date={message?.ttdate} message={message?.cmt} isMine={true} />)}
                                {message?.TStatus.sname !== "New" && (<ChatBubble date={message?.ttdate} message={message?.cmt} isMine={false} />)}




                            </Row>
                        )
                    }))}
                </div>



                <Form onSubmit={(e) => {
                    handleMessageSubmit(e, rowdata.tid)
                }}>
                    {rowdata?.TicketProcesses[0]?.TStatus?.sname !== 'Closed' && (
                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "center" }}>
                            <Form.Group>
                                <Form.Control style={{ width: '390px', maxWidth: '390px' }} onChange={(e) => { setMessage(e.target.value) }} className='input-box-view form-control-sm' type="text " value={message} placeholder="Type new message" />
                            </Form.Group>
                            <Button variant="default" type="submit" className='ticket-button' >
                                <IoIcons.IoMdSend style={{ height: "20px", width: "20px", color: "#2a7824" }} />
                            </Button>
                        </div>

                    )}


                </Form>



                {rowdata?.TicketProcesses[0]?.TStatus?.sname !== 'Closed' && (

                    <Button variant="danger" type="Button" className='ticket-button' onClick={() => { handleTicketClose(rowdata.tid, rowdata.TicketProcesses[0]?.tsid) }}>
                        Close Ticket
                    </Button>
                )}

            </Modal>

            }


        </div>
    )
}

export default Chat
