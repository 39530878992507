import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as GiIcons from 'react-icons/gi';
import * as MdIcons from 'react-icons/md';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';



export const SidebarData = [
  {
    title: 'Home',
    path: '/home',
    icon: <RiIcons.RiDashboardFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    
  },
  
  {
    title: 'Concepts',
    path: '#',
    icon: <GiIcons.GiConvergenceTarget />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Identity ',
        path: '/identitydash',
        icon: <AiIcons.AiOutlineUser />
      },
      // {
      //   title: 'PinniMass',
      //   path: '/meetingdash',
      //   icon: <FaIcons.FaUsers />
        
      // },
      {
        title: 'Documents',
        path: '/documents',
        icon: <MdIcons.MdOutlineDocumentScanner />
        
      },
      {
        title: 'Event Management',
        path: '/events',
        icon: <MdIcons.MdOutlineEventRepeat />
        
      },
      // {
      //   title: 'PinniPay',
      //   path: '#',
      //   icon: <GiIcons.GiTakeMyMoney />
        
      // },
      // {
      //   title: 'Adds ON',
      //   path: '#',
      //   icon: <MdIcons.MdAddShoppingCart />
      // }
      
    ]
  },
  {
    title: 'Billing & Payments',
    path: '#',
    icon: <GiIcons.GiMoneyStack />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Billing Accounts',
        path: '/billing',
        icon: <GiIcons.GiPayMoney />
      },
      {
        title: 'Collection Accounts',
        path: '/collections',
        icon: <GiIcons.GiReceiveMoney />
      },
      
    ]
    
  },
  
  {
    title: 'Support',
    path: '#',
    icon: <IoIcons.IoMdHelpCircle />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Ticket ',
        path: '/ticket',
        icon: <BsIcons.BsTicketPerforatedFill />
      },
      
      
    ]
    
  }
];
