import React from 'react'
import { Table, Form } from 'react-bootstrap'
import Loader from '../Loader';
import { useState } from 'react';
import useFetch from '../../hooks/hooks/getData';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import BreadcrumbNav from '../BreadCrumb';

const UserEventPasses = () => {


    const [loading, setLoading] = useState(false);
    const [operationsModal, setOperationsModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedevent, setSelectedevent] = useState();
    const [eventLinkView, setEventLinkView] = useState();
    const [newPassView, setNewPassView] = useState();
    const [passNumber, setPassNumber] = useState();
    const [refresh, setRefresh] = useState(false);
    const [selectedpass, setSelectedpass] = useState('615E0411-EC9B-4951-A44A-628DEBBE8126');
    const [passUpdateView, setPassUpdateView] = useState(false);
    const [formDataPass, setFormDataPass] = useState({
        FullName: '',
        DateOfBirth: '',
        Email: '',
        Phone: '',
        face: '',
        tagid: '',
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDataPass({
            ...formDataPass,
            [name]: value,
        });
    };



    const userevents = useFetch("/api/events/", setLoading);
    const userpasses = useFetch("/api/events/passes/", setLoading, refresh);
    const passCategories = useFetch("/api/events/passcategories", setLoading);
    const navigate = useNavigate();

    console.log(selectedRows)

    const userPassIds = selectedRows.map((pass) => {
        return pass.PassID
    })


    const toggleRowSelection = (data) => {
        const isSelected = selectedRows.includes(data);
        if (isSelected) {
            setSelectedRows(selectedRows.filter((row) => row !== data));
        } else {
            setSelectedRows([...selectedRows, data]);
        }
    };

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(userpasses?.data?.userPasses || []);
        }
        setSelectAll(!selectAll);
    };

    const openEventLinkModal = () => {
        setNewPassView()
        setEventLinkView(true)
        setOperationsModal(true)
    }

    const openNewPassModal = () => {
        setEventLinkView()
        setNewPassView(true)
        setOperationsModal(true)
    }

    const openUpdatePassModal = () => {
        setEventLinkView()
        setNewPassView()
        setPassUpdateView(true)
        setOperationsModal(true)
    }

    const closeOperationsModal = () => {
        setEventLinkView()
        setNewPassView()
        setPassUpdateView()
        setOperationsModal()
        setSelectedRows([])
    }

    const createPass = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            passCategory: selectedpass,
            passNo: passNumber,
        };
        const createPassUrl = '/api/events/create/pass'
        try {
            setLoading(true);
            const response = await axios.post(createPassUrl, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Passes created successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log("Pass creation Error:", err)
            return Promise.reject('An error occured while creating the passes.')

        }
    };

    const linkEventToPass = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            passIdArray: userPassIds,
            eventId: selectedevent,
        };
        const createPassUrl = '/api/events/add/pass'
        try {
            setLoading(true);
            const response = await axios.post(createPassUrl, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Linked successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log("Pass Event link creation Error:", err)
            return Promise.reject('An error occured while linking passes to event.')

        }
    };


    const updatePassInfo = async (setLoading) => {

        const token = sessionStorage.getItem('token');
        const postData = {
            FullName: formDataPass.FullName,
            DateOfBirth: formDataPass.DateOfBirth,
            Email: formDataPass.Email,
            Phone: formDataPass.Phone,
            face: formDataPass.face,
            tagid: formDataPass.tagid,
            PassID:selectedRows[0].PassID

        }
        const updatePassUrl = '/api/events/add/pass/update'

        setLoading(true);

        try {
            const response = await axios.patch(updatePassUrl,
                JSON.stringify(postData),
                {
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                    }

                }

            );

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Data Updated successfully.')
            }




        } catch (err) {


            setLoading(false);
            console.log("Pass update Error:", err)
            return Promise.reject('An error occured while updating pass data.')

        }

    }


    const handlePassSubmit = (e) => {
        e.preventDefault();

        if (!passNumber || !selectedpass) {
            twit('info', `Please fill out all required fields.`);

        } else {

            createPass(setLoading).then((data) => {
                setRefresh(!refresh)
                setPassNumber('')
                closeOperationsModal()
                twit('success', data)

            }).catch((error) => {
                twit('info', error)
            })

        }



    };


    const handlePassEventSubmit = (e) => {
        e.preventDefault();

        if (!selectedevent) {
            twit('info', `Please fill out all required fields.`);

        } else {

            linkEventToPass(setLoading).then((data) => {
                setRefresh(!refresh)
                closeOperationsModal()
                twit('success', data)

            }).catch((error) => {
                twit('info', error)
            })

        }



    };

    const handlePassUpdateSubmit = (e) => {
        e.preventDefault();



        if (
            !formDataPass.FullName ||
            !formDataPass.DateOfBirth ||
            !formDataPass.Email ||
            !formDataPass.Phone
        ) {

            twit('info', `Please fill out all required fields.`);

        } else {

            updatePassInfo(setLoading).then((data) => {
                setRefresh(!refresh)
                closeOperationsModal()
                twit('success', data)

            }).catch((error) => {
                twit('info', error)
            })

        }

    };





    if (loading) {
        return (<Loader />);
    } else {
        return (
            <div style={{fontSize:'12px'}} >
                

                {operationsModal && <Modal open={true} title={'Operations'} onCancel={closeOperationsModal}
                    footer={[


                    ]}

                    className="allmodals"
                >







                    <div>



                        {eventLinkView && (
                            <div>
                                <Form onSubmit={handlePassEventSubmit}>


                                    <Form.Group controlId="userEvent">
                                        <Form.Label  >
                                            Available Events
                                            <span style={{ color: "red" }}> *</span>
                                        </Form.Label>

                                        <Form.Select

                                            name="userEvent"
                                            onChange={(e) => { setSelectedevent(e.target.value) }}
                                            className='form-selector'>
                                            <option className='input-box' key={'s'} value=''>Select Event</option>
                                            {userevents?.data?.userEvents.map((data) => {
                                                return <option className='input-box' key={data.eid} value={data.eid}>{data.name}</option>
                                            })}


                                        </Form.Select>

                                    </Form.Group>


                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#569f51" }} variant="primary" type="primary" htmlType='submit'>
                                            Link to event
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        )}
                        {newPassView && (
                            <div>
                                <Form onSubmit={handlePassSubmit}>


                                    <Form.Group controlId="passCategory">
                                        <Form.Label  >
                                            Pass Category
                                            <span style={{ color: "red" }}> *</span>
                                        </Form.Label>

                                        <Form.Select

                                            name="passCategory"
                                            onChange={(e) => { setSelectedpass(e.target.value) }}
                                            className='form-selector'>

                                            {passCategories?.data?.passCategories.map((data) => {
                                                return <option className='input-box' key={data.pcid} value={data.pcid}>{data.CategoryName}</option>
                                            })}


                                        </Form.Select>

                                    </Form.Group>

                                    <Form.Group controlId="passes">
                                        <Form.Label>Number of Passes <span style={{ color: "red" }}> *</span></Form.Label>
                                        <Form.Control
                                            className=' input-box-identity form-control-sm'
                                            type="text"
                                            name="pass number"
                                            value={passNumber}
                                            onChange={(e) => { setPassNumber(e.target.value) }}
                                        />
                                    </Form.Group>



                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#569f51" }} variant="primary" type="primary" htmlType='submit'>
                                            Create Passes
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        )}

                        {passUpdateView && (
                            <div>
                                <Form onSubmit={handlePassUpdateSubmit}>
                                    <Form.Group controlId="FullName">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            className=' input-box-identity form-control-sm'
                                            type="text"
                                            name="FullName"
                                            value={formDataPass.FullName}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="DateOfBirth">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Form.Control
                                            className=' input-box-identity form-control-sm'
                                            type="date"
                                            name="DateOfBirth"
                                            value={formDataPass.DateOfBirth}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="Email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            className=' input-box-identity form-control-sm'
                                            type="email"
                                            name="Email"
                                            value={formDataPass.Email}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="Phone">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            className=' input-box-identity form-control-sm'
                                            type="text"
                                            name="Phone"
                                            value={formDataPass.Phone}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="face">
                                        <Form.Label>Face</Form.Label>
                                        <Form.Control
                                            className=' input-box-identity form-control-sm'
                                            type="text"
                                            name="face"
                                            value={formDataPass.face}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="tagid">
                                        <Form.Label>Tag ID</Form.Label>
                                        <Form.Control
                                            className=' input-box-identity form-control-sm'
                                            type="text"
                                            name="tagid"
                                            value={formDataPass.tagid}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#569f51", fontSize:'10px' }} variant="primary" type="primary" htmlType='submit'>
                                            Assign
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        )}

                    </div>


                </Modal>}
                <div className='d-flex flex-row justify-content-end w-100'>
                    {selectedRows.length > 0 && (
                        <div>
                            <Button onClick={openEventLinkModal} title='Assign selected passes to event' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                                Link to event
                            </Button>

                        </div>
                    )}

                    {selectedRows.length == 1 && (
                        <div>

                            <Button onClick={openUpdatePassModal} title='Update Pass info' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                                Update
                            </Button>
                        </div>
                    )}

                    <Button onClick={openNewPassModal} title='Create new event pass' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                        + new pass
                    </Button>

                </div>

                <div className='table-responsive' >
                    <Table striped hover style={{ cursor: 'pointer' }}>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={toggleSelectAll}
                                    />
                                </th>
                                <th>No.</th>
                                <th>User Name</th>
                                <th>Gender</th>
                                <th>Email</th>
                                <th>Category</th>
                                <th>Event</th>

                            </tr>
                        </thead>
                        <tbody>
                            {userpasses?.data?.userPasses.map((data, index) => (
                                <tr key={index} >
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedRows.includes(data)}
                                            onChange={() => toggleRowSelection(data)}
                                        />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{data.FullName}</td>
                                    <td>{data.eventDate}</td>
                                    <td>{data.Email}</td>
                                    <td>{data.PassCategory.CategoryName}</td>
                                    <td>{data.PassEvents[0]?.event.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

            </div>
        )
    }
};

export default UserEventPasses;
