import { Container, Row, Col, Image, Table } from 'react-bootstrap';
import dashLogo from './images/logo.jpg';
import pinnisoftLogo from './images/pinnisoft.jpg';
import useFetch from '../hooks/hooks/getData';
import Loader from './Loader';
import { useEffect, useState } from 'react';
import * as MdIcons from 'react-icons/md';
import * as ImIcons from 'react-icons/im';
import * as FaIcons from 'react-icons/fa';
import * as GiIcons from 'react-icons/gi';
import * as HiIcons from 'react-icons/hi';
import numberToWords from 'number-to-words';
import { twit } from './Notificationpopout';
import { getInvoiceNumber } from '../Utilities/utilities';
import { fetchData } from '../Utilities/apiRequests';

const Invoice = ({ items, invoiceNumber,invoiceDate,setInvoiceNumberExt, printable,deliveryCost,setTotalCost }) => {

  const [loading, setLoading] = useState(false);
  const [InvoiceNumber, setInvoiceNumber] = useState(false);
  const [userData, setUserData] = useState();
  //const userData = useFetch("/api/auth/user/profile", setLoading);




  useEffect(() => {
    
      fetchData(`/api/auth/user/profile`, setLoading).then((response) => {
        setUserData(response.data.data)
      }).catch((error) => {
        setLoading(false)
        console.log('User profile loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading user profile')
      })
    

  }, []);






  const billingCompany = {
    name: 'PinniSOFT',
    address: 'Theta House,Plot 724/5,Mawanda Road,Kamwokya,Kampala-Uganda',
    phone: '256 393 969 600',
    email: 'info@pinnisoft.com',

  };

  const billedCompany = {
    name: userData?.regname,
    address: userData?.location,
    phone: userData?.phone,
    email: userData?.email
  };



  const deliveryFee = deliveryCost>=0? deliveryCost :5000;
  const handlingFees = 5000;
  const adminFees = 5000;

  const date = new Date().toLocaleDateString();
  const taxRate = 0.18;
  let total = 0;

  items?.filter((data) => {
    return data?.qty > 0
  })?.forEach(data => {
    total += (data?.amount);

  });




  const priceInWords = numberToWords.toWords(200000);

  useEffect(()=>{
    if(userData){
      setInvoiceNumber(getInvoiceNumber(userData?.regname?.substring(0, 3)?.toUpperCase()))
    }
    
  },[userData])

  useEffect(()=>{
   
    if(userData && setInvoiceNumberExt&&(!invoiceNumber)){
      setInvoiceNumberExt(getInvoiceNumber(userData?.regname?.substring(0, 3)?.toUpperCase()))
    }
  },[userData])


  useEffect(()=>{
   
    if(setTotalCost){
      setTotalCost(Number((total + (total * taxRate) + deliveryFee + handlingFees )))
    }
  },[])

  if (loading) {
    return (<Loader />);
  } else {

    return (
      <Container style={{ fontSize: "10px", display: "flex", flexDirection: "column", height: printable ? "1100px" : "100%", padding: printable ? "50px" : "0%" }}>
        <div style={{

          color: "black",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "4px",
          padding: "10px",
          justifyContent: "space-between"

        }}>

          <div
            style={{

              color: "black",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "4px",

              justifyContent: "space-between"

            }}
          >
            <div style={{ paddingLeft: "0%", paddingRight: "0%", backgroundColor: "white", maxWidth: "100%" }}>
              <Image src={pinnisoftLogo} fluid />
            </div>
            <div className='billing-company'>
              {/* <p style={{fontWeight:"bold",color:"green"}}>BILL FROM:</p>
          <p>{billingCompany.name}</p> */}
              <p><ImIcons.ImLocation2 />{billingCompany.address}</p>
              <p><GiIcons.GiRotaryPhone /> +{billingCompany.phone}</p>
              <p><HiIcons.HiOutlineMailOpen /> {billingCompany.email}</p>
            </div>
          </div>


          <div style={{ maxWidth: "50%", display: "flex", flexDirection: "row", justifyContent: "end", backgroundColor: "white" }}>
            <div style={{ paddingLeft: "40%", paddingRight: "0%", backgroundColor: "white", maxWidth: "80%" }}>
              <Image src={dashLogo} fluid />
            </div>

          </div>


        </div>

        <hr />
        <div
          style={{

            color: "black",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            // alignItems:"center",
            borderRadius: "4px",
            padding: "10px",
            justifyContent: "space-between"
          }}


        >


          <div className='billed-company'>
            <p style={{ fontWeight: "bold", color: "green" }}>Bill To:</p>
            <p>{billedCompany.name}</p>
            <p>{billedCompany.address}</p>
            <p>Phone: {billedCompany.phone}</p>
            <p>Email: {billedCompany.email}</p>
          </div>
          <div className='invoice-info'>
            <Row>
              <p style={{ fontWeight: "bold", color: "green" }}>Invoice Number: <span style={{ color: "black" }}>{invoiceNumber?invoiceNumber:InvoiceNumber}</span></p>

            </Row>
            <Row>
              <p style={{ fontWeight: "bold", color: "green" }}>Date: <span style={{ color: "black" }}>{invoiceDate?invoiceDate:date}</span></p>

            </Row>

          </div>
        </div>


        <div style={{ padding: "10px" }}>
          <Table striped bordered hover>
            <thead>
              <tr style={{ backgroundColor: "#569f51", color: "white" }}>
                <th>Description</th>
                <th>Quantity</th>
                <th>Unit Price (UGX)</th>
                <th>Amount (UGX)</th>
              </tr>
            </thead>
            <tbody>

              {items?.filter((data) => {
                return data?.qty > 0
              })?.map((data, index) => (


                <tr style={{ height: "1px" }} key={index} >

                  <td>{data?.name}</td>
                  <td style={{ textAlign: "center" }}>{data?.qty}</td>
                  <td style={{ textAlign: "right" }}>{data?.unitPrice?.toLocaleString()}</td>
                  <td style={{ textAlign: "right" }}>{data?.amount?.toLocaleString()}</td>

                </tr>
              ))}
              <tr style={{ border: "none" }}>
                <td colSpan="1" style={{ border: "none", color: "white", textAlign: "center" }} className="text-right"></td>
                <td colSpan="2" style={{ backgroundColor: "grey", color: "white", textAlign: "center" }} className="text-right">SubTotal</td>

                {/* <td colSpan="1" style={{ border: "none", backgroundColor: "grey", color: "white" }} className="text-right">SubTotal</td> */}
                <td colSpan="1" style={{ backgroundColor: "grey", color: "white", textAlign: "right" }} className="text-right">{total.toLocaleString()}</td>
              </tr>



            </tbody>

          </Table>




          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <Table bordered style={{ width: "65%" }}>
              <tbody >

                <tr style={{ backgroundColor: "#569f51", color: "white" }}>
                  <td colSpan="3">Charge</td>

                  <td colSpan="1">Amount</td>
                </tr>

                <tr>
                  <td colSpan="3" className="text-right">Tax ({(taxRate * 100).toFixed(0)}%)</td>
                  <td style={{ textAlign: "right" }}>{(total * taxRate).toLocaleString()}</td>
                </tr>
                {deliveryFee>0&&(
                  <tr>
                  <td colSpan="3" className="text-right">Delivery</td>
                  <td style={{ textAlign: "right" }}>{deliveryFee.toLocaleString()}</td>
                </tr>
                )}
                
                <tr>
                  <td colSpan="3" className="text-right">Handling fees</td>
                  <td style={{ textAlign: "right" }}>{handlingFees.toLocaleString()}</td>
                </tr>
                <tr style={{ border: "none" }}>

                  <td colSpan="3" style={{ border: "none", backgroundColor: "grey", color: "white", textAlign: "center" }} className="text-right">SubTotal</td>
                  <td colSpan="1" style={{ backgroundColor: "grey", color: "white", textAlign: "right" }} className="text-right">{((total * taxRate) + deliveryFee + handlingFees ).toLocaleString()}</td>
                </tr>

              </tbody>
            </Table>
          </div>
          <Table>
            <tbody>
              <tr style={{ border: "none" }}>

                <td colSpan="3" style={{ border: "none", backgroundColor: "#569f51", color: "white" }} className="text-right">Total</td>
                <td colSpan="1" style={{ border: "none", backgroundColor: "#569f51", color: "white", textAlign: "right" }} className="text-right">{(total + (total * taxRate) + deliveryFee + handlingFees ).toLocaleString()}</td>
              </tr>
            </tbody>

          </Table>
          <p style={{ margin: "0px" }}>Total in words: <span style={{fontWeight:"bold",fontStyle:"italic"}}>{numberToWords.toWords((total + (total * taxRate) + deliveryFee + handlingFees ))} shillings</span>. </p>
        </div>
        <div className="footer">
          <p style={{ margin: "0px" }}>Payment Terms: - Cash / Bank / Cheque.</p>
          <p className='text-muted' style={{ margin: "0px", fontSize: "10px" }}>All Bank (RTGS, EFT SWIFT or Direct Credit) & Cheque payments to be made to: -</p>
          <p style={{ margin: "0px" }}>EQUITY BANK</p>
          <p className='text-muted' style={{ margin: "0px", fontSize: "10px" }}>PINNISOFT – SMC LTD | Church House Branch | Account No.: 1001201310761</p>
          <p className='text-muted' style={{ margin: "0px", fontSize: "10px" }}>Accounts are due on demand for 7 days from date of invoice. </p>
          <p className='text-muted' style={{ margin: "0px", fontSize: "10px", fontStyle: "italic" }}>Thank you for your Business. All prices are exclusive of VAT. Please visit www.pinnisoft.com or Email : info@pinnisoft.com .</p>

        </div>


      </Container>
    );
  };
};

export default Invoice;
