import axios from '../api/axios';
const rid = sessionStorage.getItem('rid');
const token = sessionStorage.getItem('token');
const useProducts = async() => {
    const  productsUrl = `/api/products/${rid}`


        try {
          const response = await axios.get(productsUrl,{
            headers: {  Authorization: `Bearer ${token}`
        }

        });
           
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useProducts;