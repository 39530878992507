import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Table, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { convertFileToDataURL, downloadImage, downloadPDF, getNairobiTime, getformattedDate, removeSpacesInString, simulateLoading, splitArrayIntoChunks, threeDigits } from '../../Utilities/utilities';
import { fetchData } from '../../Utilities/apiRequests';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import * as GiIcon from 'react-icons/gi';
import * as GrIcon from 'react-icons/gr';
import * as BsIcons from 'react-icons/bs';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import visualPlacer from '../images/imagePlaceholder.png'
import { ReactMultiEmail } from 'react-multi-email';
import io from 'socket.io-client';
import { apiBaseUrl, fileUploadBaseUrl } from '../../Utilities/globals';
import PassPage from '../PassA4';
import ColorCube from '../ColorCube';
import ImagePreview from '../ImagePreview';
import ImageCropper from '../ImageCropper';
import Pagination from '../Pagination';

const EventPassAddsOnIdentities = () => {


    const [hname, setHname] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [eventPasses, setEventPasses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPasses, setShowPasses] = useState(false);
    const [faceImage, setFaceImage] = useState(false);
    const [humanRowData, setHumanRowData] = useState();
    const [updatePassInfo, setUpdatePassInfo] = useState();
    const [eventInfo, setEventInfo] = useState();
    const [imageLoaded, setImageLoaded] = useState(true);
    const [sendingMails, setSendingMails] = useState(false);
    const [numberSent, setNumberSent] = useState(0);
    const [socket, setSocket] = useState(null);
    const [email, setEmail] = useState('');
    const [sendPassOnMail, setSendPassOnMail] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    const [cropperImage, setCropperImage] = useState('');
    const [currentPageH, setCurrentPageH] = useState(1);
    const [numberToSend, setNumberToSend] = useState(0);
    const [passTitle, setPassTitle] = useState('');
    const [profession, setProfession] = useState('');
    const [initialTitle, setInitialTitle] = useState('');
    const [pTitles, setPTitles] = useState([]);
    const [otherTitle, setOtherTitle] = useState('');
    const [isActive, setIsActive] = useState(false);


    const { eid } = useParams();
    const faceformData = new FormData()
    faceformData.append("image", faceImage);





    useEffect(() => {



        fetchData(`/api/events/passidentities/all`, setLoading).then((response) => {
            setEventPasses(response.data.identities)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event passes.')
        })


        fetchData(`/api/events/passtitles`, setLoading).then((response) => {
            setPTitles(response.data.titles)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

    }, [refresh])


    useEffect(() => {

        const newSocket = io(apiBaseUrl,{transports: ['websocket', 'polling']});
        setSocket(newSocket);

    }, []);


    if (socket) {


        socket.on('PassSent', (data) => {



            if (numberToSend = 0) {
                setNumberToSend(data.total)
            }

            setNumberSent(data.sent)

        });


    }

    const getCurrentPassTitle = () => {
        const title = pTitles?.filter((data) => {
            return data.id == passTitle
        })[0].name

        return title
    }




    const createPassHuman = async (setLoading) => {
        const token = sessionStorage.getItem('token');

        const faceImageData = await convertFileToDataURL(faceImage)



        const postData = {
            name: hname,
            pcid: selectedCategory.id,
            eid,
            email,
            imageData: faceImageData,
            profession,
            title: passTitle ? passTitle : null,
            otherTitle,

        };

        const Url = '/api/events/passidentities/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Pass Identity created successfully.')

            }


        } catch (err) {
            setLoading(false);
            console.log("Event Pass  Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while creating event pass identity.')

        }
    };

    const updatePassHuman = async (setLoading) => {
        const token = sessionStorage.getItem('token');

        const faceImageData = await convertFileToDataURL(faceImage)

        const postData = {
            name: hname ? hname : humanRowData.name,
            email: email ? email : humanRowData.email,
            hpid: humanRowData.id,
            eid,
            imageData: faceImageData,
            profession,
            title: passTitle ? passTitle : null,
            otherTitle,
            pstatus: isActive
        };

        const Url = '/api/events/passidentities/update'
        try {
            setLoading(true);
            const response = await axios.patch(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Pass Identity updated successfully.')



            }


        } catch (err) {
            setLoading(false);
            console.log("Event Pass  Error:", err.response?.message)
            return Promise.reject(err.response?.data?.message ? err.response?.data?.message : 'An error occured while updating event pass.')

        }
    };


    const handlePassSubmit = (e) => {
        e.preventDefault();

        if (!hname) {
            twit('info', 'Please provide the name.')
        } else {
            createPassHuman(setLoading).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setHname('');
                setEmail('');
                setFaceImage('')
                setPassTitle('')
                setProfession('')
                setOtherTitle('')

            }).catch((error) => {
                twit('info', error)
            })


        }



    };


    const handlePassSubmitUpdate = (e) => {
        e.preventDefault();


        updatePassHuman(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            setHname('');
            setEmail('');
            setUpdatePassInfo(false)
            setFaceImage('')
            setPassTitle('')
            setOtherTitle('')
            setProfession('')
            setIsActive(false)
        }).catch((error) => {
            twit('info', error)
        })






    };







    //pagination logic
    const postsPerPage = 5

    const indexOfLastPostH = currentPageH * postsPerPage;
    const indexOfFirstPostH = indexOfLastPostH - postsPerPage;
    const paginatedPassHuman = eventPasses?.sort((a, b) => a.name.localeCompare(b.name))?.filter((data) => {
        return data.pcid == selectedCategory.id
    })?.slice(indexOfFirstPostH, indexOfLastPostH)
    const paginateH = (number) => {
        setCurrentPageH(number);

    }




    const otherPassTitleId = pTitles?.filter((title) => {
        return title?.name == 'Other'
    })[0]?.id



    if (loading) {
        return (<Loader message={sendingMails ? `Sent ${numberSent} of ${numberToSend}` : null} />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>Event Pass Identity Management</p>
                </div>


                {updatePassInfo && <Modal open={true} title={'Pass Management'} onCancel={() => {
                    setProfession('')
                    setUpdatePassInfo(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >



                        <Form onSubmit={handlePassSubmitUpdate}>

                            <Card>
                                <Card.Body >

                                    {/* <Card.Img variant="top" style={{ maxHeight: '350px' }} src={'https://st4.depositphotos.com/6903990/27898/i/450/depositphotos_278981062-stock-photo-beautiful-young-woman-clean-fresh.jpg'} /> */}

                                    <Row>

                                        <Col className='my-1' md={4}>
                                            <Card.Img variant="top" style={{ maxHeight: '350px' }} alt='face' src={imageLoaded ? `${humanRowData.face}` : visualPlacer} onLoad={() => {

                                            }} onError={() => {
                                                setImageLoaded(false)
                                            }} />
                                        </Col>


                                        <Col className='my-1' md={8}>
                                            <Row>
                                                <Col className='my-1' md={12}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}>Title<span style={{ color: "red" }}> *</span></p>
                                                        </div>
                                                        <Form.Select

                                                            name="passTitle"
                                                            onChange={(e) => {
                                                                setPassTitle(e.target.value)
                                                            }}
                                                            className='form-selector'>
                                                            <option className='input-box' key={1} value={''}>{initialTitle ? initialTitle : 'Select Pass Title'}</option>

                                                            {pTitles?.map((data) => {
                                                                return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                                            })}


                                                        </Form.Select>
                                                    </div>

                                                </Col>
                                                {((passTitle == otherPassTitleId) || (humanRowData.titleid == otherPassTitleId)) ?

                                                    <Col className='my-1' md={12}>
                                                        <Form.Group controlId="name">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Other Title</p>
                                                                </div>

                                                                <Form.Control
                                                                    required
                                                                    className=' input-box-events form-control-sm'
                                                                    type="text"
                                                                    placeholder="Enter Other Title"
                                                                    value={(otherTitle != '' ? otherTitle : humanRowData?.otherTitle)}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            setOtherTitle(e.target.value)
                                                                        } else {
                                                                            setOtherTitle(null)
                                                                        }


                                                                    }}

                                                                />
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                    :
                                                    <></>

                                                }
                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="name">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>



                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name<span style={{ color: "red" }}> *</span></p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="text"
                                                                placeholder="Enter name"
                                                                value={(hname != '' ? hname : humanRowData?.name)}
                                                                onChange={(e) => {

                                                                    if (e.target.value !== '') {
                                                                        setHname(e.target.value)
                                                                    } else {
                                                                        setHname(null)
                                                                    }
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>

                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="email">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>



                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Email</p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="email"
                                                                placeholder="Enter email"
                                                                value={(email != '' ? email : humanRowData?.email)}
                                                                onChange={(e) => {

                                                                    if (e.target.value !== '') {
                                                                        setEmail(e.target.value)
                                                                    } else {
                                                                        setEmail(null)
                                                                    }
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>

                                                    <Form.Group controlId="designation">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Designation</p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="text"
                                                                placeholder="Enter Designation"
                                                                value={profession}
                                                                onChange={(e) => setProfession(e.target.value)}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="faceImage">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Visual</p>
                                                            </div>
                                                            <Form.Control

                                                                accept='.jpeg,.jpg,.png'
                                                                name="faceImage"
                                                                onChange={(e) => {

                                                                    //setFaceImage(e.target.files[0]);
                                                                    setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                    setShowCropper(true)

                                                                }}
                                                                className=' input-box-events form-control-sm'

                                                                type="file" />
                                                        </div>

                                                    </Form.Group>

                                                </Col>

                                                <Col className='my-1' md={12} >

                                                    <Form.Group className='my-2' controlId="eventType">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                            <div className='d-flex flex-row   w-100'>



                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Is Active"
                                                                    name="active"
                                                                    checked={isActive}
                                                                    onChange={(e) => {
                                                                        setIsActive(!isActive)
                                                                    }}

                                                                    className="my-0 mx-1 input-box-events form-control-sm"
                                                                />




                                                            </div>
                                                        </div>


                                                    </Form.Group>

                                                </Col>
                                            </Row>
                                        </Col>




                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GrIcon.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Pass Identity
                                </Button>
                            </div>
                        </Form>



                    </div>


                </Modal>}



                {showCropper && cropperImage && <Modal open={true} title={`Pass Image Formating (600 * 600)`} onCancel={() => {
                    setShowCropper(false)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >

                        <Card>
                            <Card.Body >
                                <Row>
                                    <ImageCropper image={cropperImage} setImage={setCropperImage} setOpen={setShowCropper} setCropResultFile={setFaceImage} windowWidth={'300px'} windowHeight={'300px'} aspectRatio={(600 / 600)} />
                                </Row>
                            </Card.Body>
                        </Card>

                    </div>


                </Modal>}

                <div style={{ fontSize: '12px' }} className='table-responsive'>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{selectedCategory.name}</p>
                    </div>

                    <Form onSubmit={handlePassSubmit}>

                        <Card>
                            <Card.Body >
                                <Row>
                                    <Col className='my-1' md={6}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                            <div className='mx-2 FormLabels'>
                                                <p style={{ margin: 0 }}>Title</p>
                                            </div>
                                            <Form.Select

                                                name="passTitle"
                                                onChange={(e) => {
                                                    setPassTitle(e.target.value)
                                                }}
                                                className='form-selector'>
                                                <option className='input-box' key={1} value={''}>{passTitle ? getCurrentPassTitle() : 'Select Pass Title'}</option>

                                                {pTitles?.map((data) => {
                                                    return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                                })}


                                            </Form.Select>
                                        </div>

                                    </Col>
                                    {(passTitle == otherPassTitleId) ?

                                        <Col className='my-1' md={6}>
                                            <Form.Group controlId="name">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Other Title</p>
                                                    </div>

                                                    <Form.Control
                                                        required
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter Other Title"
                                                        value={otherTitle}
                                                        onChange={(e) => setOtherTitle(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        :
                                        <></>

                                    }
                                    <Col className='my-1' md={6}>
                                        <Form.Group controlId="name">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Name<span style={{ color: "red" }}> *</span></p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="text"
                                                    placeholder="Enter name"
                                                    value={hname}
                                                    onChange={(e) => setHname(e.target.value)}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={6}>
                                        <Form.Group controlId="email">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Email</p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="email"
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={6}>

                                        <Form.Group controlId="designation">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Designation</p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="text"
                                                    placeholder="Enter Designation"
                                                    value={profession}
                                                    onChange={(e) => setProfession(e.target.value)}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={6}>
                                        <Form.Group className='my-2' controlId="faceImage">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Visual</p>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                                                    <div>
                                                        <div className='my-1 ' style={{ width: '100%', }} >


                                                            <ImagePreview selectedImage={faceImage} size={'600 * 600'} containerHeight='300px' containerWidth='300px' containerHeightMobile='200px' containerWidthMobile='200px' placeholderMessage={'This will appear On The Generated Pass.'} />

                                                        </div>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <Form.Control

                                                            accept='.jpeg,.jpg,.png'
                                                            name="faceImage"
                                                            onChange={(e) => {

                                                                //setFaceImage(e.target.files[0]);
                                                                setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                setShowCropper(true)

                                                            }}
                                                            className=' input-box-events form-control-sm'

                                                            type="file" />
                                                    </div>
                                                </div>

                                            </div>

                                        </Form.Group>

                                    </Col>



                                </Row>



                            </Card.Body>
                        </Card>



                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add Pass Identity
                            </Button>
                        </div>
                    </Form>

                    {eventPasses?.length > 0 ?
                        <Table striped hover style={{ cursor: "pointer" }}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Name</th>
                                    <th> Status</th>
                                    {/* <th> State</th> */}
                                    <th> Sticker No. </th>
                                    <th>Operations</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedPassHuman?.map((data, index) => (
                                    <tr key={index} onClick={() => { }}>
                                        <td >{((currentPageH * 5) - postsPerPage) + (index + 1)}</td>
                                        <td >{data.name ? data.name : data.email}</td>
                                        <td >{!(data.pstatus) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                        {/* <td >{data.name ? <p style={{ color: 'green', fontSize: '12px', margin: '0px' }}>REGISTERED</p> : <p style={{ margin: '0px', color: 'red', fontSize: '12px' }}>PENDING</p>}</td> */}
                                        <td >{data.indexNumber ? threeDigits(Number(data.indexNumber)) : 'N/A'}</td>
                                        <td>


                                            <GrIcon.GrUploadOption className='mx-1' title='Update Pass Details' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                setHname('')
                                                setHumanRowData(data)
                                                const titleData = pTitles?.filter((tt) => {
                                                    return tt.id == data.titleid
                                                })
                                                setProfession(data.profession)
                                                setInitialTitle(titleData[0]?.name)
                                                setPassTitle(data.titleid)
                                                setUpdatePassInfo(true)
                                                setIsActive(data.pstatus)

                                            }} />


                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        :

                        <p>No pass identities added.</p>

                    }
                    {eventPasses?.length > 0 && (
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={eventPasses.filter((data) => {
                                return data.pcid == selectedCategory.id
                            })?.length}
                            paginate={paginateH}
                            currentPage={currentPageH}
                        />
                    )}


                </div>

            </Container>
        );
    }
};

export default EventPassAddsOnIdentities;
