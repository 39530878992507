import React, { createContext, useReducer } from 'react';
import cartreducer from './CartReducer';
import axios from '../api/axios'
import { twit } from "../components/Notificationpopout";
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchData } from '../Utilities/apiRequests';

let initialstate = []











export const GeneralContext = createContext(initialstate);

const GeneralStateProvider = ({ children }) => {

  const [currency, setCurrency] = useState('UGX');
  const [accounts, setAccounts] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const  base_code='UGX'

  const getExchangeRates = async () => {
    
    const exchangeRatesUrl = `https://v6.exchangerate-api.com/v6/bc628f85c8c4e66d59b7ab30/latest/${base_code}`


    try {
      const response = await axios.get(exchangeRatesUrl);

      
      if (response.status === 200) {
        return Promise.resolve(response.data?.conversion_rates)
      }




    } catch (err) {
      console.log("Exchange rates error:", err)
      return Promise.reject('Error loading exchange rates.')

    }



  }

  return (

    <GeneralContext.Provider value={{ currency , actions:{setRefresh,setCurrency},refresh}}>
      {children}
    </GeneralContext.Provider>
    
  )
}


export default GeneralStateProvider;