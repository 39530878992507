import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { formatPrice, getNairobiTime, getNairobiTimeWithParam, getformattedDate } from '../../Utilities/utilities'
import Loader from '../Loader';
import { fetchData } from '../../Utilities/apiRequests';
import Pagination from '../Pagination';
import { costPerCredit } from '../../Utilities/globals';
import { Button, Modal } from 'antd';
import useGeneralState from '../../hooks/hooks/generalState';
import * as GiIcon from 'react-icons/gi';
import axios from '../../api/axios';
import { twit } from '../Notificationpopout';

const CashOutRequests = () => {


    const [starts, setStarts] = useState();
    const [ends, setEnds] = useState();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [requestsData, setRequestsData] = useState([]);
    const [requests, setRequests] = useState([]);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [amount, setAmount] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [availableBalance, setAvailableBalance] = useState(0);

    const inputRef = useRef(null);

    useEffect(() => {
        fetchData(`/api/billing/accounts/collection/cashout/requests`, setLoading).then((response) => {

            setRequestsData(response.data.requests)

        }).catch((error) => {
            console.log('Cash out requests loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading cash out requests.')
        })

        fetchData(`/api/billing/accounts/collection/balance`, setLoading1).then((response) => {

            setAvailableBalance(Number(response.data.balance))

        }).catch((error) => {
            console.log('Cash out balance loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading cash out balance.')
        })

    }, [refresh]);




    useEffect(() => {



        if (requestsData) {
            if ((!ends) && (!starts)) {
                setRequests(requestsData)
            } else if ((!ends) && starts) {
                setRequests(requestsData.filter((data) => {
                    return (data.createdAt).split('T')[0] >= starts
                }))
            } else if ((!starts) && ends) {
                setRequests(requestsData.filter((data) => {
                    return (data.createdAt).split('T')[0] <= ends
                }))
            } else if (starts && ends) {
                setRequests(requestsData.filter((data) => {
                    return ((data.createdAt).split('T')[0] <= ends) && ((data.createdAt).split('T')[0] >= starts)
                }))
            }
        }


    }, [requestsData, ends, starts]);


    useEffect(() => {
        if (showRequestModal) {

            inputRef?.current?.focus();

        }

    }, [showRequestModal])


    const submitCashOutRequest = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            amount
        };

        const Url = '/api/billing/accounts/collection/cashout/request'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Cash Out Request Submitted Successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Cash Out Request Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while requesting cash out.')

        }
    };



    const handleSubmit = (e) => {
        e.preventDefault();


        submitCashOutRequest(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            setAmount(0)
            setShowRequestModal(false)
        }).catch((error) => {
            twit('info', error)
        })

    };



    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedRequests = requests?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }



    const generalAppState = useGeneralState();
    const userCurrency = (generalAppState?.currency)




    if (loading||loading1) {
        return (<Loader />);
    } else {

        return (
            <Container>
                {showRequestModal && <Modal open={true} title={'Withdraw Request'} onCancel={() => {
                    setShowRequestModal(false)
                    setAmount(0)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >
                    <Form onSubmit={handleSubmit}>

                        <Card>
                            <Card.Body >

                                <Row>
                                <Col className='my-1' md={12}>
                                        <Form.Group controlId="balance">

                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Balance</p>
                                                </div>
                                                <InputGroup>
                                                    <Form.Control
                                                        disabled
                                                        type="number"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter amount"
                                                        value={availableBalance}
                                                               
                                                    />
                                                    <InputGroup.Text style={{ fontSize: '10px' }} >
                                                        {userCurrency ? userCurrency : 'UGX'}
                                                    </InputGroup.Text>
                                                </InputGroup>

                                            </div>


                                        </Form.Group>
                                    </Col>
                                    <Col className='my-1' md={12}>
                                        <Form.Group controlId="cost">

                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Amount</p>
                                                </div>
                                                <InputGroup>
                                                    <Form.Control
                                                        ref={inputRef}
                                                        type="number"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter amount"
                                                        value={amount}
                                                        onChange={(e) => { setAmount(e.target.value) }}
                                                        required
                                                        min={10000}
                                                        // max={availableBalance}
                                                    />
                                                    <InputGroup.Text style={{ fontSize: '10px' }} >
                                                        {userCurrency ? userCurrency : 'UGX'}
                                                    </InputGroup.Text>
                                                </InputGroup>

                                            </div>


                                        </Form.Group>
                                    </Col>

                                </Row>


                            </Card.Body>
                        </Card>



                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Submit
                            </Button>
                        </div>
                    </Form>




                </Modal>}
                <Row className='bills-summary-header'>
                    <Col md={6} >
                        <p className='mb-0' style={{ fontSize: '18px', fontWeight: 'bold' }}>Withdraw Requests</p>
                        <p className='text-muted my-2'>Cash Out requests Information</p>
                    </Col>
                    <Col md={6} >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'end' }}>

                            <Button onClick={() => { setShowRequestModal(true) }} title='Create New Cash Out Request ' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" >
                                + | New Request
                            </Button>

                        </div>
                    </Col>
                </Row>

                <Row className='my-2' >

                    <Col md={6} className='bill-summary-dates' style={{ border: 'none' }}>
                        <p className='mb-0 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>Request Period</p>
                        <Row>
                            <Col md={6} className='my-2'>
                                <Form.Group className='my-2' controlId="eventDate">
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                        <div className='mx-2 FormLabels'>
                                            <p style={{ margin: 0 }}>Start</p>
                                        </div>
                                        <Form.Control
                                            className=' input-box-events form-control-sm'
                                            type="date"
                                            name="startDate"
                                            value={starts}
                                            onChange={(e) => {
                                                setStarts(e.target.value)
                                            }}

                                        />
                                    </div>

                                </Form.Group>
                            </Col>
                            <Col md={6} className='my-2'>

                                <Form.Group className='my-2' controlId="endDate">

                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                        <div className='mx-2 FormLabels'>
                                            <p style={{ margin: 0 }}>End</p>
                                        </div>
                                        <Form.Control
                                            className=' input-box-events form-control-sm'
                                            type="date"
                                            name="endDate"
                                            value={ends}
                                            onChange={(e) => {
                                                setEnds(e.target.value)
                                            }}
                                            min={starts}
                                        />
                                    </div>

                                </Form.Group>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <Row style={{ borderTop: '1px solid gray' }}>
                    <p className='mb-0 mt-2 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>Requests</p>
                    <div className='table-responsive'>
                        {paginatedRequests?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Submitted</th>
                                        <th>Modified</th>
                                        <th style={{ textAlign: "right" }}>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedRequests?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.createdAt)}</td>
                                            <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{data.modifiedAt?getformattedDate(data.modifiedAt):''}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.amount)}</td>
                                            <td style={{ color: data?.CashOutStatus?.name == 'SUCCEEDED' ? 'green' : data?.CashOutStatus?.name == 'FAILED' ? 'red' : '#FFBF00'}} >{data?.CashOutStatus?.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No Requests.</p>

                        }

                        {requests?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={requests?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>

                </Row>


            </Container>
        )
    }
}

export default CashOutRequests
