import { Button } from 'antd';
import React from 'react'
import { Card, Row, Col } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { useNavigate } from 'react-router-dom';

const AssetsDashboard = () => {

  const navigate = useNavigate();



  const data = [
    { name: 'Category A', value: 30 },
    { name: 'Category B', value: 45 },
    { name: 'Category C', value: 25 },
    
  ];


  const branchData = [
    { branch: 'Main Branch ', assets: 50 },
    { branch: 'Kampala Branch ', assets: 75 },
    { branch: ' Kamwokya Branch ', assets: 30 },
  ];

  const totalAssets = 500; // Example: Total number of assets

  const assetsByStatus = [
    { name: 'In Use', value: 250 },
    { name: 'Damaged', value: 50 },
    { name: 'Lost', value: 30 },
    { name: 'Maintenance', value: 70 },
  ];

  const assetsByCategory = [
    { name: 'Electronics', value: 180 },
    { name: 'Furniture', value: 80 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']; // Colors for the Pie Chart
  return (
    <div style={{fontSize:'12px'}}>

      <Row>
        <div className='d-flex flex-row justify-content-end w-100'>
          <Button onClick={() => { navigate('/assets/operations') }} title='View operations' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
           Operations
          </Button>
        </div>

      </Row>



      {/* <Row className='my-2'>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Asset Tagging Statistics</Card.Title>
              <BarChart width={200} height={150} data={data}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Category A</Card.Title>
              <PieChart width={200} height={150}>
                <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8">
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Card.Body>
          </Card>
        </Col>



      </Row> */}

      <Row className='my-2'>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Assets Per Branch</Card.Title>
              <BarChart width={200} height={150} data={branchData}>
                <XAxis dataKey="branch" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="assets" fill="#0088FE" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Total Number of Assets</Card.Title>
              <BarChart width={200} height={150} data={[{ name: 'Total', value: totalAssets }]}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#00C49F" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className='my-2'>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Assets by Status</Card.Title>
              <PieChart width={200} height={150}>
                <Pie data={assetsByStatus} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
                  {assetsByStatus.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Assets by Category</Card.Title>
              <PieChart width={200} height={150}>
                <Pie data={assetsByCategory} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
                  {assetsByCategory.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AssetsDashboard;
