import React from 'react'
import { useNavigate } from 'react-router-dom';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { Card, Col, Row } from 'react-bootstrap';

const EventTicketAddsOnHome = () => {


    const navigate = useNavigate();


    const categories = [

        {
            title: 'Box Office Sellers',
            icon: <MdIcons.MdOutlineCreate title='Create new sellers' style={{ height: "30px", width: "30px" }} />,
            clickFunction: () => { navigate('/events/addson/ticket/ticketSellers') }
        },
        {
            title: 'Ticket Vendors',
            icon: <MdIcons.MdOutlineCreate title='Create new vendors' style={{ height: "30px", width: "30px" }} />,
            clickFunction: () => { navigate('/events/addson/ticket/vendors') }
        },
        {
            title: 'Assignable Tickets',
            icon: <MdIcons.MdOutlineAssignment title='Assign Tickets To Event' style={{ height: "30px", width: "30px" }} />,
            clickFunction: () => { navigate('/events/addson/ticket/assignableTickets') }
        }
    ];


    return (
        <div>
            <Row className='mt-2'>

                {categories.map((category, index) => (

                    <Col md={3}>
                        <Card key={index} className='document-card'
                            onClick={category.clickFunction}
                        >
                            <Card.Body style={{ textAlign: "center" }}>
                                <div>
                                    {category.icon}
                                </div>
                                <Card.Text>
                                    {category.title}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}

            </Row>

        </div>
    )
}

export default EventTicketAddsOnHome;
