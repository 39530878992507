import {  Card } from 'react-bootstrap';

const Card3=()=> {
    return (
      <Card className='dash-card'>
        <Card.Body>
          <Card.Title>Card 3</Card.Title>
          <Card.Text>This is the content of Card 3</Card.Text>
        </Card.Body>
      </Card>
    );
  }
  
  export default Card3;