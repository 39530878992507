import React, { useEffect, useState } from 'react'
import { Container, Card, Col } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../Utilities/apiRequests';
import { twit } from '../Notificationpopout';
import Loader from '../Loader';

const EventManagementAddsOn = () => {

    const [userProducts, setUserProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();



    const categories = [
        {
            title: 'Pass',
            icon: <BsIcons.BsPass title='Subscribe To Pass' style={{ height: "30px", width: "30px" }} />,
            link: '/events/addson/description?concept=pass'
        },
        {
            title: 'Coupons',
            icon: <RiIcons.RiCoupon2Fill title='Subscribe To Coupon' style={{ height: "30px", width: "30px" }} />,
            link: '/events/addson/description?concept=coupons'
        },
        {
            title: 'Tickets',
            icon: <BsIcons.BsTicketPerforated title='Subscribe To Ticket' style={{ height: "30px", width: "30px" }} />,
            link: '/events/addson/description?concept=tickets'
        }
    ];

    const getCategoryDetails = (name) => {
        return {
            ...categories.filter((data) => {
                return data.title == name
            }).map((data) => {
                return { icon: data.icon, link: data.link }
            })[0]
        }
    }

    const isAlreadySubscribedto = (name) => {
        if(userProducts.includes(name)){
            return true
        }else{
            return false
        }
    }

    useEffect(() => {

        fetchData(`/api/events/products/myproducts`, setLoading).then((response) => {

            setUserProducts(response.data.products.map((data) => {
                return data.productName
            }))

        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading products')
        })

        fetchData(`/api/events/products`, setLoading).then((response) => {

            setAllProducts(response.data.products.filter((data) => {
                return data.productName != 'Events'
            }).map((data)=>{
                return {...data,...getCategoryDetails(data.productName)}
            }))

        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading products')
        })


    }, [])



    if (loading) {
        return (<Loader />);
    } else {


    return (
        <div>
            <Col style={{ minHeight: '100vh' }}>
                <div className='card-operations-row' >

                    {allProducts.map((category, index) => (
                        <Card key={index} className={isAlreadySubscribedto(category.productName)?'document-card-inactive':'document-card'}
                            onClick={() => {
                                if(!isAlreadySubscribedto(category.productName)){
                                    navigate(`${category.link}&productid=${category.productId}`)
                                }
                               
                            }}

                        >

                            <Card.Body style={{ textAlign: "center" }}>
                                <div>
                                    {category.icon}
                                </div>

                                <Card.Text>
                                    {category.productName}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}

                </div>

            </Col>
        </div>
    )
}

}

export default EventManagementAddsOn;
