import { Button, Modal } from 'antd'
import React from 'react'
import { Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { downloadPDFV2, getformattedDate, removeSpacesInString, splitArrayIntoChunks } from '../../Utilities/utilities';
import { fetchData } from '../../Utilities/apiRequests';
import * as MdIcons from 'react-icons/md';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import * as GrIcon from 'react-icons/gr';
import Loader from '../Loader';
import { useState } from 'react';
import { useEffect } from 'react';
import Pagination from '../Pagination';
import TicketTagPage from '../TicketTagLable';
import QRCodePage from '../tagPageA4';

const TagPurchase = () => {

    const [type, setType] = useState('');
    const [numberOfTags, setNumberOfTags] = useState(1);
    const [eid, setEid] = useState('');
    const [zid, setZid] = useState('');
    const [zones, setZones] = useState([]);
    const [events, setEvents] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [orders, setOrders] = useState([]);
    const [tagtypes, setTagtypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [tagData, setTagData] = useState([]);
    const [selectedOrderid, setSelectedOrderid] = useState('');
    const [pageFormat, setPageFormat] = useState(1)
    const [lableType, setLableType] = useState('');


    useEffect(() => {

        fetchData(`/api/events/list`, setLoading).then((response) => {

            setEvents(response.data.events)

        }).catch((error) => {
            console.log('collections transactions  events loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading collection transactions events.')
        })

        fetchData(`/api/events/presaletags/tagtypes`, setLoading).then((response) => {

            setTagtypes(response.data.tagTypes)

        }).catch((error) => {
            console.log('tagTypes loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading tagTypes.')
        })

        fetchData(`/api/events/presaletags/orders`, setLoading1).then((response) => {

            setOrders(response.data.orders)

        }).catch((error) => {
            console.log('Orders loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading orders.')
        })

    }, [refresh])


    useEffect(() => {

        if (eid) {
            fetchData(`/api/events/zones/${eid}`, setLoading).then((response) => {

                setZones(response.data.zones)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event zones.')
            })
        }


    }, [eid])

    useEffect(() => {

        if (selectedOrderid) {
            fetchData(`/api/events/presaletags/orders/tags/${selectedOrderid}`, setLoading).then((response) => {

                setTagData(response.data.tags)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event zones.')
            })
        }


    }, [selectedOrderid])


    const orderTags = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            tagType: type,
            eid,
            zid,
            numberOfTags
        };
        const Url = '/api/events/presaletags/tickets'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false)
                setEid('')
                setZid('')
                setNumberOfTags(1)
                setTagData(response.data.generatedTags)
                setRefresh(!refresh)
                return Promise.resolve('Tag(s) Generated Successfully.')

            }


        } catch (error) {
            setLoading(false);
            console.log("Tag ordering creation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while ordering tags.')

        }
    };


    const getLabelType = (tagTypeid) => {
        const tagTypeName = tagtypes.filter((data) => {
            return data.cid == tagTypeid
        })[0]?.ctname

        if (tagTypeName == 'QRCODE') {
            return 1
        } else if (tagTypeName == 'BARCODE') {
            return 3
        } else if (tagTypeName == 'PDF417') {
            return 2
        } else {
            return 1
        }

    }

    const getA4pageData = (data) => {
        const arraySize = data?.length
        const page1size = [1, 4, 6].includes(getLabelType(type)) ? 25 : 45;
        const nextPageSize = [1, 4, 6].includes(getLabelType(type)) ? 30 : 50
        const firstChunk = data?.slice(0, page1size);
        const lastChunk = data?.slice(page1size, arraySize);
        return { page1Data: firstChunk, otherData: splitArrayIntoChunks(lastChunk, nextPageSize) }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!eid || !zid || !type || !pageFormat) {
            twit('info', 'All form fields are required.', 4)
        } else {
            orderTags(setLoading).then((message) => {
                
                twit('success', message, 4)
            }).catch((message) => {
                twit('info', message, 4)
            })
        }



    };

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedOrders = orders?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }



    if (loading||loading1) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <Modal open={(tagData.length > 0)} title={'Powered by PinniTAGS'} onCancel={() => {
                    setTagData([])
                    setType('')
                    setPageFormat(1)
                    setSelectedOrderid('')
                }}
                    footer={[


                    ]}
                    style={{}}
                    closable={true}
                    className='allmodals pageA4'

                >

                    {pageFormat == 1 && (
                        <div id="page">
                            <QRCodePage pageNumber={0} page1Data={getA4pageData(tagData).page1Data} />
                            {getA4pageData(tagData).otherData?.map((chunk, index) => (
                                <div key={index}>
                                    <QRCodePage dataArray={chunk} pageNumber={(index + 1)} />
                                </div>

                            ))}

                        </div>
                    )}

                    {pageFormat == 2 && (
                        <div id="page">

                            {tagData.map((data, index) => (
                                <div className=" html2pdf__page-break" key={index}>
                                    <TicketTagPage labelData={data} labelType={getLabelType(type)} />
                                </div>

                            ))}

                        </div>
                    )}





                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                        <Form.Group controlId="pageFormat">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                <div className='mx-2 FormLabels'>
                                    <p style={{ margin: 0 }}>Page Format<span style={{ color: "red" }}> *</span></p>
                                </div>
                                <Form.Select
                                    required
                                    name="tagtype"
                                    onChange={(e) => {
                                        setPageFormat(e.target.value)
                                    }}
                                    className='form-selector'>
                                    {/* <option className='input-box' key={1} value={''}>Select Page Format</option> */}
                                    {[{ name: 'A4', id: 1 }, { name: '51mm*25mm', id: 2 }]?.map((data) => {
                                        return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                    })}


                                </Form.Select>
                            </div>
                        </Form.Group>
                    </div>


                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='button' onClick={() => {
                            if (pageFormat == 1) {
                                downloadPDFV2('page', tagData[0].orderNumber ? `${tagData[0].orderNumber}-TAGS` : `TAGS`)
                            } else if (pageFormat == 2) {
                                downloadPDFV2('page', tagData[0].orderNumber ? `${tagData[0].orderNumber}-TAGS` : `TAGS`, [1, 4, 6].includes(getLabelType(type)) ? [28, 54] : [51, 27], [1, 4, 6].includes(getLabelType(type)) ? null : 'landscape')
                            }

                        }} >
                            <GrIcon.GrDownload className='mx-1' style={{ width: '15px', height: '15px' }} /> | Download Tags
                        </Button>
                    </div>




                </Modal>
                <Row>
                    <Col>
                        <p style={{ fontWeight: 'bold' }}>Order Tags </p>
                        <Form onSubmit={handleSubmit}>

                            <Card>
                                <Card.Body >
                                    <Row>
                                        <Col className='my-1' md={3}>
                                            <Form.Group controlId="event">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Event<span style={{ color: "red" }}> *</span></p>
                                                    </div>
                                                    <Form.Select

                                                        name="event"
                                                        onChange={(e) => {
                                                            setEid(e.target.value)
                                                        }}
                                                        className='form-selector'>
                                                        <option className='input-box' key={1} value={''}>{eid ? events.filter((data) => (data.eid == eid))[0].name : 'Select Event'}</option>
                                                        {events?.map((data) => {
                                                            return <option className='input-box' key={data.eid} value={data.eid}>{data.name}</option>
                                                        })}


                                                    </Form.Select>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        {eid && (
                                            <Col className='my-1' md={3}>
                                                <Form.Group controlId="zone">
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}>Zone<span style={{ color: "red" }}> *</span></p>
                                                        </div>
                                                        <Form.Select
                                                            required
                                                            name="zone"
                                                            onChange={(e) => {
                                                                setZid(e.target.value)
                                                            }}
                                                            className='form-selector'>
                                                            <option className='input-box' key={1} value={''}>Select Zone</option>
                                                            {zones?.map((data) => {
                                                                return <option className='input-box' key={data.zid} value={data.zid}>{data.name}</option>
                                                            })}


                                                        </Form.Select>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        )}
                                        {eid && (
                                            <Col className='my-1' md={3}>
                                                <Form.Group controlId="tagtype">
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}>Type<span style={{ color: "red" }}> *</span></p>
                                                        </div>
                                                        <Form.Select
                                                            required
                                                            name="tagtype"
                                                            onChange={(e) => {
                                                                setType(e.target.value)
                                                            }}
                                                            className='form-selector'>
                                                            <option className='input-box' key={1} value={''}>Select Tag Type</option>
                                                            {tagtypes?.map((data) => {
                                                                return <option className='input-box' key={data.cid} value={data.cid}>{data?.ctname}</option>
                                                            })}


                                                        </Form.Select>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        )}





                                        {eid && zid && (
                                            <Col className='my-1' md={3}>
                                                <Form.Group className='my-2' controlId="tagNumber">
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}>Number <span style={{ color: "red" }}> *</span>  </p>
                                                        </div>
                                                        <Form.Control
                                                            type="number"
                                                            className=' input-box-events form-control-sm'
                                                            placeholder="Enter number of tags"
                                                            value={numberOfTags}
                                                            onChange={(e) => {

                                                                setNumberOfTags(e.target.value);


                                                            }}
                                                            min={1}
                                                            required
                                                        />
                                                    </div>


                                                </Form.Group>
                                            </Col>
                                        )}




                                    </Row>


                                    <Row>

                                        {/* <Col md={12}>

                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className='my-2'>
                                                <p className='text-muted' style={{ margin: 0 }}><MdIcons.MdInfoOutline className='mx-1 ' style={{ width: '15px', height: '15px' }} /> | Each batch contains 50 tags</p>
                                            </div>

                                        </Col> */}
                                    </Row>


                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <MdIcons.MdAddShoppingCart className='mx-1' style={{ width: '15px', height: '15px' }} /> | Order Tags
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <p style={{ fontWeight: 'bold' }}> Orders </p>
                    <div className='table-responsive'>
                        {orders?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Order No.</th>
                                        <th>Tags Total</th>
                                        <th>Tags Sold</th>
                                        <th style={{ textAlign: "right" }}>Unit Credits</th>
                                        <th style={{ textAlign: "right" }}>Total Credits</th>
                                        <th>Event</th>
                                        <th>Zone</th>
                                        <th>Tag Type</th>
                                        <th>Operations</th>
                                        <th>Date</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedOrders?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.orderNumber}</td>
                                            <td>{data.totalGenerated}</td>
                                            <td>{data.totalSold}</td>
                                            <td style={{ textAlign: "right" }}>{data.unitCost}</td>
                                            <td style={{ textAlign: "right" }}>{data.totalCost}</td>
                                            <td>{data.eventDetails?.name}</td>
                                            <td>{data.zoneDetails?.name}</td>
                                            <td>{data.tagTypeName}</td>
                                            <td>


                                                <GrIcon.GrDownload className='mx-1' title='Download Order Tags' style={{ height: "15px", width: "15px" }} onClick={() => {

                                                    setSelectedOrderid(data.orderNumber)
                                                    setType(data.tagTypeid)
                                                }} />



                                            </td>
                                            <td>{getformattedDate(data.date)}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No Orders.</p>

                        }
                        {orders?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={orders?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        )
    }
}

export default TagPurchase
