import React from 'react';
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import { Outlet, useNavigate} from 'react-router-dom';
import BreadcrumbNav from '../BreadCrumb';




const AssetManagementCard = () => {
   
    const navigate = useNavigate();

    return (
        <Container style={{ fontSize: "12px" }} className='my-3'>
            <BreadcrumbNav/>
            <Card style={{ fontSize: "12px", minHeight: "100vh" }}>
                <Card.Header style={{ fontSize: "15px", fontWeight: "bold" }}>Asset Management</Card.Header>
                <Card.Body>

                    <div >
                        <Outlet />
                    </div>

                </Card.Body>
            </Card>
        </Container>

    );
};

export default AssetManagementCard;
