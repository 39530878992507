import React, { useState } from 'react';
import { Form, Card,  Row, Col, Container } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import useDoctypes from '../hooks/getDocTypes';
import useMarital from '../hooks/getMaritalst';
import useGender from '../hooks/getGender';
import { useEffect } from 'react';
import { Modal } from 'antd';
import ColoredD from './ColorSport';
import useCountries from '../hooks/getCountry';
import axios from '../api/axios';
import Loader from './Loader';
import { twit } from './Notificationpopout';
import useFetch from '../hooks/hooks/getData';
import usePost from '../hooks/hooks/postData';
import FormStatusCard from './RegistrationSections';
import { Percentage } from '../Utilities/utilities';
import { Button } from 'antd';
import * as GiIcon from 'react-icons/gi';

const IdentityForm = () => {

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [doctype, setDoctype] = useState('');
  const [docNo, setDocNo] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState(new Date());
  const [marital, setMarital] = useState('');
  const [position, setPosition] = useState('');
  const [signature, setSignature] = useState('');
  const [fingerprint, setFingerprint] = useState('');
  const [face, setFace] = useState('');
  const [eye, setEye] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [address, setAddress] = useState('');
  const [nationality, setNationality] = useState('222');
  const [kfname, setKfname] = useState('');
  const [klname, setKlname] = useState('');
  const [kemail, setKemail] = useState('');
  const [krelation, setKrelation] = useState('');
  const [kphone, setKphone] = useState('');
  const [kaddress, setKaddress] = useState('');
  const [kgender, setKgender] = useState('');
  const [doctypedata, setDoctypdata] = useState();
  const [maritaldata, setMaritaldata] = useState();
  const [genderdata, setGenderdata] = useState();
  const [countrydata, setCountrydata] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [registrationType, setRegistrationType] = useState("Adult");
  const [cardType, setCardType] = useState("4DC66684-1AE0-4C7E-BEB0-867B15C7B23B");
  const [existingCard, setExistingCard] = useState(false);
  const [cardId, setCardId] = useState("");
  const [phoneLength, setPhonelength] = useState(true);
  const [selectedMarital, setSelectedMarital] = useState("Select Your Marital status.");
  const [selectedKgender, setSelectedKgender] = useState("Select Your Gender.");
  const [selectedGender, setSelectedGender] = useState("Select Your Gender.");
  const [selectedDoctype, setSelectedDoctype] = useState("Select Government Document.");
  const [existingUserName, setExistingUserName] = useState("");
  const [personalCompleted, setPersonalCompleted] = useState(false);
  const [nextofkinCompleted, setNextofkinCompleted] = useState(false);
  const [biometricsCompleted, setBiometricsCompleted] = useState(false);




  const doctypes = useDoctypes();
  // const maritals = useMarital();
  // const genders = useGender();
  const countries = useCountries();
  const cardTypeData = useFetch("/api/products/card/types", () => { });
  const allmaritalData = useFetch('/api/marital-status', () => { });
  const allgenderData = useFetch('/api/gender', () => { });
  const { post } = usePost(`/api/products/add/card/${cardId}`, setLoading);









  const getUserData = async (id) => {

    try {
      const response = await axios.get(`/api/products/card/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }

      });
      
      setExistingUserName(response?.data?.data.fhname + " " + response?.data?.data.lhname);

    } catch (err) {
      twit("info", "Invalid card Id entered.")
    }


  }





  useEffect(() => {
    doctypes.then((data) => {
      setDoctypdata(data);
    });

    // maritals.then((data) => {
    //   setMaritaldata(data);
    // });

    // genders.then((data) => {

    //   setGenderdata(data);
    // });

    countries.then((data) => {

      setCountrydata(data)
    })

  }, []);

  const handleCancelerror = () => {
    setErrorMsg(false);

  }

  const handleCancel = () => {

    setExistingCard(false)

  }

  const handleRegistrationTypeChange = (e) => {
    setRegistrationType(e.target.value);
  };

  //getting the status of completion

  const getCompletionStatus = () => {
    const dataObject = {
      fname, lname, gender, phone, doctype, docNo, dob, nationality, email, address, marital, kfname, klname,
      kphone, kgender, kemail, kaddress, krelation
    }
    const nullValuesCount = Object.values(dataObject).filter(val => val === null || val === "").length;
    if (nullValuesCount === 0) {
      return ('F0B7C01D-F120-43A7-A843-AFEDC624A7BA');

    } else {
      return ('A5BC347D-D4FD-43E5-924A-938CE333A2D4');
    }

  }





  //All form submission logic


  const registerUrl = "/api/products/register/card";
  const token = sessionStorage.getItem('token');
  const payload = {

    maritalStatus: marital,
    gender: gender,
    email: email,
    address: address,
    fhname: fname,
    lhname: lname,
    gid: doctype,
    docno: docNo,
    contactno: phone,
    nationalityNo: nationality,
    position: position,
    dob: dob,
    regstatus: getCompletionStatus(),
    cardType: cardType,

    fName: kfname,
    lName: klname,
    contact: kphone,
    kemail: kemail,
    relationship: krelation,
    genderId: kgender,
    nextofkinaddress: kaddress




  }

  const clearAll = () => {
    setFname("");
    setLname("");
    setDoctype("");
    setDocNo("");
    setEmail("");
    setDob("");
    setMarital("");
    setPosition("");
    setKfname("");
    setAddress("");
    setKlname("");
    setKemail("")
    setKrelation("");
    setKphone("256");
    setKaddress("");
    setPhone("256");
    setSelectedDoctype("Select Government Document");
    setSelectedGender("Select Your Gender.");
    setSelectedKgender("Select Your Gender.");
    setSelectedMarital("Select Your Marital status.")
  }

  const formData = new FormData()
    formData.append("image", face);

  const register = async () => {

    setLoading(true);

    try {
      const response = await axios.post(registerUrl,
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json', Authorization: `Bearer ${token}`
          }

        }

      );

      const hid = response.data.hid;


      if(face!==''){
        const faceresponse = await axios.patch(`/api/products/card/face/${hid}`, formData, {
          headers: {
              'content-type': 'multipart/form-data'
              , Authorization: `Bearer ${token}`
          }

      });

      if(faceresponse.status === 201){
        setLoading(false);
        twit("success", "Registered successfully", 3);
        clearAll();
      }

      }else{
        if (response.request.status === 201) {
          setLoading(false);
          twit("success", "Registered successfully", 3);
          clearAll();
        }
      }
      

    } catch (err) {
      setLoading(false);

      if (!err?.response) {

        twit("error", 'No Server Response.');
      } else if (err.response?.status === 400) {

        twit("info", err.response.data.message);
      } else if (err.response?.status === 401) {

        twit("info", err.response.data.message);
      } else {

        twit("error", "Internal server error.");
      }
      
    }

  }





  //handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();


    if (gender === "" || doctype === "" || kgender === "") {


      twit("info", 'Please input all required fields.');





    } else if (!phoneLength) {
      twit("info", "Invalid phone number.", 3);
    } else if ((phone.length < 12 && registrationType === 'Adult') || kphone.length < 12) {

      twit("info", "Invalid phone number.", 3);

    } else {
      register();
    }

  }



  //getting the completion  of personal information

  const personalCompletion = () => {
    const dataObject = {
      fname, lname, gender, phone, doctype, docNo, dob, nationality, email, address, marital, position
    }
    const objectLength = Object.keys(dataObject).length;
    const nullValuesCount = Object.values(dataObject).filter(val => val !== null && val !== "").length;
    
    
    setPersonalCompleted(Percentage(nullValuesCount, objectLength) === 100);
  }

  //getting the completion  of next of kin information



  const nextofKinCompletion = () => {
    const dataObject = {
      kfname, klname, kphone, kgender, kemail, kaddress, krelation
    }
    const objectLength = Object.keys(dataObject).length;
    const nullValuesCount = Object.values(dataObject).filter(val => val !== null && val !== "").length;
    

    setNextofkinCompleted(Percentage(nullValuesCount, objectLength) === 100);
  }


  //biometrics completion
  const biometricsCompletion = () => {
    const dataObject = {
      eye, face, signature, fingerprint
    }
    const objectLength = Object.keys(dataObject).length;
    const nullValuesCount = Object.values(dataObject).filter(val => val !== null && val !== "").length;
    

    setBiometricsCompleted(Percentage(nullValuesCount, objectLength) === 100);
  }



  useEffect(() => {

    personalCompletion();
    nextofKinCompletion();
    biometricsCompletion();




  }, [fname, lname, gender, phone, doctype, docNo,
    dob, nationality, email, address, marital, kfname,
    klname,
    kphone, kgender, kemail, kaddress, krelation, position,
    eye, face, signature, fingerprint
  ]);


  const personalSection = { name: "Personal Information", completed: personalCompleted, sectionId: 'personal-information' }
  const nextofkinSection = { name: "Next of kin Information", completed: nextofkinCompleted, sectionId: 'Nok-information' }
  const biometricSection = { name: "Biometrics", completed: biometricsCompleted, sectionId: 'personal-information' }






  if (loading) {
    return (<Loader />);
  } else {

    return (


      <Container style={{ fontSize: "12px" }}>
        {existingCard && <Modal open={true} title={'Add Existing User.'} onCancel={handleCancel} onOk={handleCancel}
          footer={[


          ]}
          className='allmodals'
        >
          <Form.Group controlId="hid" style={{ minHeight: "20vh" }}>
            <Form.Label style={{ marginBottom: "0px" }}>Card No. </Form.Label>
            <Form.Control
              required
              value={cardId}
              onChange={(e) => {
                setCardId(e.target.value);
                setExistingUserName();
              }}

              className=' input-box-identity form-control-sm' type="text" name="cardId" placeholder="Card No." />

            <div>
              <p>{existingUserName}</p>
            </div>
            <div>
              <Button className='identity-form-button' variant="success" type="button" onClick={() => {
                getUserData(cardId);
              }}>
                Get User Name
              </Button>
              {existingUserName && (
                <Button className='identity-form-button' variant="success" type="button" onClick={() => {
                  post({}).then((data) => {
                    if (data?.type === "data") {
                      setExistingCard();
                      setExistingUserName();
                      twit("success", data?.data?.message);

                    } else {
                      
                      twit("info", data?.error?.response?.data?.message);
                    }
                    

                  })
                }}>
                  Add User
                </Button>
              )}

            </div>

          </Form.Group>


        </Modal>}


        {errorMsg && <Modal open={true} title={'Failed'} onCancel={handleCancelerror} onOk={handleCancelerror}
          footer={[


          ]}
        >
          <ColoredD color="red">{errorMsg}</ColoredD>

        </Modal>}


        <Form onSubmit={handleSubmit}>
          <Card className='border-0'>
            <Form>
              <Form.Group controlId="formRegistration" className='registration-form-top-div' >

                <div style={{ display: "flex", flexDirection: "column", }}>
                  <Form.Label>Are you registering a minor or Adult?</Form.Label>
                  <div style={{ display: "flex", flexDirection: "row", }}>
                    <Form.Check
                      title='Minor is a person under the age of full legal responsibility.'
                      inline
                      label="Minor"
                      type="radio"
                      id="minorRadio"
                      value="Minor"
                      onChange={handleRegistrationTypeChange}
                      checked={registrationType === 'Minor'}
                    />
                    <Form.Check
                      title='Adult is a person who is fully grown or developed.'
                      inline
                      label="Adult"
                      type="radio"
                      id="majorRadio"
                      value="Adult"
                      onChange={handleRegistrationTypeChange}
                      checked={registrationType === 'Adult'}
                    />

                  </div>






                </div>
                <div>
                  <a style={{ color: "green", cursor: "pointer" }} className='identity-form-button'
                    onClick={() => {
                      setExistingCard(true);
                    }}
                  >
                    Add Existing Human...
                  </a>
                </div>
                <div>

                  <FormStatusCard
                    sections={[personalSection, nextofkinSection, biometricSection]}
                    isRegistrationComplete={personalCompleted && nextofkinCompleted && biometricsCompleted}


                  />
                  {/* <FormStatusCard
                sections={[personalSection,nextofkinSection,biometricSection]}
                isRegistrationComplete={personalCompleted&&nextofkinCompleted&&biometricsCompleted}
                
                
                /> */}
                </div>
              </Form.Group>
              {/* <p>You selected: <span style={{color:"green",fontWeight:"bold"}}>{registrationType} </span> </p> */}
            </Form>



            <Card.Header >Personal Information</Card.Header>
            <Card.Body id='personal-information'>


              <Row>
                <Col  xs={12} md={4}>

                  <Form.Group controlId="formBasicName">
                    <Form.Label style={{ marginBottom: "0px" }}> First Name <span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Control
                      required
                      value={fname}
                      onChange={(e) => {
                        setFname(e.target.value);
                      }}

                      className=' input-box-identity form-control-sm' type="text" name="fname" placeholder="Enter first name" />
                  </Form.Group>

                  <Form.Group controlId="formBasicName2">
                    <Form.Label style={{ marginBottom: "0px" }}>Last Name <span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Control

                      required
                      value={lname}
                      onChange={(e) => {
                        setLname(e.target.value);
                      }}

                      className=' input-box-identity form-control-sm' type="text" name="lname" placeholder="Enter last name" />
                  </Form.Group>

                  <Form.Group style={{ marginBottom: "14px" }}>
                    <Form.Label style={{ marginBottom: "0px" }} >
                      Gender

                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>

                    <Form.Select


                      onChange={(e) => {
                        setGender(e.target.value);

                        const selected = allgenderData?.data?.data?.filter((data) => {
                          return data.gid === e.target.value;
                        })
                        
                        setSelectedGender(selected[0]?.gender)
                      }}
                      className='form-selector'>
                      <option key={1} value={gender}>{selectedGender}</option>
                      {allgenderData?.data?.data?.map((data) => {
                        return <option className='input-box' key={data.gid} value={data.gid}>{data.gender}</option>
                      })}


                    </Form.Select>

                  </Form.Group>

                  {registrationType === "Adult" &&
                    <Form.Group style={{ marginBottom: "17px" }} controlId="forFace">
                      <Form.Label style={{ marginBottom: "0px" }}>Mobile No. <span style={{ color: "red" }}>*</span></Form.Label>
                      <PhoneInput
                        country={'ug'}
                        value={phone}
                        localization={{ ug: 'Uganda' }}
                        onChange={(e) => {
                          setPhone(e);

                        }}
                        isValid={(inputNumber, country) => {

                          if (country.countryCode === "256" && (inputNumber.length > 12)) {
                            setPhonelength(false);
                            return false
                          } else {
                            setPhonelength(true);
                            return true
                          }
                        }}

                        disableDropdown
                        countryCodeEditable={false}
                        masks={{ ug: '... ... ...' }}

                        inputProps={{
                          name: 'phone',
                          required: true,

                        }}


                        inputStyle={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px"
                        }}

                      />
                    </Form.Group>


                  }


                  <Form.Group style={{ marginBottom: "14px" }}>
                    <Form.Label style={{ marginBottom: "0px" }}> Recognized Government Document <span style={{ color: "red" }}>*</span>  </Form.Label>
                    <Form.Select

                      onChange={(e) => {
                        setDoctype(e.target.value);
                        const selected = doctypedata?.filter((data) => {
                          return data.gid === e.target.value;
                        })
                        
                        setSelectedDoctype(selected[0]?.dname);
                      }}

                      className='form-selector'>
                      <option key={1} value={doctype}>{selectedDoctype} </option>
                      {doctypedata?.map((data) => {
                        return <option className='input-box' key={data.gid} value={data.gid}>{data.dname}</option>
                      })}


                    </Form.Select>
                  </Form.Group>

                  <Form.Group >
                    <Form.Label style={{ marginBottom: "0px" }} >
                      Document No.
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      required
                      value={docNo}
                      onChange={(e) => {
                        setDocNo(e.target.value);
                      }}

                      className=' input-box-identity form-control-sm' type="text" placeholder="Enter your document number" />

                  </Form.Group>







                </Col>
                <Col  xs={12} md={4}>
                  <Form.Group controlId="date">
                    <Form.Label style={{ marginBottom: "0px" }}>Date of Birth</Form.Label>
                    <Form.Control


                      className=' input-box-identity form-control-sm'
                      type="date"
                      name="date"
                      value={dob}
                      onChange={(e) => {
                        setDob(e.target.value);
                      }}

                    />
                  </Form.Group>
                  <Form.Group style={{ marginBottom: "14px" }} controlId="formBasicLocation">
                    <Form.Label style={{ marginBottom: "0px" }}>Nationality</Form.Label>
                    <Form.Select


                      onChange={(e) => {
                        setNationality(e.target.value);
                      }}
                      className='form-selector'>
                      <option key={1} value={"222"}>UGANDA</option>
                      {countrydata?.map((data) => {
                        return <option className='input-box' key={data.Id} value={data.Id}>{data.Name}</option>
                      })}


                    </Form.Select>
                  </Form.Group>
                  {registrationType === "Adult" &&
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label style={{ marginBottom: "0px" }}>Email Address</Form.Label>
                      <Form.Control

                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}

                        className=' input-box-identity form-control-sm' type="email" name="email" placeholder="domain@example.com" />
                    </Form.Group>

                  }


                  <Form.Group controlId="formBasicLocation">
                    <Form.Label style={{ marginBottom: "0px" }}>Address</Form.Label>
                    <Form.Control

                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}

                      className=' input-box-identity form-control-sm' type="text" name="address" placeholder="Enter your address" />
                  </Form.Group>
                  {registrationType === "Adult" &&
                    <Form.Group style={{ marginBottom: "14px" }} >
                      <Form.Label style={{ marginBottom: "0px" }}>
                        Marital Status
                      </Form.Label>

                      <Form.Select


                        onChange={(e) => {
                          setMarital(e.target.value);
                          const selected = allmaritalData?.data?.data?.filter((data) => {
                            return data.msid === e.target.value;
                          })
                          
                          setSelectedMarital(selected[0]?.mstatus)
                        }}

                        className='form-selector'>
                        <option key={1} value={marital}>{selectedMarital}</option>
                        {allmaritalData?.data?.data?.map((data) => {
                          return <option className='input-box' key={data.msid} value={data.msid}>{data.mstatus}</option>
                        })}

                      </Form.Select>

                    </Form.Group>

                  }



                  <Form.Group >
                    <Form.Label style={{ marginBottom: "0px" }} >
                      Level
                    </Form.Label>

                    <Form.Control

                      value={position}
                      onChange={(e) => {
                        setPosition(e.target.value);
                      }}

                      className=' input-box-identity form-control-sm' type="text" placeholder="Enter your role" />

                  </Form.Group>








                </Col>

                <Col>


                  <Form.Group style={{ marginBottom: "14px" }} >
                    <Form.Label style={{ marginBottom: "0px" }}>
                      Card Type
                    </Form.Label>

                    <Form.Select


                      onChange={(e) => {
                        setCardType(e.target.value);
                      }}

                      className='form-selector'>
                      <option key={1} value={"4F79DD80-7E47-4ADE-AFE2-915644260697"}>bronze</option>
                      {cardTypeData?.data?.data?.map((data) => {
                        return <option className='input-box' key={data.id} value={data.id}>{data.ctype}</option>
                      })}

                    </Form.Select>

                  </Form.Group>

                  <Form.Group controlId="forFace">
                    <Form.Label style={{ marginBottom: "0px" }}>Facial Image</Form.Label>
                    <Form.Control


                      onChange={(e) => {
                        setFace(e.target.files[0]);
                      }}
                      className=' input-box-identity form-control-sm'

                      type="file" />
                  </Form.Group>


                  <Form.Group controlId="forFingerprint">
                    <Form.Label style={{ marginBottom: "0px" }}>Fingerprint</Form.Label>
                    <Form.Control



                      onChange={(e) => {
                        setFingerprint(e.target.value);
                      }}
                      className=' input-box-identity form-control-sm'

                      type="file" />
                  </Form.Group>

                  <Form.Group controlId="forFace">
                    <Form.Label style={{ marginBottom: "0px" }}>Iris </Form.Label>
                    <Form.Control


                      onChange={(e) => {
                        setEye(e.target.value);
                      }}
                      className=' input-box-identity form-control-sm'

                      type="file" />
                  </Form.Group>
                  <Form.Group controlId="forSignature">
                    <Form.Label style={{ marginBottom: "0px" }}>Signature</Form.Label>
                    <Form.Control


                      onChange={(e) => {
                        setSignature(e.target.value);
                      }}
                      className=' input-box-identity form-control-sm'

                      type="file" />
                  </Form.Group>






















                </Col>


              </Row>



            </Card.Body>
          </Card>
          {/* <FloatButton  style={{bottom:200}} tooltip={<div style={{color:"black"}}>

          <FormStatusCard
                sections={[personalSection,nextofkinSection,biometricSection]}
                isRegistrationComplete={personalCompleted&&nextofkinCompleted&&biometricsCompleted}
                
                
                />







          </div>}/> */}
          <Card className='my-1 border-0'>
            <Card.Header >Next of Kin Information</Card.Header>
            <Card.Body id='Nok-information'>
              <Row>
                <Col className='' xs={12} md={4}>
                  <Form.Group controlId="formBasickName">
                    <Form.Label style={{ marginBottom: "0px" }}> First Name <span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Control
                      required
                      value={kfname}
                      onChange={(e) => {
                        setKfname(e.target.value);
                      }}

                      className=' input-box-identity form-control-sm' type="text" name="kfname" placeholder="Enter name" />
                  </Form.Group>

                  <Form.Group controlId="formkBasicName">
                    <Form.Label style={{ marginBottom: "0px" }}>Last Name <span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Control
                      required
                      value={klname}
                      onChange={(e) => {
                        setKlname(e.target.value);
                      }}
                      className=' input-box-identity form-control-sm' type="text" name="klname" placeholder="Enter name" />
                  </Form.Group>

                  <Form.Group controlId="forFace">
                    <Form.Label style={{ marginBottom: "0px" }}>Mobile No. <span style={{ color: "red" }}>*</span></Form.Label>
                    <PhoneInput
                      country={'ug'}
                      value={kphone}
                      localization={{ ug: 'Uganda' }}
                      onChange={(e) => {

                        setKphone(e);
                      }}

                      disableDropdown
                      countryCodeEditable={false}
                      masks={{ ug: '... ... ...' }}
                      isValid={(inputNumber, country) => {

                        if (country.countryCode === "256" && (inputNumber.length > 12)) {
                          setPhonelength(false);
                          return false
                        } else {
                          setPhonelength(true);
                          return true
                        }
                      }}


                      inputProps={{
                        name: 'phone',
                        required: true,

                      }}


                      inputStyle={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px"
                      }}

                    />


                  </Form.Group>








                </Col>

                <Col className='' xs={12} md={4} >


                  <Form.Group style={{ marginBottom: "14px" }}>
                    <Form.Label style={{ marginBottom: "0px" }} >
                      Gender
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>

                    <Form.Select


                      onChange={(e) => {
                        setKgender(e.target.value);
                        const selected = allgenderData?.data?.data?.filter((data) => {
                          return data.gid === e.target.value;
                        })
                        
                        setSelectedKgender(selected[0]?.gender)
                      }}

                      className='form-selector'>
                      <option key={1} value={kgender}>{selectedKgender}</option>
                      {allgenderData?.data?.data?.map((data) => {
                        return <option className='input-box' key={data.gid} value={data.gid}>{data.gender}</option>
                      })}
                    </Form.Select>

                  </Form.Group>

                  <Form.Group controlId="formBasicKEmail">
                    <Form.Label style={{ marginBottom: "0px" }}>Email Address</Form.Label>
                    <Form.Control

                      value={kemail}
                      onChange={(e) => {
                        setKemail(e.target.value);
                      }}
                      className=' input-box-identity form-control-sm' type="email" name="kemail" placeholder="domain@example.com" />
                  </Form.Group>




                  <Form.Group controlId="formBasicLocation">
                    <Form.Label style={{ marginBottom: "0px" }}>Address</Form.Label>
                    <Form.Control

                      value={kaddress}
                      onChange={(e) => {
                        setKaddress(e.target.value);
                      }}

                      className='  input-box-identity form-control-sm' type="text" name="kname" placeholder="Enter your address" />
                  </Form.Group>




                </Col>

                <Col >
                  <Form.Group controlId="formBasicKrelation">
                    <Form.Label style={{ marginBottom: "0px" }}>Relationship</Form.Label>
                    <Form.Control

                      value={krelation}
                      onChange={(e) => {
                        setKrelation(e.target.value);
                      }}
                      className=' input-box-identity form-control-sm' type="text" name="krelation" placeholder="Enter relationship type" />
                  </Form.Group>

                </Col>






              </Row>



            </Card.Body>
          </Card>

          {/* <Button className='identity-form-button' variant="success" type="submit">
            Register
          </Button> */}

          <div className='identity-form-button' style={{ width: '100%', textAlign: 'center' }}>
            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
              <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Register
            </Button>
          </div>

        </Form>
      </Container>

    );

  };
};
export default IdentityForm;
