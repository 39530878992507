import { useState, useEffect } from "react";
import axios from "../../api/axios";

const usePost = (url,setLoading) => {
  
  
  
  const token = sessionStorage.getItem('token');



  const post = async (postData) => {
    try {
      setLoading(true);
      const response = await axios.post(url, JSON.stringify(postData) ,{
        headers: {
          'Content-Type': 'application/json', Authorization: `Bearer ${token}`
        }
        
      });
      
      setLoading(false);
      return {data:response.data,type:"data"}
      
    } catch (err) {
      setLoading(false);
      
      return {error:err,type:"error"}
    }
  };

  return {  post };
};

export default usePost;
