import React, { createContext, useReducer } from 'react';
import cartreducer from './CartReducer';
import axios from '../api/axios'
import { twit } from "../components/Notificationpopout";
import { useState } from 'react';
import { useEffect } from 'react';

let initialstate = []











export const CartContext = createContext(initialstate);

const CartStateProvider = ({ children }) => {

  const [items, setItems] = useState();


  const getItems = async () => {
    const token = sessionStorage.getItem('token');
    const cartUrl = '/api/orders/cart/items'


    try {
      const response = await axios.get(cartUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }

      });

      

      if (response.status === 200) {
        setItems(response.data)
        return Promise.resolve('Cart items Loaded.')
      }




    } catch (err) {
      console.log("Cart GET ERROR:", err)
      return Promise.reject('An error occurred while adding items to the cart.')

    }



  }








  const addItem = async (itemsArray, setLoading) => {
    const token = sessionStorage.getItem('token');
    const postData = { items: itemsArray }
    const addItemsUrl = '/api/orders/cart/add'
    try {
      setLoading(true);
      const response = await axios.post(addItemsUrl, JSON.stringify(postData), {
        headers: {
          'Content-Type': 'application/json', Authorization: `Bearer ${token}`
        }

      });

      if (response.status === 201) {
        setLoading(false);
        return Promise.resolve('Items added to the cart.')
      }


    } catch (err) {
      setLoading(false);
      console.log("Cart ADD ERROR:", err)
      return Promise.reject('An error occured while adding items to the cart.')

    }
  };








  const removeItem = async (itemsArray, setLoading) => {
    const token = sessionStorage.getItem('token');
    const deleteData =  itemsArray 
    const removeItemsUrl = '/api/orders/cart/remove'
    try {
      setLoading(true);
      const response = await axios.delete(removeItemsUrl,{
    
        headers: {
          'Content-Type': 'application/json', Authorization: `Bearer ${token}`
        }
        
      ,
      data: {
        items:deleteData
      }
    });

      if (response.status === 200) {
        setLoading(false);
        return Promise.resolve('Items removed from cart.')
      }

    } catch (err) {
      setLoading(false);
      console.log("Cart REMOVE ERROR:", err)
      return Promise.reject('An error occured while adding items to the cart.')
    }
  }

  const checkout = async ( setLoading,charges) => {
    const token = sessionStorage.getItem('token');
    const postData = { orderId:items?.orderId , charges}
    const checkoutUrl = '/api/orders/cart/checkout'
    try {
      setLoading(true);
      const response = await axios.post(checkoutUrl, JSON.stringify(postData), {
        headers: {
          'Content-Type': 'application/json', Authorization: `Bearer ${token}`
        }

      });

      if (response.status === 200) {
        setLoading(false);
        const url = response.data.paymentUrl
        return Promise.resolve(url)
      }


    } catch (err) {
      setLoading(false);
      console.log("Cart Checkout Error:", err)
      return Promise.reject('An error occured while checkingout the cart.')

    }
  };

  const getUnitPrices = async (orderId,location,deliver) => {
    const token = sessionStorage.getItem('token');
    const unitPriceUrl = `/api/orders/cart/unitprices/${orderId}`


    try {
      const response = await axios.post(unitPriceUrl,JSON.stringify({dlocation:location,deliver}),{
        headers: {
          'Content-Type': 'application/json',Authorization: `Bearer ${token}`
        }

      });

      

      if (response.status === 200) {
        
        return Promise.resolve(response.data.ranges)
      }




    } catch (err) {
      console.log("UNIT PRICE ERROR:", err)
      return Promise.reject('An error occurred while getting unit prices.')

    }



  }



  return (

    <CartContext.Provider value={{ items, getItems, removeItem, addItem,checkout,getUnitPrices }}>
      {children}
    </CartContext.Provider>
    
  )
}


export default CartStateProvider;