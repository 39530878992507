import React, { useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import Loader from '../Loader';
import PhoneInput from 'react-phone-input-2';
import { twit } from '../Notificationpopout';
import { Button } from 'antd';
import axios from '../../api/axios';

const BranchForm = () => {

    const [phone, setPhone] = useState()
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        bname: '',
        email: '',
        location: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const createBranch = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            bname: formData.bname,
            phoneNumber: phone,
            email: formData.email,
            location: formData.location,
        };
        const createEventUrl = '/api/assetmanagement/branch'
        try {
            setLoading(true);
            const response = await axios.post(createEventUrl, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Branch created successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log("Branch creation Error:", err)
            return Promise.reject('An error occured while creating the branch.')

        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        const requiredFields = ['bname', 'email', 'location'];
        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0) {
            twit('info', `Please fill out all required fields: ${missingFields.join(', ')}`);

        } else {

            createBranch(setLoading).then((data) => {
                setFormData({
                    bname: '',
                    email: '',
                    location: '',
                })
                setPhone('')
                twit('success', data)
            }).catch((error) => {
                twit('info', error)
            })

        }



    };

    if (loading) {
        return (<Loader />);
    } else {

    return (
        <Container>
            <Row>
                <Col>
                    <p style={{fontWeight:'bold'}}>Branch Information</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="bname">
                            <Form.Label>Branch Name</Form.Label>
                            <Form.Control
                                className=' input-box-identity form-control-sm'
                                type="text"
                                name="bname"
                                value={formData.bname}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>

                            <PhoneInput
                                country={'ug'}
                                value={phone}
                                localization={{ ug: 'Uganda' }}
                                onChange={(e) => {
                                    setPhone(e);

                                }}


                                disableDropdown
                                countryCodeEditable={false}
                                masks={{ ug: '... ... ...' }}

                                inputProps={{
                                    name: 'phoneNumber',
                                    required: true,

                                }}


                                inputStyle={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px"
                                }}

                            />


                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                className=' input-box-identity form-control-sm'
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="location">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                                className=' input-box-identity form-control-sm'
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: "#569f51",fontSize:'10px' }} variant="primary" type="primary" htmlType='submit'>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}
};

export default BranchForm;
