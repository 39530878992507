import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Image, Card } from 'react-bootstrap';
import { Button, Modal } from 'antd';
import Cookies from 'js-cookie';
import neldLogo from '../images/SRHR.png'
import neldAgenda from '../images/neldAgenda2.jpg'
import Bwongo2 from '../images/bwongoAgenda1.png'
import Bwongo1 from '../images/bwongoAgenda2.png'
import Loader from '../Loader';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import pinnitags from '../images/plogotransparent.png'
import { fetchData } from '../../Utilities/apiRequests';
import { pinnitagsCookiePolicy, pinnitagsLandingUrl } from '../../Utilities/globals';
import * as RxIcon from 'react-icons/rx';
import * as GiIcon from 'react-icons/gi';
import * as MdIcon from 'react-icons/md';
import { downloadContactCSV, getNairobiTime } from '../../Utilities/utilities';
import PhoneInput from 'react-phone-input-2';
import successGif from '../images/success.gif';
import successImage from '../images/success.jpg';
import EventPass from '../PassTemplate';



const PassVerification = () => {

    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(false);
    const [regfields, setRegfields] = useState([]);
    const [showAgenda, setShowAgenda] = useState(false);
    const [mobile, setMobile] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [tagStatus, setTagStatus] = useState(false);
    const [cookypolicy, setCookypolicy] = useState(true);
    const [userSelectedFields, setUserSelectedFields] = useState([])
    const [userFormatIndex, setUserFormatIndex] = useState()
    const [formData, setFormData] = useState([])
    const [faceImage, setFaceImage] = useState(false);
    const [pauseGif, setPauseGif] = useState(false);
    const [showGif, setShowGif] = useState(false);
    const [ptag, setPtag] = useState('');

    const navigate = useNavigate()
    const { tag } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode');












    useEffect(() => {

        fetchData(`/api/events/passdata/${tag}`, setLoading).then((response) => {


            if (response.status == 200) {
                setUserInfo(response.data.passInfo)
                setPtag(response.data.pdf417)

            }

        }).catch((error) => {

            if (error.response?.status == 403 || error.response?.status == 401 || error.response?.status == 400) {
                window.location.href = pinnitagsLandingUrl
            }

            console.log('Pass Verification error:', error.response?.data)

        })
        fetchData(`/api/events/regfields`, setLoading).then((response) => {

            setRegfields(response.data.fields)

        }).catch((error) => {
            twit('info', 'failed to load registration fields.')
            console.log('failed to load registration fields  error:', error.response?.data)
        })
    }, [])


    useEffect(() => {
        if (userInfo) {
            if (userInfo?.event?.endtime < getNairobiTime()) {
                twit('info', 'Event is Expired redirecting ...', 4)
                window.location.href = pinnitagsLandingUrl
            }
            // } else if (!(userInfo?.event?.selfReg)) {
            //     setShowAgenda(true)
            // }
        }

    }, [userInfo])

    const faceformData = new FormData()
    faceformData.append("image", faceImage);

    const uploadFace = async (passid) => {
        const token = sessionStorage.getItem('token');

        try {



            const response = await axios.post(`/api/events/face/${passid}`, faceformData, {
                headers: {
                    'content-type': 'multipart/form-data'
                    , Authorization: `Bearer ${token}`
                }

            });
            return Promise.resolve(response);
        } catch (error) {

            return Promise.reject(error);
        }



    }

    const submitUserInfo = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            eid: userInfo?.event?.eid,
            regData: formData.join(),
            tagName: tag,
            name,
            mobile,
            email
        };

        const Url = '/api/events/passverification/register'
        try {
            setLoading(true);
            // const response = await axios.post(Url, JSON.stringify(postData), {
            //     headers: {
            //         'Content-Type': 'application/json', Authorization: `Bearer ${token}`
            //     }

            // });

            // if (response.status === 201) {
            //     if (faceImage) {
            //         const faceUpload = await uploadFace(response.data.pass.id)

                    
            //         if (faceUpload.status === 201) {
            //             setLoading(false);
            //             setShowGif(true);

            //             setTimeout(function () {
            //                 setPauseGif(true);

            //             }, 2000);
            //             return Promise.resolve(response.data.message)
            //         }
            //     } else {
            //         setLoading(false);
            //         setShowGif(true);

            //         setTimeout(function () {
            //             setPauseGif(true);

            //         }, 2000);
            //         return Promise.resolve(response.data.message)
            //     }
            //     // setLoading(false);
            //     // return Promise.resolve(response.data.message)
            // }

            if(faceImage){
                const faceUpload = await uploadFace(userInfo.id)

                    
                    if (faceUpload.status === 201) {
                        const response = await axios.post(Url, JSON.stringify(postData), {
                            headers: {
                                'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                            }
            
                        });

                        if (response.status === 201) {
                        setLoading(false);
                        setShowGif(true);

                        setTimeout(function () {
                            setPauseGif(true);

                        }, 2000);
                        return Promise.resolve(response.data.message)
                    }
                    }
            }else{
                const response = await axios.post(Url, JSON.stringify(postData), {
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                    }
    
                });

                if (response.status === 201) {
                setLoading(false);
                setShowGif(true);

                setTimeout(function () {
                    setPauseGif(true);

                }, 2000);
                return Promise.resolve(response.data.message)
            } 
            }


        } catch (error) {
            setLoading(false);
            console.log("Info submission error:", error)
            return Promise.reject(error?.response?.data?.message ? error?.response?.data?.message : 'An error occured during registration.')

        }
    };





    const EventCookiePolicy = Cookies.get('EventCookyPolicy');
    const isRegistered = Cookies.get('passreg');

    useEffect(() => {
        if (EventCookiePolicy) {
            setCookypolicy(false);
            if((mode == 2 || mode == 3)){
                setShowAgenda(true);
            }else if(mode == 1){
                setLoading(true)
            }
        }

        if (isRegistered && (!mode)) {
            setShowAgenda(true)
            setShowGif(true);

            setTimeout(function () {
                setPauseGif(true);

            }, 2000);
        }

    }, [cookypolicy,loading])


    const getIndexesAndValues = (arrA, arrB) => {
        const result = {};
        for (let i = 0; i < arrB?.length; i++) {
            const index = arrA?.indexOf(arrB[i]);
            if (index !== -1) {
                result[arrB[i]] = index;
            }
        }
        return result;
    }

    useEffect(() => {
        if (userInfo && regfields) {

            const userfields = userInfo?.event?.regStruct?.split(',')
            const allfields = regfields?.map((data) => {
                return data.name
            })
            const indexes = getIndexesAndValues(userfields, allfields)
            setUserSelectedFields(userfields)
            setUserFormatIndex(indexes)
        }

    }, [userInfo, regfields])


    useEffect(() => {

        if (userInfo && (mode == 1 || mode == 3)&&(!cookypolicy)) {
            downloadContactCSV({ name: userInfo?.name, email: userInfo?.email, phone: userInfo?.mobile })
            twit('success', 'Contact downloaded successfully.')
            if(mode==1){
                setTimeout(function () {
                    window.location.href=pinnitagsLandingUrl 
                }, 3000);
               
            }
        }


    }, [userInfo,cookypolicy])





    const handleFormSubmit = (e) => {
        e.preventDefault();


        submitUserInfo(setLoading).then((data) => {
            twit('success', 'You have been registered successfully, please check your email for your event pass.',5)
            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 525600)
            Cookies.set('passreg', JSON.stringify({ user: formData.join() }), { expires });
            setShowAgenda(true);
        }).catch((error) => {
            twit('info', error)
        })




    };



    const eventZones = userInfo?.eventPassCategory?.PassCategoryZones?.map((data)=>{
        return {name:data.EventZone.name,desc:data.EventZone.desc}
    })

    const tagName = userInfo?.DocumentTag?.tagName




    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container className='eventAgendaContainer' style={{ fontSize: '12px' }}>
                {cookypolicy && <Modal open={true}

                    closable={false}
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                    footer={[


                    ]}
                >

                    <Col  >
                        <Row>
                            <RxIcon.RxCookie style={{ width: '150px', height: '150px', color: "green" }} />
                        </Row>
                        <Row>
                            <h6>Your privacy</h6>
                            <p>By clicking "Accept all cookies",
                                you agree <span style={{ fontWeight: "bold" }}><a style={{ color: "green" }} href='https://pinnisoft.com/'>PinniSOFT</a> </span>can store cookies on your device
                                and disclose information in accordance with our
                                &nbsp;
                                <a style={{ color: "green" }} href={pinnitagsCookiePolicy}>Cookie Policy</a>

                            </p>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={() => {
                                Cookies.set('EventCookyPolicy', true, { expires: 7 });
                                setCookypolicy(false);
                            }} style={{ fontSize: '10px' }} variant="primary" htmlType='submit'>
                                <MdIcon.MdCookie className='mx-1' style={{ width: '15px', height: '15px' }} /> Accept all Cookies
                            </Button>

                        </Row>

                    </Col>




                </Modal>}
                <Row>
                    <Col>
                        {!showAgenda ? (
                            <div>
                                <div className='my-0 mx-0' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <p className=' neldTheme ' style={{ fontWeight: 'bold', fontSize: '15px' }}>{userInfo?.event?.name}</p>
                                </div>
                                <Form className=' neldRegForm' onSubmit={handleFormSubmit}>
                                    <Card>
                                        <Card.Body >
                                            <Row>

                                                <Col className='my-1' md={6}>
                                                    <Form.Group controlId="name">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name</p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="text"
                                                                placeholder="Enter name"
                                                                value={name}
                                                                onChange={(e) => {
                                                                    setName(e.target.value)
                                                                    const updatedArray = [...formData];
                                                                    updatedArray[userFormatIndex?.Name] = e.target.value;
                                                                    setFormData(updatedArray);
                                                                }}
                                                                required
                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>

                                                {/* <Col className='my-1' md={6}>
                                                    <Form.Group controlId="email">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Email</p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="email"
                                                                placeholder="Enter email"
                                                                value={email}
                                                                onChange={(e) => {
                                                                    setEmail(e.target.value)
                                                                    const updatedArray = [...formData];
                                                                    updatedArray[userFormatIndex?.Email] = e.target.value;
                                                                    setFormData(updatedArray);
                                                                }}
                                                                required
                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col> */}

                                                <Col className='my-1' md={6}>

                                                    <Form.Group controlId="Mobile">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Mobile</p>
                                                            </div>

                                                            <PhoneInput
                                                                country={'ug'}
                                                                value={mobile}
                                                                localization={{ ug: 'Uganda' }}
                                                                onChange={(e) => {
                                                                    setMobile(e)
                                                                    const updatedArray = [...formData];
                                                                    updatedArray[userFormatIndex?.Mobile] = e;
                                                                    setFormData(updatedArray);
                                                                }}


                                                                disableDropdown
                                                                countryCodeEditable={false}
                                                                masks={{ ug: '... ... ...' }}

                                                                inputProps={{
                                                                    name: 'phone',
                                                                    required: true,

                                                                }}


                                                                inputStyle={{
                                                                    width: "100%",
                                                                    height: "30px",
                                                                    fontSize: "12px"
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>

                                                {userSelectedFields?.includes('Visual') && (
                                                    <Col className='my-1' md={6}>
                                                        <Form.Group className='my-2' controlId="faceImage">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Visual</p>
                                                                </div>
                                                                <Form.Control

                                                                    accept='.jpeg,.jpg,.png'
                                                                    name="faceImage"
                                                                    onChange={(e) => {

                                                                        setFaceImage(e.target.files[0]);

                                                                    }}
                                                                    className=' input-box-events form-control-sm'

                                                                    type="file" />
                                                            </div>

                                                        </Form.Group>

                                                    </Col>
                                                )}

                                                {userSelectedFields?.includes('Address') && (
                                                    <Col className='my-1' md={6}>
                                                        <Form.Group controlId="Address">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Address</p>
                                                                </div>

                                                                <Form.Control
                                                                    className=' input-box-events form-control-sm'
                                                                    type="text"
                                                                    placeholder="Enter Address"
                                                                    value={formData[userFormatIndex?.Address]}
                                                                    onChange={(e) => {
                                                                        const updatedArray = [...formData];
                                                                        updatedArray[userFormatIndex?.Address] = e.target.value;
                                                                        setFormData(updatedArray);
                                                                    }}
                                                                    required
                                                                />
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                )}

                                                {userSelectedFields?.includes('Occupation') && (
                                                    <Col className='my-1' md={6}>
                                                        <Form.Group controlId="Occupation">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Occupation</p>
                                                                </div>

                                                                <Form.Control
                                                                    className=' input-box-events form-control-sm'
                                                                    type="text"
                                                                    placeholder="Enter Occupation"
                                                                    value={formData[userFormatIndex?.Occupation]}
                                                                    onChange={(e) => {
                                                                        const updatedArray = [...formData];
                                                                        updatedArray[userFormatIndex?.Occupation] = e.target.value;
                                                                        setFormData(updatedArray);
                                                                    }}
                                                                    required
                                                                />
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                )}
                                                {userSelectedFields?.includes('Gender') && (
                                                    <Col className='my-1' md={6}>
                                                        <Form.Group controlId="Gender">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Gender</p>
                                                                </div>

                                                                <Form.Select

                                                                    name="gender"
                                                                    onChange={(e) => {
                                                                        const updatedArray = [...formData];
                                                                        updatedArray[userFormatIndex?.Gender] = e.target.value;
                                                                        setFormData(updatedArray);
                                                                    }}
                                                                    className='form-selector'>
                                                                    <option className='input-box' key={1} value={''}>Select Gender</option>
                                                                    <option className='input-box' key={2} value={'Male'}>Male</option>
                                                                    <option className='input-box' key={3} value={'Female'}>Female</option>

                                                                </Form.Select>

                                                                {/* <Form.Control
                                                                    className=' input-box-events form-control-sm'
                                                                    type="text"
                                                                    placeholder="Enter Gender"
                                                                    value={formData[userFormatIndex?.Gender]}
                                                                    onChange={(e) => {
                                                                        const updatedArray = [...formData];
                                                                        updatedArray[userFormatIndex?.Gender] = e.target.value;
                                                                        setFormData(updatedArray);
                                                                    }}
                                                                    required
                                                                /> */}
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                )}
                                                {userSelectedFields?.includes('Age') && (
                                                    <Col className='my-1' md={6}>
                                                        <Form.Group controlId="Age">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Age</p>
                                                                </div>

                                                                <Form.Control
                                                                    className=' input-box-events form-control-sm'
                                                                    type="number"
                                                                    placeholder="Enter Age"
                                                                    value={formData[userFormatIndex?.Age]}
                                                                    onChange={(e) => {
                                                                        const updatedArray = [...formData];
                                                                        updatedArray[userFormatIndex?.Age] = e.target.value;
                                                                        setFormData(updatedArray);
                                                                    }}
                                                                    required
                                                                />
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                )}
                                                {userSelectedFields?.includes('Company') && (
                                                    <Col className='my-1' md={6}>
                                                        <Form.Group controlId="Company">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Company</p>
                                                                </div>

                                                                <Form.Control
                                                                    className=' input-box-events form-control-sm'
                                                                    type="text"
                                                                    placeholder="Enter Company"
                                                                    value={formData[userFormatIndex?.Company]}
                                                                    onChange={(e) => {
                                                                        const updatedArray = [...formData];
                                                                        updatedArray[userFormatIndex?.Company] = e.target.value;
                                                                        setFormData(updatedArray);
                                                                    }}
                                                                    required
                                                                />
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                )}
                                                {userSelectedFields?.includes('MaritalStatus') && (
                                                    <Col className='my-1' md={6}>
                                                        <Form.Group controlId="Marital Status">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Marital Status</p>
                                                                </div>

                                                                <Form.Select

                                                                    name="maritalStatus"
                                                                    onChange={(e) => {
                                                                        const updatedArray = [...formData];
                                                                        updatedArray[userFormatIndex?.MaritalStatus] = e.target.value;
                                                                        setFormData(updatedArray);
                                                                    }}
                                                                    className='form-selector'>
                                                                    <option className='input-box' key={1} value={''}>Select Marital Status</option>
                                                                    <option className='input-box' key={2} value={'Married'}>Married</option>
                                                                    <option className='input-box' key={3} value={'Single'}>Single</option>

                                                                </Form.Select>

                                                                {/* <Form.Control
                                                                    className=' input-box-events form-control-sm'
                                                                    type="text"
                                                                    placeholder="Enter Marital Status"
                                                                    value={formData[userFormatIndex?.MaritalStatus]}
                                                                    onChange={(e) => {
                                                                        const updatedArray = [...formData];
                                                                        updatedArray[userFormatIndex?.MaritalStatus] = e.target.value;
                                                                        setFormData(updatedArray);
                                                                    }}
                                                                    required
                                                                /> */}
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                )}




                                            </Row>



                                        </Card.Body>
                                    </Card>

                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                            <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> |  Register
                                        </Button>
                                    </div>
                                </Form>


                            </div>
                        ) : (
                            <div className='neldAgendaContainer' >
                                {/* <div className='my-0 mx-0' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                    <p className=' neldTheme ' style={{ fontWeight: 'bold', fontSize: '15px' }}>{userInfo?.event?.name}</p>
                                </div> */}

                                {/* <Image
                                src={userInfo?.event?.bannerURL}
                                alt="EVENT BANNER"
                                fluid
                                style={{ border: '1px solid #ddd', borderRadius: '10px',width:'30%' }}
                                className=' neldAgenda mb-3 '
                            /> */}
                                
                                {userInfo&&(mode == 2 || mode == 3)&&(
                                    <EventPass eventDetails={userInfo} passZones={eventZones} qrcode={ptag} tagName={tagName}/>
                                )}
                                

                                {/* {(mode == 2 || mode == 3) && (

                                    <Image
                                        // src={userInfo?.face}
                                        src={'http://192.168.100.32:9091/humanFaceImages/17D3E555-A676-49D6-980A-75AA8D0A0D18_FC.png'}
                                        alt="FACE IMAGE"
                                        fluid
                                        style={{ border: '1px solid #ddd', borderRadius: '50%', width: '40%' }}
                                        className=' neldAgenda  '
                                    />

                                )}

                                {(mode == 2 || mode == 3) && (
                                    <div className='mt-3' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <p className=' neldTheme ' style={{ fontWeight: 'bold', margin: '0px' }}>NAME: <span style={{ fontWeight: 'lighter', margin: '0px' }}>{userInfo?.name ? userInfo?.name : userInfo?.email}</span></p>
                                        <p className=' neldTheme ' style={{ fontWeight: 'bold', margin: '0px' }}>STATUS: <span style={{ fontWeight: 'lighter', margin: '0px', color: userInfo?.pstatus ? 'green' : 'red' }}>{userInfo?.pstatus ? 'ACTIVE' : 'REVOKED'}</span> </p>
                                        <p className=' neldTheme ' style={{ fontWeight: 'bold', margin: '0px' }}>CATEGORY: <span style={{ fontWeight: 'lighter', margin: '0px' }}>{userInfo?.eventPassCategory?.name}</span> </p>
                                    </div>
                                )}

                                {(mode == 2 || mode == 3) && (
                                    <div className='mt-3' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <p className=' neldTheme ' style={{ fontWeight: 'bold', margin: '0px' }}>ACCESS TYPE</p>
                                        <div className='mt-3' style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            {userInfo?.eventPassCategory?.PassCategoryZones?.map((zone) => {
                                                return (<p key={zone.EventZone.zid} className=' neldTheme ' style={{ margin: '0px' }}>{zone.EventZone.name}</p>)
                                            })}
                                        </div>
                                    </div>
                                )} */}

                                {/* <Image
                                    // src={userInfo?.agendaURL}
                                    src={Bwongo1}
                                    alt="agenda image"
                                    fluid
                                    style={{ border: '1px solid #ddd', borderRadius: '1%', width: '95%' }}
                                    className='neldAgenda'
                                /> */}
                                {!(mode == 1 || mode == 2 || mode == 3) && showAgenda && (
                                    <Modal closable={false} width={400} open={true}
                                        footer={[


                                        ]}
                                        className="allmodals"
                                    >

                                        <Container className='verify-container' style={{ display: "flex", flexDirection: "column" }}>

                                            <div className='my-0 mx-0' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <p className=' neldTheme ' style={{ fontWeight: 'bold', fontSize: '15px' }}>{userInfo?.event?.name}</p>
                                                <p className=' neldTheme text-muted' style={{ fontWeight: 'bold', fontSize: '12px' }}>{userInfo?.event?.eventdescription}</p>
                                            </div>

                                            {showGif && (
                                                <div style={{ paddingLeft: "25%", paddingRight: "25%", marginTop: "25%" }} >
                                                    <img src={pauseGif ? successImage : successGif} alt='gif' className='email-verification-image' />
                                                </div>
                                            )}

                                            <div className='my-0 mx-0' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <p className=' neldTheme text-muted' style={{ fontWeight: 'bold', fontSize: '15px' }}>REGISTERED</p>
                                            </div>
                                            <div className='footer'>
                                                <p className="text-muted" style={{ fontSize: "8px", marginTop: "auto" }}>
                                                    Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a style={{ color: "green" }} href="https://pinnisoft.com/">PinniSOFT</a> <br />
                                                    All rights reserved.
                                                </p>
                                            </div>


                                        </Container>
                                    </Modal>
                                )}
                                {(mode == 1 || mode == 2 || mode == 3) && !showAgenda && (
                                    <div className='mt-3' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <p className=' neldTheme ' style={{ fontWeight: 'bold', margin: '0px' }}>Powered by</p>
                                        <a style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }} href={pinnitagsLandingUrl}><img src={pinnitags} alt="Logo" className='neldpinnitagsLogo' /></a>
                                    </div>
                                )}

                            </div>
                        )}



                    </Col>
                </Row>
            </Container>
        );
    };
};

export default PassVerification;
