import { useState, useRef } from 'react';
import { InputGroup, FormControl, FormCheck } from 'react-bootstrap';

function FiveDigitCodeInput({ setValue1, setValue2, setValue3, setValue4, setValue5 }) {
  const [digit1, setDigit1] = useState('');
  const [digit2, setDigit2] = useState('');
  const [digit3, setDigit3] = useState('');
  const [digit4, setDigit4] = useState('');
  const [digit5, setDigit5] = useState('');
  const [checked, setChecked] = useState(false);

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);

  const handleDigit1Change = e => {
    if (e.target.value !== "") {
      setDigit1(e.target.value);
      setValue1(e.target.value);

      input2Ref.current.focus();

    }

  };

  const handleDigit2Change = e => {
    if (e.target.value !== "") {
      setDigit2(e.target.value);
      setValue2(e.target.value);

      input3Ref.current.focus();

    }



  };

  const handleDigit3Change = e => {

    if (e.target.value !== "") {
      setDigit3(e.target.value);
      setValue3(e.target.value);

      input4Ref.current.focus();

    }

  };

  const handleDigit4Change = e => {
    if (e.target.value !== "") {
      setDigit4(e.target.value);
      setValue4(e.target.value);

      input5Ref.current.focus();

    }



  };

  const handleDigit5Change = e => {

    if (e.target.value !== "") {
      setDigit5(e.target.value);
      setValue5(e.target.value);
    }


  };


  return (
    <div >
      <InputGroup className='five-input'>
        <FormControl
          className='input-box form-control-sm'
          value={digit1}
          onChange={handleDigit1Change}
          onKeyUp={(e) => {
            if (e.key === "Backspace") {

              setDigit1("");


            }

          }}
          maxLength='1'
          ref={input1Ref}
          placeholder='*'
          type={checked ? "text" : "password"}
        />
        <FormControl
          className='input-box form-control-sm'
          value={digit2}
          onChange={handleDigit2Change}
          onKeyUp={(e) => {
            if (e.key === "Backspace") {
              input1Ref.current.focus();
              setDigit2("");


            }

          }}
          maxLength='1'
          ref={input2Ref}
          placeholder='*'
          type={checked ? "text" : "password"}
        />
        <FormControl
          className='input-box form-control-sm'
          value={digit3}
          onChange={handleDigit3Change}
          onKeyUp={(e) => {
            if (e.key === "Backspace") {
              input2Ref.current.focus();
              setDigit3("");


            }

          }}
          maxLength='1'
          ref={input3Ref}
          placeholder='*'
          type={checked ? "text" : "password"}
        />
        <FormControl
          className='input-box form-control-sm'
          value={digit4}
          onChange={handleDigit4Change}
          onKeyUp={(e) => {
            if (e.key === "Backspace") {
              input3Ref.current.focus();
              setDigit4("");


            }

          }}
          maxLength='1'
          ref={input4Ref}
          placeholder='*'
          type={checked ? "text" : "password"}
        />
        <FormControl
          className='input-box form-control-sm'
          value={digit5}
          onChange={handleDigit5Change}
          onKeyUp={(e) => {
            if (e.key === "Backspace") {
              input4Ref.current.focus();
              setDigit5("")


            }

          }}

          maxLength='1'
          ref={input5Ref}
          placeholder='*'
          type={checked ? "text" : "password"}
        />
      </InputGroup>
      <div className='mt-2' style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <FormCheck
          type="checkbox"
          label="Show verification code"
          checked={checked}
          onChange={() => setChecked(!checked)}
          style={{ textAlign: "start" }}
        />
      </div>

    </div>

  );
}


export default FiveDigitCodeInput;