import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Table, Card, InputGroup } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import { fetchData } from '../../Utilities/apiRequests';
import * as GiIcon from 'react-icons/gi';
import { formatPrice, getNairobiTime, getformattedDate } from '../../Utilities/utilities';
import * as GrIcon from 'react-icons/gr';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import * as TiIcons from 'react-icons/ti';
import useGeneralState from '../../hooks/hooks/generalState';
import Pagination from '../Pagination';

const EventZoneForm = () => {
    const [zoneName, setZoneName] = useState('');
    const [zoneDesc, setZoneDesc] = useState('');
    const [cost, setCost] = useState(0);
    const [zones, setZones] = useState([]);
    const [zoneInfo, setZoneInfo] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [earlyBird, setEarlyBird] = useState(false);
    const [ebcost, setEbcost] = useState(0);
    const [ebstart, setEbstart] = useState('');
    const [ebend, setEbend] = useState('');
    const [eventInfo, setEventInfo] = useState([]);
    const [maxTickets, setMaxTickets] = useState('');
    const [showZoneUpdate, setShowZoneUpdate] = useState(false);
    const [zoneRowdata, setZoneRowdata] = useState();
    const [showcapacity, setShowcapacity] = useState(false);
    const [showOnApp, setShowOnApp] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [zoneTicketData, setZoneTicketData] = useState([]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setZoneName(value);
    };




    const { eid } = useParams();
    const generalAppState = useGeneralState();
    const userCurrency = (generalAppState?.currency)



    useEffect(() => {

        fetchData(`/api/events/zones/${eid}`, setLoading).then((response) => {

            setZones(response.data.zones)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event zones.')
        })

        fetchData(`/api/events/zones/info/${eid}`, setLoading).then((response) => {

            setZoneInfo(response.data)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event scans.')
        })

        fetchData(`/api/events/details/${eid}`, setLoading).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

        fetchData(`/api/events/statistics/tickets/${eid}`, setLoading).then((response) => {

            setZoneTicketData(response.data.zoneData)

        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading ticket stats.')
        })


    }, [refresh])


    const createzone = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        let postData = {
            name: zoneName,
            eid,
            cost,
            maxtickets: showcapacity ? maxTickets : null,
            visibleOnApp: showcapacity ? showOnApp : null,
            desc: zoneDesc
        };

        if (earlyBird) {
            postData = {
                name: zoneName,
                eid,
                cost,
                ebcost,
                ebends: ebend,
                ebstarts: ebstart,
                eb: earlyBird,
                maxtickets: showcapacity ? maxTickets : null,
                visibleOnApp: showcapacity ? showOnApp : null,
                desc: zoneDesc
            };
        }





        const Url = '/api/events/zone'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('zone created successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("zone creation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while creating zones.')

        }
    };

    const getNumberAttended = (zid) => {
        return zoneInfo?.AttendedZones?.filter((data) => {
            return data.zid == zid
        })?.length
    }

    const getNumberNotAttended = (zid) => {
        return zoneInfo?.NotAttendedZones?.filter((data) => {
            return data.zid == zid
        })?.length
    }


    const revokeActivateZone = async (setLoading, zid) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            zid
        };

        const Url = '/api/events/zone/status/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Zone status updated successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Zone update Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating zone status.')

        }
    };



    const handleSubmit = (e) => {
        e.preventDefault();

        if (!zoneName || (!(eventInfo?.prive) && (eventInfo?.paid) && cost < 0) || (earlyBird && ebcost == 0) || (earlyBird && !ebstart) || (earlyBird && !ebend)) {
            if (!zoneName) {
                twit('info', 'Please provide the zone name.')
            } else if (cost < 0) {
                twit('info', 'Please provide the standard price.')
            } else if ((earlyBird && ebcost == 0)) {
                twit('info', 'Please provide the early bird price.')
            } else if ((earlyBird && !ebstart)) {
                twit('info', 'Please provide the early bird start date.')
            } else if ((earlyBird && !ebend)) {
                twit('info', 'Please provide the early bird end date.')
            }
        } else {

            createzone(setLoading).then((data) => {

                setZoneName('');
                setCost(0)
                setEbcost(0)
                setEarlyBird(false)
                setEbstart('')
                setEbend('')
                setMaxTickets()
                setShowOnApp(false)
                setZoneDesc('')
                setShowOnApp(false)
                setShowcapacity(false)
                setRefresh(!refresh)
                twit('success', data)
            }).catch((error) => {
                twit('info', error)
            })

        }


    };


    const updateZone = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        let postData = {
            name: zoneName ? zoneName : zoneRowdata.name,
            desc: zoneDesc ? zoneDesc : zoneRowdata.desc,
            eid,
            cost: cost ? cost : zoneRowdata.cost,
            maxtickets: showcapacity ? (maxTickets ? maxTickets : zoneRowdata.maxtickets) : null,
            zid: zoneRowdata.zid,
            visibleOnApp: showcapacity ? showOnApp : null,

        };

        if (earlyBird) {
            postData = {
                name: zoneName ? zoneName : zoneRowdata.name,
                eid,
                cost: cost ? cost : zoneRowdata.cost,
                ebcost,
                ebends: ebend,
                ebstarts: ebstart,
                eb: earlyBird,
                maxtickets: showcapacity ? (maxTickets ? maxTickets : zoneRowdata.maxtickets) : null,
                zid: zoneRowdata.zid,
                visibleOnApp: showcapacity ? showOnApp : null,
                desc: zoneDesc ? zoneDesc : zoneRowdata.desc,
            };
        }
        const Url = '/api/events/zone/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setEbcost()
                setEbend()
                setEbstart()
                setCost()
                setShowOnApp()
                setMaxTickets()
                setZoneDesc()
                setLoading(false);
                return Promise.resolve('Zone updated successfully.')

            }


        } catch (error) {
            setLoading(false);
            console.log("zone creation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating zones.')

        }
    };

    const handleZoneUpdate = (e) => {
        e.preventDefault();



        updateZone(setLoading).then((data) => {

            setZoneName('');
            setZoneDesc('')
            setCost(0)
            setEbcost(0)
            setMaxTickets()
            setEarlyBird(false)
            setEbstart('')
            setEbend('')
            setRefresh(!refresh)
            setShowOnApp(false)
            setShowcapacity(false)
            setShowZoneUpdate(false)
            twit('success', data)
        }).catch((error) => {
            twit('info', error)
        })




    };

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedZones = zones?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }




    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>
                {showZoneUpdate && <Modal open={true} title={'Zone Management'} onCancel={() => {
                    setZoneName('');
                    setZoneDesc('')
                    setCost(0)
                    setMaxTickets()
                    setEarlyBird(false)
                    setEbstart('')
                    setEbend('')
                    setShowOnApp(false)
                    setShowcapacity(false)
                    setEbcost(0)
                    setShowZoneUpdate(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >

                        <Row>
                            <Col>

                                <Form onSubmit={handleZoneUpdate}>

                                    <Card>
                                        <Card.Body>

                                            <Row>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="zoneName">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name</p>
                                                            </div>

                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter zone name"
                                                                value={(zoneName != '') ? zoneName : zoneRowdata.name}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setZoneName(e.target.value);
                                                                    } else {
                                                                        setZoneName(null);
                                                                    }
                                                                }}
                                                                required
                                                                maxLength={10}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="zoneDesc">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Description</p>
                                                            </div>

                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter zone description"
                                                                value={(zoneDesc != '') ? zoneDesc : zoneRowdata.desc}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setZoneDesc(e.target.value);
                                                                    } else {
                                                                        setZoneDesc(null);
                                                                    }
                                                                }}

                                                                required
                                                                maxLength={20}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                {((!(eventInfo?.prive) && (eventInfo?.paid))) && (
                                                    <Col className='my-1' md={12}>
                                                        <Form.Group controlId="cost">

                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Standard Price</p>
                                                                </div>
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        type="number"
                                                                        className=' input-box-events form-control-sm'
                                                                        placeholder="Enter zone price"
                                                                        value={(cost != '') ? cost : zoneRowdata.cost}
                                                                        onChange={(e) => {
                                                                            if (e.target.value !== '') {
                                                                                setCost(e.target.value)
                                                                            } else {
                                                                                setCost(null)
                                                                            }

                                                                        }}
                                                                        // required

                                                                        disabled={!(eventInfo?.ticketPass)}
                                                                    />
                                                                    <InputGroup.Text style={{ fontSize: '10px' }} >
                                                                        {userCurrency ? userCurrency : 'UGX'}
                                                                    </InputGroup.Text>
                                                                </InputGroup>

                                                            </div>


                                                        </Form.Group>
                                                    </Col>
                                                )}




                                            </Row>


                                            {((cost != '') ? cost : zoneRowdata.cost) > 0 ?

                                                <>
                                                    <Row>

                                                        {((!(eventInfo?.prive) && (eventInfo?.paid))) && (
                                                            <Col className='my-1' md={12}>
                                                                <Form.Group controlId="showCapacity">
                                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                                        <Form.Check
                                                                            disabled={!(eventInfo?.ticketPass || eventInfo?.inviteOnly)}
                                                                            type="checkbox"
                                                                            label="Capacity"
                                                                            name="capacity"
                                                                            checked={showcapacity}
                                                                            onChange={() => {

                                                                                setShowcapacity(!showcapacity)
                                                                            }}
                                                                            className="my-0 mx-1 input-box-events form-control-sm"
                                                                        />


                                                                    </div>


                                                                </Form.Group>
                                                            </Col>
                                                        )}


                                                        {showcapacity && (

                                                            <Col className='my-1' md={12}>
                                                                <Form.Group controlId="Max tickets">

                                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                        <div className='mx-2 FormLabels'>
                                                                            <p style={{ margin: 0 }}>Maximum tickets</p>
                                                                        </div>

                                                                        <Form.Control
                                                                            type="number"
                                                                            required
                                                                            min={1}
                                                                            className=' input-box-events form-control-sm'
                                                                            placeholder="Enter Max Number Of Tickets"
                                                                            value={(maxTickets != '') ? maxTickets : zoneRowdata.maxtickets}
                                                                            onChange={(e) => {

                                                                                if (e.target.value !== '') {
                                                                                    setMaxTickets(e.target.value)
                                                                                } else {
                                                                                    setMaxTickets(null)
                                                                                }

                                                                            }}

                                                                        />
                                                                    </div>


                                                                </Form.Group>
                                                            </Col>
                                                        )}

                                                        {showcapacity && (
                                                            <Col className='my-1' md={12}>
                                                                <Form.Group controlId="showOnapp">
                                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            label="Show On Vending Platforms"
                                                                            name="show"
                                                                            checked={showOnApp}
                                                                            onChange={() => {
                                                                                setShowOnApp(!showOnApp)
                                                                            }}
                                                                            className="my-0 mx-1 input-box-events form-control-sm"
                                                                        />


                                                                    </div>


                                                                </Form.Group>
                                                            </Col>
                                                        )}


                                                    </Row>

                                                    {((!(eventInfo?.prive) && (eventInfo?.paid))) && (
                                                        <Form.Group controlId="earlybird">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                                <Form.Check
                                                                    disabled={!(eventInfo?.ticketPass || eventInfo?.inviteOnly)}
                                                                    type="checkbox"
                                                                    label="Configure Early Bird"
                                                                    name="earlybird"
                                                                    checked={earlyBird}
                                                                    onChange={() => {
                                                                        setEarlyBird(!earlyBird)
                                                                    }}
                                                                    className="my-0 mx-1 input-box-events form-control-sm"
                                                                />


                                                            </div>


                                                        </Form.Group>
                                                    )}



                                                    {earlyBird && (
                                                        <div>
                                                            <Row>
                                                                <Col className='my-1' md={12}>
                                                                    <Form.Group controlId="cost">

                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                            <div className='mx-2 FormLabels'>
                                                                                <p style={{ margin: 0 }}>Early Bird Price</p>
                                                                            </div>
                                                                            <InputGroup>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    className=' input-box-events form-control-sm'
                                                                                    placeholder="Enter zone  cost"
                                                                                    value={ebcost}
                                                                                    onChange={(e) => { setEbcost(e.target.value) }}
                                                                                    required
                                                                                />
                                                                                <InputGroup.Text style={{ fontSize: '10px' }} >
                                                                                    {userCurrency ? userCurrency : 'UGX'}
                                                                                </InputGroup.Text>
                                                                            </InputGroup>

                                                                        </div>


                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='my-1' md={12}>
                                                                    <Form.Group controlId="Date">
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                            <div className='mx-2 FormLabels'>
                                                                                <p style={{ margin: 0 }}>Starts</p>
                                                                            </div>

                                                                            <Form.Control
                                                                                className=' input-box-events form-control-sm'
                                                                                type="datetime-local"
                                                                                name="startDate"
                                                                                value={ebstart}
                                                                                onChange={(e) => { setEbstart(e.target.value) }}
                                                                                max={eventInfo?.endtime?.slice(0, 16)}
                                                                                min={getNairobiTime().slice(0, 16)}
                                                                            />
                                                                        </div>

                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='my-1' md={12}>
                                                                    <Form.Group controlId="Date">
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                            <div className='mx-2 FormLabels'>
                                                                                <p style={{ margin: 0 }}>Ends</p>
                                                                            </div>

                                                                            <Form.Control
                                                                                className=' input-box-events form-control-sm'
                                                                                type="datetime-local"
                                                                                name="endDate"
                                                                                value={ebend}
                                                                                onChange={(e) => { setEbend(e.target.value) }}
                                                                                max={eventInfo?.endtime?.slice(0, 16)}
                                                                                min={ebstart}
                                                                            />
                                                                        </div>

                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>



                                                        </div>
                                                    )}


                                                </>
                                                :
                                                <>
                                                </>

                                            }




                                        </Card.Body>
                                    </Card>

                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                            <GrIcon.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Zone
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>



                    </div>


                </Modal>}

                <Row>
                    <Col>
                        <p style={{ fontWeight: 'bold' }}>Zone Management</p>
                        <Form onSubmit={handleSubmit}>

                            <Card>
                                <Card.Body>

                                    <Row>
                                        <Col className='my-1' md={((!(eventInfo?.prive) && (eventInfo?.paid))) ? 4 : 6}>
                                            <Form.Group controlId="zoneName">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Name</p>
                                                    </div>

                                                    <Form.Control
                                                        type="text"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter zone name"
                                                        value={zoneName}
                                                        onChange={handleInputChange}
                                                        required
                                                        maxLength={10}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={((!(eventInfo?.prive) && (eventInfo?.paid))) ? 4 : 6}>
                                            <Form.Group controlId="zoneDesc">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Description</p>
                                                    </div>

                                                    <Form.Control
                                                        type="text"
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter zone description"
                                                        value={zoneDesc}
                                                        onChange={(e) => {
                                                            setZoneDesc(e.target.value)
                                                        }}
                                                        required
                                                        maxLength={20}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        {((!(eventInfo?.prive) && (eventInfo?.paid))) && (
                                            <Col className='my-1' md={4}>
                                                <Form.Group controlId="cost">

                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}>Standard Price</p>
                                                        </div>
                                                        <InputGroup>
                                                            <Form.Control
                                                                disabled={!(eventInfo?.ticketPass)}
                                                                type="number"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter zone price"
                                                                value={cost}
                                                                onChange={(e) => { setCost(e.target.value) }}
                                                            // required
                                                            />

                                                            <InputGroup.Text style={{ fontSize: '10px' }} >
                                                                {userCurrency ? userCurrency : 'UGX'}
                                                            </InputGroup.Text>
                                                        </InputGroup>

                                                    </div>


                                                </Form.Group>
                                            </Col>
                                        )}




                                    </Row>

                                    {cost > 0 ?

                                        <>
                                            {((!(eventInfo?.prive) && (eventInfo?.paid))) && (

                                                <Row>
                                                    <Col className='my-1' md={3}>
                                                        <Form.Group controlId="showCapacity">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                                <Form.Check
                                                                    disabled={!(eventInfo?.ticketPass)}
                                                                    type="checkbox"
                                                                    label="Capacity"
                                                                    name="capacity"
                                                                    checked={showcapacity}
                                                                    onChange={() => {
                                                                        setShowcapacity(!showcapacity)
                                                                    }}
                                                                    className="my-0 mx-1 input-box-events form-control-sm"
                                                                />


                                                            </div>


                                                        </Form.Group>
                                                    </Col>


                                                    {showcapacity && (
                                                        <Col className='my-1' md={6}>
                                                            <Form.Group controlId="Max tickets">

                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                    <div className='mx-2 FormLabels'>
                                                                        <p style={{ margin: 0 }}>Maximum tickets</p>
                                                                    </div>

                                                                    <Form.Control
                                                                        type="number"
                                                                        required
                                                                        min={1}
                                                                        className=' input-box-events form-control-sm'
                                                                        placeholder="Enter Max Number Of Tickets"
                                                                        value={maxTickets}
                                                                        onChange={(e) => { setMaxTickets(e.target.value) }}

                                                                    />
                                                                </div>


                                                            </Form.Group>
                                                        </Col>
                                                    )}

                                                    {showcapacity && (
                                                        <Col className='my-1' md={3}>
                                                            <Form.Group controlId="showOnapp">
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        label="Show On Vending Platforms"
                                                                        name="show"
                                                                        checked={showOnApp}
                                                                        onChange={() => {
                                                                            setShowOnApp(!showOnApp)
                                                                        }}
                                                                        className="my-0 mx-1 input-box-events form-control-sm"
                                                                    />


                                                                </div>


                                                            </Form.Group>
                                                        </Col>
                                                    )}

                                                </Row>
                                            )}



                                            {((!(eventInfo?.prive) && (eventInfo?.paid))) && (
                                                <Form.Group controlId="earlybird">
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                        <Form.Check
                                                            disabled={!(eventInfo?.ticketPass)}
                                                            type="checkbox"
                                                            label="Configure Early Bird"
                                                            name="earlybird"
                                                            checked={earlyBird}
                                                            onChange={() => {
                                                                setEarlyBird(!earlyBird)
                                                            }}
                                                            className="my-0 mx-1 input-box-events form-control-sm"
                                                        />


                                                    </div>


                                                </Form.Group>
                                            )}


                                            {earlyBird && (
                                                <div>
                                                    <Row>
                                                        <Col className='my-1' md={4}>
                                                            <Form.Group controlId="cost">

                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                    <div className='mx-2 FormLabels'>
                                                                        <p style={{ margin: 0 }}>Early Bird Price</p>
                                                                    </div>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            type="number"
                                                                            className=' input-box-events form-control-sm'
                                                                            placeholder="Enter zone  cost"
                                                                            value={ebcost}
                                                                            onChange={(e) => { setEbcost(e.target.value) }}
                                                                            required
                                                                        />

                                                                        <InputGroup.Text style={{ fontSize: '10px' }} >
                                                                            {userCurrency ? userCurrency : 'UGX'}
                                                                        </InputGroup.Text>
                                                                    </InputGroup>

                                                                </div>


                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='my-1' md={4}>
                                                            <Form.Group controlId="Date">
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                    <div className='mx-2 FormLabels'>
                                                                        <p style={{ margin: 0 }}>Starts</p>
                                                                    </div>

                                                                    <Form.Control
                                                                        className=' input-box-events form-control-sm'
                                                                        type="datetime-local"
                                                                        name="startDate"
                                                                        value={ebstart}
                                                                        onChange={(e) => { setEbstart(e.target.value) }}
                                                                        max={eventInfo?.endtime?.slice(0, 16)}
                                                                        min={getNairobiTime().slice(0, 16)}
                                                                    />
                                                                </div>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='my-1' md={4}>
                                                            <Form.Group controlId="Date">
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                    <div className='mx-2 FormLabels'>
                                                                        <p style={{ margin: 0 }}>Ends</p>
                                                                    </div>

                                                                    <Form.Control
                                                                        className=' input-box-events form-control-sm'
                                                                        type="datetime-local"
                                                                        name="endDate"
                                                                        value={ebend}
                                                                        onChange={(e) => { setEbend(e.target.value) }}
                                                                        max={eventInfo?.endtime?.slice(0, 16)}
                                                                        min={ebstart}
                                                                    />
                                                                </div>

                                                            </Form.Group>
                                                        </Col>
                                                    </Row>



                                                </div>
                                            )}


                                        </>
                                        :
                                        <>
                                        </>

                                    }





                                </Card.Body>
                            </Card>

                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add Zone
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <p style={{ fontWeight: 'bold' }}> Event Zones </p>
                    {(eventInfo?.paid) && !(eventInfo?.prive) && (
                        <div className='table-responsive'>
                            {paginatedZones?.length > 0 ?
                                <Table striped hover style={{ cursor: "pointer" }}>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Zone</th>
                                            <th>Status</th>
                                            <th>Standard  {`(${userCurrency ? userCurrency : 'UGX'})`} </th>
                                            <th>Early Bird  {`(${userCurrency ? userCurrency : 'UGX'})`}</th>
                                            <th>EB Starts</th>
                                            <th>EB Ends</th>
                                            {(eventInfo?.gateSale) && (<th>POS Sales</th>)}
                                            {(eventInfo?.onlineSale) && (<th>Ticket Sales</th>)}
                                            <th>Operations</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedZones?.map((data, index) => (
                                            <tr key={index} onClick={() => { }}>
                                                <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                                <td>{data.name}</td>
                                                <td>{(eventInfo.endtime < getNairobiTime()) ? <FcExpired title='Expired' style={{ height: "15px", width: "15px", color: 'red' }} /> : !(data.isactive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                                <td>{formatPrice(data.cost)}</td>
                                                <td>{formatPrice(data.ebcost)}</td>
                                                <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.ebstarts)}</td>
                                                <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.ebends)}</td>
                                                {(eventInfo?.gateSale) && (<td>{zoneTicketData?.filter((stats) => { return stats.name == data.name })[0]?.platform?.filter((pt) => pt?.name == 'POS')[0]?.sold}</td>)}
                                                {(eventInfo?.onlineSale) && (<td>{zoneTicketData?.filter((stats) => { return stats.name == data.name })[0]?.platform?.filter((pt) => pt?.name == 'ONLINE')[0]?.sold}</td>)}
                                                <td>
                                                    <GrIcon.GrUploadOption className='mx-1' title='Update Zone' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                        setZoneName('')
                                                        setZoneDesc('')
                                                        setCost('')
                                                        setMaxTickets('')
                                                        setShowcapacity(data.maxtickets ? true : false)
                                                        setEbcost(data.ebcost)
                                                        setEarlyBird((data.ebcost && data.ebends && data.ebstarts))
                                                        setEbend('')
                                                        setEbstart(data?.ebstarts?.slice(0, 16))
                                                        setEbend(data?.ebends?.slice(0, 16))
                                                        setShowOnApp(data.visibleOnApp)
                                                        setZoneRowdata(data)
                                                        setShowZoneUpdate(true)

                                                    }} />

                                                    {!(eventInfo.endtime < getNairobiTime()) && (
                                                        <>
                                                            {data.isactive ? (
                                                                <IoIosRemoveCircleOutline className='mx-1' title='Deactivate Zone' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                                    revokeActivateZone(setLoading, data.zid).then((data) => {
                                                                        setRefresh(!refresh)
                                                                        twit('success', data)
                                                                    }).catch((error) => {
                                                                        twit('info', error)
                                                                    })
                                                                }} />
                                                            ) : (
                                                                <IoIosAddCircleOutline className='mx-1' title='Activate Zone' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                                    revokeActivateZone(setLoading, data.zid).then((data) => {
                                                                        setRefresh(!refresh)
                                                                        twit('success', data)
                                                                    }).catch((error) => {
                                                                        twit('info', error)
                                                                    })
                                                                }} />
                                                            )}
                                                        </>
                                                    )}


                                                </td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                :

                                <p>No zones added.</p>

                            }
                            {zones?.length > 0 && (
                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={zones?.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            )}

                        </div>
                    )}

                    {((eventInfo?.prive) || (!(eventInfo?.prive) && !(eventInfo?.paid))) && (
                        <div className='table-responsive'>
                            {zones?.length > 0 ?
                                <Table striped hover style={{ cursor: "pointer" }}>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Zone</th>
                                            <th>Description</th>
                                            <th>Operations</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedZones?.map((data, index) => (
                                            <tr key={index} onClick={() => { }}>
                                                <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                                <td>{data.name}</td>
                                                <td>{data.desc}</td>
                                                <td>{!(data.name == 'General Admission') && (<GrIcon.GrUploadOption className='mx-1' title='Update Zone' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setZoneName('')
                                                    setZoneDesc('')
                                                    setCost('')
                                                    setMaxTickets('')
                                                    setShowcapacity(data.maxtickets ? true : false)
                                                    setEbcost(data.ebcost)
                                                    setEarlyBird((data.ebcost && data.ebends && data.ebstarts))
                                                    setEbend('')
                                                    setEbstart(data?.ebstarts?.slice(0, 16))
                                                    setEbend(data?.ebend?.slice(0, 16))
                                                    setShowOnApp(data.visibleOnApp)
                                                    setZoneRowdata(data)
                                                    setShowZoneUpdate(true)

                                                }} />)}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                :

                                <p>No zones added.</p>

                            }
                            {zones?.length > 0 && (
                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={zones?.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            )}

                        </div>
                    )}

                </Row>
            </Container>
        );
    }

}

export default EventZoneForm;
