import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import { formatPrice, getNairobiTime, getNairobiTimeWithParam, getformattedDate } from '../../Utilities/utilities'
import Loader from '../Loader';
import { fetchData } from '../../Utilities/apiRequests';
import Pagination from '../Pagination';
import { costPerCredit } from '../../Utilities/globals';

const CreditsSummary = () => {


    const [starts, setStarts] = useState();
    const [ends, setEnds] = useState();
    const [loading, setLoading] = useState(false);
    const [baccounts, setBaccounts] = useState([]);
    const [activeAccount, setActiveAccount] = useState();
    const [billsData, setBillsData] = useState();
    const [creditsData, setCreditsData] = useState();
    const [totalBills, setTotalBills] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchData(`/api/billing/accounts`, setLoading).then((response) => {

            setBaccounts(response.data.accounts)
            setActiveAccount(response.data.accounts.filter((data) => {
                return data.name == 'Primary'
            })[0]?.aid)

        }).catch((error) => {
            console.log('accounts loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading accounts')
        })

    }, []);

    useEffect(() => {

        if(activeAccount){
            fetchData(`/api/billing/accounts/bills/${activeAccount}`, setLoading).then((response) => {

            setBillsData(response.data.bills)
            

        }).catch((error) => {
            console.log('bills loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading bills')
        })

        fetchData(`/api/billing/accounts/credits/${activeAccount}`, setLoading).then((response) => {

            setCreditsData(response.data.credits)
            
        }).catch((error) => {
            console.log('credits loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading credits')
        })



        }
        

    }, [activeAccount]);



   
    

    useEffect(() => {

        let billsTotal = 0

        if(billsData){
            for(const bill of billsData){
                billsTotal += bill.debit
            }

            setTotalBills(billsTotal)
        }
        

    }, [billsData]);


    useEffect(() => {

        let creditsTotal = 0

        if(creditsData){
            for(const credit of creditsData){
                creditsTotal += credit.credit
            }

            setTotalCredits(creditsTotal)
        }
        

    }, [creditsData]);

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedCredits = creditsData?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }






    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>
                <Row className=' bills-summary-header'>
                    <Col md={6} >
                        <p className='my-1' style={{ fontSize: '18px', fontWeight: 'bold' }}>PinniTAGS Credits summary</p>
                        <Form.Group className='my-3' controlId="account">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                <Form.Select
                                    disabled
                                    name="account"
                                    onChange={(e) => {

                                    }}
                                    className='form-selector'>

                                    <option className='input-box' key={1} value={''}>{'Primary'}</option>
                                    {baccounts.map((data) => {
                                        return <option className='input-box' key={data?.aid} value={data?.aid}>{data?.name}</option>
                                    })}


                                </Form.Select>
                            </div>

                        </Form.Group>
                    </Col>
                </Row>

                <Row className='my-2' >
                    <Col md={6} >
                        <p className='mb-0 text-muted' style={{ fontSize: '15px',fontWeight: 'bold' }}>Total Amount Remaining</p>
                        
                        <p className='my-2 ' style={{ fontSize: '20px', fontWeight: 'bold' }}>{formatPrice((Number(totalCredits)-Number(totalBills)).toFixed(2))}</p>
                       

                    </Col>
                    <Col md={6} className='bill-summary-dates'>
                        <p className='mb-0 text-muted' style={{ fontSize: '15px',fontWeight: 'bold' }}>Total Amount Used</p>
                        <p className='my-2' style={{ fontSize: '20px', fontWeight: 'bold' }}>{formatPrice(totalBills.toFixed(2))}</p>
                    </Col>
                </Row>

                <Row  style={{ borderTop: '1px solid gray' }}>
                    <p className='mb-0 mt-2 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>Credits</p>
                    <div className='table-responsive'>
                        {paginatedCredits?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th style={{ textAlign: "right" }}>#Credits</th>
                                        <th>Date</th>
                                        

                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedCredits?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.credit)}</td>
                                            <td style={{fontSize:'10px',whiteSpace:'nowrap'}}>{getformattedDate(data.tdate)}</td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            
                            :

                            <p>No transactions.</p>

                        }

                        {creditsData?.length > 0 &&(
                            <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={creditsData?.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                        ) }

                    </div>
                </Row>
                <Row  style={{ borderTop: '1px solid gray' }}>
                    <Col className='mt-3' md={8} style={{textAlign:'end'}} >
                        <p className='mb-0' style={{ fontSize: '18px', fontWeight: 'bold' }}>Estimated Credits Total:</p>
                    </Col>
                    <Col className='mt-3' md={4} style={{textAlign:'center'}} >
                        <p className='mb-0' style={{ fontSize: '20px', fontWeight: 'bold',borderBottom: '4px dotted #000' }}>{formatPrice(totalCredits.toFixed(2))}</p>
                    </Col>
                </Row>
                <div className='my-2' style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%', paddingLeft: '10px' }}>

                            <p className='text-muted' style={{ margin: 0 }}>1 Credit = {costPerCredit} UGX</p>

                        </div>
            </Container>
        )
    }
}

export default CreditsSummary
