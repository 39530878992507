import React from 'react'
import { Table, Form } from 'react-bootstrap'
import Loader from '../Loader';
import { useState } from 'react';
import useFetch from '../../hooks/hooks/getData';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import BreadcrumbNav from '../BreadCrumb';
import { useEffect } from 'react';

const IdentityPass = () => {


    const [loading, setLoading] = useState(false);
    const [operationsModal, setOperationsModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedevent, setSelectedevent] = useState();
    const [role, setRole] = useState();
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [selectedZones, setSelectedZones] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [formDataPass, setFormDataPass] = useState({
        FullName: '',
        DateOfBirth: '',
        Email: '',
        Phone: '',
        face: '',
        tagid: '',
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDataPass({
            ...formDataPass,
            [name]: value,
        });
    };


    const rid = sessionStorage.getItem('rid');
    const userInfo = useFetch(`/api/products/registered/people/${rid}`, setLoading)
    const userevents =  useFetch("/api/events/", setLoading)
    const eventZones =  useFetch(`/api/events/zone/${selectedevent}`, setLoading, refresh)
    const eventCoupons = useFetch(`/api/events/coupon/${selectedevent}`, setLoading, refresh)
    const navigate = useNavigate();


    

    const toggleRowSelection = (data) => {
        const isSelected = selectedRows.includes(data);
        if (isSelected) {
            setSelectedRows(selectedRows.filter((row) => row !== data));
        } else {
            setSelectedRows([...selectedRows, data]);
        }
    };

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(userInfo?.data?.data || []);
        }
        setSelectAll(!selectAll);
    };

    

  const handleCouponCheckboxChange = (coupon) => {
    if (selectedCoupons.includes(coupon)) {
      setSelectedCoupons(selectedCoupons.filter((item) => item !== coupon));
    } else {
      setSelectedCoupons([...selectedCoupons, coupon]);
    }
  };

  const handleZoneCheckboxChange = (zone) => {
    if (selectedZones.includes(zone)) {
      setSelectedZones(selectedZones.filter((item) => item !== zone));
    } else {
      setSelectedZones([...selectedZones, zone]);
    }
  };

  const Hids = selectedRows.map((hid)=>{
     return hid.HumanIdentification.hid
  })


  const assignEventZones = async (setLoading) => {
    const token = sessionStorage.getItem('token');
    const postData = {
        hids:Hids,
        zids:selectedZones,
        role
    };
    const Url = '/api/events/zone/add'
    try {
        setLoading(true);
        const response = await axios.post(Url, JSON.stringify(postData), {
            headers: {
                'Content-Type': 'application/json', Authorization: `Bearer ${token}`
            }

        });

        if (response.status === 201) {
            setLoading(false);
            return Promise.resolve('Zones assigned successfully.')
        }


    } catch (err) {
        setLoading(false);
        console.log("Zone assignment Error:", err)
        return Promise.reject(err.response.data.message?err.response.data.message:'An error occurred while assigning event zones')

    }
};


const assignEventCoupons = async (setLoading) => {
    const token = sessionStorage.getItem('token');
    const postData = {
        hids:Hids,
        ecids:selectedCoupons,
    };
    const Url = '/api/events/coupon/add'
    try {
        setLoading(true);
        const response = await axios.post(Url, JSON.stringify(postData), {
            headers: {
                'Content-Type': 'application/json', Authorization: `Bearer ${token}`
            }

        });

        if (response.status === 201) {
            setLoading(false);
            return Promise.resolve('Coupons assigned successfully.')
        }


    } catch (err) {
        setLoading(false);
        console.log("Coupons assignment Error:", err)
        return Promise.reject(err.response.data.message?err.response.data.message:'An error occurred while assigning event coupons')

    }
};



 const assignPass = async ()=>{

    try {

        await assignEventZones(setLoading)
        if(!(selectedCoupons.length==0)){
            await assignEventCoupons(setLoading)
        }
       
        return Promise.resolve('Pass assigned successfully.')
    } catch (error) {
        setLoading(false);
        console.log("Pass assignment error:", error)
        return Promise.reject(error? error:'An error occurred while assigning event pass')
    }
 }





  const handlePassAssignmentSubmit = (e) => {
    e.preventDefault();

    if (!selectedevent || selectedZones.length==0 || !role) {
            twit('info', `Please select atleast one event and zone and also add  user role.`);

        } else {

            assignPass(setLoading).then((data) => {
                setRefresh(!refresh)
                closeOperationsModal()
                twit('success', data)

            }).catch((error) => {
                console.log('pass assignment error',error)
                twit('info', error)
            })

        }


};



    const closeOperationsModal = () => {
        setOperationsModal(false)
    }


    


    if (loading) {
        return (<Loader />);
    } else {
        return (
            <div style={{ fontSize: '12px' }} >


                {operationsModal && <Modal open={true} title={'Operations'} onCancel={closeOperationsModal}
                    footer={[


                    ]}

                    className="allmodals"
                >


                    <div style={{fontSize:'12px'}}>
                        <Form onSubmit={handlePassAssignmentSubmit}>


                            <Form.Group controlId="userEvent">
                                <Form.Label  >
                                    Available Events
                                    <span style={{ color: "red" }}> *</span>
                                </Form.Label>

                                <Form.Select

                                    name="userEvent"
                                    onChange={(e) => { 
                                        setSelectedevent(e.target.value)
                                        setRefresh(!refresh)
                                    
                                    }}
                                    className='form-selector'>
                                    <option className='input-box' key={'s'} value=''>Select Event</option>
                                    {userevents?.data?.userEvents.map((data) => {
                                        return <option className='input-box' key={data.eid} value={data.eid}>{data.name}</option>
                                    })}


                                </Form.Select>

                            </Form.Group>

                            <Form.Group controlId="userRole">
                                <Form.Label>User Role</Form.Label>
                                <Form.Control
                                    type="text"
                                    className=' input-box-identity form-control-sm'
                                    placeholder="Enter user role."
                                    value={role}
                                    onChange={(e)=>{setRole(e.target.value)}}
                                    required
                                />
                            </Form.Group>


                            {(eventZones?.data?.zones) && (
                                <Form.Group controlId="userEvent">
                                    <Form.Label  >
                                        Event Zones
                                        <span style={{ color: "red" }}> *</span>
                                    </Form.Label>

                                    {eventZones?.data?.zones.map((zone, index) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            id={index}
                                            label={zone.name}
                                            checked={selectedZones.includes(zone.zid)}
                                            onChange={() => handleZoneCheckboxChange(zone.zid)}
                                        />
                                    ))}

                                </Form.Group>
                            )}

                            {(eventCoupons?.data?.coupons) && (
                                <Form.Group controlId="userEvent">
                                    <Form.Label  >
                                        Event Coupons
                                        <span style={{ color: "red" }}> *</span>
                                    </Form.Label>

                                    {eventCoupons?.data?.coupons.map((coupon, index) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            id={index}
                                            label={coupon.name}
                                            checked={selectedCoupons.includes(coupon.ecid)}
                                            onChange={() => handleCouponCheckboxChange(coupon.ecid)}
                                        />
                                    ))}
                                </Form.Group>
                            )}

                           


                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#569f51",fontSize:"12px" }} variant="primary" type="primary" htmlType='submit'>
                                    Assign Pass
                                </Button>
                            </div>
                        </Form>






                    </div>


                </Modal>}

                <div className='d-flex flex-row justify-content-end w-100'>
                    {selectedRows.length > 0 && (
                        <div>
                            <Button onClick={() => { setOperationsModal(true)}} title='Add event pass to selected humans' style={{ backgroundColor: "#569f51", fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
                                Assign Event pass
                            </Button>

                        </div>
                    )}





                </div>

                <div className='table-responsive' >
                    <Table striped hover style={{ cursor: 'pointer' }}>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={toggleSelectAll}
                                    />
                                </th>
                                <th>No.</th>
                                <th>Name</th>
                                <th>Level</th>
                                <th>Registration Status</th>
                                <th> Card Status</th>
                                <th>Card State</th>
                                <th>Card Type</th>

                            </tr>
                        </thead>
                        <tbody>
                            {userInfo?.data?.data.map((data, index) => (
                                <tr key={index} >
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedRows.includes(data)}
                                            onChange={() => toggleRowSelection(data)}
                                        />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{data.HumanIdentification.fhname}  {data.HumanIdentification.lhname}</td>
                                    <td>{data.HumanIdentification.Position}</td>
                                    <td>{data.HumanIdentification.TagStatus?.sname}</td>
                                    <td>{data.HumanIdentification.IdStatus?.sname}</td>
                                    <td>{data.HumanIdentification.IdState?.sname}</td>
                                    <td>{data.HumanIdentification.CardType?.ctype}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

            </div>
        )
    }
};

export default IdentityPass;
