import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as BiIcons from 'react-icons/bi';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import BreadcrumbNav from '../BreadCrumb';



const CollectionsOutlet = () => {



    const [isopen, SetIsopen] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [currentPath,setCurrentPath] = useState('')
    const path = useLocation().pathname
    const navigate = useNavigate();

    useEffect(() => {

        setCurrentPath(path)

    }, [useLocation().pathname]);

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
          if(window.innerWidth<=767){
            SetIsopen(true)
          }
        };
    
        window.addEventListener('resize', handleResize);
    
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [window.innerWidth]);


      const navItems = [
        {name:'Transactions',icon:<GrIcons.GrTransaction  style={{ height: "20px", width: "20px",marginRight:'10px' }} />,path:'/collections',isActive:(currentPath=='/collections')},
        {name:'Events',icon:<MdIcons.MdOutlineEventAvailable  style={{ height: "20px", width: "20px",marginRight:'10px' }} />,path:'/collections/events',isActive:(currentPath=='/collections/events')},
        {name:'Donations',icon:<FaIcons.FaDonate  style={{ height: "20px", width: "20px",marginRight:'10px' }} />,path:'/collections/donations',isActive:(currentPath=='/collections/donations')},
        {name:'Cash Outs',icon:<BsIcons.BsCashCoin  style={{ height: "20px", width: "20px",marginRight:'10px' }} />,path:'/collections/cashOuts',isActive:(currentPath=='/collections/cashOuts')},
        {name:'Cash Outs Requests',icon:<MdIcons.MdOutlineAccessTime  style={{ height: "20px", width: "20px",marginRight:'10px' }} />,path:'/collections/requests',isActive:(currentPath=='/collections/requests')},
    ]
    


    return (


        <Container className="meetingsdash-container mt-2 mb-3" >
            <BreadcrumbNav />
            <Card style={{ minHeight: '100vh' }}>
                <Card.Header>
                    <Row>
                        <Col style={{ borderRight: '1px solid gray' }} xs={5}  md={isopen ? 3 : 2} xl={isopen ? 2 : 1} >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                                <p style={{ margin: '0px', fontWeight: 'bold', fontSize: '14px' }}><BsIcons.BsCreditCardFill className='mx-2' style={{ height: "15px", width: "15px" }} />  {isopen && 'Collection'}  </p>
                              {screenWidth>767&&(
                                <>
                                {isopen ? <FaIcons.FaArrowLeft style={{ height: "10px", width: "10px" }} onClick={() => { SetIsopen(false) }} /> : <FaIcons.FaArrowRight style={{ height: "10px", width: "10px" }} onClick={() => { SetIsopen(true) }} />}
                                </>
                                 
                              )} 
                            </div>

                        </Col>
                        <Col xs={7} md={isopen ? 9 : 10} xl={isopen ? 10 : 11}>
                            <div>
                                <p style={{margin:'0px'}}>{navItems.filter((data)=>{
                                    return data.isActive
                                })[0]?.name}</p>
                            </div>
                            
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>

                    <Row >
                        <Col  md={isopen ? 3 : 2} xl={isopen ? 2 : 1} className='billing-sidebar-column'  >
                            <div className='billing-sidebar'>
                                {navItems.map((data, index) => (
                                    <div key={index} style={{cursor:'pointer'}} className={data.isActive?'billing-sidebar-item-active':'billing-sidebar-item'} onClick={()=>{
                                        navigate(data.path)
                                    }} >
                                        {data.icon}
                                        {isopen && (
                                            <p className='mx-1' style={{ margin: '0px',cursor:'pointer' }}>{data.name}</p>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </Col>
                        <Col md={isopen ? 9 : 10} xl={isopen ? 10 : 11}>
                            <Outlet />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        </Container>

    );
};

export default CollectionsOutlet;



