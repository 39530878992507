import axios from '../api/axios';


const useCategory = async() => {
    const  CategoryUrl = '/api/categories'


        try {
          const response = await axios.get(CategoryUrl);
           
           return response.data.data
           
        } catch (err) {
           console.log(err)
        }

      
       
}

export default useCategory;