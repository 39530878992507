import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import SwitchSelector from 'react-switch-selector';
import axios from 'axios';
import { twit } from './Notificationpopout';
import usePost from '../hooks/hooks/postData';
import mtnLogo from './images/mtnMomo.png';
import airtelLogo from './images/airtelLogo.png';
import visaLogo from './images/visaPaymentLogos.png';
import PhoneInput from 'react-phone-input-2';
import logo from './images/logo.jpg'
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import LoaderButton from './LoaderButton';




const PaymentCard = ({ orderId, close }) => {
    const [phoneNumberType, setPhoneNumberType] = useState('MTN');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);



    

    // const payMobile = usePost(`/api/products/payment/mobile/money/${orderId}`, setLoading);
    const payVisa = usePost(`/api/products/payment/flutterwave/${orderId}`, setLoading);

    const payMobile = usePost(`/api/products/payments`, setLoading);
    const navigate = useNavigate()












    const handlePayment = () => {
        // Perform the payment initiation logic here
        if (phoneNumberType === "MTN") {
            if (phoneNumber.length < 10) {
                twit("info", "Enter a valid phone number.")
            } else {


                const response = payMobile.post({ amount: '500', currency: 'UGX' });
                response.then((data) => {

                    if (data?.type === "data") {
                        if (data?.data?.paymentUrl) {
                            if (close) {
                                close();
                            }
                            twit("success", 'Payment initiated.');

                            window.location.href = data?.data?.paymentUrl;

                        }
                    } else {

                        twit("error", "An error occured while processing payment.");
                    }


                })

            }





        } else if (phoneNumberType === "Visa") {
            const response = payVisa.post({});
            response.then((data) => {

                if (data?.type === "data") {
                    if (data?.data?.data) {
                        if (close) {
                            close();
                        }
                        twit("success", "Payment Initiated.")
                        window.location.href = data?.data?.data;

                    }
                } else {

                    twit("error", "An error occured while processing payment.");
                }


            })
        } else {

            if (phoneNumber.length < 10) {
                twit("info", "Enter a valid phone number.")
            } else {
                const response = payMobile.post({ phone: phoneNumber, telcom: "Airtel" });
                response.then((data) => {

                    if (data?.type === "data") {
                        if (data?.data?.data) {
                            if (close) {
                                close();
                            }
                            twit("success", data?.data?.data?.message)

                            window.location.href = data?.data?.data?.meta?.authorization?.redirect;

                        }
                    } else {

                        twit("error", "An error occured while processing payment.");
                    }


                })
            }
        }






    };







    const options = [
        { label: 'MTN', value: 'MTN', selectedBackgroundColor: "#569f51", },
        { label: 'Airtel', value: 'Airtel', selectedBackgroundColor: "#569f51", },
        { label: 'Visa', value: 'Visa', selectedBackgroundColor: "#569f51", }
    ];








    // if (loading) {
    //     return (<Loader />)
    // } else {





    return (
        <Card className='border-0 payment-card-container' style={{ fontSize: "12px", borderRadius: "0px" }}>
            <div className='my-2'>
                <img src={logo} className='email-verification-image' />
            </div>

            <Card.Body style={{ paddingBottom: "0px" }}>
                {/* <Card.Title>Payment</Card.Title> */}
                <Form >
                    <Form.Group controlId="phoneNumberType">
                        <Form.Label>Payment Mode</Form.Label>
                        <SwitchSelector
                            options={options}
                            onChange={(value) => setPhoneNumberType(value)}
                            initialSelectedIndex={-1}
                            wrapperBorderRadius={0}
                            fontSize={12}
                            optionBorderRadius={4}
                        />

                        <div className='my-3' style={{ height: "10vh" }} >
                            {phoneNumberType === "MTN" && (
                                <img src={mtnLogo} className='payment-card-mtn-image' />
                            )}
                            {phoneNumberType === "Airtel" && (
                                <img src={airtelLogo} className=' payment-card-mtn-image mt-3' />
                            )}
                            {phoneNumberType === "Visa" && (
                                <img src={visaLogo} className='payment-card-visa-image mt-2 mb-2' />
                            )}

                        </div>




                    </Form.Group>

                    {phoneNumberType !== 'Visa' && (
                        <Form.Group controlId="phoneNumber">

                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                                country={'ug'}
                                value={phoneNumber}
                                localization={{ ug: 'Uganda' }}
                                onChange={(e) => {

                                    setPhoneNumber(e);
                                }}

                                disableDropdown
                                countryCodeEditable={false}
                                masks={{ ug: '... ... ...' }}



                                inputProps={{
                                    name: 'phone',
                                    required: true,

                                }}


                                inputStyle={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px"
                                }}

                            />

                        </Form.Group>
                    )}

                    <div className='mt-2' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        {/* <Button
                                style={{ backgroundColor: "#569f51" }}
                                title='Pay for the items'
                                onClick={() => {

                                    handlePayment();


                                }}

                                className='identity-form-button ' variant="default" type="button">

                                <span style={{ fontWeight: "bold", color: "white" }}>Pay Now </span>
                            </Button> */}
                        <LoaderButton onClick={() => {
                            handlePayment();
                        }} loading={loading}
                            variant={"primary"}
                            label={loading ? "Processing" : "Pay Now"}
                            style={{ backgroundColor: "#569f51", color: "white", fontSize: "10px" }}
                        />


                    </div>
                    {/* <div style={{textAlign:"center"}}>
                        <p className="text-muted" style={{ fontSize: "8px", marginTop: "9px" }}>
                Copright &copy; 2023 PinniTAGS is a product of <a style={{color:"green"}} href="https://pinnisoft.com/">PinniSOFT</a> <br />
                All rights reserved.
              </p>
                        </div> */}

                </Form>
            </Card.Body>
        </Card>
    );
};
// };

export default PaymentCard;
